import React, {useRef, useCallback} from 'react';
import {XIcon} from "../../../../assets/icons/XIcon";
import {UploadIcon} from "../../../../assets/icons/UploadIcon";
import {Engine} from "@rendley/sdk";
import './Panel.styles.scss';
import Box from "@mui/material/Box";
import Button from '../../../../components/Button/Button';
import {EditorUploadButton} from "../../../../../pages/NewHome/HomeButtons";
import {DialogContent} from "@mui/material";
import UploadContentCard from "../../../../../pages/NewHome/UploadContentCard";
import Dialog from "@mui/material/Dialog";


interface PanelProps {
    isMedia?: boolean;
    onClose?: () => void;
    children?: React.ReactNode;
    projectId?: string;
}

const boxStyle = {
    display: "flex",
    flexDirection: "row",
    height: "35px",
    padding: "10px 20px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "6px",
    backgroundColor: "#2B6BFD",
    cursor: "pointer",
    // Set hovered state
    "&:hover": {
        background: "linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #2B6BFD",
    },
    // Set focused state
    "&:focus": {
        background: "linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #2B6BFD"
    },
    // Set disabled state
    "&:disabled": {
        background: "#2B6BFD",
        opacity: 0.3,
        cursor: "not-allowed",
    }

}

const Panel: React.FC<PanelProps> = ({isMedia = false, projectId, onClose, children}) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [showUploadCard, setShowUploadCard] = React.useState(false);

    const handleClickUpload = useCallback(() => {
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
            fileInputRef.current.click();
        }
    }, []);

    const handleUpload = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if (!files) {
            return;
        }

        const library = Engine.getInstance().getLibrary();
        const promises: Promise<string | null>[] = [];

        for (let i = 0; i < files.length; i++) {
            promises.push(library.addMedia(files[i]));
        }

        try {
            await Promise.all(promises);
        } catch (e) {
            console.log(e);
        }
    }, []);

    return (
        <div className="panel">
            <Dialog
                open={showUploadCard}
                onClose={() => setShowUploadCard(false)}
                aria-labelledby="form-dialog-title"
                maxWidth={false}
            >
                <DialogContent sx={{
                    borderRadius: '30px',
                    display: 'flex',        // Enable flexbox
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center',     // Center vertically
                    padding: "0px 0px 0px 0px",
                }}>
                    <UploadContentCard onClose={() => setShowUploadCard(false)}
                                       currentProjectId={projectId} allowImageUpload={true} editId={""}/>
                </DialogContent>
            </Dialog>
            <div className="panel__header">
                <div>
                    {isMedia && (
                        <EditorUploadButton onClick={() => setShowUploadCard(!showUploadCard)}/>
                    )}
                </div>
                <Button variant="default" onClick={onClose}>
                    <XIcon/>
                </Button>
            </div>

            {children}

        </div>
    );
};

export default Panel;