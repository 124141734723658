import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import {AdaptRecordingMode, LibrettoPlan, LibrettoRecordingMode, OppositeRecordingMode} from "../../utils/utils";
import {collection, doc, onSnapshot, query, updateDoc, where} from "firebase/firestore";
import {db} from "../../Firebase";
import {kRoomStateCollectionName, RoomName} from "../../utils/CollectionConstants";

const ToggleContainerStyle = {
    display: "flex",
    backgroundColor: "#2B6BFD",
    borderRadius: "5px",
    padding: "2.2px 2px 2px 2px",
    cursor: "pointer",
    width: "fit-content",
}

const ToggleOptionStyle = {
    padding: "8px 16px",
    borderRadius: "5px",
    fontSize: "14px",
    transition: "background-color 0.3s ease",
    color: "white",
}

const ActiveToggleOptionStyle = {
    padding: "8px 16px",
    borderRadius: "5px",
    fontSize: "14px",
    transition: "background-color 0.3s ease",
    backgroundColor: "white",
    color: "black",
}

const effectiveStyle = (active) => active ? ActiveToggleOptionStyle : ToggleOptionStyle;

const textStyle = {
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: "0.14px",
    color: '#eee',
}

const selectedStyle = {
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: "0.14px",
    color: '#1a1a1a',
}

const effectiveTextStyle = (active) => active ? selectedStyle : textStyle;

const RecordingModeToggle = ({roomName, userPlan, recordingMode}) => {

    const isVideoRecordingDisabled = userPlan === LibrettoPlan.AppSumoTier1;

    const handleState = async (state) => {
        if (roomName === '') {
            return;
        }

        state = AdaptRecordingMode(state);

        const entityRef = doc(db, kRoomStateCollectionName, roomName);
        try {
            await updateDoc(entityRef, {recordingType: state});
        } catch (error) {
            console.error('Error updating recording type for room: ', error);
        }
    }

    const handleToggleClick = async (recordingMode) => {
        if (isVideoRecordingDisabled && OppositeRecordingMode(recordingMode) === LibrettoRecordingMode.Video) {
            return;
        }
        await handleState(OppositeRecordingMode(recordingMode));
    }

    return (
        <Box style={ToggleContainerStyle} onClick={() => handleToggleClick(recordingMode)}>
            <Box active={recordingMode === LibrettoRecordingMode.Video} style={effectiveStyle(recordingMode === LibrettoRecordingMode.Video)}>
                <Box style={effectiveTextStyle(recordingMode === LibrettoRecordingMode.Video)}>
                    Video & audio
                </Box>
            </Box>
            <Box active={recordingMode === LibrettoRecordingMode.Audio} style={effectiveStyle(recordingMode === LibrettoRecordingMode.Audio)}>
                <Box style={effectiveTextStyle(recordingMode === LibrettoRecordingMode.Audio)}>
                    Audio
                </Box>
            </Box>
        </Box>
    );
};

export default RecordingModeToggle;
