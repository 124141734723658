import RecordRTC from 'recordrtc';
import FakeStreamFactory from "./MediaRecorder/FakeStreamFactory";
import CanvasStreamProxy from "./MediaRecorder/CanvasStreamProxy";

class CustomRecordRTC {
    constructor(mediaStream, options) {
        this.fakeStreamFactory = new FakeStreamFactory();
        this.canvasStreamProxy = new CanvasStreamProxy();
        this.recorder = null;
        this.targetStream = null;
        this.options = options;
        this.inputStream = mediaStream;
    }

    async CreateTargetStream(stream) {
        stream = stream || new MediaStream();

        if (!(stream instanceof MediaStream)) {
            return Promise.reject(new Error('Invalid arugment'));
        }

        if (!stream.getTracks().filter((track) => {
            return track.kind === 'video';
        }).length) {
            const option = {
                videoType: 'black'
            };
            const fakeVideoTrack = this.fakeStreamFactory.getFakeVideoTrack(option);
            stream.addTrack(fakeVideoTrack);
        }

        const videoTrack = this.canvasStreamProxy.createCanvasStream(stream);
        let audioTrack;

        const resultStream = new MediaStream();
        resultStream.addTrack(videoTrack);
        if (stream.getAudioTracks().length) {
            audioTrack = stream.getAudioTracks()[0];
            resultStream.addTrack(audioTrack);
        }

        return Promise.resolve(resultStream);
    }

    getBlob() {
        return this.recorder.getBlob();
    }

    replaceVideoTrack = (track) => {
        console.log("REPLACE VIDEO TRACK FUNCTION called")
        if (!(track instanceof MediaStreamTrack)) {
            console.error('Track must be an instance of MediaStreamTrack');
            return;
        }

        if (track.kind === 'video') {
            const stream = new MediaStream();
            stream.addTrack(track);
            this.canvasStreamProxy.replaceVideoStream(stream);
        }
    }

    async startRecording() {

        try {
            this.targetStream = await this.CreateTargetStream(this.inputStream);
        } catch (error) {
            console.error(error);
            return Promise.reject('TargetStream Error');
        }

        this.recorder = new RecordRTC(this.targetStream, this.options);
        this.recorder.startRecording();
    }

    stopRecording(callback) {
        this.recorder.stopRecording(callback);
    }
}

export default CustomRecordRTC;
