import React, { useState, useEffect, useCallback } from 'react';
import { Engine, LottieClip, Property } from "@rendley/sdk";
import ConfigurationPanel from "../../../edit-panel/components/ConfigurationPanel/ConfigurationPanel";
import Textarea from "../../../../components/Textarea/Textarea";
import './EditLottiePanelContainer.styles.scss';

interface EditLottiePanelProps {
    clipId: string;
}

const EditLottiePanel: React.FC<EditLottiePanelProps> = ({ clipId }) => {
    const [properties, setProperties] = useState<Property[]>([]);
    const [clip, setClip] = useState<LottieClip | null>(null);

    const updateClip = useCallback((newClipId: string) => {
        const newClip = Engine.getInstance().getClipById(newClipId) as LottieClip;
        setClip(newClip);
        setProperties(Object.values(newClip?.getProperties() ?? {}));
    }, []);

    useEffect(() => {
        updateClip(clipId);
    }, [clipId, updateClip]);

    const handleSetProperty = useCallback((name: string, value: string) => {
        clip?.setProperty(name, value);
    }, [clip]);

    return (
        <div style={{width: "100%"}}>
        <ConfigurationPanel>
            <div className="col-span-12">
                {properties.map((property) => (
                    <div key={`${clipId}-${property.name}`}>
                        {/* @ts-ignore */}
                        <Textarea
                            label={property.label}
                            placeholder={property.label}
                            defaultValue={clip?.getPropertyValue(property.name)}
                            onChangeText={(value) => handleSetProperty(property.name, value)}
                        />
                        <div className="spacer-1"></div>
                    </div>
                ))}
            </div>
        </ConfigurationPanel>
        </div>
    );
};

export default EditLottiePanel;