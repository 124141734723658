import {BASE_URL} from "../../context/FetchContext";

export const COMPOSITION_CONTAINER_ID = "composition";
export const CANVAS_ID = "rendley";

export const TEXT_EDITOR_ID = "text_editor";

export const TIMELINE_RULER_TICK_WIDTH_PX = 100;

export const TIMELINE_MIN_DURATION = 30;

export const ASSETS_PATH_CDN = "https://cdn.rendley.com/sdk/assets";
export const FILTERS_PATH_CDN = `${ASSETS_PATH_CDN}/filters/`;
export const EFFECTS_PATH_CDN = `${ASSETS_PATH_CDN}/effects/`;
export const TRANSITIONS_PATH_CDN = `${ASSETS_PATH_CDN}/transitions/`;
export const TITLES_PATH_CDN = `${ASSETS_PATH_CDN}/titles/`;
export const IMAGES_PATH_CDN = `${ASSETS_PATH_CDN}/images/`;
export const SUBTITLES_STYLES_PATH_CDN = `${ASSETS_PATH_CDN}/subtitles/`;

export interface TransitionDescription {
    id: string;
    label: string;
    thumbnailUrl: string;
    videoPreviewUrl: string;
    shaderUrl: string;
}

export interface FilterDescription {
    id: string;
    label: string;
    thumbnailUrl: string;
    lutUrl: string;
}

export interface TitleDescription {
    id: string;
    name: string;
    thumbnailUrl: string;
    videoPreviewUrl: string;
    propertiesUrl: string;
    dataUrl: string;
    duration: number;
}

export interface EffectDescription {
    id: string;
    label: string;
    thumbnailUrl: string;
    videoPreviewUrl: string;
    shaderUrl: string;
}

export interface FontSource {
    family: string;
    cssUrl: string;
}

export interface SubtitlesStylesDescription {
    modes: StyleDescription[];
    animations: StyleDescription[];
    styles: StyleDescription[];
}
export interface StyleDescription {
    name?: string;
    value: string;
    path: string;
}
