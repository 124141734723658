import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RendleyStore } from "../../../../store/RendleyStore";
import { reaction } from "mobx";
import { Clip } from '../Clip/Clip';
import './VideoClip.styles.scss';

interface VideoClipProps {
    clipId: string;
    layerId: string;
}

export const VideoClip: React.FC<VideoClipProps> = observer(({ clipId, layerId }) => {
    const [filename, setFilename] = useState<string | undefined>(RendleyStore.getFilenameByClipId(clipId));
    const [thumbnail, setThumbnail] = useState<string | undefined>(RendleyStore.getThumbnailByClipId(clipId));

    useEffect(() => {
        const disposer = reaction(
            () => ({
                filename: RendleyStore.getFilenameByClipId(clipId),
                thumbnail: RendleyStore.getThumbnailByClipId(clipId)
            }),
            ({ filename, thumbnail }) => {
                setFilename(filename);
                setThumbnail(thumbnail);
            },
            { fireImmediately: true }
        );

        return () => {
            disposer();
        };
    }, [clipId]);


    return (
        // @ts-ignore
        <Clip clipId={clipId} layerId={layerId} filename={filename} thumbnail={thumbnail}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="film" clipPath="url(#clip0_464_80)">
                    <path
                        id="Vector"
                        d="M9.91 1H2.09C1.48801 1 1 1.48801 1 2.09V9.91C1 10.512 1.48801 11 2.09 11H9.91C10.512 11 11 10.512 11 9.91V2.09C11 1.48801 10.512 1 9.91 1Z"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path id="Vector_2" d="M3.5 1V11" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                    <path id="Vector_3" d="M8.5 1V11" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                    <path id="Vector_4" d="M1 6H11" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                    <path id="Vector_5" d="M1 3.5H3.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                    <path id="Vector_6" d="M1 8.5H3.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                    <path id="Vector_7" d="M8.5 8.5H11" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                    <path id="Vector_8" d="M8.5 3.5H11" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_464_80">
                        <rect width="12" height="12" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </Clip>
    );
});

export default VideoClip;