import React, { useState, useEffect, useRef } from 'react';
import { ChevronDownIcon } from "../../assets/icons/ChevronDownIcon";
import clsx from "clsx";
import { ApplicationStore } from "../../store/ApplicationStore";
import { autorun } from "mobx";
import {Label} from "../Label/Label";
import './Select.styles.scss';
import Button from "../Button/Button";

type SelectVariant = "default" | "border";

interface SelectProps {
    value?: string;
    label?: string;
    variant?: SelectVariant;
    selectStyle?: React.CSSProperties;
    children: React.ReactNode;
    onOptionSelected?: (option: { label: string; value: string }) => void;
}

const Select: React.FC<SelectProps> = ({
                                           value,
                                           label,
                                           variant = "default",
                                           selectStyle,
                                           children,
                                           onOptionSelected
                                       }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const disposeAutorun = autorun(() => {
            selectRef.current?.setAttribute("theme", ApplicationStore.theme);
        });

        const handleOutsideClick = (e: MouseEvent) => {
            if (selectRef.current && !selectRef.current.contains(e.target as Node)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            disposeAutorun();
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    const toggleDropdown = (e: React.MouseEvent) => {
        e.stopPropagation();
        setDropdownOpen(!dropdownOpen);
    };

    const handleOptionSelected = (option: { label: string; value: string }) => {
        onOptionSelected?.(option);
        setDropdownOpen(false);
    };

    const btnVariant = variant === "default" ? "default" : "ghost";

    // @ts-ignore
    return (
        <div ref={selectRef} className={clsx("select", `select__variant-${variant}`)}>
            {label != null && <Label error={false}>{label}</Label>}

            <div className="select__content">
                {/* @ts-ignore */}
                <Button
                    aria-haspopup="listbox"
                    aria-expanded={dropdownOpen.toString()}
                    className="select__btn"
                    style={selectStyle}
                    variant={btnVariant}
                    onClick={toggleDropdown}
                >
                    {value || "Select an option"}

                    <div className="select__btn-icon">
                        <ChevronDownIcon />
                    </div>
                </Button>
                {dropdownOpen && (
                    <ul className="select__dropdown">
                        {React.Children.map(children, child =>
                            React.isValidElement(child)
                                ? React.cloneElement(child, {
                                    // @ts-ignore
                                    onOptionSelected: handleOptionSelected })
                                : child
                        )}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default Select;