/* eslint-disable react/no-unescaped-entities */
import React, {useState} from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import MicIcon from '@mui/icons-material/Mic';
import KeyboardAltIcon from '@mui/icons-material/KeyboardAlt';
import ArticleIcon from '@mui/icons-material/Article';
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import GroupIcon from "@mui/icons-material/Group";

const mock = [
    {
        title: 'Separate track recording',
        subtitle:
            'Record high-quality audio and 4K video with participants anywhere in the world.',
        icon: (
          <MicIcon/>
        ),
    },
    {
        title: 'Live soundboard',
        subtitle:
            'Add and play sounds live for intros, effects, and more during recordings.',
        icon: (
            <KeyboardAltIcon/>
        ),
    },
    {
        title: 'Edit on a transcript or on a timeline',
        subtitle:
            'Trim and correct text to edit audio and video. Or use the timeline for precision.',
        icon: (
            <ArticleIcon/>
        ),
    },
    {
        title: 'Studio-quality sound in one click',
        subtitle:
            'Enhance speech, correct voice loudness and leveling instantly with Studio Sound.',
        icon: (
            <GraphicEqIcon/>
        ),
    },
    {
        title: 'Record with noise cancellation',
        subtitle: 'Noise cancellation with Krisp AI so you can record anywhere.',
        icon: (
            <SubtitlesIcon/>
        ),
    },
    {
        title: 'Collaborative audio & video editing',
        subtitle:
            'Invite team members to edit with you in real-time, without creating an account.',
        icon: (
            <GroupIcon/>
        ),
    },
];

const CardComponent = ({item, i, isNew}) => {

    const [isHovered, setIsHovered] = useState(false);
    const theme = useTheme();

    return (
        <Box
            component={Card}
            padding={4}
            borderRadius={2}
            width={1}
            height={1}
            data-aos={'fade-up'}
            data-aos-delay={i * 100}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            border={`0.5px solid ${isHovered ? isNew ? "#2B6BFD" : theme.palette.primary.main : 'transparent'}`} // Always have a border, but make it transparent when not hovered
        >
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Box
                    component={Avatar}
                    width={50}
                    height={50}
                    marginBottom={2}
                    bgcolor={isNew ? "#2B6BFD" : theme.palette.primary.main}
                    color={theme.palette.background.paper}
                >
                    {item.icon}
                </Box>
                <Typography variant={'h6'} gutterBottom sx={{ fontWeight: 500 }}>
                    {item.title}
                </Typography>
                <Typography color="text.secondary" fontSize={"1.05rem"} fontWeight={500}>{item.subtitle}</Typography>
            </Box>
        </Box>
    );
}

export const NewBenefits = () => {

    return (
        <Grid container direction="column" alignItems="center">
            <Grid item>
                <Typography sx={{ fontWeight: 700 }} variant={'h3'} gutterBottom color={"#2B6BFD"}>
                    A suite for content creators
                </Typography>
            </Grid>
            <Grid container spacing={4}>
                {mock.map((item, i) => (
                    <Grid item xs={12} sm={6} md={4} key={i}>
                        <CardComponent item={item} i={i} isNew={true}/>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

const Benefits = () => {

    return (
        <Grid container direction="column" alignItems="center">
            <Grid item>
                    <Typography sx={{ fontWeight: 700 }} variant={'h3'} gutterBottom>
                        All-in-one{' '}
                        <Typography
                            color={'primary'}
                            component={'span'}
                            variant={'inherit'}
                        >
                            Podcaster's Suite
                        </Typography>
                    </Typography>
            </Grid>
            <Grid container spacing={4}>
                {mock.map((item, i) => (
                    <Grid item xs={12} sm={6} md={4} key={i}>
                        <CardComponent item={item} i={i}/>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

export default Benefits;
