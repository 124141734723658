import styles from "./Avatars.module.css";
import {LibrettoLiveblocksContext} from "./LibrettoLiveblocksContext";
import {useContext} from "react";

// Create live avatars using Liveblocks presence
// https://liveblocks.io/docs/api-reference/liveblocks-react#useOthers

export function Avatars() {
    const liveblocksContext = useContext(LibrettoLiveblocksContext);

    const users = liveblocksContext.useOthers();
    const currentUser = liveblocksContext.useSelf();

    return (
        <div className={styles.avatars}>
            {users.map(({ connectionId, info }) => {
                return (
                    <Avatar key={connectionId} picture={info.picture} name={info.name} />
                );
            })}

            {currentUser && (
                <div className="relative ml-8 first:ml-0">
                    <Avatar
                        picture={currentUser.info.picture}
                        name={currentUser.info.name}
                    />
                </div>
            )}
        </div>
    );
}

export function Avatar({ picture, name }) {
    return (
        <div className={styles.avatar} data-tooltip={name}>
            <img
                src={picture}
                className={styles.avatar_picture}
                data-tooltip={name}
            />
        </div>
    );
}
