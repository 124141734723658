import {useMutation} from "@liveblocks/react/suspense";

export const useCreateSetDisplayResolution = () => {
    return useMutation(({storage}, width, height) => {
        storage?.get("display")?.set("width", String(width));
        storage?.get("display")?.set("height", String(height));
    }, []);
}

export const useCreateSetDisplayBackground = () => {
    return useMutation(({storage}, backgroundColor) => {
        storage?.get("display")?.set("backgroundColor", backgroundColor);
    }, []);
}

export const useCreateAddMediaToLibrary = () => {
    return useMutation(({storage}, media) => {
        storage.get("library").push(media);
        storage.get("corrections").set(media.entityId, {mediaDataId: media.entityId, corrections: new Map()});
    }, []);
}

export const useCreateSaveTranscriptCorrection = () => {
    return useMutation(({storage}, entityId, indexOnTranscript, correctedText) => {

        console.log("Correcting at index: ", indexOnTranscript, " for entity: ", entityId, " with corrected text: ", correctedText);

        const clipCorrections = storage.get("corrections").get(String(entityId))
        const newCorrectionsForClip = clipCorrections.corrections;
        newCorrectionsForClip[String(indexOnTranscript)] = {correctedText: correctedText, index: indexOnTranscript};
        clipCorrections.corrections = newCorrectionsForClip;
        console.log("Clip corrections is: ", clipCorrections);
        storage.get("corrections").set(String(entityId), clipCorrections);

    }, []);
}