import React, {useState, useEffect, useContext, useRef} from 'react';
import {Card, Grid, Button, Slider, IconButton, Box, Tooltip, ClickAwayListener} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import {FetchContext} from "../../context/FetchContext";
import EditorMediaThumbnail, {SoundboardThumbnail} from "../ModernEditor/EditorThumbnail";
import {
    EditorMediaTypeEnums, formatDurationBrief,
    RefreshTokenAndRetry,
    StudioMediaBoardTypeEnums, TypeOfTrack,
    UploadToLibretto
} from "../../utils/utils";
import {CloseIcon} from "../NewHome/HomeIcons";
import {PlusIcon} from "../../editor/assets/icons/PlusIcon";
import {AuthContext} from "../../context/AuthContext";
import Typography from "@mui/material/Typography";
import {collection, onSnapshot, query, where} from "firebase/firestore";
import {db} from "../../Firebase";
import {
    kTracksCollectionName,
    TrackDeleted,
    TrackProjectId,
    TrackType,
    TrackUserId
} from "../../utils/CollectionConstants";
import {LocalAudioTrack, Room} from "livekit-client";
import AudioRTCRecording from "../../components/AudioRTCRecording";

const serverUrl = 'wss://librettobeta-htow58t4.livekit.cloud';

const kMaxSoundboardTracks = 8;

// Your API Keys
const API_KEY = 'a405a0768efc66ca1d79952af9891c13';
const TOKEN = 'da05f611dfe776676f9a151961a3505b';
const API_URL = 'https://www.hooksounds.com/edd-api/products/';
const SFX_URL = 'https://www.hooksounds.com/edd-api/sfx/';

export default function MediaBoard({
                                       onClose,
                                       visible,
                                       projectId,
                                       token,
                                       assetIdRef,
                                       localRecordingEnabled,
                                       setUploadProgress,
                                       setLocalRecordingDone,
                                       setLocalRecordingStopped,
                                       highQualityRecordingRef
                                   }) {
    const authContext = useContext(AuthContext);
    const fetchContext = useContext(FetchContext);
    const userId = authContext.getUserId();

    // State that have to do with fetching media files and the visual elements.
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTab, setSelectedTab] = useState(StudioMediaBoardTypeEnums.SFX);
    const [soundEffectsTracks, setSoundEffectsTracks] = useState([]);
    const [musicTracks, setMusicTracks] = useState([]);
    const [uploadedTracks, setUploadedTracks] = useState([]);

    // State that have to do with playing the sounds and publishing the audio.
    const [audioRef, setAudioRef] = useState(null);
    const [gainNode, setGainNode] = useState(null);
    const [audioContext, setAudioContext] = useState(null);
    const [mixedDestination, setMixedDestination] = useState(null);
    const [room, setRoom] = useState(null);
    const [selectedTrackId, setSelectedTrackId] = useState(null);
    const [selectedTrackUrl, setSelectedTrackUrl] = useState(null);
    const [selectedTrackTitle, setSelectedTrackTitle] = useState(null);
    const [selectedTrackDuration, setSelectedTrackDuration] = useState(null);

    const unsetSelectedTrack = () => {
        setSelectedTrackId(null);
        setSelectedTrackUrl(null);
        setSelectedTrackTitle(null);
        setSelectedTrackDuration(null);
    }

    const [loop, setLoop] = useState(false);
    const [volume, setVolume] = useState(50);

    useEffect(() => {
        setAudioContext(new AudioContext());
    }, []);

    useEffect(() => {
        // Initialize mixedDestination once
        if (!audioContext || mixedDestination) return;

        const newMixedDestination = audioContext.createMediaStreamDestination();
        setMixedDestination(newMixedDestination);

        const connectAndPublish = async () => {
            try {
                // Check if audio context and mixedDestination are fully initialized
                if (!audioContext || !newMixedDestination) {
                    console.error("Audio context or mixedDestination is not initialized yet.");
                    return;
                }

                // Initialize and connect to the room
                const room = new Room();
                await room.connect(serverUrl, token);  // Await room connection

                // Check if there is an audio track available from the mixed destination
                const audioTracks = newMixedDestination.stream.getAudioTracks();
                if (audioTracks.length === 0) {
                    console.error("No audio tracks available from mixedDestination.");
                    return;
                }

                // Create the local audio track and publish it to the room
                const initialAudioTrack = new LocalAudioTrack(audioTracks[0]);
                const trackPublication = await room.localParticipant.publishTrack(initialAudioTrack, {
                    name: "Soundboard track",
                    red: true
                });

                // Store the room instance in the state
                setRoom(room);

                // Handle room disconnection
                room.on('disconnected', () => {

                });
            } catch (error) {
                console.error('Failed to connect to the room or publish track:', error);
            }
        };

        connectAndPublish();
    }, [token, audioContext, mixedDestination]);

    useEffect(() => {
        if (!mixedDestination) return;

        const audio = new Audio();
        audio.src = "https://storage.googleapis.com/libretto-public-assets/intro.mp3";
        audio.volume = volume / 100;
        audio.loop = false;
        audio.crossOrigin = 'anonymous';

        const gain = audioContext.createGain();
        gain.gain.value = volume / 100;
        gain.connect(audioContext.destination);
        setGainNode(gain);

        audio.addEventListener('canplay', () => {
            const track = audio.captureStream().getAudioTracks()[0];
            const sourceNode = audioContext.createMediaStreamSource(new MediaStream([track]));
            sourceNode.connect(gain);
            gain.connect(mixedDestination); // Connect to AudioContext destination
        });
        audio.load();
        setAudioRef(audio);
    }, [audioContext, mixedDestination]);

    useEffect(() => {
        if (!audioContext || !gainNode || !audioRef) return; // Ensure all are initialized

        if (selectedTrackUrl) {
            // Set the new audio source
            audioRef.src = selectedTrackUrl;
            audioRef.crossOrigin = 'anonymous';
            audioRef.volume = volume / 100;  // Update the initial volume from the state
            audioRef.loop = loop;            // Apply loop state

            const handleCanPlay = () => {
                try {

                    // Capture the audio stream from the audio element
                    const track = audioRef.captureStream().getAudioTracks()[0];
                    const sourceNode = audioContext.createMediaStreamSource(new MediaStream([track]));

                    sourceNode.connect(gainNode);
                    gainNode.connect(mixedDestination);

                    audioRef.play();
                } catch (err) {
                    console.error('Error setting up audio stream:', err);
                }
            };

            // Attach the listener to handle the stream capture
            audioRef.addEventListener('canplay', handleCanPlay, {once: true});

            // Optionally handle the "ended" event for the audio
            audioRef.onended = () => {
                audioRef.removeEventListener('canplay', handleCanPlay);
                unsetSelectedTrack();
            };
        } else {
            // Stop the audio if no track is selected
            audioRef.pause();
            audioRef.currentTime = 0;
        }

        // ON destroy
        return () => {
            audioRef.pause();
            audioRef.currentTime = 0;
            audioRef.removeEventListener('canplay', () => {});
        }
    }, [selectedTrackUrl, audioContext, gainNode, audioRef, volume, loop]);

    const handlePlayPauseClick = () => {
        if (audioRef.paused) {
            audioRef.play();
        } else {
            audioRef.pause();
        }
    };

    const handleVolumeChange = (e) => {
        const newVolume = e.target.value;
        setVolume(newVolume);
        gainNode.gain.value = newVolume / 100;
    };


    const fetchTracks = async (search = '', pageNum = 1) => {
        try {
            // Fetch both audio tracks and sound effects in parallel
            await Promise.all([
                fetch(
                    fetchContext.getProxiedURL(`${SFX_URL}?key=${API_KEY}&token=${TOKEN}&s=${search}&page=${pageNum}`)
                ).then(async (response) => {
                    const sfxData = await response.json();
                    const sfxDataTracks = sfxData.results;
                    // Keep at most 6 sound effects
                    setSoundEffectsTracks(sfxDataTracks.slice(0, kMaxSoundboardTracks));
                }),
            ]);
        } catch (error) {
            console.error('Error fetching tracks:', error);
        }
    };

    useEffect(() => {
        if (projectId) {
            const q = query(collection(db, kTracksCollectionName), where(TrackProjectId, "==", projectId), where(TrackDeleted, "==", false), where(TrackUserId, "==", userId), where(TrackType, "==", TypeOfTrack.UploadedSoundboardSound));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const newTracks = [];
                    querySnapshot.forEach((doc) => {
                        newTracks.push(doc.data());
                    });
                    // @ts-ignore
                    // Keep at most 6 uploaded tracks
                    setUploadedTracks(newTracks.slice(0, kMaxSoundboardTracks));
                },
                (error) => {
                    if (error.code === 'permission-denied') {
                        authContext.logout();
                    }
                });

            return () => unsubscribe();
        } else {
            setUploadedTracks([]);
        }
    }, [projectId]);

    useEffect(() => {
        const pageNum = 1;
        const searchTerm = '';
        fetch(
            fetchContext.getProxiedURL(`${API_URL}?key=${API_KEY}&token=${TOKEN}&s=${searchTerm}&page=${pageNum}`)
        ).then(async (response) => {
            const audioData = await response.json();
            const audioDataTracks = audioData.products.map((track) => (track.info));
            setMusicTracks(audioDataTracks.slice(0, kMaxSoundboardTracks));
        })
    }, []);


    useEffect(() => {
        fetchTracks(searchTerm, page);
    }, [searchTerm, page]);

    const mediaBoardPanelStyle = {
        backgroundColor: '#ffffff',
        position: "absolute",
        padding: "5px",
        gap: "5px",
        right: "10px",
        height: "760px",
        width: "300px",
        top: "25px",
        zIndex: 1000,
        display: visible ? "flex" : "none",
        borderRadius: "10px",
        flexDirection: "column",
    }

    const topAreaStyle = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        padding: "10px",
    }

    const iconStyle = {
        width: "24px", height: "24px", marginLeft: "auto", cursor: "pointer",
    }

    const descTextStyle = {
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "16px",
        letterSpacing: "0.48px",
        color: "#999999",
    }

    const searchBoxStyle = (disabled) => ({
        backgroundColor: disabled ? "#e0e0e0" : "#f0f0f0",  // Lighter color when disabled
        padding: "5px 10px",
        borderRadius: "8px",
        marginBottom: "10px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
        cursor: disabled ? "not-allowed" : "text",  // Show not-allowed cursor when disabled
        opacity: disabled ? 0.6 : 1,               // Reduced opacity when disabled
    });

    const searchInputStyle = {
        flexGrow: 1,
        fontSize: "14px",
        border: "none",
        outline: "none",
        backgroundColor: "transparent",
        padding: "5px",
    };

    const searchIconStyle = {
        cursor: "pointer",
        color: "#999999",
    };

    const handleUpload = async (event) => {
        // Log mime type
        const mimeType = event.target.files[0].type;
        if (!mimeType.startsWith('audio')) {
            console.error("Invalid file type. Please upload an audio file.");
            return;
        }
        const file = event.target.files[0];

        // Prevent upload if file is too large. Greater than 50MB
        if (file.size > 50000000) {
            return;
        }

        if (file) {
            try {
                await UploadToLibretto({
                    inputRef: event.target,
                    setProgress: null,
                    filetype: "SoundboardAudio",
                    projectId: projectId,
                    editId: "",
                    fetchContext,
                    authContext
                });
            } catch (e) {
                if (e.response && e.response.status === 401) {
                    await RefreshTokenAndRetry(e, authContext, fetchContext);
                }
            }
        }
    };

    const searchDisabled = selectedTab === StudioMediaBoardTypeEnums.UPLOADS || selectedTab === StudioMediaBoardTypeEnums.MUSIC;

    const handleSearchCloseIconClick = () => {
        if (searchDisabled) {
            return;
        }
        setSearchTerm('');
    };

    const tracksToRender = () => {
        switch (selectedTab) {
            case StudioMediaBoardTypeEnums.SFX:
                return soundEffectsTracks;
            case StudioMediaBoardTypeEnums.MUSIC:
                return musicTracks;
            case StudioMediaBoardTypeEnums.UPLOADS:
                return uploadedTracks;
            default:
                return [];
        }
    }

    const getTrackId = (track) => {
        if (selectedTab === StudioMediaBoardTypeEnums.UPLOADS) {
            return track.trackId;
        } else {
            return track.id;
        }
    }

    const getTrackUrl = (track) => {
        if (selectedTab === StudioMediaBoardTypeEnums.UPLOADS) {
            return fetchContext.getProxiedURL(track.objectUrl);
        } else {
            return fetchContext.getProxiedURL(track.mp3);
        }
    }

    const getTrackDuration = (track) => {
        if (selectedTab === StudioMediaBoardTypeEnums.UPLOADS) {
            return formatDurationBrief(track.duration);
        } else {
            return track.duration;
        }
    }

    const handleSoundboardItemClick = (track) => {
        if (selectedTrackId === getTrackId(track)) {
            setSelectedTrackId(null);
            setSelectedTrackUrl(null);
            setSelectedTrackTitle(null);
            setSelectedTrackDuration(null);
            return;
        }
        setSelectedTrackId(getTrackId(track));
        setSelectedTrackUrl(getTrackUrl(track));
        setSelectedTrackTitle(track.title);
        setSelectedTrackDuration(getTrackDuration(track));
    }

    const DeleteAsset = async ({assetId}) => {
        await fetchContext.authAxios.delete(`/assets/${assetId}`, {
            headers: {
                Authorization: `Bearer ${authContext.getToken()}`,
            }
        });
    };

    return (
        <>
            <AudioRTCRecording room={room}
                               localRecordingEnabled={localRecordingEnabled} setUploadProgress={setUploadProgress}
                               assetIdRef={assetIdRef} livekitToken={token} setRecordingDone={setLocalRecordingDone}
                               setLocalRecordingStopped={setLocalRecordingStopped} ref={highQualityRecordingRef}/>
            <Box sx={mediaBoardPanelStyle}>
                <Box sx={topAreaStyle}>
                    <Box sx={{fontWeight: 600}}>
                        Media
                    </Box>
                    <Box sx={iconStyle} onClick={onClose}>
                        <CloseIcon/>
                    </Box>
                </Box>
                <Box sx={{paddingX: "10px", paddingBottom: "10px"}}>
                    <Box sx={descTextStyle}>
                        Play sounds. Audio will be recorded in the composite recording and on a separate track.
                    </Box>
                </Box>
                <Box sx={{paddingX: "10px"}}>
                    <Box sx={searchBoxStyle(searchDisabled)}>
                        <input
                            type="text"
                            placeholder="Search"
                            disabled={searchDisabled}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={searchInputStyle}
                        />
                        <IconButton sx={searchIconStyle} onClick={handleSearchCloseIconClick} disabled={searchDisabled}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    paddingX: "10px",
                    gap: "5px",
                    justifyContent: "space-between",
                }}>
                    <Box>
                        <Button
                            variant="text"
                            onClick={() => setSelectedTab(StudioMediaBoardTypeEnums.SFX)}
                            sx={{
                                color: '#2B6BFD',
                                borderBottom: selectedTab === StudioMediaBoardTypeEnums.SFX ? '2px solid #2B6BFD' : 'none',
                                borderRadius: '0px',
                            }}
                        >
                            <Typography fontWeight={400}>Sound effects</Typography>
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            variant="text"
                            onClick={() => setSelectedTab(StudioMediaBoardTypeEnums.MUSIC)}
                            sx={{
                                color: '#2B6BFD',
                                borderBottom: selectedTab === StudioMediaBoardTypeEnums.MUSIC ? '2px solid #2B6BFD' : 'none',
                                borderRadius: '0px',
                            }}
                        >
                            <Typography fontWeight={400}>Music</Typography>
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            variant="text"
                            onClick={() => setSelectedTab(StudioMediaBoardTypeEnums.UPLOADS)}
                            sx={{
                                color: '#2B6BFD',
                                borderBottom: selectedTab === StudioMediaBoardTypeEnums.UPLOADS ? '2px solid #2B6BFD' : 'none',
                                borderRadius: '0px',
                            }}
                        >
                            <Typography fontWeight={400}>Uploads</Typography>
                        </Button>
                    </Box>
                </Box>

                {/*<Button variant="outlined" startIcon={<PlusIcon/>} sx={{marginX: "10px"}} component="label">*/}
                {/*    Upload media*/}
                {/*    <input*/}
                {/*        type="file"*/}
                {/*        hidden*/}
                {/*        accept="audio/*"*/}
                {/*        onChange={handleUpload}*/}
                {/*    />*/}
                {/*</Button>*/}
                <Box sx={{padding: "10px"}}>
                    <Grid container spacing={2}>
                        {tracksToRender().map((track) => (
                            <Grid item xs={6} key={getTrackId(track)}>
                                {/* @ts-ignore */}
                                <SoundboardThumbnail
                                    isSelected={selectedTrackId === getTrackId(track)}
                                    thumbnailUrl={selectedTab === StudioMediaBoardTypeEnums.UPLOADS ? track.thumbnailUrl : track.thumbnail}
                                    title={track.title}
                                    status={selectedTab === StudioMediaBoardTypeEnums.UPLOADS ? track.status : "Ready"}
                                    duration={selectedTab === StudioMediaBoardTypeEnums.UPLOADS ? formatDurationBrief(track.duration) : track.duration}
                                    onClick={() => handleSoundboardItemClick(track)}
                                    onDelete={() => DeleteAsset({assetId: track.assetId})}
                                    isUpload={selectedTab === StudioMediaBoardTypeEnums.UPLOADS}
                                />
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            {selectedTab === StudioMediaBoardTypeEnums.UPLOADS ?
                                <Tooltip title={"Max 50mb audio file"} placement="top" arrow>
                                <Button variant="outlined" startIcon={<PlusIcon/>} fullWidth={true} disabled={uploadedTracks.length >= kMaxSoundboardTracks}
                                        sx={{marginTop: "10px"}} component="label">
                                    Upload audio
                                    <input
                                        type="file"
                                        hidden
                                        accept="audio/*"
                                        onChange={handleUpload}
                                    />
                                </Button>
                                </Tooltip> : null}
                        </Grid>
                    </Grid>
                </Box>

                {/*<Box sx={{*/}
                {/*    width: '100%',*/}
                {/*    backgroundColor: '#ffffff',*/}
                {/*    padding: '10px',*/}
                {/*    zIndex: 2000,*/}
                {/*    display: 'flex',*/}
                {/*    alignItems: 'center',*/}
                {/*    justifyContent: 'space-between',*/}
                {/*    color: '#fff',*/}
                {/*    flexDirection: "column",*/}
                {/*}}>*/}
                {/*    <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>*/}
                {/*        <Box>*/}
                {/*            <Typography variant="h6" sx={{flex: 1}}>*/}
                {/*                {"No Track Selected"}*/}
                {/*            </Typography>*/}
                {/*        </Box>*/}
                {/*        <Box>*/}
                {/*            <IconButton onClick={handlePlayPauseClick}>*/}
                {/*                {audioRef?.paused ? <PlayArrow sx={{color: '#fff'}} /> : <Pause sx={{color: '#fff'}} />}*/}
                {/*            </IconButton>*/}

                {/*            /!* Loop Button *!/*/}
                {/*            <IconButton onClick={() => setLoop(!loop)}>*/}
                {/*                <Loop sx={{color: loop ? '#fff' : '#999'}} />*/}
                {/*            </IconButton>*/}
                {/*        </Box>*/}
                {/*    </Box>*/}
                {/*    <Box sx={{display: "flex", flexDirection: "column"}}>*/}
                {/*        <Box>*/}
                {/*            /!*<Slider*!/*/}
                {/*            /!*    value={currentProgress}*!/*/}
                {/*            /!*    onChange={handleProgressChange}*!/*/}
                {/*            /!*    sx={{width: 200, color: '#2B6BFD'}}*!/*/}
                {/*            /!*//*/}
                {/*        </Box>*/}
                {/*        <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>*/}
                {/*            <Box>*/}
                {/*                {audioRef ? <div>{audioRef.currentTime}</div> : null}*/}
                {/*            </Box>*/}
                {/*            <Box>*/}
                {/*                <div>{selectedTrackDuration}</div>*/}
                {/*            </Box>*/}
                {/*        </Box>*/}
                {/*    </Box>*/}
                {/*    <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>*/}
                {/*        <Box>*/}
                {/*            <VolumeUp sx={{color: '#fff'}} />*/}
                {/*        </Box>*/}
                {/*        <Box>*/}
                {/*            <Slider*/}
                {/*                value={volume}*/}
                {/*                onChange={handleVolumeChange}*/}
                {/*                sx={{width: 100, color: '#2B6BFD'}}*/}
                {/*            />*/}
                {/*        </Box>*/}
                {/*    </Box>*/}
                {/*</Box>*/}
            </Box>
        </>
    );
}