import { makeAutoObservable } from "mobx";

class WindowStoreInstance {
  resolution: [number, number] = [window.innerWidth, window.innerHeight];
  canvasResolution: [number, number] = [0, 0];

  constructor() {
    makeAutoObservable(this);
  }

  setResolution(resolution: [number, number]) {
    this.resolution = resolution;
  }

  setCanvasResolution(resolution: [number, number]) {
    this.canvasResolution = resolution;
  }
}

const WindowStore = new WindowStoreInstance();

export { WindowStore };
