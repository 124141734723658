import {Track} from 'livekit-client';
import * as React from 'react';
import useMediaQuery from "@mui/material/useMediaQuery";
import {
    MediaDeviceMenu,
    DisconnectButton,
    TrackToggle,
    ChatIcon,
    LeaveIcon,
    ChatToggle,
    useLocalParticipantPermissions,
    usePersistentUserChoices,
    useMaybeLayoutContext,
    useRoomContext,
    useStartAudio,
    useStartVideo
} from "@livekit/components-react";
import {supportsScreenSharing} from '@livekit/components-core';
import BlurBackgroundButton from "../../components/BlurBackgroundButton";
import Grid from "@mui/material/Grid";
import NewRecordButton from "./NewRecordButton";
import {
    BackgroundButton,
    CameraButton,
    MicrophoneButton,
    ShareScreenButton
} from "./ControlBarButtons";

function mergeProps(...objects) {
    return Object.assign({}, ...objects);
}

export function NewCustomControlBar({
                                        recordingInProgress,
                                        setBackgroundImage,
                                        backgroundImageIndex,
                                        setBackgroundImageIndex,
                                        isRecordButtonDisabled,
                                        localRecordingInProgress,
                                        variation,
                                        controls,
                                        room,
                                        roomName,
                                        saveUserChoices = true,
                                        ...props
                                    }) {
    const [isChatOpen, setIsChatOpen] = React.useState(false);
    const layoutContext = useMaybeLayoutContext();
    React.useEffect(() => {
        if (layoutContext?.widget.state?.showChat !== undefined) {
            setIsChatOpen(layoutContext?.widget.state?.showChat);
        }
    }, [layoutContext?.widget.state?.showChat]);

    const isTooLittleSpace = useMediaQuery(`(max-width: ${isChatOpen ? 1000 : 760}px)`);

    const defaultVariation = isTooLittleSpace ? 'minimal' : 'verbose';
    variation ??= defaultVariation;

    const visibleControls = {leave: true, ...controls};

    visibleControls.recordButton = isRecordButtonDisabled ? recordingInProgress : true;
    const localPermissions = room.localParticipant.permissions;

    if (!localPermissions) {
        visibleControls.camera = false;
        visibleControls.blurBackground = false;
        visibleControls.chat = false;
        visibleControls.microphone = false;
        visibleControls.screenShare = false;
    } else {
        visibleControls.camera ??= localPermissions.canPublish;
        visibleControls.blurBackground ??= localPermissions.canPublish;
        visibleControls.microphone ??= localPermissions.canPublish;
        visibleControls.screenShare ??= localPermissions.canPublish;
        visibleControls.chat ??= localPermissions.canPublishData && controls?.chat;
    }

    visibleControls.leave = false;
    const showIcon = React.useMemo(
        () => variation === 'minimal' || variation === 'verbose',
        [variation],
    );
    const showText = React.useMemo(
        () => variation === 'textOnly' || variation === 'verbose',
        [variation],
    );

    const browserSupportsScreenSharing = supportsScreenSharing();

    const [isScreenShareEnabled, setIsScreenShareEnabled] = React.useState(false);

    const onScreenShareChange = React.useCallback(
        (enabled) => {
            setIsScreenShareEnabled(enabled);
        },
        [setIsScreenShareEnabled],
    );

    const htmlProps = mergeProps({className: 'lk-control-bar'}, props);

    const {
        saveAudioInputEnabled,
        saveVideoInputEnabled,
        saveAudioInputDeviceId,
        saveVideoInputDeviceId,
    } = usePersistentUserChoices({preventSave: !saveUserChoices});

    const microphoneOnChange = React.useCallback(
        (enabled, isUserInitiated) =>
            isUserInitiated ? saveAudioInputEnabled(enabled) : null,
        [saveAudioInputEnabled],
    );

    const cameraOnChange = React.useCallback(
        (enabled, isUserInitiated) =>
            isUserInitiated ? saveVideoInputEnabled(enabled) : null,
        [saveVideoInputEnabled],
    );

    return (
        <Grid container spacing={1.5}>
            <Grid item>
                <MicrophoneButton onChange={microphoneOnChange} saveAudioInputDevice={saveAudioInputDeviceId}
                                  room={room}/>
            </Grid>
            <Grid item>
                <CameraButton onChange={cameraOnChange} saveVideoInputDevice={saveVideoInputDeviceId} room={room}/>
            </Grid>
            <Grid item>
                <BackgroundButton setBackgroundImage={setBackgroundImage} backgroundImageIndex={backgroundImageIndex} setBackgroundImageIndex={setBackgroundImageIndex}/>
            </Grid>
            <Grid item>
                <ShareScreenButton onChange={onScreenShareChange} room={room}/>
            </Grid>
        </Grid>

        // <div {...htmlProps}>
        //     {visibleControls.microphone && (
        //         <div className="lk-button-group">
        //             <TrackToggle
        //                 source={Track.Source.Microphone}
        //                 showIcon={showIcon}
        //                 onChange={microphoneOnChange}
        //             >
        //                 {showText && 'Microphone'}
        //             </TrackToggle>
        //             <div className="lk-button-group-menu">
        //                 <MediaDeviceMenu
        //                     kind="audioinput"
        //                     onActiveDeviceChange={(_kind, deviceId) => saveAudioInputDeviceId(deviceId ?? '')}
        //                 />
        //             </div>
        //         </div>
        //     )}
        //     {visibleControls.camera && (
        //         <div className="lk-button-group">
        //             <TrackToggle source={Track.Source.Camera} showIcon={showIcon} onChange={cameraOnChange}>
        //                 {showText && 'Camera'}
        //             </TrackToggle>
        //             <div className="lk-button-group-menu">
        //                 <MediaDeviceMenu
        //                     kind="videoinput"
        //                     onActiveDeviceChange={(_kind, deviceId) => saveVideoInputDeviceId(deviceId ?? '')}
        //                 />
        //             </div>
        //         </div>
        //     )}
        //     {visibleControls.blurBackground && (
        //         <BlurBackgroundButton blurBackground={blurBackground} setBlurBackground={setBlurBackground} disabled={recordingInProgress}/>
        //     )}
        //     {visibleControls.screenShare && browserSupportsScreenSharing && (
        //         <TrackToggle
        //             source={Track.Source.ScreenShare}
        //             captureOptions={{ audio: true, selfBrowserSurface: 'include' }}
        //             showIcon={showIcon}
        //             onChange={onScreenShareChange}
        //         >
        //             {showText && (isScreenShareEnabled ? 'Stop screen share' : 'Share screen')}
        //         </TrackToggle>
        //     )}
        //     {visibleControls.chat && (
        //         <ChatToggle>
        //             {showIcon && <ChatIcon />}
        //             {showText && 'Chat'}
        //         </ChatToggle>
        //     )}
        //     {visibleControls.leave && (
        //         <DisconnectButton>
        //             {showIcon && <LeaveIcon />}
        //             {showText && 'Leave'}
        //         </DisconnectButton>
        //     )}
        // </div>
    );
}
