import React, { useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";
import { autorun } from "mobx";
import { TIMELINE_RULER_TICK_WIDTH_PX } from "../../config/config";
import { ApplicationStore } from "../../store/ApplicationStore";
import { RendleyStore } from "../../store/RendleyStore";
import { TimelineStore } from "../../store/TimelineStore";
import { TIMELINE_CONTROLS_ELEMENT_ID, TIMELINE_ELEMENT_ID, TIMELINE_TRACKS_CONTAINER_ID } from "./config/constants";
import { RendleyService } from "../../services/RendleyService";
import { convertUnitsToTime } from "../../utils/dom/convertUnitsToTime";
import './Timeline.styles.scss';
import TrackDivider from "./components/TrackDivider/TrackDivider";
import Controls from "./components/Controls/Controls";
import EmptyTimelineButton from "./components/EmptyTimelineButton/EmptyTimelineButton";
import CanvasTimeRuler from "./components/CanvasTimeRuler/CanvasTimeRuler";
import Playhead from "./components/Playhead/Playhead";
import { Track } from "./components/Track/Track";

const Timeline = observer(() => {
    const [playheadX, setPlayheadX] = useState(0);
    const [layersOrder, setLayersOrder] = useState(RendleyStore.layersOrder);

    const timelineTracksRef = useRef(null);
    const timelineTracksResizeObserver = useRef(null);

    useEffect(() => {
        const disposeAutorun = autorun(() => {
            // This will re-run whenever any observable accessed inside changes
            TimelineStore.setWidth(RendleyStore.duration * TIMELINE_RULER_TICK_WIDTH_PX);
            setLayersOrder(RendleyStore.layersOrder);
        });

        return () => {
            disposeAutorun();
            removeScrollSync();
            // @ts-ignore
            timelineTracksResizeObserver.current?.disconnect();
        };
    }, []);

    useEffect(() => {
        addScrollSync();

        // @ts-ignore
        timelineTracksResizeObserver.current = new ResizeObserver(handleTimelineTracksResize);
        if (timelineTracksRef.current) {
            // @ts-ignore
            timelineTracksResizeObserver.current.observe(timelineTracksRef.current);
        }

        return () => {
            removeScrollSync();
            // @ts-ignore
            timelineTracksResizeObserver.current?.disconnect();
        };
    }, []);

    const handleScroll = () => {
        if (!timelineTracksRef.current) return;

        // @ts-ignore
        setPlayheadX(-timelineTracksRef.current.scrollLeft);

        const event = new CustomEvent('tracksScrollLeft', {
            bubbles: true,
            // @ts-ignore
            detail: timelineTracksRef.current.scrollLeft
        });
        // @ts-ignore
        timelineTracksRef.current.dispatchEvent(event);
    };

    const addScrollSync = () => {
        // @ts-ignore
        timelineTracksRef.current?.addEventListener("scroll", handleScroll);
    };

    const removeScrollSync = () => {
        // @ts-ignore
        timelineTracksRef.current?.removeEventListener("scroll", handleScroll);
    };

    // @ts-ignore
    const handleTimelineTracksResize = (entries) => {
        const width = entries[0]?.contentRect?.width;
        if (width) {
            TimelineStore.setTracksContainerWidth(width);
        }
    };

    // @ts-ignore
    const handleCreateLayerMedia = async (event, mediaId, index) => {
        const startTime = convertUnitsToTime(event.nativeEvent.offsetX);
        const layer = RendleyService.createLayer(index);
        const clip = await RendleyService.addMediaToLayer(layer.id, mediaId, startTime);
        if (clip) {
            ApplicationStore.setSelectedClipId(clip.id);
        }
    };

    // @ts-ignore
    const handleCreateLayerText = async (event, title, index) => {
        const layer = RendleyService.createLayer(index);
        const startTime = convertUnitsToTime(event.nativeEvent.offsetX);
        const clip = await RendleyService.createLottieClip({
            dataUrl: title.dataUrl,
            propertiesUrl: title.propertiesUrl,
            startTime: startTime,
            layerId: layer.id,
        });
        if (clip) {
            ApplicationStore.setSelectedClipId(clip.id);
        }
    };

    // @ts-ignore
    const handleWheel = (event) => {
        const container = timelineTracksRef.current;
        if (container) {
            event.preventDefault();
            // @ts-ignore
            container.scrollLeft += event.deltaY + event.deltaX;
        }
    };

    // @ts-ignore
    const renderTrackDivider = (index) => {
        const newIndex = layersOrder.length - index;
        return (
            <TrackDivider
                key={`layer-divider-${newIndex}`}
                index={newIndex}
                // @ts-ignore
                onDropMediaClip={(event, mediaId) => handleCreateLayerMedia(event, mediaId, newIndex)}
                // @ts-ignore
                onDropTextClip={(event, title) => handleCreateLayerText(event, title, newIndex)}
            />
        );
    };

    useEffect(() => {
        // @ts-ignore
        const handleScrollToPage = (event) => {
            const trackOffsetWidth = TimelineStore.tracksContainerWidth;
            const page = event.detail;
            if (timelineTracksRef.current) {
                // @ts-ignore
                timelineTracksRef.current.scrollLeft = page * trackOffsetWidth;
            }
        };

        window.addEventListener('scrollToPage', handleScrollToPage);
        return () => window.removeEventListener('scrollToPage', handleScrollToPage);
    }, []);

    const newLayersOrder = [...layersOrder].reverse();
    const tracksWidth = RendleyStore.duration * TIMELINE_RULER_TICK_WIDTH_PX * TimelineStore.zoom;

    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return (
        <div id={TIMELINE_ELEMENT_ID} className="timeline">
            <div id={TIMELINE_CONTROLS_ELEMENT_ID} className="timeline__controls">
                <Controls />
            </div>

            <div className="timeline__container" onWheel={handleWheel}>
                {layersOrder.length === 0 && <EmptyTimelineButton/>}

                <div className="timeline__canvas-ruler">
                    <CanvasTimeRuler />
                </div>

                <div className="timeline__playhead" style={{ transform: `translateX(calc(-50% + ${playheadX}px))` }}>
                    {/* @ts-ignore */}
                    <Playhead containerElement={document} />
                </div>

                <div className="timeline__divider-top">{renderTrackDivider(0)}</div>

                <div
                    id={TIMELINE_TRACKS_CONTAINER_ID}
                    className="timeline__tracks"
                    onMouseDown={() => {
                        ApplicationStore.setSelectedClipId(null);
                    }}
                    ref={timelineTracksRef}
                >
                    {layersOrder.length > 0 && (
                        <div className="tracks" style={{ minWidth: `${tracksWidth}px` }}>
                            {newLayersOrder.map((layerId, index) => (
                                // @ts-ignore
                                <Track key={layerId} layerId={layerId}>
                                    {index < newLayersOrder.length - 1 && renderTrackDivider(index + 1)}
                                </Track>
                            ))}
                        </div>
                    )}

                    <div className="timeline__void-container">{renderTrackDivider(layersOrder.length)}</div>
                </div>
            </div>
        </div>
    );
});

export default Timeline;