import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CollaborativeTextEditor from "./CollaborativeTextEditor";
import React, {useContext, useState} from "react";
import TimelineEditor from "../Editor/EditorComponents/TimelineEditor";
import ModernVideoPlayer from "../../components/VideoJs/ModernVideoPlayer";
import {LibrettoLiveblocksContext} from "./LibrettoLiveblocksContext";

const EditorInner = ({tracks, orderOfTracks, timelineEditorRef, enhancedWavedataForTracks, trims, fillerWordRemovalTrims, corrections, transcripts, assetId, clipSize, transcriptsLoaded, tracksLoaded, waveDataForTracks, selectedTrackIndex, setSelectedTrackIndex, handleSelect, editorRef, captionsEnabled, videoplayerRef, videoPlayerVisible, waveformRefs, handleVideoPlayerPlayToggle, loadTracks}) => {

    const [duration, setDuration] = useState(0.0);
    const [currentTime, setCurrentTime] = useState(0.0);

    const videoPlayerWidth = videoPlayerVisible ? 7 : 12;

    const textEditorWidth = videoPlayerVisible ? 5 : 12;

    const showTextEditor = true;

    const [isPlaying, setIsPlaying] = useState(false);

    const liveblocksContext = useContext(LibrettoLiveblocksContext);

    const trackSettings = liveblocksContext.useStorage((root) => root.trackSettings);

    return (
        <main>
            <Box height={{xs: 50, sm: 30, md: 30}}/>
            <Grid container alignItems="center" justifyContent="space-between" marginBottom={0} style={{padding: 0}}>
                <Grid item xs={textEditorWidth} style={{
                    display: showTextEditor ? 'flex' : 'none',
                    flexDirection: 'column', // Centers the child vertically if 'alignItems' is set to 'center'
                    alignItems: 'center',    // Horizontally center the content in the flex container
                    justifyContent: 'center', // Vertically center the content in the flex container
                    height: 'calc(100vh - 200px)' // Adjust the height as necessary
                }}>
                    <CollaborativeTextEditor editorRef={editorRef} orderOfTracks={orderOfTracks} onSelect={handleSelect} transcripts={transcripts} trims={trims} fillerWordRemovalTrims={fillerWordRemovalTrims} corrections={corrections} transcriptsLoaded={transcriptsLoaded} setSelectedTrackIndex={setSelectedTrackIndex} currentTrackIndex={selectedTrackIndex} isPlaying={isPlaying} timelineEditorRef={timelineEditorRef} videoPlayerRef={videoplayerRef}/>
                </Grid>
                <Grid item xs={videoPlayerWidth} style={{
                    display: videoPlayerVisible ? 'flex' : 'none',
                    flexDirection: 'column', // Centers the child vertically if 'alignItems' is set to 'center'
                    alignItems: 'center',    // Horizontally center the content in the flex container
                    justifyContent: 'center', // Vertically center the content in the flex container
                    height: 'calc(100vh - 200px)', // Adjust the height as necessary
                }}>
                    <ModernVideoPlayer clipSize={clipSize} captionsEnabled={captionsEnabled} trackSettings={trackSettings} transcripts={transcripts} corrections={corrections} tracks={tracks} orderOfTracks={orderOfTracks} ref={videoplayerRef} trims={trims} fillerWordRemovalTrims={fillerWordRemovalTrims} isPlaying={isPlaying}/>
                </Grid>
            </Grid>
            {tracksLoaded && <TimelineEditor videoPlayerRef={videoplayerRef} editorRef={editorRef} currentTime={currentTime} setCurrentTime={setCurrentTime} duration={duration} setDuration={setDuration} enhancedWavedataForTracks={enhancedWavedataForTracks} isPlaying={isPlaying} setIsPlaying={setIsPlaying} waveformRefs={waveformRefs} tracks={tracks} trims={trims} fillerWordRemovalTrims={fillerWordRemovalTrims} orderOfTracks={orderOfTracks} waveDataForTracks={waveDataForTracks} selectedTrackIndex={selectedTrackIndex} setSelectedTrackIndex={setSelectedTrackIndex} assetId={assetId} handleVideoPlayerPlayToggle={handleVideoPlayerPlayToggle} loadTracks={loadTracks} ref={timelineEditorRef}/>}
        </main>
    )
}

export default EditorInner;
