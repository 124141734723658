import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import {useNavigate} from "react-router-dom";

export const NewFooter = () => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={1}
              flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <Box
                display={'flex'}
                component="a"
                href="/"
                title="Libretto"
                width={120}
            >
              <Box
                  component={'img'}
                  src={'/logo-new.svg'}
                  height={1}
                  width={1}
              />
            </Box>
            <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
              <Box marginTop={1} marginRight={2}>
                <Link
                    underline="always"
                    component="a"
                    href="/privacy"
                    color="#2B6BFD"
                    variant={'subtitle1'}
                >
                  <Typography fontWeight={600}>Privacy Policy</Typography>
                </Link>
              </Box>
              <Box marginTop={1} marginRight={2}>
                <Link
                    underline="always"
                    component="a"
                    href="/terms"
                    color="#2B6BFD"
                    variant={'subtitle1'}
                >
                  <Typography fontWeight={600}>Terms of service</Typography>
                </Link>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
              align={'center'}
              variant={'subtitle1'}
              color="text.secondary"
              gutterBottom
          >
            &copy; Libretto, 2024. All rights reserved
          </Typography>
        </Grid>
      </Grid>
  );
};

const Footer = () => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={1}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box
            display={'flex'}
            component="a"
            href="/"
            title="Libretto"
            width={120}
          >
            <Box
              component={'img'}
              src={
                mode === 'light'
                  ? '/logo-dark.svg'
                  : '/logo.svg'
              }
              height={1}
              width={1}
            />
          </Box>
          <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
            <Box marginTop={1} marginRight={2}>
              <Link
                  underline="always"
                  component="a"
                  href="/privacy"
                  color="primary"
                  variant={'subtitle1'}
              >
                <Typography fontWeight={600}>Privacy Policy</Typography>
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="always"
                component="a"
                href="/terms"
                color="primary"
                variant={'subtitle1'}
              >
                <Typography fontWeight={600}>Terms of service</Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'subtitle1'}
          color="text.secondary"
          gutterBottom
        >
          &copy; Libretto, 2024. All rights reserved
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
