import React, { useRef, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { reaction } from 'mobx';
import { ClipTypeEnum, Engine } from "@rendley/sdk";
import { ApplicationStore } from "../../../../store/ApplicationStore";
import { RendleyStore } from "../../../../store/RendleyStore";
import { TimelineStore } from "../../../../store/TimelineStore";
import { convertTimeToUnits } from "../../../../utils/dom/convertTimeToUnits";
import { convertUnitsToTime } from "../../../../utils/dom/convertUnitsToTime";
import { generateClipIdentifierClass } from "../../utils/generateClipIdentifierClass";
import { InteractiveClip, ResizeDirectionEnum } from "../../utils/InteractiveClip";
import { updateAdjacency } from "../../utils/updateLayerClipsAdjacency";
import { RendleyService } from "../../../../services/RendleyService";
import { ClipDragOverDividerEvent, TimelineEventsEnum } from "../../types/timeline.types";
import { CLIP_CLASSNAME, TIMELINE_TRACKS_CONTAINER_ID, TRACK_CLASSNAME } from "../../config/constants";
import './Clip.styles.scss';

const SNAP_THRESHOLD = 10;

interface ClipProps {
    clipId: string;
    layerId: string;
    filename?: string;
    thumbnail?: string;
    backgroundColor?: string;
    children?: React.ReactNode;
}

export const Clip: React.FC<ClipProps> = observer(({ clipId, layerId, filename = "Unknown", thumbnail, backgroundColor = "43,98,253", children }) => {
    const [startTime, setStartTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [leftTrim, setLeftTrim] = useState(0);
    const [rightTrim, setRightTrim] = useState(0);
    const [trimmedDuration, setTrimmedDuration] = useState(0);
    const [width, setWidth] = useState(200);


    const xRef = useRef(0);
    const yRef = useRef(0);

    const startTimeRef = useRef(0);
    const durationRef = useRef(0);
    const leftTrimRef = useRef(0);

    const [x, setX] = useState(0);
    const [y, setY] = useState(0);

    const [isFocused, setIsFocused] = useState(false);
    const [hasSuccessor, setHasSuccessor] = useState(false);
    const [hasPredecessor, setHasPredecessor] = useState(false);

    const elementRef = useRef<HTMLDivElement>(null);
    const interactionRef = useRef<InteractiveClip | null>(null);
    const initialPositionRef = useRef({ top: 0, height: 0, left: 0, width: 0, x: 0, clientXOffset: 0, clientYOffset: 0 });
    const layerSnapPositionsRef = useRef<{ top: number; height: number; layerId: string }[]>([]);
    const clipSnapPositionsRef = useRef<{ x: number; left: number; width: number; clipId: string }[]>([]);
    const focusEventRef = useRef<MouseEvent | null>(null);

    const clipStyle = backgroundColor ? {
        '--clip-background-color': backgroundColor
    } as React.CSSProperties : {};

    useEffect(() => {
        const disposeReaction = reaction(
            () => TimelineStore.zoom,
            () => {
                const clip = Engine.getInstance().getClipById(clipId);
                if (clip == null) return;
                xRef.current = convertTimeToUnits(clip.getStartTime() + clip.getLeftTrim());
                setX(xRef.current);
                setWidth(convertTimeToUnits(clip.getTrimmedDuration()));
            }
        );

        return () => {
            disposeReaction();
        };
    }, [clipId]);

    useEffect(() => {
        const disposer = reaction(
            () => ({
                clip: RendleyStore.clips?.[clipId],
                selectedClipId: ApplicationStore.selectedClipId,
            }),
            ({ clip, selectedClipId }) => {
                if (clip == null) return;
                startTimeRef.current = clip.startTime;
                setStartTime(clip.startTime);
                durationRef.current = clip.duration;
                setDuration(clip.duration);
                leftTrimRef.current = clip.leftTrim;
                setLeftTrim(clip.leftTrim);
                setRightTrim(clip.rightTrim);
                setTrimmedDuration(clip.trimmedDuration);
                setHasPredecessor(clip.hasPredecessor ?? false);
                setHasSuccessor(clip.hasSuccessor ?? false);
                setIsFocused(selectedClipId === clipId);
            },
            { fireImmediately: true }
        );

        return () => {
            disposer();
        };
    }, [clipId]);

    useEffect(() => {
        xRef.current = convertTimeToUnits(startTimeRef.current + leftTrimRef.current);
        setX(xRef.current);
        setWidth(convertTimeToUnits(trimmedDuration));
        updateAdjacency(layerId);
        handlePostUpdateClip();
    }, [startTimeRef.current, leftTrimRef.current, trimmedDuration, layerId]);

    useEffect(() => {
        if (elementRef.current == null || elementRef.current.parentElement?.parentElement == null) return;

        interactionRef.current = new InteractiveClip(elementRef.current, elementRef.current.parentElement.parentElement, {
            onDragStart: dragStart,
            onResizeStart: resizeStart,
            onDrag: drag,
            onResize: resize,
            onResizeEnd: resizeEnd,
            onDragEnd: dragEnd,
        });

        handleUpdateStyles();

        return () => {
            interactionRef.current?.destroy();
        };
    }, []);

    useEffect(() => {
        handleUpdateStyles();
    }, [hasPredecessor, hasSuccessor, width, xRef.current, yRef.current]);

    const handleFocusChange = () => {
        if (interactionRef.current == null) {
            // give the interaction enough time to mount
            setTimeout(() => {
                handleFocusChange();
            });
            return;
        }

        if (isFocused) {
            // @ts-ignore
            interactionRef.current.mount(focusEventRef.current);
        } else {
            interactionRef.current.destroy();
            focusEventRef.current = null;
        }

        if (elementRef.current != null) {
            elementRef.current.style.zIndex = isFocused ? "2" : "1";
        }
    };

    useEffect(() => {
        handleFocusChange();
    }, [isFocused]);

    const handleUpdateStyles = () => {
        if (elementRef.current == null) return;

        if (hasPredecessor && hasSuccessor) {
            elementRef.current.style.width = `calc(${width}px - 2px)`;
            elementRef.current.style.transform = `translate(calc(${xRef.current}px + 1px), ${yRef.current}px)`;
        } else if (hasSuccessor) {
            elementRef.current.style.width = `calc(${width}px - 1px)`;
            elementRef.current.style.transform = `translate(${xRef.current}px, ${yRef.current}px)`;
        } else if (hasPredecessor) {
            elementRef.current.style.width = `calc(${width}px - 1px)`;
            elementRef.current.style.transform = `translate(calc(${xRef.current}px + 1px), ${yRef.current}px)`;
        } else {
            elementRef.current.style.width = `${width}px`;
            elementRef.current.style.transform = `translate(${xRef.current}px, ${yRef.current}px)`;
        }

        interactionRef.current?.moveable?.updateRect();
    };

    const drag = (clientX: number, clientY: number) => {

        const deltaX = clientX - initialPositionRef.current.left;
        let newX = initialPositionRef.current.x + deltaX - initialPositionRef.current.clientXOffset;
        let nextY = clientY - initialPositionRef.current.top - initialPositionRef.current.clientYOffset;

        const { dividerIndex, layerSnapPositionIndex } = findLayerSnapPosition(clientY);

        const currentClipLeft = newX;
        const currentClipRight = currentClipLeft + width;

        for (let i = 0; i < clipSnapPositionsRef.current.length; i++) {
            const { x: clipX, width: clipWidth } = clipSnapPositionsRef.current[i];
            const clipRight = clipX + clipWidth;

            const diffLeft = Math.abs(currentClipLeft - clipX);
            const diffRight = Math.abs(currentClipRight - clipRight);
            const diffRightLeft = Math.abs(currentClipRight - clipX);
            const diffLeftRight = Math.abs(currentClipLeft - clipRight);

            if (diffLeft <= SNAP_THRESHOLD) {
                newX = clipX;
                break;
            } else if (diffRight <= SNAP_THRESHOLD) {
                newX = clipRight - width;
                break;
            } else if (diffRightLeft <= SNAP_THRESHOLD) {
                newX = clipX - width;
                break;
            } else if (diffLeftRight <= SNAP_THRESHOLD) {
                newX = clipRight;
                break;
            }
        }

        if (layerSnapPositionIndex !== -1) {
            nextY = layerSnapPositionsRef.current[layerSnapPositionIndex].top - initialPositionRef.current.top;
            dispatchClipDragOverDividerEvent();
        }

        if (dividerIndex !== -1) {
            dispatchClipDragOverDividerEvent(layerSnapPositionsRef.current.length - dividerIndex);
        }

        yRef.current = nextY;
        setY(nextY);
        xRef.current = newX;
        setX(newX);
    };

    const resize = (clientX: number, direction: ResizeDirectionEnum) => {
        if (RendleyStore.clips?.[clipId]?.type === ClipTypeEnum.SUBTITLES) {
            return;
        }

        const MARGIN_PADDING = 6;

        let deltaX = 0;
        let newWidth = initialPositionRef.current.width;

        if (direction === ResizeDirectionEnum.LEFT) {
            deltaX = clientX - initialPositionRef.current.left - MARGIN_PADDING;
            newWidth = initialPositionRef.current.left + initialPositionRef.current.width - clientX + MARGIN_PADDING;
        } else if (direction === ResizeDirectionEnum.RIGHT) {
            newWidth = clientX - initialPositionRef.current.left + MARGIN_PADDING;
            deltaX = 0;
        }

        let newX = initialPositionRef.current.x + deltaX;
        const currentClipLeft = newX;
        const currentClipRight = currentClipLeft + newWidth;

        for (let i = 0; i < clipSnapPositionsRef.current.length; i++) {
            const { x: clipX, width: clipWidth } = clipSnapPositionsRef.current[i];
            const clipRight = clipX + clipWidth;

            const diffLeft = Math.abs(currentClipLeft - clipX);
            const diffRight = Math.abs(currentClipRight - clipRight);
            const diffRightLeft = Math.abs(currentClipRight - clipX);
            const diffLeftRight = Math.abs(currentClipLeft - clipRight);

            if (direction === ResizeDirectionEnum.LEFT) {
                if (diffLeft <= SNAP_THRESHOLD) {
                    newX = clipX;
                    newWidth = initialPositionRef.current.width + (initialPositionRef.current.x - newX);
                    break;
                } else if (diffLeftRight <= SNAP_THRESHOLD) {
                    newX = clipRight;
                    newWidth = initialPositionRef.current.width + (initialPositionRef.current.x - newX);
                    break;
                }
            } else if (direction === ResizeDirectionEnum.RIGHT) {
                if (diffRight <= SNAP_THRESHOLD) {
                    newWidth = clipRight - currentClipLeft;
                    break;
                } else if (diffRightLeft <= SNAP_THRESHOLD) {
                    newWidth = clipX - currentClipLeft;
                    break;
                }
            }
        }

        xRef.current = newX;
        setX(newX);
        setWidth(newWidth);
    };

    const resizeStart = () => {
        if (RendleyStore.clips?.[clipId]?.type === ClipTypeEnum.SUBTITLES) {
            return;
        }

        if (!elementRef.current) {
            return;
        }

        const { top, height, left, width } = elementRef.current.getBoundingClientRect();
        initialPositionRef.current = { top, height, left, width, x: xRef.current, clientXOffset: 0, clientYOffset: 0 };

        TimelineStore.setDraggingClip(clipId, true);
        setClipSnapPositions();
    };

    const resizeEnd = (direction: ResizeDirectionEnum, distance: number) => {
        const clip = RendleyService.getClipById(clipId);
        if (!clip) return;

        if (RendleyStore.clips?.[clipId]?.type === ClipTypeEnum.SUBTITLES) {
            return;
        }

        const distanceToTime = convertUnitsToTime(distance);
        if (direction === ResizeDirectionEnum.LEFT) {
            clip.setLeftTrim(clip.getLeftTrim() - distanceToTime);
        } else if (direction === ResizeDirectionEnum.RIGHT) {
            clip.setRightTrim(clip.getRightTrim() - distanceToTime);
        }

        handlePostUpdateClip();
        TimelineStore.setDraggingClip(clipId, false);

        clipSnapPositionsRef.current = [];
    };

    const dragStart = (clientX: number, clientY: number) => {
        if (!elementRef.current) {
            return;
        }

        const { top, height, left, width } = elementRef.current.getBoundingClientRect();

        initialPositionRef.current = {
            top,
            height,
            left,
            width,
            x: xRef.current,
            clientXOffset: clientX - left,
            clientYOffset: clientY - top,
        };

        TimelineStore.setDraggingClip(clipId, true);
        setLayerSnapPositions();
        setClipSnapPositions();
    };

    const dragEnd = (clientX: number, clientY: number) => {
        const { dividerIndex, layerSnapPositionIndex } = findLayerSnapPosition(clientY);
        dispatchClipDragOverDividerEvent();

        if (layerSnapPositionIndex !== -1) {
            yRef.current = layerSnapPositionsRef.current[layerSnapPositionIndex].top - initialPositionRef.current.top;

            const { layerId: newLayerId } = layerSnapPositionsRef.current[layerSnapPositionIndex];

            if (layerId !== newLayerId) {
                RendleyService.moveClipToLayer(clipId, newLayerId);
            }
        } else {
            yRef.current = 0

            const layer = RendleyService.createLayer(layerSnapPositionsRef.current.length - dividerIndex);
            RendleyService.moveClipToLayer(clipId, layer.id);
        }

        handlePostUpdateClip();

        layerSnapPositionsRef.current = [];
        clipSnapPositionsRef.current = [];
    };

    const dispatchClipDragOverDividerEvent = (index: number = -1) => {
        const event = new CustomEvent<ClipDragOverDividerEvent>(TimelineEventsEnum.CLIP_DRAG_OVER_DIVIDER, {
            detail: { clipId, index },
        });
        document.dispatchEvent(event);
    };

    const findLayerSnapPosition = (clientY: number) => {
        let dividerIndex = -1;
        let layerSnapPositionIndex = -1;

        if (!layerSnapPositionsRef.current.length) {
            return { dividerIndex, layerSnapPositionIndex };
        }

        const firstSnapPosition = layerSnapPositionsRef.current[0];
        const lastSnapPosition = layerSnapPositionsRef.current[layerSnapPositionsRef.current.length - 1];

        if (clientY <= firstSnapPosition.top) {
            dividerIndex = 0;
        }

        if (clientY >= lastSnapPosition.top + lastSnapPosition.height) {
            dividerIndex = layerSnapPositionsRef.current.length;
        }

        if (dividerIndex > -1) {
            return { dividerIndex, layerSnapPositionIndex };
        }

        for (let i = 0; i < layerSnapPositionsRef.current.length; i++) {
            const { top: trackTop, height: trackHeight } = layerSnapPositionsRef.current[i];
            const nextPosition = layerSnapPositionsRef.current[i + 1];

            if (clientY >= trackTop && clientY <= trackTop + trackHeight) {
                layerSnapPositionIndex = i;
                break;
            }

            if (clientY >= trackTop + trackHeight && clientY <= nextPosition?.top) {
                dividerIndex = i + 1;
                break;
            }
        }

        return { dividerIndex, layerSnapPositionIndex };
    };

    const setLayerSnapPositions = () => {
        const tracks = document.querySelectorAll(`.${TRACK_CLASSNAME}`);
        layerSnapPositionsRef.current = Array.from(tracks).map((track) => {
            // @ts-ignore
            const { top, height } = track.getBoundingClientRect();
            // @ts-ignore
            return { top, height, layerId: track.id };
        });
    };

    const setClipSnapPositions = () => {
        const clips = document.querySelectorAll(`.${CLIP_CLASSNAME}`);
        const tracksContainer = document.getElementById(TIMELINE_TRACKS_CONTAINER_ID);
        const offsetLeft = tracksContainer?.offsetLeft ?? 0;

        // @ts-ignore
        // @ts-ignore

        clipSnapPositionsRef.current = Array.from(clips)
            // @ts-ignore
            .filter((clip) => clip.id !== clipId)
            .map((clip) => {
                // @ts-ignore
                const { left, width } = clip.getBoundingClientRect();
                // @ts-ignore
                return { left, width, x: left - offsetLeft, clipId: clip.id };
            });
    };

    const handlePostUpdateClip = () => {
        const clip = Engine.getInstance().getClipById(clipId);

        if (clip == null) {
            return;
        }

        const startTime = convertUnitsToTime(xRef.current) - clip.getLeftTrim();

        clip.setStartTime(startTime);
        startTimeRef.current = startTime;
        setStartTime(startTime);

        Engine.getInstance().getTimeline().adjustClipsLayout();

        RendleyStore.updateClip(clipId, { startTime: clip.getStartTime() });

        xRef.current = convertTimeToUnits(clip.getStartTime() + clip.getLeftTrim());
        setX(xRef.current);
        setWidth(convertTimeToUnits(clip.getTrimmedDuration()));

        TimelineStore.setDraggingClip(clipId, false);

        updateAdjacency(layerId);
    };

    const handleMouseDown = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (isFocused) {
            return;
        }

        focusEventRef.current = event.nativeEvent;
        ApplicationStore.setSelectedClipId(clipId);
    };

    return (
        <div
            ref={elementRef}
            id={clipId}
            style={clipStyle}
            className={clsx(CLIP_CLASSNAME, generateClipIdentifierClass(clipId), {
                clip__focused: isFocused,
                clip__predecessor: hasPredecessor,
                clip__successor: hasSuccessor,
            })}
            onMouseDown={handleMouseDown}
        >
            <div className="clip__content">
                {thumbnail != null && (
                    <div className="clip__thumbnail-container">
                        <div className="clip__overlay"></div>
                        <div className="clip__thumbnail" style={{ backgroundImage: `url(${thumbnail})` }} />
                    </div>
                )}

                <div className="clip__content__icon">
                    {/* In React, we don't have slots. You might want to pass this as a prop or children */}
                    {/* <slot name="icon"></slot> */}
                    {children}
                </div>

                <div className="clip__content__text">
                    <b>{filename}</b>
                </div>
            </div>
        </div>
    );
});

export default Clip;

