import {Button, Card, CardActionArea, CardContent, ClickAwayListener, IconButton, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import AudioFileIcon from '@mui/icons-material/AudioFile';
import React, {useContext, useEffect, useState} from "react";
import UploadIcon from "@mui/icons-material/Upload";
import {FetchContext} from "../../../context/FetchContext";
import {AuthContext} from "../../../context/AuthContext";
import {
    determineTrackThumbnailUrl,
    formatToReadableShortTitle,
    formatUnixTime,
    IsUploadedTrackType
} from "../../../utils/utils";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import {ArrowBackIos} from "@mui/icons-material";
import './customScrollbar.css';

const TrackThumbnail = ({track}) => {
    return (
        <img src={determineTrackThumbnailUrl(track)}
             alt={track.title} style={{width: '85px', height: '50px', borderRadius: '4px'}}/>
    )
}


const AddTrackCard = ({ closeCard, top, left, assetId, addTrack, addTrackIndex, loadTracks}) => {

    const [showUploads, setShowUploads] = useState(false);
    const [showRecordings, setShowRecordings] = useState(false);
    const [showTrackList, setShowTrackList] = useState(false);

    const [projectTracks, setProjectTracks] = useState(null);

    const handleShowRecordingsClick = () => {
        setShowUploads(false);
        setShowRecordings(true);
        setShowTrackList(true);
    }

    const handleShowUploadsClick = () => {
        setShowRecordings(false);
        setShowUploads(true);
        setShowTrackList(true);
    }

    useEffect(() => {
        loadTracks({assetId}).then((tracks) => {
            setProjectTracks(tracks);
        });
    }, [assetId]);

    const handleTrackCardClick = (trackId) => {
        addTrack(trackId, addTrackIndex);
        clickAwayHandler();
    }

    const filteredProjectTracks = projectTracks && projectTracks.filter(track => showUploads ? IsUploadedTrackType(track.trackType) : !IsUploadedTrackType(track.trackType));

    const clickAwayHandler = () => {
        setShowTrackList(false);
        setShowUploads(false);
        setShowRecordings(false);
        closeCard();
    }

    if (showTrackList) {
        return (
            <ClickAwayListener onClickAway={clickAwayHandler}>
                <Card
                    elevation={5}
                    className="custom-scrollbar"
                    style={{ cursor: 'pointer', position: 'absolute',
                        top: top - 550,
                        left: left - 37,
                        width: 310,
                        height: 500,
                        padding: 10,
                        gap: 10,
                        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark background
                        borderRadius: '16px', // Rounded corners
                        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
                        overflow: 'visible',
                        overflowY: 'auto',
                        '--top': `${top - 550}px`, '--left': `${left - 37}px`,
                        zIndex: 1000,
                    }}
                >
                    <Grid container direction="column">
                        <Box flexDirection="row" alignItems="center" display="flex">
                            <Tooltip title={"Back"} placement="top" arrow>
                                <IconButton onClick={() => setShowTrackList(false)} variant="contained">
                                    <ArrowBackIos style={{fontSize: "1.5rem", color: "white"}}/>
                                </IconButton>
                            </Tooltip>
                            <Typography variant="h6" marginBottom={0} color="white" fontWeight={800}>
                                {showRecordings ? "Recordings" : "Uploads"}
                            </Typography>
                        </Box>
                        <br/>
                        {projectTracks && filteredProjectTracks.map((track) => (
                            <Card key={track.trackId} sx={{ mb: 2, '&:hover': { boxShadow: 6 } }}>
                                <CardActionArea onClick={() => handleTrackCardClick(track.trackId)}>
                                    <CardContent>
                                            <Grid container alignItems="center" justifyContent="space-between">
                                                <Grid item xs={6}>
                                                    <TrackThumbnail track={track}/>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography color={'text.primary'} variant={'subtitle1'} fontFamily="lato">
                                                        {formatToReadableShortTitle(track.title)}
                                                    </Typography>
                                                    <Typography variant={'caption'} fontFamily="lato">{formatUnixTime(track.createTime)}</Typography>
                                                </Grid>
                                            </Grid>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        ))}
                    </Grid>
                </Card>
            </ClickAwayListener>
        );
    }

    return (
        <ClickAwayListener onClickAway={clickAwayHandler}>
            <Card
                elevation={5}
                style={{ cursor: 'pointer', position: 'absolute',
                    top: top - 150,
                    left: left - 37,
                    width: 230,
                    height: 100,
                    padding: 10,
                    gap: 10,
                    backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark background
                    borderRadius: '16px', // Rounded corners
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
                    overflow: 'visible',
                    zIndex: 1000,
                }}
            >
                <Grid container direction="column">
                    <Grid item>
                        <Button
                            variant="text"
                            style={{ height: '40px', borderRadius: '10px', justifyContent: 'flex-start' }} // Adjust button colors as needed
                            fullWidth
                            startIcon={<AudioFileIcon style={{color: "white"}}/>}
                            onClick={handleShowRecordingsClick}
                        >
                            <Typography fontWeight={600} color="white">
                                Recordings
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="text"
                            style={{ height: '40px', borderRadius: '10px', justifyContent: 'flex-start' }} // Adjust button colors as needed
                            fullWidth
                            startIcon={<UploadIcon style={{color: "white"}}/>}
                            onClick={handleShowUploadsClick}
                        >
                            <Typography fontWeight={600} color="white">
                                Uploads
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </ClickAwayListener>
    );
}

export default AddTrackCard;
