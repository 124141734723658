/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Button, ButtonBase} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {sendEmailVerification} from "firebase/auth";

const SignUpMessage = ({signUpStateMessage}) => {
    const theme = useTheme();
    const { mode } = theme.palette;

    const textMessage = signUpStateMessage.message;

    const user = signUpStateMessage.user;

    return (
        <Box sx={{ width: '90%' }}>
            {/* Logo Section */}
            <Box sx={{ marginBottom: 5 }}>
                <ButtonBase>
                    <Box
                        display={'flex'}
                        component="a"
                        href="/"
                        title="Libretto"
                        width={{ xs: 150, md: 180 }} // Increased size values
                        height={{ xs: 'auto', md: 'auto' }} // Set height to auto for maintaining aspect ratio
                        alignItems="center" // To center the image vertically if needed
                        justifyContent="center" // To center the image horizontally if needed
                    >
                        <Box
                            component={'img'}
                            src={'/logo-new.svg'}
                            height={1}
                            width={1}
                        />
                    </Box>
                </ButtonBase>
            </Box>

            {/* Text Section */}
            <Box sx={{ marginBottom: theme.spacing(3) }}>
                <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: theme.spacing(1) }}>
                    {textMessage}
                </Typography>
                {user && <Button variant="contained" onClick={() => sendEmailVerification(user)}>Resend Verification Email</Button>}
                {/* Optional: Add more login options here */}
            </Box>

            {/* Additional Info or Links */}
            <Box>
                {/* You can add terms and conditions or other relevant links here */}
            </Box>
        </Box>
    );
};

export default SignUpMessage;
