import React from 'react';
import clsx from 'clsx';
import './Paper.styles.scss';

type PaperDirection = "row" | "column";

interface PaperProps {
    direction?: PaperDirection;
    children: React.ReactNode;
}

const Paper: React.FC<PaperProps> = ({ direction = "row", children }) => {
    return (
        <div className={clsx("paper", `paper__${direction}`)}>
            {children}
        </div>
    );
};

export default Paper;