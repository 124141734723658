import React from 'react';
import clsx from 'clsx';
import './Button.styles.scss';

type ButtonVariant = "default" | "ghost" | "primary";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: ButtonVariant;
    icon?: React.ReactNode;
    children?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
                                           variant = "ghost",
                                           disabled,
                                           className,
                                           icon,
                                           children,
                                           ...rest
                                       }) => {
    return (
        <button
            className={clsx("btn", `btn__${variant}`, className)}
            disabled={disabled}
            {...rest}
        >
            {icon && <span className="btn__icon">{icon}</span>}
            {children}
        </button>
    );
};

export default Button;