import React, {useState, useRef, useEffect} from 'react';
import {styled} from '@mui/material/styles';
import {Box, Typography, Slider} from '@mui/material';

import {
    Forward15Icon,
    MaximizeVideoIcon,
    MinimizeVideoIcon,
    PauseIcon,
    PlayIcon,
    Rewind15Icon,
    VerticalEllipsisIcon,
    VolumeHighIcon,
    VolumeLowIcon,
    VolumeMutedIcon,
    LargePlayButtonIcon,
} from './player_icons';
import {formatToReadableLargeTitle, formatToReadableTitle} from "../../utils/utils";

const PlayerWrapper = styled(Box)(({theme}) => ({
    position: 'relative',
    height: '480px',
    width: '854px',
    backgroundColor: '#08090D',
    overflow: 'hidden',
    borderRadius: '25px',
    // marginBottom: '60px',
    // marginTop: '60px',
    boxShadow: "0px 30px 100px 0px rgba(0, 0, 0, 0.05)",
}));

const Controls = styled(Box)(({theme, show}) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px 40px',
    background:
        'linear-gradient(180deg, rgba(8, 9, 13, 0.00) 0%, rgba(8, 9, 13, 0.90) 80.37%)',
    backdropFilter: 'blur(1px)',
    transition: 'transform 0.5s ease, opacity 0.5s ease',
    transform: show ? 'translateY(0)' : 'translateY(100%)', // Slide effect
    opacity: show ? 1 : 0,
    pointerEvents: show ? 'auto' : 'none', // Disable interactions when hidden
}));

const ControlButton = styled('button')(({theme}) => ({
    color: 'white',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
        color: 'rgba(255, 255, 255, 0.7)',
    },
    '&:focus': {
        outline: 'none',
    },
}));

const PlayButtonWrapper = styled(Box)(({theme, show}) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: show ? 'flex' : 'none',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    zIndex: 2, // Ensure the play button is above the video
}));

const AssetVideoPlayer = ({src, title}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(1);
    const [isMuted, setIsMuted] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [showControls, setShowControls] = useState(false);
    const videoRef = useRef(null);
    const playerRef = useRef(null);

    const [videoDimensions, setVideoDimensions] = useState({width: 854, height: 480}); // Initial aspect ratio

    const maxPlayerWidth = window.innerWidth * 0.6; // Set your max width here
    const maxPlayerHeight = window.innerHeight * 0.7; // Set your max height here

    const handleLoadedMetadata = () => {
        if (!videoRef.current) return;

        const videoWidth = videoRef.current.videoWidth;
        const videoHeight = videoRef.current.videoHeight;

        // Calculate the aspect ratio
        const aspectRatio = videoWidth / videoHeight;

        // Determine the new width and height based on aspect ratio and max constraints
        let newWidth = Math.min(maxPlayerWidth, maxPlayerHeight * aspectRatio);
        let newHeight = Math.min(maxPlayerHeight, maxPlayerWidth / aspectRatio);

        setVideoDimensions({width: newWidth, height: newHeight});
        setDuration(videoRef.current.duration);
    };

    const togglePlay = () => {
        if (!videoRef.current) return; // Ensure the videoRef is correctly set

        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
            setShowControls(true); // Show controls once video starts playing
        }
        setIsPlaying(!isPlaying);
    };

    const handleTimeUpdate = () => {
        if (!videoRef.current) return;
        const current = videoRef.current.currentTime;
        const dur = videoRef.current.duration;
        setProgress((current / dur) * 100);
        setCurrentTime(current);
    };

    const handleVolumeChange = (event, newValue) => {
        if (!videoRef.current) return;
        const newVolume = newValue / 100;
        setVolume(newVolume);
        videoRef.current.volume = newVolume;
        setIsMuted(newVolume === 0);
    };

    const toggleMute = () => {
        if (!videoRef.current) return;
        if (isMuted) {
            videoRef.current.volume = volume;
        } else {
            videoRef.current.volume = 0;
        }
        setIsMuted(!isMuted);
    };

    const handleProgressChange = (event, newValue) => {
        if (!videoRef.current) return;
        const newTime = (newValue / 100) * duration;
        videoRef.current.currentTime = newTime;
        setProgress(newValue);
    };

    const toggleFullscreen = () => {
        if (!document.fullscreenElement) {
            playerRef.current.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(!!document.fullscreenElement);
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);
        return () =>
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
    }, []);

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = Math.floor(time % 60);
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return formattedTime.includes('00:')
            ? formattedTime.slice(3)
            : formattedTime;
    };

    const isVerticalVideo = videoDimensions.width < videoDimensions.height;

    return (
        <PlayerWrapper ref={playerRef} sx={{
            width: `${videoDimensions.width}px`,
            height: `${videoDimensions.height}px`,
        }}>
            <video
                ref={videoRef}
                // style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                style={{objectFit: 'cover', width: '100%', height: '100%'}}
                poster=""
                src={src}
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={handleLoadedMetadata}
            />
            <PlayButtonWrapper show={!isPlaying} onClick={togglePlay}>
                <LargePlayButtonIcon size={120}/>
            </PlayButtonWrapper>
            <Controls show={showControls}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 0.25,
                    }}
                >
                    <Typography sx={titleStyle}>{isVerticalVideo ? formatToReadableTitle(title) : formatToReadableLargeTitle(title)}</Typography>
                    <Typography sx={durationStyle}>
                        {formatTime(currentTime)} / {formatTime(duration)}
                    </Typography>
                </Box>
                <Slider
                    value={progress}
                    onChange={handleProgressChange}
                    sx={progressSliderStyle}
                />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'center', gap: 1.25}}>
                        <ControlButton onClick={togglePlay}>
                            {isPlaying ? <PauseIcon size={18}/> : <PlayIcon size={18}/>}
                        </ControlButton>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                        <ControlButton onClick={toggleMute}>
                            {isMuted ? (
                                <VolumeMutedIcon size={18}/>
                            ) : volume > 0.5 ? (
                                <VolumeHighIcon size={18}/>
                            ) : (
                                <VolumeLowIcon size={18}/>
                            )}
                        </ControlButton>
                        <Slider
                            value={Math.round(volume * 100)}
                            onChange={handleVolumeChange}
                            sx={volumeSliderStyle}
                            min={0}
                            max={100}
                        />
                        <ControlButton onClick={toggleFullscreen}>
                            {isFullscreen ? (
                                <MinimizeVideoIcon size={18}/>
                            ) : (
                                <MaximizeVideoIcon size={18}/>
                            )}
                        </ControlButton>
                    </Box>
                </Box>
            </Controls>
        </PlayerWrapper>
    );
};

const titleStyle = {
    color: 'white',
    fontFamily: 'Helvetica Neue, sans-serif',
    fontSize: '18px',
    fontWeight: 'normal',
    lineHeight: '24px',
};

const durationStyle = {
    color: 'white',
    fontFamily: 'Helvetica Neue, sans-serif',
    fontSize: '14px',
    fontWeight: '300',
    // lineHeight: '16px',
};

const progressSliderStyle = {
    width: '100%',
    mb: 0.1,
    mx: 1,
    color: 'white',
    '& .MuiSlider-track': {height: 1.2},
    '& .MuiSlider-rail': {height: 2.4, backgroundColor: '#D9D9D9'},
    '& .MuiSlider-thumb': {width: 10, height: 10},
};

const volumeSliderStyle = {
    width: 100, // Adjust width as needed
    color: 'white',
    mx: 0.05,
    '& .MuiSlider-track': {height: 1.01},
    '& .MuiSlider-rail': {height: 2.0, backgroundColor: '#D9D9D9'},
    '& .MuiSlider-thumb': {width: 8, height: 8},
};

export default AssetVideoPlayer;
