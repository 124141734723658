import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {
    ClipTypeEnum,
    Engine,
    HighlightAnimationEnum,
    MainTextStyle, SubtitlesClip,
    TextModeType,
} from '@rendley/sdk';
import {SubtitlesStylesDescription} from '../../../../config/config';
import {RendleyStore} from '../../../../store/RendleyStore';
import {ApplicationStore} from '../../../../store/ApplicationStore';
// Assuming these components are available as React components
import PanelGridListSection from "../../../../components/PanelGridListSection/PanelGridListSection";
import EffectShowcaseCard from "../../../../components/EffectsShowcaseCard/EffectShowcaseCard";
import {reaction} from "mobx";
import {ClipsToTimelineItems, GetSRTSubtitleUrl} from "../../../../../pages/ModernEditor/MultitrackTranscript";
import {RendleyService} from "../../../../services/RendleyService";
import {useStorage} from "@liveblocks/react/suspense";

interface StyleConfiguration {
    name?: string;
    value: string;
    thumbnailUrl: string;
    videoPreviewUrl?: string;
}

// @ts-ignore
const SubtitlesStylesContainer = observer(({}) => {
    const [modes, setModes] = useState<StyleConfiguration[]>([]);
    const [animations, setAnimations] = useState<StyleConfiguration[]>([]);
    const [styles, setStyles] = useState<StyleConfiguration[]>([]);
    const [enabledDisabled, setEnabledDisabled] = useState<StyleConfiguration[]>([]);

    const [selectedMode, setSelectedMode] = useState(RendleyStore.subtitlesStyles.selectedMode);
    const [selectedStyle, setSelectedStyle] = useState(RendleyStore.subtitlesStyles.selectedStyle);
    const [selectedAnimation, setSelectedAnimation] = useState(RendleyStore.subtitlesStyles.selectedAnimation);
    const [selectedEnabled, setSelectedEnabled] = useState(RendleyStore.subtitlesStyles.selectedEnabled);

    const corrections = useStorage((root) => root.corrections);

    useEffect(() => {
        const disposer = reaction(
            () => ({
                selectedMode: RendleyStore.subtitlesStyles.selectedMode,
                selectedStyle: RendleyStore.subtitlesStyles.selectedStyle,
                selectedAnimation: RendleyStore.subtitlesStyles.selectedAnimation,
                selectedEnabled: RendleyStore.subtitlesStyles.selectedEnabled,
            }),
            ({selectedMode, selectedStyle, selectedAnimation}) => {
                setSelectedMode(selectedMode);
                setSelectedStyle(selectedStyle);
                setSelectedAnimation(selectedAnimation);
                setSelectedEnabled(selectedEnabled);
            });
        return () => {
            disposer(); // Dispose of the autorun when the component unmounts
        };
    }, []);

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        const subtitleStylesRoot = ApplicationStore.subtitleStylesPath;
        fetch(subtitleStylesRoot + 'subtitles.json')
            .then((data) => data.json())
            .then((data: SubtitlesStylesDescription) => {
                setModes(
                    data.modes.map((mode) => ({
                        name: mode.name,
                        value: mode.value,
                        thumbnailUrl: subtitleStylesRoot + mode.path + 'thumbnail.webp',
                        videoPreviewUrl: subtitleStylesRoot + mode.path + 'preview.mp4',
                    }))
                );
                setAnimations(
                    data.animations.map((animation) => ({
                        name: animation.name,
                        value: animation.value,
                        thumbnailUrl: subtitleStylesRoot + animation.path + 'thumbnail.webp',
                        videoPreviewUrl: subtitleStylesRoot + animation.path + 'preview.mp4',
                    }))
                );
                setStyles(
                    data.styles.map((style) => ({
                        name: style.name,
                        value: subtitleStylesRoot + style.path + 'data.json',
                        thumbnailUrl: subtitleStylesRoot + style.path + 'thumbnail.webp',
                        videoPreviewUrl: subtitleStylesRoot + style.path + 'preview.mp4',
                    }))
                );
            });
        setEnabledDisabled([{
            name: 'Enabled',
            value: 'true',
            thumbnailUrl: '/on.png',
            videoPreviewUrl: '/on.png',
        }, {
            name: 'Disabled', value: 'false', thumbnailUrl: '/off.png',
            videoPreviewUrl: '/off.png',
        }]);
    };


    const handleUpdateSubtitlesDuration = () => {
        const clips = Engine.getInstance().getTimeline().getClips();

        // Update all clips associated with the subtitle id
        clips?.forEach((clip) => {
            if (clip.getType() === ClipTypeEnum.SUBTITLES) {
                (clip as SubtitlesClip).updateDuration();
            }
        });

        Engine.getInstance().getTimeline().adjustClipsLayout();
    }

    const handleSetMode = (value: StyleConfiguration) => {
        const subtitlesManager = Engine.getInstance().getSubtitlesManager();
        RendleyStore.setSubtitlesMode(value.value);
        subtitlesManager.setTextMode(value.value as TextModeType);
    };

    const handleSetEnabled = async (value: boolean) => {
        RendleyStore.setSubtitlesEnabled(value);
        if (value) {
            const timelineLayers = ClipsToTimelineItems(RendleyStore.clips);
            const srtContent = GetSRTSubtitleUrl({timelineStateLayers: timelineLayers, corrections: corrections});

            const subtitlesClipId = await RendleyService.importSubtitlesSrt(srtContent);

            handleUpdateSubtitlesDuration();

            RendleyStore.setSubtitlesClipId(subtitlesClipId);
            ApplicationStore.setSelectedClipId(subtitlesClipId);
            return;
        }
        RendleyService.deleteClipById(RendleyStore.subtitlesStyles.subtitlesClipId);
        RendleyStore.setSubtitlesClipId(null);
    }

    const handleSetStyle = async (value: StyleConfiguration) => {
        const subtitlesManager = Engine.getInstance().getSubtitlesManager();
        if (value.value === RendleyStore.subtitlesStyles.selectedStyle) {
            RendleyStore.setSubtitlesStyle(null);
            const defaultStyles = {} as MainTextStyle;
            subtitlesManager.setMainTextStyle(defaultStyles, true);
            subtitlesManager.setHighlightedTextStyle(defaultStyles, true);
            return;
        }
        RendleyStore.setSubtitlesStyle(value.value);
        const data = await fetch(value.value).then((data) => data.json());
        subtitlesManager.setMainTextStyle(data.mainTextStyle, true);
        subtitlesManager.setHighlightedTextStyle(data.highlightTextStyle, true);
    };

    const handleSetAnimation = (value: StyleConfiguration) => {
        const subtitlesManager = Engine.getInstance().getSubtitlesManager();
        if (value.value === RendleyStore.subtitlesStyles.selectedAnimation) {
            RendleyStore.setSubtitlesAnimation(null);
            subtitlesManager.setHighlightAnimation(HighlightAnimationEnum.NONE);
            return;
        }
        RendleyStore.setSubtitlesAnimation(value.value);
        subtitlesManager.setHighlightAnimation(
            value.value as HighlightAnimationEnum,
            1
        );
    };

    return (
        <div style={{width: '100%', maxHeight: '600px', height: "600px", minWidth: "250px", paddingBottom: "40px"}}>
            <PanelGridListSection
                title={'Subtitles'}
                data={enabledDisabled}
                columns={2}
                renderCard={(mode) => (
                    <EffectShowcaseCard
                        key={mode.value}
                        isSelected={RendleyStore.subtitlesStyles.selectedEnabled === (mode.value === 'true')}
                        tooltip={mode.name}
                        imageUrl={mode.thumbnailUrl}
                        onMouseDown={() => handleSetEnabled(mode.value === 'true')}
                    />
                )}
            />
            <div className="spacer-2"></div>
            <PanelGridListSection
                title={'Mode'}
                data={modes}
                columns={1}
                renderCard={(mode: StyleConfiguration) => (
                    <EffectShowcaseCard
                        key={mode.value}
                        isSelected={RendleyStore.subtitlesStyles.selectedMode === mode.value}
                        tooltip={mode.name}
                        imageUrl={mode.thumbnailUrl}
                        onMouseDown={() => handleSetMode(mode)}
                    />
                )}
            />
            <div className="spacer-2"></div>
            <PanelGridListSection
                title={'Animations'}
                data={animations}
                columns={1}
                renderCard={(animation: StyleConfiguration) => (
                    <EffectShowcaseCard
                        key={animation.value}
                        isSelected={
                            RendleyStore.subtitlesStyles.selectedAnimation === animation.value
                        }
                        tooltip={animation.name}
                        imageUrl={animation.thumbnailUrl}
                        videoUrl={animation.videoPreviewUrl}
                        onMouseDown={() => handleSetAnimation(animation)}
                    />
                )}
            />
            <div className="spacer-2"></div>
            <PanelGridListSection
                title={'Styles'}
                data={styles}
                visibleItemsCount={styles.length}
                columns={1}
                renderCard={(style: StyleConfiguration) => (
                    <EffectShowcaseCard
                        key={style.value}
                        isSelected={RendleyStore.subtitlesStyles.selectedStyle === style.value}
                        // tooltip={style.name}
                        imageUrl={style.thumbnailUrl}
                        videoUrl={style.videoPreviewUrl}
                        onMouseDown={() => handleSetStyle(style)}
                    />
                )}
            />
            <div className="spacer-2"></div>
        </div>
    );
});

export default SubtitlesStylesContainer;
