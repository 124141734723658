export const ChevronDownIcon = () => {
    return (
        <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.5 5.25 7 8.75l3.5-3.5"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};