import React from 'react';
import Clip from '../Clip/Clip';  // Adjust the import path as needed

interface ShapeClipProps {
    clipId: string;
    layerId: string;
}

const ShapeClip: React.FC<ShapeClipProps> = ({ clipId, layerId }) => {
    return (
        <Clip clipId={clipId} layerId={layerId} filename="Shape" backgroundColor={"250, 238, 77"}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
            </svg>
        </Clip>
    );
};

export default ShapeClip;