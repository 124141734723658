export const ConfigError = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="70"
            height="70"
            x="0"
            y="0"
            viewBox="0 0 100 100"
            className=""
        >
            <g>
                <path
                    d="M52.9 90h31.9c1.9 0 3.5-1 4.5-2.6.9-1.6.9-3.5 0-5.2l-16-27.6c-.9-1.6-2.6-2.6-4.5-2.6s-3.5 1-4.5 2.6l-16 27.6c-.9 1.6-.9 3.5 0 5.2 1 1.6 2.7 2.6 4.6 2.6zm-2.3-6.5 16-27.6c.5-.8 1.3-1.3 2.2-1.3s1.8.5 2.2 1.3l16 27.6c.5.8.5 1.8 0 2.6s-1.3 1.3-2.2 1.3H52.9c-.9 0-1.8-.5-2.2-1.3-.5-.8-.5-1.7-.1-2.6z"
                    fill="#acacc1"
                    opacity="1"
                    data-original="#acacc1"
                ></path>
                <path
                    d="M68.7 77c.6 0 1.2-.4 1.3-1.1l.8-11.9v-.3c-.1-1.1-1-2-2.2-1.9-1.1.1-2 1-1.9 2.2l.8 11.8c.2.7.6 1.1 1.2 1.2z"
                    fill="#acacc1"
                    opacity="1"
                    data-original="#acacc1"
                ></path>
                <circle cx="68.8" cy="81.9" r="2" fill="#acacc1" opacity="1" data-original="#acacc1"></circle>
                <path
                    d="m33.9 86.7 4.6 1.7c.6.2 1.2.3 1.8.3 1.1 0 2.2-.4 3.2-1.1.6-.4.7-1.3.2-1.8-.4-.6-1.3-.7-1.8-.2-.7.6-1.6.7-2.4.4l-4.6-1.7c-1.1-.4-1.8-1.5-1.7-2.7l.8-7.4v-.5c0-.1-.1-.2-.1-.2 0-.1-.1-.1-.1-.2l-.2-.2-.1-.1c-1.1-.8-2.2-1.7-3.2-2.7l-.1-.1c-.1-.1-.1-.1-.2-.1s-.1-.1-.2-.1H29l-7.2 2c-1.1.3-2.4-.2-2.9-1.2l-2.5-4.3c-.6-1-.4-2.3.4-3.1l5.3-5.2c.3-.2.4-.6.4-.9V57c-.3-1.4-.6-2.8-.7-4.2v-.2c0-.1 0-.2-.1-.2 0-.1-.1-.2-.1-.2 0-.1-.1-.1-.1-.2-.1-.1-.2-.1-.2-.2l-.1-.1-6.8-3c-1.1-.5-1.7-1.6-1.5-2.8l.9-4.9c.2-1.2 1.2-2 2.4-2.1l7.4-.5h.1c.1 0 .2 0 .4-.1h.1l.3-.3v-.1c.1-.1.1-.2.2-.2.6-1.3 1.3-2.5 2.1-3.7 0-.1.1-.1.1-.2v-.1c.1-.1.1-.3.1-.5s0-.4-.1-.5L23.3 26c-.5-1.1-.2-2.3.7-3.1l3.8-3.2c.9-.8 2.2-.8 3.2-.1l6 4.4c.1.1.3.2.4.2h.4c.1 0 .2 0 .3-.1h.1c1.3-.6 2.7-1.1 4-1.5.1 0 .1-.1.1-.1.1 0 .2-.1.2-.1.1 0 .1-.1.2-.2l.2-.2c0-.1.1-.2.1-.2 0-.1.1-.1.1-.1l1.8-7.2c.3-1.2 1.3-2 2.5-2h4.9c1.2 0 2.2.8 2.5 2l1.8 7.2c0 .1.1.1.1.2s0 .1.1.2l.3.3.1.1c.1.1.3.2.4.2 1.3.4 2.7.9 4 1.5h.1c.1 0 .2.1.3.1h.4c.1 0 .3-.1.4-.2l6-4.4c1-.7 2.3-.7 3.2.1l3.8 3.2c.9.8 1.2 2 .7 3.1l-3.2 6.7c-.1.2-.1.3-.1.5s0 .3.1.5c0 0 0 .1.1.1 0 .1 0 .1.1.2.8 1.2 1.5 2.4 2.1 3.7 0 .1.1.2.2.2v.1l.3.3h.1c.1 0 .2.1.4.1h.1l7.4.5c1.2.1 2.2 1 2.4 2.1l.9 4.9c.2 1.2-.4 2.3-1.5 2.8l-6.8 3c-.4.2-.7.6-.7 1.1-.1.5-.1 1.1-.2 1.6-.1.7.3 1.4 1 1.5h.2c.6 0 1.2-.4 1.3-1.1.1-.3.1-.7.1-1l6.1-2.7c2.2-1 3.4-3.3 3-5.6l-.9-4.9c-.4-2.3-2.4-4.1-4.7-4.3l-6.7-.5c-.5-.9-1-1.8-1.6-2.7l2.9-6c1-2.1.5-4.7-1.3-6.2l-3.8-3.2c-1.8-1.5-4.4-1.6-6.4-.2L62 21.5c-1-.4-1.9-.8-2.9-1.1l-1.6-6.5c-.6-2.3-2.6-3.9-5-3.9h-4.9c-2.4 0-4.4 1.6-5 3.9L41 20.4c-1 .3-2 .7-2.9 1.1l-5.4-3.9c-1.9-1.4-4.5-1.3-6.4.2l-4 3.2c-1.8 1.5-2.3 4-1.3 6.2l2.9 6c-.6.9-1.1 1.8-1.6 2.7l-6.7.5c-2.4.2-4.3 1.9-4.7 4.3l-.9 4.9c-.4 2.3.8 4.6 3 5.6l6.1 2.7c.1 1 .3 2.1.5 3.1L15 61.6c-1.7 1.6-2.1 4.2-.9 6.3l2.5 4.3c.9 1.6 2.7 2.6 4.5 2.6.5 0 1-.1 1.4-.2l6.5-1.9c.8.7 1.5 1.4 2.4 2l-.7 6.7c-.4 2.3 1 4.5 3.2 5.3z"
                    fill="#acacc1"
                    opacity="1"
                    data-original="#acacc1"
                ></path>
                <path
                    d="M49.6 71.2c.7 0 1.3-.6 1.3-1.3s-.6-1.3-1.3-1.3c-10.1-.2-18.3-8.5-18.3-18.6 0-10.3 8.4-18.6 18.6-18.6 9.1 0 17 6.8 18.4 15.7.1.7.8 1.2 1.5 1.1s1.2-.8 1.1-1.5C69.3 36.3 60.5 28.8 50 28.8c-11.7 0-21.2 9.5-21.2 21.2-.1 11.5 9.3 21 20.8 21.2z"
                    fill="#acacc1"
                    opacity="1"
                    data-original="#acacc1"
                ></path>
            </g>
        </svg>
    );
};