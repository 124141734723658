import {Box, ClickAwayListener} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {CLIENT_URL, FetchContext} from "../../context/FetchContext";
import {AuthContext} from "../../context/AuthContext";
import {RefreshTokenAndRetry} from "../../utils/utils";
import TextField from "@mui/material/TextField";
import * as React from "react";

const headerStyle = {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.14px',
}

const textStyle = {
    color: "#999",
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '16px',
    letterSpacing: '0.24px',
}

const otherTextStyle = {
    ...textStyle,
    color: "white",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.14px",
}

const buttonAreaStyle = {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: "12px",
    alignSelf: "stretch",
}

const buttonStyle = {
    display: "flex",
    height: "48px",
    padding: "10px 20px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    color: "white",
    borderRadius: "8px",
    minWidth: "100px",
    cursor: "pointer",
    background: "#2B6BFD",
    // Set hovered state
    "&:hover": {
        background: "linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #2B6BFD",
    },
    // Set focused state
    "&:focus": {
        background: "linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #2B6BFD"
    },
    // Set disabled state
    "&:disabled": {
        background: "#2B6BFD",
        opacity: 0.3,
        cursor: "not-allowed",
    }
}

const NewShareProjectCard = ({entityId, onClose, rightPosition, isAsset}) => {

    const [linkCopied, setLinkCopied] = useState(false);

    const fetchContext = useContext(FetchContext);
    const authContext = useContext(AuthContext);

    const [token, setToken] = useState('');

    const projectShareUrl = ({projectId, token}) => {
        return CLIENT_URL + "/shared/projects/" + projectId + "/" + token;
    }

    const assetShareUrl = ({assetId, token}) => {
        return CLIENT_URL + "/shared/assets/" + assetId + "/" + token;
    }

    const [shareLink, setShareLink] = useState(isAsset ? assetShareUrl({assetId: entityId, token: token}) : projectShareUrl({projectId: entityId, token: token}));

    useEffect(() => {
        const getProjectToken = async () => {
            try {

                const path = isAsset ? `/share/asset/${entityId}` : `/share/project/${entityId}`;

                const response = await fetchContext.authAxios.get(path, {
                    headers: {
                        Authorization: `Bearer ${authContext.getToken()}`,
                    },
                });
                setToken(response.data.token);
                setShareLink(isAsset ? assetShareUrl({assetId: entityId, token: response.data.token}) : projectShareUrl({projectId: entityId, token: response.data.token}));
            } catch (error) {
                if (error.response.status === 401) {
                    await RefreshTokenAndRetry(error, authContext, fetchContext);
                }
            }
        }

        getProjectToken();

    }, [entityId, isAsset]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(shareLink);
        setLinkCopied(true);
    };

    const copyButtonText = linkCopied ? 'Copied!' : 'Copy';

    const textForProject = "Share access to this project's files. Anyone with the link can preview and download all recordings\n" +
        "                    and uploaded files."

    const textForAsset = "Share access to this recording. Anyone with the link can preview and download all tracks and exported edits of this recording."

    const mainStyle = {
        position: "absolute",
        top: "70px",
        right: isAsset ? "140px" : "32px",
        display: "flex",
        width: "390px",
        padding: "16px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "24px",
        borderRadius: "12px",
        background: "#FFF",
        boxShadow: "0px 30px 80px 0px rgba(0, 0, 0, 0.15)",
        zIndex: 1000,
    }

    return (
        <ClickAwayListener onClickAway={onClose}>
            <Box style={mainStyle}>
                <Box style={headerStyle}>
                    {isAsset ? "Share Recording" : "Share Project"}
                </Box>
                <Box style={textStyle}>
                    {isAsset ? textForAsset : textForProject}
                </Box>
                <Box style={buttonAreaStyle}>
                    <Box style={{width: "100%"}}>
                        <TextField
                            variant="outlined"
                            value={shareLink}
                            InputProps={{
                                readOnly: true,
                                style: {
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    lineHeight: "20px",
                                    letterSpacing: "0.14px",
                                    color: "#000000",
                                    height: "48px",
                                    backgroundColor: "white",
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#9E9E9E',
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                },
                                shrink: true,
                            }}
                            sx={{
                                width: '100%',
                                height: '48px',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: "1px solid #e8e8e8",
                                    },
                                    '&:hover fieldset': {
                                        border: "1px solid #e8e8e8",
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: "1px solid #e8e8e8",
                                    },
                                },
                                '& .MuiInputBase-root': {
                                    backgroundColor: 'white',
                                },
                            }}
                        />
                    </Box>
                    <Box sx={buttonStyle} onClick={copyToClipboard}>
                        <div style={otherTextStyle}>{copyButtonText}</div>
                    </Box>
                </Box>
            </Box>
        </ClickAwayListener>
    )
}

export default NewShareProjectCard;
