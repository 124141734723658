import {action, makeAutoObservable} from "mobx";
import {number} from "yup";

class TimelineStoreInstance {
  width = 0;
  tracksContainerWidth = 0;
  draggedClips: Record<string, true> = {};
  draggedTransitions: Record<string, true> = {};
  zoom = 1;

  constructor() {
    makeAutoObservable(this, {
      setWidth: action,
    }, { deep: true });
  }

  setWidth(width: number) {
    this.width = width;
  }

  setTracksContainerWidth(width: number) {
    this.tracksContainerWidth = width;
  }

  setDraggingClip(clipId: string, isDragging: boolean) {
    if (isDragging) {
      this.draggedClips[clipId] = true;
    } else {
      delete this.draggedClips[clipId];
    }
  }

  setDraggingTransition(transitionId: string, isDragging: boolean) {
    if (isDragging) {
      this.draggedTransitions[transitionId] = true;
    } else {
      delete this.draggedTransitions[transitionId];
    }
  }

  setZoom(zoom: number) {
    if (zoom < 0.2) {
      this.zoom = 0.2;
    } else if (zoom > 2) {
      this.zoom = 2;
    } else {
      this.zoom = zoom;
    }
  }
}

const TimelineStore = new TimelineStoreInstance();

export { TimelineStore };
