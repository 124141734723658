import './InfoBanner.styles.scss';

const InfoBanner = ({title, message, icon}) => {
    return (
        <div className="info-banner">
            {icon && <div className="info-banner__icon">{icon}</div>}

            <p className="info-banner__title">{title}</p>
            {message && <p className="info-banner__description">{message}</p>}
        </div>
    );
}

export default InfoBanner;