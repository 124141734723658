import {$createParagraphNode, $createTextNode, $getRoot} from "lexical";
import {ParseTranscriptWords} from "../Editor/Transcript";
import {$createSoundNode} from "../Editor/SoundNode";
import {ClipTypeEnum} from "@rendley/sdk";
import {RendleyStore} from "../../editor/store/RendleyStore";
import {RendleyService} from "../../editor/services/RendleyService";
import {$createModernSoundNode} from "./ModernSoundNode";
import { useStorage } from "@liveblocks/react";

export const calculateSoundNodeTimestamp = ({timelineItem, wordTime}) => {
    return wordTime - timelineItem.startTime + timelineItem.position;
}

export const zeroIfNull = (lastEndTime) => {
    return lastEndTime === null ? 0 : lastEndTime;
}

const FillerWordsList = ["uh", "um", "mhmm", "mm-mm", "uh-uh", "uh-huh", "nuh-uh"]

export const IsFillerWordInTranscript = (text) => {
    return FillerWordsList.includes(text.toLowerCase());
}

export const dotsForDuration = (duration) => {
    // one dot for durations under 1 second
    // two dots for durations greater than 1 second
    if (duration < 1) {
        return '\u00B7';
    }

    if (duration < 3) {
        return '\u00B7\u00B7';
    }

    return '\u00B7\u00B7\u00B7';
}

export const ClipStartIfNull = (lastEndTime, timelineItem) => {
    return lastEndTime === null ? timelineItem.position : lastEndTime;
}

export const ClipsToTimelineItems = (clips) => {
    if (!clips) {
        return [];
    }

   const timelineStateLayers = new Map();
    Object.entries(clips)?.forEach(([clipId, clip]) => {
        if (clip.type === ClipTypeEnum.VIDEO || clip.type === ClipTypeEnum.AUDIO) {
            const mediaDataId = clip.mediaDataId;
            const transcript = RendleyService.getMediaById(mediaDataId)?.getCustomData("transcript");
            const entityId = RendleyService.getMediaById(mediaDataId)?.getCustomData("entityId");

            const layerId = RendleyService.getClipById(clipId)?.getCustomData("layerId");
            const timelineStateItem = {
                transcript: transcript,
                mediaDataId: mediaDataId,
                entityId: entityId,
                clipId: clipId,
                layerId: layerId,
                position: clip.leftTrim + clip.startTime,
                startTime: clip.leftTrim,
                endTime: clip.duration - clip.rightTrim
            };

            if (!timelineStateLayers.has(layerId)) {
                timelineStateLayers.set(layerId, []);
            }
            timelineStateLayers.get(layerId).push(timelineStateItem);
        }
    });

    // Convert map into a list of lists.
    const timelineState = [];
    timelineStateLayers.forEach((value, key) => {
        timelineState.push(value);
    });
    return timelineState;
}

export const GetSRTSubtitleUrl = ({timelineStateLayers, corrections}) => {

    const allWords = [];

    let srt = ""; // Initialize an empty string for SRT format
    let captionIndex = 1;
    let captionText = "";
    let captionStart = null;
    let captionEnd = null;

    timelineStateLayers?.forEach((layer, layerIndex) => {
        layer?.forEach((clip) => {
            const transcriptJson = clip.transcript;
            if (transcriptJson) {
                const words = ParseTranscriptWords(transcriptJson);
                words?.forEach(word => {
                    // Only include words within the clip's trimmed range.
                    if (word.start >= clip.startTime && word.end <= clip.endTime) {
                        allWords.push({
                            speaker: word.speaker,
                            word: word.word,
                            start: calculateSoundNodeTimestamp({timelineItem: clip, wordTime: word.start}),
                            end: calculateSoundNodeTimestamp({timelineItem: clip, wordTime: word.end}),
                            layerIndex: layerIndex,
                            mediaDataId: clip.mediaDataId,
                            timelineStartTime: clip.position + word.start,
                            clip
                        });
                    }
                });
            }
        });
    });

    allWords.sort((a, b) => a.start - b.start);

    let lastEndTime = null;
    for (const word of allWords) {

        // Words may overlap. Skip words that start before the last word we added.
        if (zeroIfNull(lastEndTime) > word.start) {
            continue;
        }

        const wordText = word.word;

        if (!captionStart) {
            captionStart = word.start;
        }

        if (captionText.length + wordText.length + 1 <= 37) {
            captionText += (captionText.length > 0 ? " " : "") + wordText;
            captionEnd = word.end;
        } else {
            srt += `${captionIndex}\n${SecondsToSRTTimestamp(captionStart)} --> ${SecondsToSRTTimestamp(captionEnd)}\n${captionText}\n\n`;
            captionIndex++;
            captionText = wordText;
            captionStart = word.start;
            captionEnd = word.end;
        }

        if (captionText.length > 0 && captionText.length + wordText.length + 1 > 37 && captionText.split(" ").length > 1) {
            srt += `${captionIndex}\n${SecondsToSRTTimestamp(captionStart)} --> ${SecondsToSRTTimestamp(captionEnd)}\n${captionText}\n\n`;
            captionIndex++;
            captionText = "";
            captionStart = null;
            captionEnd = null;
        }

    }

    if (captionText.length > 0) {
        srt += `${captionIndex}\n${SecondsToSRTTimestamp(captionStart)} --> ${SecondsToSRTTimestamp(captionEnd)}\n${captionText}\n\n`;
    }

   return srt;
}

function SecondsToSRTTimestamp(seconds) {
    const date = new Date(0);
    date.setSeconds(seconds);
    const iso = date.toISOString();
    const [hours, minutes, secondsWithMs] = iso.substr(11, 12).split(':');
    const secondsOnly = secondsWithMs.replace('.', ',');
    return `${hours}:${minutes}:${secondsOnly}`;
}

export const BuildOnRoot = ({timelineStateLayers, corrections, root}) => {

    console.log("Corrections is build on root: ", corrections);

    let para = null;
    let lastSource = null;
    let lastEndTime = null;
    let lastEndTimeLayerId = null;
    let lastEndTimeClipId = null;
    let lastMediaDataId = null;

    // Step 1: Collect all words from all clips across all layers
    const allWords = [];

    timelineStateLayers?.forEach((layer, layerIndex) => {
        layer?.forEach((clip) => {

            const correctionsForClip = corrections?.get(clip.entityId);

            console.log("Corrections for clip: ", correctionsForClip)

            const transcriptJson = clip.transcript;
            if (transcriptJson) {
                const words = ParseTranscriptWords(transcriptJson);
                let indexWithinTranscript = 0;
                words?.forEach(word => {
                    // Only include words within the clip's trimmed range.
                    if (word.start >= clip.startTime && word.end <= clip.endTime) {

                         const correctionForWord = correctionsForClip?.corrections[String(indexWithinTranscript)];

                        if (correctionForWord) {
                            console.log("Correction for word is: ", correctionForWord.correctedText);
                        }

                        allWords.push({
                            speaker: word.speaker,
                            word: correctionForWord?.correctedText || word.word,
                            rawWord: correctionForWord?.originalText || word.rawWord,
                            originalText: word.word,
                            start: calculateSoundNodeTimestamp({timelineItem: clip, wordTime: word.start}),
                            end: calculateSoundNodeTimestamp({timelineItem: clip, wordTime: word.end}),
                            layerId: clip.layerId,
                            entityId: clip.entityId,
                            mediaDataId: clip.mediaDataId,
                            clipId: clip.clipId,
                            indexWithinTranscript: indexWithinTranscript,
                            timelineStartTime: clip.position + word.start,
                            clip
                        });
                    }
                    indexWithinTranscript++;
                });
            }
        });
    });

    allWords.sort((a, b) => a.start - b.start);

    let index = 0
    let speakerNodeIndex = -1
    for (const word of allWords) {

        // Words may overlap. Skip words that start before the last word we added.
        if (zeroIfNull(lastEndTime) > word.start) {
            continue;
        }

        if (lastSource !== word.speaker || lastMediaDataId !== word.mediaDataId) {
            const speakerNameParagraph = $createParagraphNode()
            root.append(speakerNameParagraph)

            const speakerNameTextNode = $createModernSoundNode(`${word.speaker}: `, {
                source: word.speaker,
                start: word.start,
                end: word.end,
                isSilence: false,
                isSpeakerNode: true,
                originalText: undefined,
                clipId: word.clipId,
                mediaDataId: word.mediaDataId,
                entityId: word.entityId,
                indexForCorrection: speakerNodeIndex,
                layerId: word.layerId,
            });
            speakerNameTextNode.toggleFormat('bold');
            speakerNameParagraph.append(speakerNameTextNode);
            speakerNodeIndex--;

            para = $createParagraphNode()
            root.append(para)
            lastSource = word.speaker
            lastMediaDataId = word.mediaDataId
        }

        let soundNodeWord = word.word;
        const currentSoundNode = $createModernSoundNode(soundNodeWord, {
            source: word.speaker,
            start: word.start,
            end: word.end,
            isSilence: false,
            isSpeakerNode: false,
            transcriptIndex: index,
            rawWord: word.rawWord,
            clipId: word.clipId,
            originalText: word.originalText,
            mediaDataId: word.mediaDataId,
            entityId: word.entityId,
            layerId: word.layerId,
            indexForCorrection: word.indexWithinTranscript,
        });

        // Add silence nodes if there is a significant gap between the last word and the current word.
        if (zeroIfNull(lastEndTime) + 0.7 < currentSoundNode.getStart()) {
            const dots = dotsForDuration(currentSoundNode.getStart() - zeroIfNull(lastEndTime));
            const soundNode = $createModernSoundNode(dots, {
                source: word.speaker,
                start: zeroIfNull(lastEndTime),
                end: word.start,
                isSilence: true,
                layerId: word.layerId === lastEndTimeLayerId ? lastEndTimeLayerId : null, // A silence could be between a gap between words from two different layers (clips.)
                clipId: word.clipId === lastEndTimeClipId ? lastEndTimeClipId : null, // A silence could be between a gap between words from two different clips.
                isSpeakerNode: false,
                indexForCorrection: 0,
            });
            soundNode.toggleFormat('bold');
            para.append(soundNode);
            para.append($createTextNode(' '))

            // New paragraph if there is a significant gap between the last word and the current word.
            if (zeroIfNull(lastEndTime) + 5 < currentSoundNode.getStart()) {
                para = $createParagraphNode()
                root.append(para)
                para.append($createTextNode(''))
            }
        }

        lastEndTime = currentSoundNode.getEnd();
        lastEndTimeLayerId = word.layerId;
        lastEndTimeClipId = word.clipId;

        para.append(currentSoundNode)

        if (index !== allWords.length) {
            para.append($createTextNode(' '))
        }
    }
}


export const CreateMultiTrackEditorState = ({timelineState, corrections}) => {
    return () => {
        const root = $getRoot()
        root.clear();
        BuildOnRoot({timelineState, corrections, root});
    }
}