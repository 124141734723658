import React, { useEffect } from 'react';

const TermsOfService = () => {

    useEffect(() => {

        // Function to dynamically load the Termly script for the Terms of Service
        const loadTermlyScript = () => {
            const scriptId = 'termly-jssdk';
            if (document.getElementById(scriptId)) {
                return; // Script already loaded
            }
            const script = document.createElement('script');
            script.id = scriptId;
            script.src = "https://app.termly.io/embed-policy.min.js";
            document.body.appendChild(script);
        };

        // Call the function to load the Termly script
        loadTermlyScript();

    }, []);

    // Render your terms container
    return (
        <div name="termly-embed" data-id="1ac94273-c863-4bbe-b45a-606066c9312a" style={{backgroundColor: "white"}}></div>
    );
};

export default TermsOfService;