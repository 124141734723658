import clsx from "clsx";

export function chain(...callbacks) {
    return (...args) => {
        for (const callback of callbacks) {
            if (typeof callback === 'function') {
                try {
                    callback(...args);
                } catch (e) {
                    console.error(e);
                }
            }
        }
    };
}

export function mergeProps(...args) {
    // Start with a base clone of the first argument. This is a lot faster than starting
    // with an empty object and adding properties as we go.
    const result = {...args[0]};
    for (let i = 1; i < args.length; i++) {
        const props = args[i];
        for (const key in props) {
            const a = result[key];
            const b = props[key];

            // Chain events
            if (
                typeof a === 'function' &&
                typeof b === 'function' &&
                // This is a lot faster than a regex.
                key[0] === 'o' &&
                key[1] === 'n' &&
                key.charCodeAt(2) >= /* 'A' */ 65 &&
                key.charCodeAt(2) <= /* 'Z' */ 90
            ) {
                result[key] = chain(a, b);

                // Merge classnames, sometimes classNames are empty string which eval to false, so we just need to do a type check
            } else if (
                (key === 'className' || key === 'UNSAFE_className') &&
                typeof a === 'string' &&
                typeof b === 'string'
            ) {
                result[key] = clsx(a, b);
            } else {
                result[key] = b !== undefined ? b : a;
            }
        }
    }

    return result;
}