import * as React from "react";
import {useRef, useState} from "react";
import {wasClickOutside} from "@livekit/components-core";
import Box from "@mui/material/Box";
import {DropdownArrowIcon} from "./SettingsIcon";
import {BackgroundSelect} from "./BackgroundSelect";


const textStyle = {
    fontFamily: "Inter",
    fontSize: {md: "9px", lg: "11px", xl: "14px"},
    fontStyle: "normal",
    fontWeight: {md: "350", lg: "400", xl: "500"},
    lineHeight: {md: "13px", lg: "15px", xl: "20px"},
    letterSpacing: {md: "0.1px" , lg:"0.1px", xl: "0.14px"},
    color: "#1A1A1A",
    // disable highlight on double click
    WebkitTouchCallout: "none",
    WebkitUserSelect: "none",
    KhtmlUserSelect: "none",
    MozUserSelect: "none",
    msUserSelect: "none",
}

export function BackgroundMenu({onBackgroundChange, initialSelection, setBackgroundImage, backgroundImageIndex,
                                   setBackgroundImageIndex, ...props}) {
    const [isOpen, setIsOpen] = React.useState(false);
    const [positionLeft, setPositionLeft] = useState(0);
    const [positionBottom, setPositionBottom] = useState(0);

    const buttonRef = useRef(null);
    const lastCallTime = useRef(0);

    const setIsOpenWithThrottle = (newState) => {
        const now = Date.now();
        if (now - lastCallTime.current > 100) {
            lastCallTime.current = now;
            setIsOpen(newState);
        }
    };

    const BackgroundIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" fill="none">
                <path
                    d="M15.6849 3.33331H5.31449C4.49629 3.33331 3.83301 3.99659 3.83301 4.81479V15.1852C3.83301 16.0034 4.49629 16.6666 5.31449 16.6666H15.6849C16.5031 16.6666 17.1663 16.0034 17.1663 15.1852V4.81479C17.1663 3.99659 16.5031 3.33331 15.6849 3.33331Z"
                    stroke="#1A1A1A" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M8.41699 9.16669C9.10735 9.16669 9.66699 8.60704 9.66699 7.91669C9.66699 7.22633 9.10735 6.66669 8.41699 6.66669C7.72664 6.66669 7.16699 7.22633 7.16699 7.91669C7.16699 8.60704 7.72664 9.16669 8.41699 9.16669Z"
                    stroke="#1A1A1A" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17.1667 13.0303L13.5208 10L5.5 16.6667" stroke="#1A1A1A" strokeWidth="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
            </svg>
        )
    }

    const button = React.useRef<HTMLButtonElement>(null);
    const tooltip = React.useRef(null);

    const handleClickOutside = React.useCallback(
        (event) => {
            if (!tooltip.current) {
                return;
            }
            if (event.target === button.current) {
                return;
            }
            if (isOpen && wasClickOutside(tooltip.current, event)) {
                setIsOpenWithThrottle(false);
            }
        },
        [isOpen, tooltip, button],
    );

    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside]);

    const buttonStyle = {
        display: "flex",
        padding: {md: "3px 3px", lg: "6px 10px", xl: "12px 18px"},
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        cursor: "pointer",
        backgroundColor: "#f3f4f5",
        width: {md: "130px", lg: "147px", xl: "160px"},
        height: {md: "34px", lg: "39px", xl: "45px"},
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#e5e5e5', // Darker primary color
        },
        borderRadius: "5px 5px 5px 5px",
    }

    const handleClick = () => {
        setPositionLeft(buttonRef.current.getBoundingClientRect().left);
        setPositionBottom(buttonRef.current.getBoundingClientRect().bottom);
        setIsOpenWithThrottle(!isOpen);
    }

    return (
        <>
            <Box onClick={handleClick} ref={buttonRef} sx={buttonStyle} alignItems="center" justifyContent="center" flexDirection="row">
                {<BackgroundIcon/>}
                <div sx={textStyle}>Background</div>
            </Box>
            {/** only render when enabled in order to make sure that the permissions are requested only if the menu is enabled */}
            {!props.disabled && isOpen && (
                <BackgroundSelect
                    left={positionLeft}
                    bottom={positionBottom}
                    setBackgroundImage={setBackgroundImage}
                    backgroundImageIndex={backgroundImageIndex}
                setBackgroundImageIndex={setBackgroundImageIndex}
                    setIsOpen={setIsOpenWithThrottle}
                />
            )}
        </>
    );
}
