import {LeaveIcon, useConnectionState} from "@livekit/components-react";
import * as React from "react";
import {setupDisconnectButton} from "@livekit/components-core";
import {ConnectionState} from "livekit-client";
import {Button} from "@mui/material";

export const CustomLeaveButton = /* @__PURE__ */ React.forwardRef(function CustomLeaveButton({room}, ref) {
    const connectionState = useConnectionState(room);

    const { className, disconnect } = setupDisconnectButton(room);

    const buttonStyle = {
        height: "50px",
        borderRadius: "8px",
        width: "130px",
        background: "#F00000",
        // Hover style
        "&:hover": {
            background: "#FF0000",
            color: "white"
        }
    }

    return (
        <Button startIcon={<LeaveIcon color={"white"}/>} onClick={() => disconnect(true)} disabled={connectionState === ConnectionState.Disconnected} sx={buttonStyle}>
            <div style={{color: "white"}}>Leave</div>
        </Button>
    );
});

export const HostLeaveButton = ({onClick, room}) => {

    const connectionState = useConnectionState(room);

    const buttonStyle = {
        height: "52px",
        borderRadius: "8px",
        width: "130px",
        background: "#1A1A1A",
        // Hover style
        "&:hover": {
            background: "#000000",
            color: "white"
        }
    }

    return (
        <Button startIcon={<LeaveIcon color={"white"}/>} onClick={onClick} disabled={connectionState === ConnectionState.Disconnected} sx={buttonStyle}>
            <div style={{color: "white"}}>Leave</div>
        </Button>
    );
};