import {
    formatChatMessageLinks, useLocalParticipant, useRoomContext
} from "@livekit/components-react";
import {CustomVideoConference} from "./CustomVideoConference";
import {useEffect} from "react";
import {LocalAudioTrack, LocalVideoTrack, RoomEvent, Track, VideoPresets} from "livekit-client";
import {isKrispNoiseFilterSupported, KrispNoiseFilter} from "@livekit/krisp-noise-filter";
import {CustomAudioConference} from "./CustomAudioConference";
import PerformLocalRecording from "./LocalRecording";
import RecordVideo from "./RecordVideo";
import {BackgroundBlur, VirtualBackground} from '@livekit/track-processors';
import {HighVideoResolution} from "../utils/utils";
import {NewCustomVideoConference} from "../pages/NewStudio/NewCustomVideoConference";
import AudioRTCRecording from "./AudioRTCRecording";
import VideoRTCRecording from "./VideoRTCRecording";


const useVideoBackgroundImage = (backgroundImage) => {

    const {cameraTrack} = useLocalParticipant();

    const EnableBackgroundBlur = async (trackPublication) => {
        if (trackPublication.source === Track.Source.Camera && trackPublication.track instanceof LocalVideoTrack) {
            const backgroundBlur = BackgroundBlur(6, {delegate: 'GPU'});
            await trackPublication.track.setProcessor(backgroundBlur);
        } else {
            console.log("Track is not a camera local track");
        }
    };

    const EnableVirtualBackground = async (trackPublication, imgPath) => {
        if (trackPublication.source === Track.Source.Camera && trackPublication.track instanceof LocalVideoTrack) {
            const virtualBackground = VirtualBackground(imgPath, {delegate: 'GPU'});
            await trackPublication.track.setProcessor(virtualBackground);
        } else {
            console.log("Track is not a camera local track");
        }
    }

    useEffect(() => {
        const cameraPublication = cameraTrack;
        if (cameraPublication && cameraPublication.track instanceof LocalVideoTrack) {
            const currentProcessor = cameraPublication.track.getProcessor();
            if (currentProcessor && !backgroundImage.isBlur && !backgroundImage.imageUrl) {
                cameraPublication.track.stopProcessor();
            } else if (backgroundImage.isBlur) {
                EnableBackgroundBlur(cameraPublication).then(() => {
                    console.log("Background blur enabled");
                }).catch((e) => {
                    console.log("Error enabling background blur filter", e);
                });
            } else if (backgroundImage.imageUrl) {
                EnableVirtualBackground(cameraPublication, backgroundImage.imageUrl).then(() => {
                    console.log("Background blur enabled");
                }).catch((e) => {
                    console.log("Error enabling background blur filter", e);
                });
            }
        }
    }, [backgroundImage.isBlur, backgroundImage.imageUrl]);

}


const useVideoBackgroundBlur = (blurBackground) => {

    const {cameraTrack} = useLocalParticipant();

    const EnableBackgroundBlur = async (trackPublication) => {
        if (trackPublication.source === Track.Source.Camera && trackPublication.track instanceof LocalVideoTrack) {
            const backgroundBlur = BackgroundBlur(7);
            await trackPublication.track.setProcessor(backgroundBlur);
        } else {
            console.log("Track is not a camera local track");
        }
    };

    useEffect(() => {
        const cameraPublication = cameraTrack;
        if (cameraPublication && cameraPublication.track instanceof LocalVideoTrack) {
            const currentProcessor = cameraPublication.track.getProcessor();
            if (currentProcessor && !blurBackground) {
                cameraPublication.track.stopProcessor();
            } else if (!currentProcessor && blurBackground) {
                EnableBackgroundBlur(cameraPublication).then(() => {
                    console.log("Krisp noise filter enabled");
                }).catch((e) => {
                    console.log("Error enabling Krisp noise filter", e);
                });
            }
        }
    }, [blurBackground]);
}

const useKrispNoiseCancellation = (enableKrispNoiseCancellation) => {

    const {microphoneTrack} = useLocalParticipant();

    const EnableKrisp = async (trackPublication) => {
        if (trackPublication.source === Track.Source.Microphone && trackPublication.track instanceof LocalAudioTrack) {
            // once instantiated the filter will begin initializing and will download additional resources
            const krispProcessor = KrispNoiseFilter();
            await trackPublication.track.setProcessor(krispProcessor);
        } else {
            console.log("Track is not a microphone local track");
        }
    };

    useEffect(() => {
        const micPublication = microphoneTrack;
        if (micPublication && micPublication.track instanceof LocalAudioTrack) {
            const currentProcessor = micPublication.track.getProcessor();
            if (currentProcessor && !enableKrispNoiseCancellation) {
                console.log("Disabling Krisp noise filter")
                micPublication.track.stopProcessor();
            } else if (!currentProcessor && enableKrispNoiseCancellation) {
                if (!isKrispNoiseFilterSupported()) {
                    console.log("enhanced noise filter is currently not supported on this browser");
                    return;
                }
                EnableKrisp(micPublication).then(() => {
                    console.log("Krisp noise filter enabled");
                }).catch((e) => {
                    console.log("Error enabling Krisp noise filter", e);
                });
            }
        }
    }, [enableKrispNoiseCancellation]);
};

const StudioVideoConference = ({
                                   recordingInProgress,
                                   startRecording,
                                   stopRecording,
                                   enableKrispNoiseCancellation,
                                   localRecordingInProgress,
                                   setLocalRecordingStopped,
                                   livekitToken,
                                   localRecordingDone,
                                   setLocalRecordingDone,
                                   blurBackground,
                                   setBlurBackground,
                                   videoResolution
                               }) => {
    const room = useRoomContext();
    room.options.publishDefaults.videoSimulcastLayers = videoResolution === HighVideoResolution
        ? [VideoPresets.h1080, VideoPresets.h720]
        : [VideoPresets.h540, VideoPresets.h216];
    room.options.videoCaptureDefaults.resolution = videoResolution === HighVideoResolution ? VideoPresets.h2160 : VideoPresets.h720;
    useKrispNoiseCancellation(enableKrispNoiseCancellation);
    useVideoBackgroundBlur(blurBackground);
    const isHDQuality = videoResolution === HighVideoResolution;
    RecordVideo({
        room,
        localRecordingInProgress,
        livekitToken,
        setLocalRecordingDone,
        setLocalRecordingStopped,
        localRecordingDone,
        isHDQuality
    });

    return (
        <CustomVideoConference chatMessageFormatter={formatChatMessageLinks} recordingInProgress={recordingInProgress}
                               startRecording={startRecording} stopRecording={stopRecording}
                               isRecordButtonDisabled={false} localRecordingInProgress={localRecordingInProgress}
                               blurBackground={blurBackground} setBlurBackground={setBlurBackground}/>
    );
}

const NewStudioVideoConference = ({
                                      recordingInProgress,
                                      assetIdRef,
                                      localRecordingEnabled,
                                      highQualityRecordingRef,
                                      backgroundImage,
                                      startRecording,
                                      roomName,
                                      hostIdentity,
                                      brandLogoUrl,
                                      stopRecording,
                                      enableKrispNoiseCancellation,
                                      localRecordingInProgress,
                                      setLocalRecordingStopped,
                                      livekitToken,
                                      studioNameStyle,
                                      videoMirrored,
                                      localRecordingDone,
                                      setLocalRecordingDone,
                                      setUploadProgress,
                                      videoResolution,
                                      setRoom
                                  }) => {
    const room = useRoomContext();
    room.options.publishDefaults.videoSimulcastLayers = videoResolution === HighVideoResolution
        ? [VideoPresets.h1080, VideoPresets.h720]
        : [VideoPresets.h540, VideoPresets.h216];
    room.options.videoCaptureDefaults.resolution = videoResolution === HighVideoResolution ? VideoPresets.h2160 : VideoPresets.h720;
    setRoom(room);
    useKrispNoiseCancellation(enableKrispNoiseCancellation);
    useVideoBackgroundImage(backgroundImage);
    return (
        <>
            <AudioRTCRecording ref={highQualityRecordingRef} livekitToken={livekitToken} room={room}
                               localRecordingEnabled={localRecordingEnabled} setRecordingDone={setLocalRecordingDone}
                               assetIdRef={assetIdRef}
                               setLocalRecordingStopped={setLocalRecordingStopped}
                               setUploadProgress={setUploadProgress}/>
            <NewCustomVideoConference chatMessageFormatter={formatChatMessageLinks} hostIdentity={hostIdentity}
                                      brandLogoUrl={brandLogoUrl} recordingInProgress={recordingInProgress}
                                      startRecording={startRecording} stopRecording={stopRecording}
                                      videoMirrored={videoMirrored}
                                      nameStyle={studioNameStyle} isRecordButtonDisabled={false}
                                      localRecordingInProgress={localRecordingInProgress}/>
        </>
    );
}

const NewStudioVideoConferenceVideoRecording = ({
                                                    recordingInProgress,
                                                    localRecordingEnabled,
                                                    highQualityRecordingRef,
                                                    backgroundImage,
                                                    startRecording,
                                                    roomName,
                                                    hostIdentity,
                                                    brandLogoUrl,
                                                    stopRecording,
                                                    enableKrispNoiseCancellation,
                                                    localRecordingInProgress,
                                                    setLocalRecordingStopped,
                                                    livekitToken,
                                                    studioNameStyle,
                                                    videoMirrored,
                                                    setUploadProgress,
                                                    localRecordingDone,
                                                    assetIdRef,
                                                    setLocalRecordingDone,
                                                    videoResolution,
                                                    setRoom
                                                }) => {
    const room = useRoomContext();
    room.options.publishDefaults.videoSimulcastLayers = videoResolution === HighVideoResolution
        ? [VideoPresets.h1080, VideoPresets.h720]
        : [VideoPresets.h540, VideoPresets.h216];
    room.options.videoCaptureDefaults.resolution = videoResolution === HighVideoResolution ? VideoPresets.h2160 : VideoPresets.h720;
    setRoom(room);
    useKrispNoiseCancellation(enableKrispNoiseCancellation);
    useVideoBackgroundImage(backgroundImage);
    const isHDQuality = videoResolution === HighVideoResolution;
    return (
        <>
            <VideoRTCRecording room={room} localRecordingEnabled={localRecordingEnabled}
                               setRecordingDone={setLocalRecordingDone} assetIdRef={assetIdRef}
                               ref={highQualityRecordingRef} livekitToken={livekitToken}
                               isHDQuality={isHDQuality}
                               setLocalRecordingStopped={setLocalRecordingStopped}
                               setUploadProgress={setUploadProgress}/>
            <NewCustomVideoConference chatMessageFormatter={formatChatMessageLinks} hostIdentity={hostIdentity}
                                      brandLogoUrl={brandLogoUrl} recordingInProgress={recordingInProgress}
                                      startRecording={startRecording} stopRecording={stopRecording}
                                      videoMirrored={videoMirrored}
                                      nameStyle={studioNameStyle} isRecordButtonDisabled={false}
                                      localRecordingInProgress={localRecordingInProgress}/>
        </>
    );
}

const NewGuestStudioVideoConference = ({
                                           recordingInProgress,
                                           backgroundImage,
                                           assetIdRef,
                                           localRecordingEnabled,
                                           enableKrispNoiseCancellation,
                                           localRecordingInProgress,
                                           setLocalRecordingStopped,
                                           livekitToken,
                                           studioNameStyle,
                                           videoMirrored,
                                           highQualityRecordingRef,
                                           localRecordingDone,
                                           setLocalRecordingDone,
                                           videoResolution,
                                           setUploadProgress,
                                           setRoom,
                                           roomName,
                                       }) => {
    const room = useRoomContext();
    room.options.publishDefaults.videoSimulcastLayers = videoResolution === HighVideoResolution
        ? [VideoPresets.h1080, VideoPresets.h720]
        : [VideoPresets.h540, VideoPresets.h216];
    room.options.videoCaptureDefaults.resolution = videoResolution === HighVideoResolution ? VideoPresets.h2160 : VideoPresets.h720;
    setRoom(room);
    useKrispNoiseCancellation(enableKrispNoiseCancellation);
    useVideoBackgroundImage(backgroundImage);
    return (
        <>
            <AudioRTCRecording ref={highQualityRecordingRef} livekitToken={livekitToken} room={room}
                               localRecordingEnabled={localRecordingEnabled} setRecordingDone={setLocalRecordingDone}
                               assetIdRef={assetIdRef}
                               setLocalRecordingStopped={setLocalRecordingStopped}
                               setUploadProgress={setUploadProgress}/>

            <NewCustomVideoConference chatMessageFormatter={formatChatMessageLinks}
                                      recordingInProgress={recordingInProgress} startRecording={null}
                                      stopRecording={null}
                                      videoMirrored={videoMirrored}
                                      nameStyle={studioNameStyle} isRecordButtonDisabled={true}
                                      localRecordingInProgress={localRecordingInProgress}/>

        </>
    );
}

const NewGuestStudioVideoConferenceVideoRecording = ({
                                                         recordingInProgress,
                                                         backgroundImage,
                                                         localRecordingEnabled,
                                                         enableKrispNoiseCancellation,
                                                         localRecordingInProgress,
                                                         setLocalRecordingStopped,
                                                         livekitToken,
                                                         studioNameStyle,
                                                         videoMirrored,
                                                         assetIdRef,
                                                         roomName,
                                                         localRecordingDone,
                                                         setLocalRecordingDone,
                                                         videoResolution,
                                                         setUploadProgress,
                                                         setRoom,
                                                         highQualityRecordingRef
                                                     }) => {
    const room = useRoomContext();
    room.options.publishDefaults.videoSimulcastLayers = videoResolution === HighVideoResolution
        ? [VideoPresets.h1080, VideoPresets.h720]
        : [VideoPresets.h540, VideoPresets.h216];
    room.options.videoCaptureDefaults.resolution = videoResolution === HighVideoResolution ? VideoPresets.h2160 : VideoPresets.h720;
    setRoom(room);
    useKrispNoiseCancellation(enableKrispNoiseCancellation);
    useVideoBackgroundImage(backgroundImage);
    const isHDQuality = videoResolution === HighVideoResolution;
    return (
        <>
            <VideoRTCRecording room={room} localRecordingEnabled={localRecordingEnabled}
                               setRecordingDone={setLocalRecordingDone} assetIdRef={assetIdRef}
                               ref={highQualityRecordingRef} livekitToken={livekitToken}
                               isHDQuality={isHDQuality}
                               setLocalRecordingStopped={setLocalRecordingStopped}
                               setUploadProgress={setUploadProgress}/>
            <NewCustomVideoConference chatMessageFormatter={formatChatMessageLinks}
                                      recordingInProgress={recordingInProgress} startRecording={null}
                                      stopRecording={null}
                                      videoMirrored={videoMirrored}
                                      nameStyle={studioNameStyle} isRecordButtonDisabled={true}
                                      localRecordingInProgress={localRecordingInProgress}/>
        </>
    );
}

const StudioAudioConferenceWithVideo = ({
                                            recordingInProgress,
                                            startRecording,
                                            stopRecording,
                                            enableKrispNoiseCancellation,
                                            livekitToken,
                                            localRecordingInProgress,
                                            setLocalRecordingStopped,
                                            setLocalRecordingDone,
                                            blurBackground,
                                            setBlurBackground
                                        }) => {
    const room = useRoomContext();
    useKrispNoiseCancellation(enableKrispNoiseCancellation);
    useVideoBackgroundBlur(blurBackground);
    PerformLocalRecording({
        room,
        localRecordingInProgress,
        setLocalRecordingDone,
        setLocalRecordingStopped,
        livekitToken
    });
    return (
        <CustomVideoConference chatMessageFormatter={formatChatMessageLinks} recordingInProgress={recordingInProgress}
                               startRecording={startRecording} stopRecording={stopRecording}
                               isRecordButtonDisabled={false} localRecordingInProgress={localRecordingInProgress}
                               blurBackground={blurBackground} setBlurBackground={setBlurBackground}/>
    );
}

const StudioAudioConference = ({
                                   recordingInProgress,
                                   startRecording,
                                   stopRecording,
                                   enableKrispNoiseCancellation,
                                   localRecordingInProgress,
                                   setLocalRecordingDone,
                                   setLocalRecordingStopped,
                                   livekitToken
                               }) => {
    const room = useRoomContext();
    useKrispNoiseCancellation(enableKrispNoiseCancellation);
    PerformLocalRecording({
        room,
        localRecordingInProgress,
        setLocalRecordingDone,
        setLocalRecordingStopped,
        livekitToken
    });

    return (
        <CustomAudioConference recordingInProgress={recordingInProgress} startRecording={startRecording}
                               stopRecording={stopRecording} isRecordButtonDisabled={false}
                               localRecordingInProgress={localRecordingInProgress}/>
    );
}

const GuestStudioVideoConference = ({
                                        recordingInProgress,
                                        enableKrispNoiseCancellation,
                                        localRecordingInProgress,
                                        livekitToken,
                                        setLocalRecordingDone,
                                        setLocalRecordingStopped,
                                        blurBackground,
                                        localRecordingDone,
                                        setBlurBackground,
                                        videoResolution
                                    }) => {
    const room = useRoomContext();
    room.options.publishDefaults.videoSimulcastLayers = videoResolution === HighVideoResolution
        ? [VideoPresets.h1080, VideoPresets.h720]
        : [VideoPresets.h540, VideoPresets.h216];
    room.options.videoCaptureDefaults.resolution = videoResolution === HighVideoResolution ? VideoPresets.h2160 : VideoPresets.h720;
    useKrispNoiseCancellation(enableKrispNoiseCancellation);
    useVideoBackgroundBlur(blurBackground);
    const isHDQuality = videoResolution === HighVideoResolution;
    RecordVideo({
        room,
        localRecordingInProgress,
        livekitToken,
        setLocalRecordingDone,
        setLocalRecordingStopped,
        localRecordingDone,
        isHDQuality
    });

    return (
        <CustomVideoConference chatMessageFormatter={formatChatMessageLinks} recordingInProgress={recordingInProgress}
                               startRecording={null} stopRecording={null} isRecordButtonDisabled={true}
                               localRecordingInProgress={localRecordingInProgress} blurBackground={blurBackground}
                               setBlurBackground={setBlurBackground}/>
    );
}

const GuestStudioAudioConferenceWithVideo = ({
                                                 recordingInProgress,
                                                 enableKrispNoiseCancellation,
                                                 localRecordingInProgress,
                                                 livekitToken,
                                                 setLocalRecordingDone,
                                                 setLocalRecordingStopped,
                                                 blurBackground,
                                                 setBlurBackground
                                             }) => {
    const room = useRoomContext();
    useKrispNoiseCancellation(enableKrispNoiseCancellation);
    useVideoBackgroundBlur(blurBackground);
    PerformLocalRecording({
        room,
        localRecordingInProgress,
        setLocalRecordingDone,
        setLocalRecordingStopped,
        livekitToken
    });

    return (
        <CustomVideoConference chatMessageFormatter={formatChatMessageLinks} recordingInProgress={recordingInProgress}
                               startRecording={null} stopRecording={null} isRecordButtonDisabled={true}
                               localRecordingInProgress={localRecordingInProgress} blurBackground={blurBackground}
                               setBlurBackground={setBlurBackground}/>
    );
}

const GuestStudioAudioConference = ({
                                        recordingInProgress,
                                        enableKrispNoiseCancellation,
                                        localRecordingInProgress,
                                        setLocalRecordingDone,
                                        setLocalRecordingStopped,
                                        livekitToken
                                    }) => {
    const room = useRoomContext();
    useKrispNoiseCancellation(enableKrispNoiseCancellation);
    PerformLocalRecording({
        room,
        localRecordingInProgress,
        setLocalRecordingDone,
        setLocalRecordingStopped,
        livekitToken
    });

    return (
        <CustomAudioConference recordingInProgress={recordingInProgress} startRecording={null} stopRecording={null}
                               isRecordButtonDisabled={true} localRecordingInProgress={localRecordingInProgress}/>
    );
}

export {
    StudioVideoConference,
    NewStudioVideoConferenceVideoRecording,
    NewGuestStudioVideoConferenceVideoRecording,
    NewStudioVideoConference,
    NewGuestStudioVideoConference,
    GuestStudioVideoConference,
    StudioAudioConference,
    GuestStudioAudioConference,
    StudioAudioConferenceWithVideo,
    GuestStudioAudioConferenceWithVideo
};
