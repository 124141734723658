import {createLocalTracks, facingModeFromLocalTrack, Mutex, Track,} from 'livekit-client';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {TrackToggle, usePersistentUserChoices} from '@livekit/components-react';
import {defaultUserChoices, log} from '@livekit/components-core';
import CustomParticipantPlaceHolder from "./CustomParticipantPlaceholder";
import Box from "@mui/material/Box";
import {PrejoinCameraButton, PrejoinMicrophoneButton} from "./ControlBarButtons";
import TextField from "@mui/material/TextField";
import {PrejoinBackgroundMenu} from "./BackgroundMenu";

/**
 * Props for the PreJoin component.
 * @public
 */

/** @alpha */
export function usePreviewTracks(
    options,
    onError,
) {
    const [tracks, setTracks] = React.useState();

    const trackLock = React.useMemo(() => new Mutex(), []);

    React.useEffect(() => {
        let needsCleanup = false;
        let localTracks = [];
        trackLock.lock().then(async (unlock) => {
            try {
                if (options.audio || options.video) {
                    localTracks = await createLocalTracks(options);

                    if (needsCleanup) {
                        localTracks.forEach((tr) => tr.stop());
                    } else {
                        setTracks(localTracks);
                    }
                }
            } catch (e) {
                if (onError && e instanceof Error) {
                    onError(e);
                } else {
                    log.error(e);
                }
            } finally {
                unlock();
            }
        });

        return () => {
            needsCleanup = true;
            localTracks.forEach((track) => {
                track.stop();
            });
        };
    }, [JSON.stringify(options), onError, trackLock]);

    return tracks;
}

/**
 * The `PreJoin` prefab component is normally presented to the user before he enters a room.
 * This component allows the user to check and select the preferred media device (camera und microphone).
 * On submit the user decisions are returned, which can then be passed on to the `LiveKitRoom` so that the user enters the room with the correct media devices.
 *
 * @remarks
 * This component is independent of the `LiveKitRoom` component and should not be nested within it.
 * Because it only access the local media tracks this component is self contained and works without connection to the LiveKit server.
 *
 * @example
 * ```tsx
 * <PreJoin />
 * ```
 * @public
 */

const PrejoinTextBox = ({username, setUserName}) => {

    return (
        <TextField
            variant="outlined"
            value={username}
            onChange={(e) => setUserName(e.target.value)}
            label="Username"
            InputProps={{
                style: {
                    backgroundColor: '#F3F4F5', width: '540px', border: "1px solid rgba(255, 255, 255, 0.25)",
                    background: "rgba(0, 0, 0, 0.20)", backdropFilter: "blur(12.5px)",
                    fontFamily: "Inter", fontSize: "16px", fontStyle: "normal", fontWeight: 500,
                    lineHeight: "20px", letterSpacing: "0.14px", color: "#ffffff", height: "64px",
                },
            }}
            InputLabelProps={{
                style: {
                    color: '#ffffff',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontWeight: 400,
                    letterSpacing: '0.14px',
                },
                shrink: true,
            }}
            sx={{
                borderRadius: "8px",
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        border: "1px solid rgba(255, 255, 255, 0.25)",
                    },
                    '&:hover fieldset': {
                        border: "1px solid rgba(255, 255, 255, 0.25)",
                    },
                    '&.Mui-focused fieldset': {
                        border: "1px solid rgba(255, 255, 255, 0.25)",
                    },
                },
            }}
        />
    )
}

export function CustomPrejoin({
                                  defaults = {},
                                  onValidate,
                                  onSubmit,
                                  onAudioEnabledChange,
                                  onVideoEnabledChange,
                                  onUsernameChange,
                                  onError,
                                  debug,
                                  joinLabel = 'Join Room',
                                  micLabel = 'Microphone',
                                  camLabel = 'Camera',
                                  userLabel = 'Username',
                                  persistUserChoices = true,
                                  ...htmlProps
                              }) {
    const [userChoices, setUserChoices] = React.useState(defaultUserChoices);

    // TODO: Remove and pipe `defaults` object directly into `usePersistentUserChoices` once we fully switch from type `LocalUserChoices` to `UserChoices`.
    const partialDefaults = {
        ...(defaults.audioDeviceId !== undefined && {audioDeviceId: defaults.audioDeviceId}),
        ...(defaults.videoDeviceId !== undefined && {videoDeviceId: defaults.videoDeviceId}),
        ...(defaults.audioEnabled !== undefined && {audioEnabled: defaults.audioEnabled}),
        ...(defaults.videoEnabled !== undefined && {videoEnabled: defaults.videoEnabled}),
        ...(defaults.username !== undefined && {username: defaults.username}),
    };

    const {
        userChoices: initialUserChoices,
        saveAudioInputDeviceId,
        saveAudioInputEnabled,
        saveVideoInputDeviceId,
        saveVideoInputEnabled,
        saveUsername,
    } = usePersistentUserChoices({
        defaults: partialDefaults,
        preventSave: !persistUserChoices,
        preventLoad: !persistUserChoices,
    });

    // Initialize device settings
    const [audioEnabled, setAudioEnabled] = React.useState(initialUserChoices.audioEnabled);
    const [videoEnabled, setVideoEnabled] = React.useState(initialUserChoices.videoEnabled);
    const [audioDeviceId, setAudioDeviceId] = React.useState(
        initialUserChoices.audioDeviceId,
    );
    const [videoDeviceId, setVideoDeviceId] = React.useState(
        initialUserChoices.videoDeviceId,
    );
    const [username, setUsername] = React.useState(initialUserChoices.username);

    // For the initial states.
    useEffect(() => {
        if (onUsernameChange) {
            onUsernameChange(username);
        }
        if (onAudioEnabledChange) {
            onAudioEnabledChange(audioEnabled);
        }
        if (onVideoEnabledChange) {
            onVideoEnabledChange(videoEnabled);
        }
    }, []);


    // Save user choices to persistent storage.
    React.useEffect(() => {
        saveAudioInputEnabled(audioEnabled);
        if (onAudioEnabledChange) {
            onAudioEnabledChange(audioEnabled);
        }
    }, [audioEnabled, saveAudioInputEnabled]);
    React.useEffect(() => {
        saveVideoInputEnabled(videoEnabled);
        if (onVideoEnabledChange) {
            onVideoEnabledChange(videoEnabled);
        }
    }, [videoEnabled, saveVideoInputEnabled]);
    React.useEffect(() => {
        saveAudioInputDeviceId(audioDeviceId);
    }, [audioDeviceId, saveAudioInputDeviceId]);
    React.useEffect(() => {
        saveVideoInputDeviceId(videoDeviceId);
    }, [videoDeviceId, saveVideoInputDeviceId]);
    React.useEffect(() => {
        saveUsername(username);
    }, [username, saveUsername]);

    const tracks = usePreviewTracks(
        {
            audio: audioEnabled ? {deviceId: initialUserChoices.audioDeviceId} : false,
            video: videoEnabled ? {deviceId: initialUserChoices.videoDeviceId} : false,
        },
        onError,
    );

    const videoEl = React.useRef(null);

    const videoTrack = React.useMemo(
        () => tracks?.filter((track) => track.kind === Track.Kind.Video)[0],
        [tracks],
    );

    const facingMode = React.useMemo(() => {
        if (videoTrack) {
            const {facingMode} = facingModeFromLocalTrack(videoTrack);
            return facingMode;
        } else {
            return 'undefined';
        }
    }, [videoTrack]);

    const audioTrack = React.useMemo(
        () => tracks?.filter((track) => track.kind === Track.Kind.Audio)[0],
        [tracks],
    );

    React.useEffect(() => {
        if (videoEl.current && videoTrack) {
            videoTrack.unmute();
            videoTrack.attach(videoEl.current);
        }

        return () => {
            videoTrack?.detach();
        };
    }, [videoTrack]);

    const [isValid, setIsValid] = React.useState();

    const handleValidation = React.useCallback(
        (values) => {
            if (typeof onValidate === 'function') {
                return onValidate(values);
            } else {
                return values.username !== '';
            }
        },
        [onValidate],
    );

    React.useEffect(() => {
        const newUserChoices = {
            username,
            videoEnabled,
            videoDeviceId,
            audioEnabled,
            audioDeviceId,
        };
        setUserChoices(newUserChoices);
        setIsValid(handleValidation(newUserChoices));
    }, [username, videoEnabled, handleValidation, audioEnabled, audioDeviceId, videoDeviceId]);

    function handleSubmit(event) {
        event.preventDefault();
        if (handleValidation(userChoices)) {
            if (typeof onSubmit === 'function') {
                onSubmit(userChoices);
            }
        } else {
            log.warn('Validation failed with: ', userChoices);
        }
    }

    const microphoneOnChange = React.useCallback(
        (enabled, isUserInitiated) =>
            isUserInitiated ? saveAudioInputEnabled(enabled) : null,
        [saveAudioInputEnabled],
    );

    const cameraOnChange = React.useCallback(
        (enabled, isUserInitiated) =>
            isUserInitiated ? saveVideoInputEnabled(enabled) : null,
        [saveVideoInputEnabled],
    );

    const toggleVideoRef = React.useRef(null);
    const toggleAudioRef = React.useRef(null);

    const handleUsernameChanged = (username) => {
        setUsername(username);
        if (onUsernameChange) {
            onUsernameChange(username);
        }
    }

    return (
        <Box style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px"}}>
            <div style={{
                position: 'relative',
                width: '560px',
                height: '415px',
                borderRadius: '8px',
                overflow: 'hidden'
            }}>
                {videoTrack && videoEnabled ? (
                    <video ref={videoEl} data-lk-facing-mode={facingMode}
                           style={{objectFit: 'cover', width: '100%', height: '100%', border: "1px solid #e8e8e8", borderRadius: "6px", transform: facingMode === 'user' ? "scaleX(-1)" : "none"}}/>
                ) : (
                    <CustomParticipantPlaceHolder style={{objectFit: 'cover', width: '100%', height: '100%', border: "1px solid #e8e8e8", borderRadius: "6px"}}/>
                )}

                <div style={{
                    position: 'absolute',
                    bottom: '10px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 10
                }}>
                    <Box style={{display: "flex", flexDirection: "column", gap: "10px", marginBottom: "10px"}}>
                        <Box>
                            <PrejoinTextBox username={username} setUserName={handleUsernameChanged}/>
                        </Box>
                    </Box>
                </div>
            </div>
            <Box style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                alignItems: "center",
                alignSelf: "stretch",
                justifyContent: "center",
            }}>
                <Box>
                    <PrejoinMicrophoneButton onChange={microphoneOnChange} audioDeviceId={audioDeviceId}
                                             audioEnabled={audioEnabled} audioTrack={audioTrack}
                                             onActiveDeviceChange={(_, id) => setVideoDeviceId(id)}
                                             toggleRef={toggleAudioRef}/>
                </Box>
                <Box>
                    <PrejoinCameraButton onChange={cameraOnChange}
                                         videoDeviceId={videoDeviceId}
                                         videoTrack={videoTrack} videoEnabled={videoEnabled}
                                         onActiveDeviceChange={(_, id) => setVideoDeviceId(id)}
                                         toggleRef={toggleVideoRef}/>
                </Box>
            </Box>

            <div style={{display: "none"}}>
                <div className="lk-button-group video">
                    <TrackToggle
                        initialState={videoEnabled}
                        source={Track.Source.Camera}
                        onChange={(enabled) => setVideoEnabled(enabled)}
                        ref={toggleVideoRef}
                    >
                        {camLabel}
                    </TrackToggle>
                </div>
            </div>

            <div style={{display: "none"}}>
                <div className="lk-button-group audio">
                    <TrackToggle
                        initialState={audioEnabled}
                        source={Track.Source.Microphone}
                        onChange={(enabled) => setAudioEnabled(enabled)}
                        ref={toggleAudioRef}
                    >
                        {micLabel}
                    </TrackToggle>
                </div>
            </div>
        </Box>
    );
}
