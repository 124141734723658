import {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {LocalAudioTrack, RoomEvent} from "livekit-client";
import {UploadWithSignedUrl} from "../utils/utils";
import {FetchContext} from "../context/FetchContext";
import RecordRTC, {StereoAudioRecorder} from 'recordrtc';

var isEdge = navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveBlob || !!navigator.msSaveOrOpenBlob);
var isOpera = !!window.opera || navigator.userAgent.indexOf('OPR/') !== -1;
var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1 && ('netscape' in window) && / rv:/.test(navigator.userAgent);
var isChrome = (!isOpera && !isEdge && !!navigator.webkitGetUserMedia) || navigator.userAgent.toLowerCase().indexOf('chrome/') !== -1;

var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

if (isSafari && !isChrome && navigator.userAgent.indexOf('CriOS') !== -1) {
    isSafari = false;
    isChrome = true;
}

function mergeLeftRightBuffers(config, callback) {
    function mergeAudioBuffers(config, cb) {
        var numberOfAudioChannels = config.numberOfAudioChannels;

        // todo: "slice(0)" --- is it causes loop? Should be removed?
        var leftBuffers = config.leftBuffers.slice(0);
        var rightBuffers = config.rightBuffers.slice(0);
        var sampleRate = config.sampleRate;
        var internalInterleavedLength = config.internalInterleavedLength;
        var desiredSampRate = config.desiredSampRate;

        if (numberOfAudioChannels === 2) {
            leftBuffers = mergeBuffers(leftBuffers, internalInterleavedLength);
            rightBuffers = mergeBuffers(rightBuffers, internalInterleavedLength);
            if (desiredSampRate) {
                leftBuffers = interpolateArray(leftBuffers, desiredSampRate, sampleRate);
                rightBuffers = interpolateArray(rightBuffers, desiredSampRate, sampleRate);
            }
        }

        if (numberOfAudioChannels === 1) {
            leftBuffers = mergeBuffers(leftBuffers, internalInterleavedLength);
            if (desiredSampRate) {
                leftBuffers = interpolateArray(leftBuffers, desiredSampRate, sampleRate);
            }
        }

        // set sample rate as desired sample rate
        if (desiredSampRate) {
            sampleRate = desiredSampRate;
        }

        // for changing the sampling rate, reference:
        // http://stackoverflow.com/a/28977136/552182
        function interpolateArray(data, newSampleRate, oldSampleRate) {
            var fitCount = Math.round(data.length * (newSampleRate / oldSampleRate));
            //var newData = new Array();
            var newData = [];
            //var springFactor = new Number((data.length - 1) / (fitCount - 1));
            var springFactor = Number((data.length - 1) / (fitCount - 1));
            newData[0] = data[0]; // for new allocation
            for (var i = 1; i < fitCount - 1; i++) {
                var tmp = i * springFactor;
                //var before = new Number(Math.floor(tmp)).toFixed();
                //var after = new Number(Math.ceil(tmp)).toFixed();
                var before = Number(Math.floor(tmp)).toFixed();
                var after = Number(Math.ceil(tmp)).toFixed();
                var atPoint = tmp - before;
                newData[i] = linearInterpolate(data[before], data[after], atPoint);
            }
            newData[fitCount - 1] = data[data.length - 1]; // for new allocation
            return newData;
        }

        function linearInterpolate(before, after, atPoint) {
            return before + (after - before) * atPoint;
        }

        function mergeBuffers(channelBuffer, rLength) {
            var result = new Float64Array(rLength);
            var offset = 0;
            var lng = channelBuffer.length;

            for (var i = 0; i < lng; i++) {
                var buffer = channelBuffer[i];
                result.set(buffer, offset);
                offset += buffer.length;
            }

            return result;
        }

        function interleave(leftChannel, rightChannel) {
            var length = leftChannel.length + rightChannel.length;

            var result = new Float64Array(length);

            var inputIndex = 0;

            for (var index = 0; index < length;) {
                result[index++] = leftChannel[inputIndex];
                result[index++] = rightChannel[inputIndex];
                inputIndex++;
            }
            return result;
        }

        function writeUTFBytes(view, offset, string) {
            var lng = string.length;
            for (var i = 0; i < lng; i++) {
                view.setUint8(offset + i, string.charCodeAt(i));
            }
        }

        // interleave both channels together
        var interleaved;

        if (numberOfAudioChannels === 2) {
            interleaved = interleave(leftBuffers, rightBuffers);
        }

        if (numberOfAudioChannels === 1) {
            interleaved = leftBuffers;
        }

        var interleavedLength = interleaved.length;

        // create wav file
        var resultingBufferLength = 44 + interleavedLength * 2;

        var buffer = new ArrayBuffer(resultingBufferLength);

        var view = new DataView(buffer);

        // RIFF chunk descriptor/identifier
        writeUTFBytes(view, 0, 'RIFF');

        // RIFF chunk length
        view.setUint32(4, 44 + interleavedLength * 2, true);

        // RIFF type
        writeUTFBytes(view, 8, 'WAVE');

        // format chunk identifier
        // FMT sub-chunk
        writeUTFBytes(view, 12, 'fmt ');

        // format chunk length
        view.setUint32(16, 16, true);

        // sample format (raw)
        view.setUint16(20, 1, true);

        // stereo (2 channels)
        view.setUint16(22, numberOfAudioChannels, true);

        // sample rate
        view.setUint32(24, sampleRate, true);

        // byte rate (sample rate * block align)
        view.setUint32(28, sampleRate * 2, true);

        // block align (channel count * bytes per sample)
        view.setUint16(32, numberOfAudioChannels * 2, true);

        // bits per sample
        view.setUint16(34, 16, true);

        // data sub-chunk
        // data chunk identifier
        writeUTFBytes(view, 36, 'data');

        // data chunk length
        view.setUint32(40, interleavedLength * 2, true);

        // write the PCM samples
        var lng = interleavedLength;
        var index = 44;
        var volume = 1;
        for (var i = 0; i < lng; i++) {
            view.setInt16(index, interleaved[i] * (0x7FFF * volume), true);
            index += 2;
        }

        if (cb) {
            return cb({
                buffer: buffer,
                view: view
            });
        }

        postMessage({
            buffer: buffer,
            view: view
        });
    }

    if (!isChrome) {
        // its Microsoft Edge
        mergeAudioBuffers(config, function(data) {
            callback(data.buffer, data.view);
        });
        return;
    }


    var webWorker = processInWebWorker(mergeAudioBuffers);

    webWorker.onmessage = function(event) {
        callback(event.data.buffer, event.data.view);

        // release memory
        URL.revokeObjectURL(webWorker.workerURL);
    };

    webWorker.postMessage(config);
}

function processInWebWorker(_function) {
    var workerURL = URL.createObjectURL(new Blob([_function.toString(),
        ';this.onmessage =  function (eee) {' + _function.name + '(eee.data);}'
    ], {
        type: 'application/javascript'
    }));

    var worker = new Worker(workerURL);
    worker.workerURL = workerURL;
    return worker;
}

const AudioRTCRecording = forwardRef(function AudioRTCRecording({
                                                                    livekitToken,
                                                                    assetIdRef,
                                                                    localRecordingEnabled,
                                                                    room,
                                                                    setRecordingDone,
                                                                    setUploadProgress,
                                                                    setLocalRecordingStopped,
                                                                }, ref) {

    const fetchContext = useContext(FetchContext);

    const audioContextRef = useRef(null);
    const mixedDestinationRef = useRef(null);
    const localTrackConnectedRef = useRef(false);
    const isRecordingRef = useRef(false);
    const recorderRef = useRef(null);

    useEffect(() => {
        if (!localRecordingEnabled) {
            return;
        }

        const newAudioContext = new AudioContext({sampleRate: 48000, latencyHint: "playback"});
        const newMixedDestination = newAudioContext.createMediaStreamDestination();
        audioContextRef.current = newAudioContext;
        mixedDestinationRef.current = newMixedDestination;

        return () => {
            if (newAudioContext !== null && newAudioContext.state !== "closed") {
                newAudioContext.close();
            }
        };
    }, [localRecordingEnabled]);

    useEffect(() => {
        if (room === null || audioContextRef.current === null || mixedDestinationRef.current === null || !localRecordingEnabled) {
            return;
        }

        const ConnectTrack = async (trackPublication) => {
            if (trackPublication.track instanceof LocalAudioTrack) {
                const audioTrack = trackPublication.audioTrack;
                const sourceNode = audioContextRef.current.createMediaStreamSource(new MediaStream([audioTrack.mediaStreamTrack]));
                sourceNode.connect(mixedDestinationRef.current);
                localTrackConnectedRef.current = true;
            }
        };

        room.on(RoomEvent.LocalTrackPublished, ConnectTrack);
    }, [room, localRecordingEnabled]);


    const startRecording = async () => {
        if (isRecordingRef.current) {
            console.log("Start recording called but already recording");
            return;
        }

        if (!localRecordingEnabled) {
            console.log("Local recording not enabled");
            isRecordingRef.current = true; // Set to true to prevent multiple calls
            return;
        }

        if (!localTrackConnectedRef.current && room.localParticipant.audioTrackPublications.size !== 0) {
            const firstKey = room.localParticipant.audioTrackPublications.keys().next().value;
            const audioTrack = room.localParticipant.audioTrackPublications.get(firstKey).audioTrack;
            const sourceNode = audioContextRef.current.createMediaStreamSource(new MediaStream([audioTrack.mediaStreamTrack]));
            sourceNode.connect(mixedDestinationRef.current);
            localTrackConnectedRef.current = true;
        }

        const audioContext = audioContextRef.current;
        const mixedDestination = mixedDestinationRef.current;

        const audioTrack = new LocalAudioTrack(mixedDestination.stream.getAudioTracks()[0]);
        const source = audioContext.createMediaStreamSource(audioTrack.mediaStream);

        recorderRef.current = RecordRTC(mixedDestination.stream, {
            type: 'audio',
            recorderType: StereoAudioRecorder,
        });

        recorderRef.current.startRecording();
        isRecordingRef.current = true;
    }

    const stopRecording = async () => {
        if (!isRecordingRef.current) {
            return;
        }

        if (!localRecordingEnabled) {
            setLocalRecordingStopped(true);
            setTimeout(() => {
                setRecordingDone(true);
            }, 1000);
            recorderRef.current = null;
            isRecordingRef.current = false;
            return;
        }

        const signedUrl = room.localParticipant.metadata;

        if (recorderRef.current) {
            recorderRef.current.stopRecording(() => {
                setLocalRecordingStopped(true);
                isRecordingRef.current = false;

                var internalRecorder = recorderRef.current.getInternalRecorder();
                var leftchannel = internalRecorder.leftchannel;
                var rightchannel = internalRecorder.rightchannel;

                    mergeLeftRightBuffers({
                        desiredSampRate: internalRecorder.desiredSampRate,
                        sampleRate: internalRecorder.sampleRate,
                        numberOfAudioChannels: internalRecorder.numberOfAudioChannels,
                        internalInterleavedLength: internalRecorder.recordingLength,
                        leftBuffers: leftchannel,
                        rightBuffers: internalRecorder.numberOfAudioChannels === 1 ? [] : rightchannel
                    }, function(buffer, view) {
                        // ------------------------------------------------------------
                        // here is your own WAV (generated by your own codes)
                        // ------------------------------------------------------------
                        const blob = new Blob([buffer], {
                            type: 'audio/wav'
                        });

                        UploadWithSignedUrl({
                            blob,
                            signedUrl,
                            isVideo: false,
                            setProgress: setUploadProgress,
                            setRecordingDone,
                        })
                    });
            });
        }
    };

    const isHighQualityRecordingActive = () => {
        return isRecordingRef.current;
    };

    useImperativeHandle(ref, () => ({
        startRecording,
        stopRecording,
        isHighQualityRecordingActive
    }), [startRecording, stopRecording]);

    return (
        <div style={{display: "none"}}/>
    )
});

export default AudioRTCRecording;