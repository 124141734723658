import React from 'react';
import Button from '../Button/Button'; // Assuming you have a Button component
import PlaceholderPanelCard from '../PlaceholderPanelCard/PlaceholderPanelCard'; // Assuming you have this component
import './PanelGridListSection.styles.scss';

interface PanelGridListSectionProps<T> {
    title?: string;
    data: T[];
    className?: string;
    isLoading?: boolean;
    renderFirst?: () => React.ReactNode;
    renderCard: (item: T) => React.ReactNode;
    visibleItemsCount?: number;
    columns?: number;
    isExtended?: boolean;
    onExtend?: () => void;
}

function PanelGridListSection<T>({
                                     title = "Title",
                                     data = [],
                                     className,
                                     columns=2,
                                     isLoading = false,
                                     renderFirst,
                                     renderCard,
                                     visibleItemsCount = 6,
                                     isExtended = false,
                                     onExtend
                                 }: PanelGridListSectionProps<T>): React.ReactElement {
    const isSeeAllVisible = !isExtended && data.length > visibleItemsCount;

    // @ts-ignore
    return (
        <div style={{width: "100%"}}>
        <div className={`panel-grid-list ${className || ''}`}>
            <div className="panel-grid-list__header">
                <span className="panel-grid-list__header-section-text">{title}</span>
                {isSeeAllVisible && (
                    <Button onClick={onExtend}>
                        <span className="panel-grid-list__header-action-text">
                            See all
                            {/* You might want to pass an icon as a prop instead of using a slot */}
                        </span>
                    </Button>
                )}
            </div>
            {/* ts-ignore */}
            <div className="panel-grid-list__content" style={{ "--columns": `${columns}` } as any}>
                {renderFirst && renderFirst()}

                {!isLoading &&
                    data
                        .slice(0, isExtended ? data.length : visibleItemsCount)
                        .map((item, index) => <div key={index}>{renderCard(item)}</div>)}

                {isLoading &&
                    Array.from({ length: visibleItemsCount }).map((_, index) => (
                        <PlaceholderPanelCard key={index} />
                    ))}
            </div>
        </div>
        </div>
    );
}

export default PanelGridListSection;