import Box from "@mui/material/Box";
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import TextField from "@mui/material/TextField";


const CopyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
            <path
                d="M8.11873 4.73089L8.94414 5.5563C9.32336 5.93548 9.62417 6.38564 9.82941 6.88108C10.0346 7.37652 10.1403 7.90754 10.1403 8.4438C10.1403 8.98007 10.0346 9.51108 9.82941 10.0065C9.62417 10.502 9.32336 10.9521 8.94414 11.3313L8.73764 11.5372C7.97183 12.303 6.93317 12.7333 5.85014 12.7333C4.76712 12.7333 3.72846 12.303 2.96264 11.5372C2.19683 10.7714 1.7666 9.73274 1.7666 8.64972C1.7666 7.5667 2.19683 6.52803 2.96264 5.76222L3.78806 6.58764C3.51528 6.85804 3.29859 7.17966 3.15043 7.53404C3.00228 7.88841 2.92558 8.26856 2.92474 8.65266C2.9239 9.03675 2.99893 9.41723 3.14553 9.77225C3.29213 10.1273 3.50741 10.4498 3.77901 10.7214C4.05061 10.993 4.37317 11.2083 4.72819 11.3549C5.08321 11.5015 5.46369 11.5765 5.84779 11.5757C6.23189 11.5749 6.61203 11.4982 6.96641 11.35C7.32078 11.2019 7.6424 10.9852 7.91281 10.7124L8.11931 10.5059C8.6661 9.95893 8.97327 9.2172 8.97327 8.4438C8.97327 7.67041 8.6661 6.92868 8.11931 6.38172L7.29389 5.5563L8.11931 4.73147L8.11873 4.73089ZM12.0376 8.2373L11.2127 7.41247C11.4855 7.14206 11.7022 6.82044 11.8504 6.46607C11.9985 6.11169 12.0752 5.73155 12.076 5.34745C12.0769 4.96335 12.0019 4.58287 11.8553 4.22785C11.7087 3.87283 11.4934 3.55027 11.2218 3.27867C10.9502 3.00707 10.6276 2.79179 10.2726 2.64519C9.91757 2.4986 9.53709 2.42356 9.153 2.4244C8.7689 2.42524 8.38875 2.50194 8.03438 2.6501C7.68 2.79825 7.35838 3.01494 7.08798 3.28772L6.88148 3.49422C6.33469 4.04118 6.02752 4.78291 6.02752 5.5563C6.02752 6.3297 6.33469 7.07143 6.88148 7.61839L7.70689 8.4438L6.88148 9.26864L6.05664 8.4438C5.67743 8.06463 5.37661 7.61446 5.17138 7.11902C4.96615 6.62358 4.86052 6.09257 4.86052 5.5563C4.86052 5.02004 4.96615 4.48902 5.17138 3.99358C5.37661 3.49814 5.67743 3.04798 6.05664 2.6688L6.26314 2.46289C7.02896 1.69707 8.06762 1.26685 9.15064 1.26685C10.2337 1.26685 11.2723 1.69707 12.0381 2.46289C12.804 3.2287 13.2342 4.26736 13.2342 5.35039C13.2342 6.43341 12.804 7.47207 12.0381 8.23789L12.0376 8.2373Z"
                fill="white"/>
        </svg>
    )
}


export const NewInviteParticipantCard = ({onClose, inviteLink}) => {

    const [inviteLinkCopied, setInviteLinkCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(inviteLink);
        setInviteLinkCopied(true);
    };

    const copyButtonText = inviteLinkCopied ? 'Copied!' : 'Copy link';

    const mainStyle = {
        display: 'flex',
        height: "auto",
        width: "640px",
        padding: "24px 24px 40px 24px",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "32px",
        borderRadius: "12px",
    }

    const headerText = {
        fontFamily: "Inter",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "24px",
        letterSpacing: "0.18px",
    }

    const topTitleStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
    }

    return (
        <Box style={mainStyle}>
            <Box style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                alignSelf: "stretch",
                flexDirection: "column",
                gap: "8px",
            }}>
                <Box style={topTitleStyle}>
                    <div style={headerText}>Invite Participants</div>
                    <CloseIcon onClick={onClose} sx={{cursor: 'pointer'}}/>
                </Box>
                <Typography variant="body1" color="text.secondary">Share this link to invite people to your
                        studio.</Typography>
            </Box>
            <Box sx={{display: "flex", flexDirection: "row", gap: "12px"}}>
                <Box>
                    <TextField
                        variant="outlined"
                        value={inviteLink}
                        InputProps={{
                            readOnly: true,
                            style: { backgroundColor: '#F3F4F5', width: '440px', height: "48px",
                                fontFamily: "Inter", fontSize: "14px", fontStyle: "normal", fontWeight: 500,
                                lineHeight: "20px", letterSpacing: "0.14px", color: "#1A1A1A"
                            }, // Adjust text color if needed
                        }}
                        style={{ borderRadius: '7px' }}
                    />
                </Box>
                <Box sx={{display: "flex", cursor: "pointer", width: "130px", height: "48px", justifyContent: "center", borderRadius: "6px", padding: "10px 20px", backgroundColor: "#2B6BFD", alignItems: "center", gap: "10px",
                    '&:hover': {
                        background: "linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #2B6BFD", // Darker primary color
                    },
                }} onClick={copyToClipboard}>
                    <CopyIcon/>
                    <div style={{fontFamily: "Inter", fontSize: "14px", fontStyle: "normal", fontWeight: "500", lineHeight: "20px", letterSpacing: "0.14px", color: "white"
                    }}>
                        {copyButtonText}
                    </div>
                </Box>
            </Box>
        </Box>
    )
}
