import {useTheme} from "@mui/material/styles";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../Firebase";
import {kLibrettoUserCollectionName} from "../../utils/CollectionConstants";
import NewStudio from "./NewStudio";
import NewLobby from "./NewLobby";
import useMediaQuery from "@mui/material/useMediaQuery";


const NewStudioAndLobby = () => {
    const [preJoinChoices, setPreJoinChoices] = useState(undefined);
    const [recordingOptions, setRecordingOptions] = useState(undefined);
    const [userFriendlyRoomName, setUserFriendlyRoomName] = useState(undefined);

    const theme = useTheme();

    const isTooSmall = useMediaQuery(theme.breakpoints.down('smd'));

    const recordingStateRef = useRef();

    const {projectId} = useParams();
    const [searchParams] = useSearchParams();

    const t = searchParams.get('t');

    const handlePreJoinSubmit = (values) => {
        setRecordingOptions(recordingStateRef.current);
        setPreJoinChoices(values);
    }

    const authContext = useContext(AuthContext);

    const logoutUser = async () => {
        await authContext.logout();
    }

    const userId = authContext.getUserId();

    useEffect(() => {
        const fetchUserData = async () => {
            const entityRef = doc(db, kLibrettoUserCollectionName, userId);
            try {
                const docSnap = await getDoc(entityRef);
                if (docSnap.exists()) {
                    setUserFriendlyRoomName(docSnap.data().userFriendlyRoomName);
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching title: ', error);
            }
        };

        if (userId) {
            fetchUserData();
        }
    }, [userId]);

    return (
        preJoinChoices === undefined ?
            <NewLobby userFriendlyStudioName={userFriendlyRoomName} onSubmit={handlePreJoinSubmit} isHost={true} errorMessage={null} isSmallScreen={isTooSmall}/> :
            <NewStudio userChoices={preJoinChoices} projectId={projectId} userFriendlyStudioName={userFriendlyRoomName}/>
    );
}

export default NewStudioAndLobby;
