import * as GiphyTypes from "../types/giphy.types";
import * as PexelsTypes from "../types/pexels.types";

const PEXELS_BASE_URL = "https://api.pexels.com/v1";
const GIPHY_BASE_URL = "https://api.giphy.com/v1";
const PER_PAGE_LIMIT = 14;

interface StockMediaServiceOptions {
    pexelsApiKey: string;
    giphyApiKey: string;
}

export class StockMediaService {
    private static instance: StockMediaService | null = null;
    private pexelsApiKey?: string;
    private giphyApiKey?: string;

    private constructor() {}

    static getInstance(): StockMediaService {
        if (!StockMediaService.instance) {
            StockMediaService.instance = new StockMediaService();
        }
        return StockMediaService.instance;
    }

    init(options: StockMediaServiceOptions) {
        this.pexelsApiKey = options.pexelsApiKey;
        this.giphyApiKey = options.giphyApiKey;
    }

    hasApiKeys() {
        return !!this.pexelsApiKey && !!this.giphyApiKey;
    }

    getCurratedImages(): Promise<PexelsTypes.GetImagesResponse> {
        return this.pexelsFetcher(`${PEXELS_BASE_URL}/curated?per_page=${PER_PAGE_LIMIT}`);
    }

    getCurratedVideos(): Promise<PexelsTypes.GetVideosResponse> {
        return this.pexelsFetcher(`${PEXELS_BASE_URL}/videos/popular?per_page=${PER_PAGE_LIMIT}`);
    }

    getCurratedGifs(): Promise<GiphyTypes.GetGiphyResponse> {
        return this.giphyFetcher(`${GIPHY_BASE_URL}/gifs/trending?rating=g`);
    }

    getCurratedStickers(): Promise<GiphyTypes.GetGiphyResponse> {
        return this.giphyFetcher(`${GIPHY_BASE_URL}/stickers/trending?rating=g`);
    }

    searchImages(query: string): Promise<PexelsTypes.GetImagesResponse> {
        if (!query) {
            return this.getCurratedImages();
        }

        return this.pexelsFetcher(`${PEXELS_BASE_URL}/search?query=${query}&per_page=${PER_PAGE_LIMIT}`);
    }

    searchVideos(query: string): Promise<PexelsTypes.GetVideosResponse> {
        if (!query) {
            return this.getCurratedVideos();
        }

        return this.pexelsFetcher(`${PEXELS_BASE_URL}/videos/search?query=${query}&per_page=${PER_PAGE_LIMIT}`);
    }

    searchGifs(query: string): Promise<GiphyTypes.GetGiphyResponse> {
        if (!query) {
            return this.getCurratedGifs();
        }

        return this.giphyFetcher(`${GIPHY_BASE_URL}/gifs/search?q=${query}`);
    }

    searchStickers(query: string): Promise<GiphyTypes.GetGiphyResponse> {
        if (!query) {
            return this.getCurratedStickers();
        }

        return this.giphyFetcher(`${GIPHY_BASE_URL}/stickers/search?q=${query}`);
    }

    private pexelsFetcher(url: string) {
        return fetch(url, {
            headers: {
                Authorization: `${this.pexelsApiKey}`,
                "Content-Type": "application/json",
            },
        }).then((response) => response.json());
    }

    private giphyFetcher(url: string) {
        return fetch(`${url}&api_key=${this.giphyApiKey}&limit=${PER_PAGE_LIMIT}`).then((response) => response.json());
    }
}
