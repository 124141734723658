import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RendleyStore } from "../../../../store/RendleyStore";
import { reaction } from "mobx";
import { Clip } from '../Clip/Clip';
import './AudioClip.styles.scss';

interface AudioClipProps {
    clipId: string;
    layerId: string;
}

export const AudioClip: React.FC<AudioClipProps> = observer(({ clipId, layerId }) => {
    const [filename, setFilename] = useState<string | undefined>(RendleyStore.getFilenameByClipId(clipId));
    const [thumbnail, setThumbnail] = useState<string | undefined>(RendleyStore.getThumbnailByClipId(clipId));

    useEffect(() => {
        const disposer = reaction(
            () => ({
                filename: RendleyStore.getFilenameByClipId(clipId),
                thumbnail: RendleyStore.getThumbnailByClipId(clipId)
            }),
            ({ filename, thumbnail }) => {
                setFilename(filename);
                setThumbnail(thumbnail);
            },
            { fireImmediately: true }
        );

        return () => {
            disposer();
        };
    }, [clipId]);

    return (
        <Clip clipId={clipId} layerId={layerId} filename={filename} thumbnail={thumbnail} backgroundColor={"255, 169, 40"}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="music">
                    <path
                        id="Vector"
                        d="M4.5 9V2.5L10.5 1.5V8"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_2"
                        d="M3 10.5C3.82843 10.5 4.5 9.82843 4.5 9C4.5 8.17157 3.82843 7.5 3 7.5C2.17157 7.5 1.5 8.17157 1.5 9C1.5 9.82843 2.17157 10.5 3 10.5Z"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_3"
                        d="M9 9.5C9.82843 9.5 10.5 8.82843 10.5 8C10.5 7.17157 9.82843 6.5 9 6.5C8.17157 6.5 7.5 7.17157 7.5 8C7.5 8.82843 8.17157 9.5 9 9.5Z"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </svg>
        </Clip>
    );
});

export default AudioClip;