import {useContext, useEffect, useRef, useState} from "react";
import {LocalAudioTrack, RoomEvent} from "livekit-client";
import {
    Float32ToInt16Array,
    Int16ToUint8Array,
    SendEndOfStreamMarker,
    TimeKeeper,
    UploadSerializedChunks
} from "../utils/utils";
import {FetchContext} from "../context/FetchContext";

const DATA_CHANNEL_CAPACITY = 10 * 1024 * 1024;

const timeKeeper = new TimeKeeper();

const pongs = [];

const SendTimeToServer = (room) => {
    const clientTime = performance.now();
    const buffer = new Float64Array([clientTime]);
    const uint8View = new Uint8Array(buffer.buffer);
    room.localParticipant.publishData(uint8View, {reliable: true, destinationIdentities: ['TimerAgent']});
}

const ParseTimerAgentData = (payload) => {
    const payloadString = new TextDecoder().decode(payload);
    const payloadObject = JSON.parse(payloadString);
    const clientTime = payloadObject.clientTimestamp;
    const serverTime = payloadObject.serverTimestamp;
    return {clientTime, serverTime};
}

const TimerAgentDataHandler = (payload, participant, kind) => {
    const {clientTime, serverTime} = ParseTimerAgentData(payload);
    const timeReceived = performance.now();
    pongs.push(timeReceived - clientTime);
    while (pongs.length > 5) {
        pongs.shift();
    }
    const latency = pongs.reduce((a, b) => a + b) / pongs.length;
    const remoteTime = serverTime + latency / 2;
    timeKeeper.setStartTime(remoteTime);
}

const RoomMetadataChangedHandler = (metadata, identity, trackIdRef) => {
    const payloadObject = JSON.parse(metadata);

    if (payloadObject.command === "RECORDING_STOPPED") {
        return;
    }

    const trackIds = payloadObject.trackIdsByIdentity;
    const trackId = trackIds[identity];

    console.log("Setting track id");
    trackIdRef.current = trackId;
}

const ConsumeData = async (audioData, consumeAll, fetchContext, livekitToken, trackIdRef, setLocalRecordingDone) => {
    console.log("Consume data called")
    console.log("Audio data has length ", audioData.length)

    if (trackIdRef.current === null) {
        console.log("Unable to consume data, trackId is null");
        return;
    }

    if (audioData.length > 0) {

        if (consumeAll) {
            console.log("Consuming all data");
        }

        let accumulatedChunks = [];
        let totalSize = 0;

        let sizeLimit = DATA_CHANNEL_CAPACITY;
        if (consumeAll) {
            sizeLimit *= 1000;
        }

        while (audioData.length > 0 && totalSize < sizeLimit) {
            const chunk = audioData[0];
            const chunkSize = chunk.length;

            if (totalSize + chunkSize > DATA_CHANNEL_CAPACITY) {
                break;
            }

            audioData.shift();  // Consider optimizing this operation if audioData is large
            accumulatedChunks.push(chunk);
            totalSize += chunkSize;
        }

        console.log("Accumulated audio chunks length is ", accumulatedChunks.length)

        if (accumulatedChunks.length > 0) {
            const blob = new Blob(accumulatedChunks, {type: "application/octet-stream"});
            console.log("Uploading audio data")
            await UploadSerializedChunks({
                blob,
                fetchContext,
                livekitToken,
                trackId: trackIdRef.current,
                consumeAll,
                setLocalRecordingDone,
                SendEndOfStreamMarker,
                isVideo: false
            });
        }
    } else if (consumeAll) {
        await SendEndOfStreamMarker(fetchContext, trackIdRef.current, livekitToken, setLocalRecordingDone);
    }
};

const PerformLocalRecording = ({room, localRecordingInProgress, setLocalRecordingDone, setLocalRecordingStopped, livekitToken}) => {
    const [recorder, setRecorder] = useState(null);
    const audioDataRef = useRef([]);
    const consumeIntervalRef = useRef(null);
    const sendTimeToServerIntervalRef = useRef(null);
    const [audioContext, setAudioContext] = useState(null);
    const [mixedDestination, setMixedDestination] = useState(null);
    const trackIdRef = useRef(null);

    const fetchContext = useContext(FetchContext);

    useEffect(() => {
        const newAudioContext = new AudioContext({ sampleRate: 48000, latencyHint: "playback" });
        const newMixedDestination = newAudioContext.createMediaStreamDestination();
        setAudioContext(newAudioContext);
        setMixedDestination(newMixedDestination);

        return () => {
            if (newAudioContext !== null && newAudioContext.state !== "closed") {
                newAudioContext.close();
            }
        };
    }, []);

    useEffect(() => {
        if (room === null || audioContext === null || mixedDestination === null) {
            return;
        }

        const ConnectTrack = async (trackPublication) => {
            if (trackPublication.track instanceof LocalAudioTrack) {
                const audioTrack = trackPublication.audioTrack;
                const sourceNode = audioContext.createMediaStreamSource(new MediaStream([audioTrack.mediaStreamTrack]));
                sourceNode.connect(mixedDestination);
            }
        };
        room.on(RoomEvent.DataReceived, (payload, participant, kind) => TimerAgentDataHandler(payload, participant, kind, room));
        room.on(RoomEvent.RoomMetadataChanged, (metadata) => RoomMetadataChangedHandler(metadata, room.localParticipant.identity, trackIdRef));
        room.on(RoomEvent.LocalTrackPublished, ConnectTrack);

        let activeRecorder = null;

        if (sendTimeToServerIntervalRef.current) {
            clearInterval(sendTimeToServerIntervalRef.current);
        }
        sendTimeToServerIntervalRef.current = setInterval(() => SendTimeToServer(room), 300);

        const startLocalRecording = () => {
            const audioTrack = new LocalAudioTrack(mixedDestination.stream.getAudioTracks()[0]);
            const mediaStream = audioTrack.mediaStream;

            const source = audioContext.createMediaStreamSource(mediaStream);
            const processor = audioContext.createScriptProcessor(undefined, 1, 1);

            processor.onaudioprocess = function(e) {
                const input = e.inputBuffer.getChannelData(0);
                const timestamp = timeKeeper.getCurrentTime();
                // const audioChunk = {
                //     packetTime: timestamp,
                //     packetData: Int16ToUint8Array(Float32ToInt16Array(input))
                // }
                audioDataRef.current.push(Int16ToUint8Array(Float32ToInt16Array(input)));
            };

            source.connect(processor);
            processor.connect(audioContext.destination);

            if (sendTimeToServerIntervalRef.current) {
                clearInterval(sendTimeToServerIntervalRef.current);
            }
            sendTimeToServerIntervalRef.current = setInterval(() => SendTimeToServer(room), 5000);
            if (consumeIntervalRef.current) {
                clearInterval(consumeIntervalRef.current);
            }
            consumeIntervalRef.current = setInterval(() => ConsumeData(audioDataRef.current, false, fetchContext, livekitToken, trackIdRef, setLocalRecordingDone), 10000);

            activeRecorder = {
                stop: async () => {
                    processor.disconnect(); // Disconnect the processor.
                    source.disconnect(); // Disconnect the source.
                    if (audioContext !== null && audioContext.state !== "closed") {
                        audioContext.close(); // Close the audio context
                    }
                }
            };

            setRecorder(activeRecorder);
        };


        if (localRecordingInProgress) {
            startLocalRecording();
        } else if (recorder) {
            // Stop the recording and handle the recorded data, for example, by saving it
            setLocalRecordingStopped(true);
            recorder.stop();
            console.log("Consuming all audio data")
            setTimeout(() => {
                // Wait some time for the last onDataAvailable event to be fired after stop is called.
                ConsumeData(audioDataRef.current, true, fetchContext, livekitToken, trackIdRef, setLocalRecordingDone).then(() => {
                    setRecorder(null); // Reset the recorder state
                });
            }, 2000);
            clearInterval(consumeIntervalRef.current);
        }

        // Cleanup function when component unmounts or re-renders
        return () => {
            // Cleanup function to stop recording if the component unmounts while recording is in progress
            if (activeRecorder) {
                activeRecorder.stop();
            }
            room.off(RoomEvent.LocalTrackPublished, ConnectTrack);
            room.off(RoomEvent.DataReceived, TimerAgentDataHandler);
            room.off(RoomEvent.RoomMetadataChanged, RoomMetadataChangedHandler);
            clearInterval(sendTimeToServerIntervalRef.current);
            clearInterval(consumeIntervalRef.current);
        };
    }, [localRecordingInProgress, room, audioContext, mixedDestination]);

    return null;
};

export default PerformLocalRecording;
