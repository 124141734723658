import {forwardRef, useContext, useEffect, useImperativeHandle, useRef} from "react";
import {FetchContext} from "../context/FetchContext";
import {LocalAudioTrack, LocalVideoTrack, RoomEvent} from "livekit-client";
import {UploadWithSignedUrl} from "../utils/utils";
import CustomRecordRTC from "./CustomRecordRTC";


const VideoRTCRecording = forwardRef(function VideoRTCRecording({
                                                                    livekitToken,
                                                                    assetIdRef,
                                                                    localRecordingEnabled,
                                                                    room,
                                                                    setRecordingDone,
                                                                    isHDQuality,
                                                                    setUploadProgress,
                                                                    setLocalRecordingStopped,
                                                                }, ref) {

    const fetchContext = useContext(FetchContext);

    const audioContextRef = useRef(null);
    const mixedDestinationRef = useRef(null);
    const localTrackConnectedRef = useRef(false);
    const isRecordingRef = useRef(false);
    const recorderRef = useRef(null);

    useEffect(() => {
        if (!localRecordingEnabled) {
            return;
        }

        const newAudioContext = new AudioContext({sampleRate: 48000, latencyHint: "playback"});
        const newMixedDestination = newAudioContext.createMediaStreamDestination();
        audioContextRef.current = newAudioContext;
        mixedDestinationRef.current = newMixedDestination;

        return () => {
            if (newAudioContext !== null && newAudioContext.state !== "closed") {
                newAudioContext.close();
            }
        };
    }, [localRecordingEnabled]);

    useEffect(() => {
        if (room === null || audioContextRef.current === null || mixedDestinationRef.current === null || !localRecordingEnabled) {
            return;
        }

        const ConnectTrack = async (trackPublication) => {
            if (trackPublication.track instanceof LocalAudioTrack) {
                const audioTrack = trackPublication.audioTrack;
                const sourceNode = audioContextRef.current.createMediaStreamSource(new MediaStream([audioTrack.mediaStreamTrack]));
                sourceNode.connect(mixedDestinationRef.current);
                localTrackConnectedRef.current = true;
            }
        };

        room.on(RoomEvent.LocalTrackPublished, ConnectTrack);
    }, [room, localRecordingEnabled]);

    const startRecording = async () => {
        if (isRecordingRef.current) {
            console.log("Start recording called but already recording");
            return;
        }

        if (!localRecordingEnabled) {
            console.log("Local recording not enabled");
            isRecordingRef.current = true; // Set to true to prevent multiple calls
            return;
        }

        if (!localTrackConnectedRef.current && room.localParticipant.audioTrackPublications.size !== 0) {
            const firstKey = room.localParticipant.audioTrackPublications.keys().next().value;
            const audioTrack = room.localParticipant.audioTrackPublications.get(firstKey).audioTrack;
            const sourceNode = audioContextRef.current.createMediaStreamSource(new MediaStream([audioTrack.mediaStreamTrack]));
            sourceNode.connect(mixedDestinationRef.current);
            localTrackConnectedRef.current = true;
        }

        const audioContext = audioContextRef.current;
        const mixedDestination = mixedDestinationRef.current;

        const audioTrack = new LocalAudioTrack(mixedDestination.stream.getAudioTracks()[0]);

        let mediaStream;
        if (room.localParticipant.videoTrackPublications.size === 0) {
            mediaStream = new MediaStream([audioTrack.mediaStreamTrack]);
        } else {
            const firstKey = room.localParticipant.videoTrackPublications.keys().next().value;
            const videoTrack = room.localParticipant.videoTrackPublications.get(firstKey).videoTrack;
            mediaStream = new MediaStream([videoTrack.mediaStreamTrack, audioTrack.mediaStreamTrack]);
        }

        const videoBitsPerSecond = isHDQuality ? 10000000 : 6000000;
        recorderRef.current = new CustomRecordRTC(mediaStream, {
            type: 'video',
            mimeType: 'video/x-matroska;codecs=avc1',
            videoBitsPerSecond: videoBitsPerSecond,
        });

        const ReplaceVideoTrack = async (trackPublication) => {
            if (trackPublication.track instanceof LocalVideoTrack) {
                const videoStreamTrack = trackPublication.videoTrack.mediaStreamTrack;
                if (recorderRef.current) {
                    console.log("Calling replace video track");
                    recorderRef.current.replaceVideoTrack(videoStreamTrack);
                }
            }
        }

        room.on(RoomEvent.LocalTrackPublished, ReplaceVideoTrack);
        room.on(RoomEvent.TrackUnmuted, ReplaceVideoTrack);

        recorderRef.current.startRecording();
        isRecordingRef.current = true;
    }

    const stopRecording = async () => {
        if (!isRecordingRef.current) {
            return;
        }

        if (!localRecordingEnabled) {
            setLocalRecordingStopped(true);
            setTimeout(() => {
                setRecordingDone(true);
            }, 1000);
            recorderRef.current = null;
            isRecordingRef.current = false;
            return;
        }

        const signedUrl = room.localParticipant.metadata;

        if (recorderRef.current) {
            recorderRef.current.stopRecording(() => {
                setLocalRecordingStopped(true);
                isRecordingRef.current = false;

                const blob = recorderRef.current.getBlob();

                UploadWithSignedUrl({
                    blob,
                    signedUrl,
                    isVideo: true,
                    setProgress: setUploadProgress,
                    setRecordingDone,
                })
            });
        }
    };

    const isHighQualityRecordingActive = () => {
        return isRecordingRef.current;
    };

    useImperativeHandle(ref, () => ({
        startRecording,
        stopRecording,
        isHighQualityRecordingActive
    }), [startRecording, stopRecording]);

    return (
        <div style={{display: "none"}}/>
    )
});

export default VideoRTCRecording;