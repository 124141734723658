import React from 'react';
import './ConfigurationPanel.styles.scss';

interface ConfigurationPanelProps {
    title?: string;
    children?: React.ReactNode;
}

const ConfigurationPanel: React.FC<ConfigurationPanelProps> = ({ title, children }) => {
    return (
        <div className="configuration-panel">
            <div>
                <h3>{title}</h3>
            </div>

            <div className="configuration-panel-content grid gap-3">
                {children}
            </div>
        </div>
    );
};

export default ConfigurationPanel;