import { FontSource } from "./config";

export const fontSources: FontSource[] = [
  {
    family: "Roboto",
    cssUrl: "https://fonts.googleapis.com/css2?family=Roboto&display=swap",
  },
  {
    family: "Open Sans",
    cssUrl: "https://fonts.googleapis.com/css2?family=Open+Sans&display=swap",
  },
  {
    family: "Montserrat",
    cssUrl: "https://fonts.googleapis.com/css2?family=Montserrat&display=swap",
  },
  {
    family: "Poppins",
    cssUrl: "https://fonts.googleapis.com/css2?family=Poppins&display=swap",
  },
  {
    family: "Lato",
    cssUrl: "https://fonts.googleapis.com/css2?family=Lato&display=swap",
  },
  {
    family: "Oswald",
    cssUrl: "https://fonts.googleapis.com/css2?family=Oswald&display=swap",
  },
  {
    family: "Rubik",
    cssUrl: "https://fonts.googleapis.com/css2?family=Rubik&display=swap",
  },
  {
    family: "Ubuntu",
    cssUrl: "https://fonts.googleapis.com/css2?family=Ubuntu&display=swap",
  },
  {
    family: "PT Sans",
    cssUrl: "https://fonts.googleapis.com/css2?family=PT+Sans&display=swap",
  },
  {
    family: "Kanit",
    cssUrl: "https://fonts.googleapis.com/css2?family=Kanit&display=swap",
  },
  {
    family: "Lora",
    cssUrl: "https://fonts.googleapis.com/css2?family=Lora&display=swap",
  },
  {
    family: "Dancing Script",
    cssUrl: "https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap",
  },
  {
    family: "Bangers",
    cssUrl: "https://fonts.googleapis.com/css2?family=Bangers&display=swap",
  },
];
