import {Card, CardActions, CardContent, ClickAwayListener, IconButton, Slider, Stack, Switch} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React, {useContext, useState} from "react";
import Box from "@mui/material/Box";
import {ArrowBackIos, ArrowForwardIosOutlined, HourglassEmpty} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import {useTheme} from "@mui/material/styles";
import {createSetEnhancedAudio, createSetRemoveFillerWords} from "./Liveblocks";
import {LibrettoLiveblocksContext} from "./LibrettoLiveblocksContext";

const MagicToolsCard = ({
                            handleClose,
                            track,
    noAudioStream,
                            selectedTrackindex,
                            handleEnhanceAudioRequest,
                            topPosition,
                            leftPosition,
                            guestEditor,
                            handleRemoveFillerWordsRequest,
                        }) => {

    const theme = useTheme();

    const isDarkMode = theme.palette.mode === 'dark';

    const [showSetPaceCard, setShowSetPaceCard] = useState(false);
    const [paceSetting, setPaceSetting] = useState(0);

    const liveblocksContext = useContext(LibrettoLiveblocksContext);
    const trackSettings = liveblocksContext.useStorage((root) => root.trackSettings);
    const editSettings = liveblocksContext.useStorage((root) => root.editSettings);

    const [removeFillerWords, setRemoveFillerWords] = useState(() => {
        const initialSettings = editSettings.get("editSettings");
        return initialSettings && initialSettings.removeFillerWords ? initialSettings.removeFillerWords : false;
    });

    const strIndex = String(selectedTrackindex);
    const [enhancedAudioSelected, setEnhancedAudioSelected] = useState(trackSettings.get(strIndex) ? trackSettings.get(strIndex).enhanceAudio : false);

    const setEnhanceAudio = createSetEnhancedAudio(liveblocksContext);

    const setRemoveFillerWordsInStorage = createSetRemoveFillerWords(liveblocksContext);

    const handleEnhanceAudioChange = (enhancedAudioSelected) => {
        setEnhanceAudio(selectedTrackindex, enhancedAudioSelected);
        setEnhancedAudioSelected(enhancedAudioSelected);
    }

    const handleRemoveFillerWords = (removeFillerWordsArg) => {
        handleRemoveFillerWordsRequest(removeFillerWordsArg);
        setRemoveFillerWordsInStorage(removeFillerWordsArg);
        setRemoveFillerWords(removeFillerWordsArg);
    }


    // Pace setting to word description map
    const paceSettingTitleMap = {
        0: "Original",
        1: "Natural",
        2: "Balanced",
        3: "Fast",
        4: "Very fast"
    }

    const paceSettingDescriptionMap = {
        0: "Keep all pauses in original recording.",
        1: "A smooth pace for long form content on YouTube, Spotify etc.",
        2: "An engaging pace for long form content on YouTube, Spotify etc.",
        3: "A face pace for short and long form content on YouTube, Instagram & TikTok.",
        4: "A very fast pace for short form content like YouTube Shorts, Reels and TikToks."
    }

    const paceSettingToTitle = (paceSetting) => {
        return paceSettingTitleMap[paceSetting];
    }

    const paceSettingToDescription = (paceSetting) => {
        return paceSettingDescriptionMap[paceSetting];
    }


    const EnhanceAudioButton = ({disabled}) => {
        return (
            <Box width="85px" justifyContent="center" alignItems="center">
                {track.enhancedAudioUrl === "placeholder" ?
                    <Box width="85px" justifyContent="center" alignItems="center">
                        <HourglassEmpty sx={{color: "white"}}/>
                    </Box> :
                    <Button variant="contained" style={{borderRadius: "5px", height: "35px", width: "85px"}}
                            onClick={() => handleEnhanceAudioRequest(track.trackId)} disabled={disabled}>
                        <Typography color={isDarkMode ? "#0d47a1" : "white"}>Apply</Typography>
                    </Button>}
            </Box>

        )
    }

    const enhanceAudioDisabled = track ? !track.hasAudioStream : true;

    const removeFillerWordsDisabled = noAudioStream;

    const effectiveTopPosition = topPosition + 43;

    const effectiveLeftPosition = leftPosition - 175;

    const marksMap = {
        0: 'Keep all pauses',
        1: '3s or more',
        2: '1.5s or more',
        3: '1s or more',
        4: 'Remove all pauses',
    };

    const valueText = (value) => {
        return marksMap[value];
    }

    const marks = [
        {value: 0, label: ''},
        {value: 1, label: ''},
        {value: 2, label: ''},
        {value: 3, label: ''},
        {value: 4, label: ''},
    ];

    const handleSliderChange = (event, newValue) => {
        setPaceSetting(newValue);
    }

    if (showSetPaceCard) {
        return (
            <ClickAwayListener onClickAway={handleClose}>
                <Card
                    elevation={5}
                    style={{
                        cursor: 'pointer', position: 'absolute',
                        top: effectiveTopPosition,
                        left: effectiveLeftPosition,
                        width: 360,
                        backgroundColor: 'rgba(0, 0, 0, 0.95)', // Dark background
                        borderRadius: '16px', // Rounded corners
                        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)', overflow: 'visible', height: 290, zIndex: 9999
                    }}
                >
                    <CardContent>
                        <Box flexDirection="row" alignItems="center" display="flex">
                            <Tooltip title={"Back"} placement="top" arrow>
                                <IconButton onClick={() => setShowSetPaceCard(false)} variant="contained">
                                    <ArrowBackIos style={{fontSize: "1.5rem", color: "white"}}/>
                                </IconButton>
                            </Tooltip>
                            <Typography variant="h6" marginBottom={0} color="white" fontWeight={700}>
                                Set pace
                            </Typography>
                        </Box>
                        <br/>
                        <Box flexDirection="column">
                            <Typography color="white" variant="subtitle1">{paceSettingToTitle(paceSetting)}</Typography>
                            <Typography color="text.secondary" variant="subtitle2" style={{
                                minHeight: '2em',
                                lineHeight: '1em'
                            }}>{paceSettingToDescription(paceSetting)}</Typography>
                        </Box>
                        <br/>
                        <Box sx={{width: 300}} flexDirection="column" display="flex">
                            <Slider defaultValue={0} onChange={handleSliderChange} valueLabelFormat={valueText}
                                    marks={marks} step={1} min={0} max={4} valueLabelDisplay="on" sx={{
                                width: 300,
                                '& .MuiSlider-mark': {
                                    backgroundColor: 'green', // Change the color of the marks here
                                    height: 3,
                                    width: 3,
                                    borderRadius: '50%', // Opti
                                },
                                '& .MuiSlider-rail': {
                                    color: 'grey', // Change the color of the rail here
                                },
                                '& .MuiSlider-track': {
                                    color: `${theme.palette.primary.main}`, // Change the color of the track here
                                },
                                '& .MuiSlider-thumb': {
                                    color: 'green', // Change the color of the thumb here
                                },
                            }}/>
                            <Box flexDirection="row" display="flex" justifyContent="space-between" paddingX={0}>
                                <Typography color="text.secondary">Original</Typography>
                                <Typography color="text.secondary">Very fast</Typography>
                            </Box>
                        </Box>
                        <br/>
                        <Box flexDirection="row" display="flex" justifyContent="space-between" alignItems="center">
                            <Typography color="text.secondary" variant="subtitle2">Remove pauses.</Typography>
                            <Button variant="contained" style={{borderRadius: "10px", height: "35px"}}>
                                Apply
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </ClickAwayListener>
        )
    }

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Card
                elevation={5}
                style={{
                    cursor: 'pointer', position: 'absolute',
                    top: effectiveTopPosition,
                    left: effectiveLeftPosition,
                    width: 360,
                    backgroundColor: 'rgba(0, 0, 0, 0.9)', // Dark background
                    borderRadius: '16px', // Rounded corners
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)', overflow: 'visible', height: 290, zIndex: 9999
                }}
            >
                <CardContent>
                    <IconButton onClick={handleClose} sx={{position: 'absolute', right: 2, top: 2}} variant="contained">
                        <CloseIcon style={{fontSize: "1.0rem", color: "white"}}/>
                    </IconButton>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between"
                           marginBottom={1.5} marginTop={2}>
                        <Box flexDirection="column" width="300px">
                            <Typography variant="subtitle2" color="white" fontSize="1.0rem" fontWeight={800}>Enhance
                                audio</Typography>
                            <Typography variant="subtitle2" color="text.secondary" fontWeight={800}>Speech improvement,
                                noise reduction and more. Reversible.</Typography>
                        </Box>
                        {track && track.hasEnhancedAudio ?
                            <Box width="85px" justifyContent="center" alignItems="center">
                                <Switch
                                    checked={enhancedAudioSelected}
                                    onChange={() => handleEnhanceAudioChange(!enhancedAudioSelected)}
                                    inputProps={{'aria-label': 'controlled'}}
                                    sx={{
                                        color: 'white', // color when off
                                        '& .MuiSwitch-switchBase.Mui-checked': {
                                            color: 'white', // color of the thumb when on
                                        },
                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                            backgroundColor: 'white', // color of the track when on
                                        }
                                    }}
                                />
                            </Box> : <EnhanceAudioButton disabled={enhanceAudioDisabled}/>}
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between"
                           marginBottom={1.5}>
                        <Box flexDirection="column">
                            <Typography variant="subtitle2" color="white" fontSize="1.0rem" fontWeight={800}>Set
                                pace</Typography>
                            <Typography variant="subtitle2" color="text.secondary" fontWeight={800}>Remove pauses and
                                improve speech flow.</Typography>
                        </Box>
                        <Box width="85px" justifyContent="center" alignItems="center">
                            <Button disabled={true}
                                    onClick={() => setShowSetPaceCard(true)} variant="contained"
                                    style={{borderRadius: "5px", height: "35px", width: "85px"}}>
                                <Typography color="white">Set</Typography>
                            </Button>
                        </Box>
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between"
                           marginBottom={1.5} marginTop={2}>
                        <Box flexDirection="column" width="300px">
                            <Typography variant="subtitle2" color="white" fontSize="1.0rem" fontWeight={800}>Smooth
                                speech</Typography>
                            <Typography variant="subtitle2" color="text.secondary" fontWeight={800}>Remove filler words
                                and unwanted sounds. Click to
                            <span>
                                {removeFillerWords ? " revert." : " apply."}
                            </span>
                            </Typography>
                        </Box>
                        <Box width="85px" justifyContent="center" alignItems="center">
                            <Button disabled={removeFillerWordsDisabled}
                                    onClick={() => handleRemoveFillerWords(!removeFillerWords)} variant="contained"
                                    style={{borderRadius: "5px", height: "35px", width: "85px"}}>
                                {removeFillerWords ? <Typography color={isDarkMode ? "#0d47a1" : "white"}>
                                    Revert
                                </Typography> : <Typography color={isDarkMode ? "#0d47a1" : "white"}>Apply</Typography>}
                            </Button>
                        </Box>
                    </Stack>
                </CardContent>
            </Card>
        </ClickAwayListener>
    )
}

export default MagicToolsCard;
