import {useTheme} from "@mui/material/styles";
import React, {useEffect, useState} from "react";
import {doc, getDoc, updateDoc} from "firebase/firestore";
import {db} from "../../Firebase";
import {formatToReadableLargeTitle, formatToReadableTitle, formatUnixTime} from "../../utils/utils";
import {ClickAwayListener} from "@mui/material";
import Typography from "@mui/material/Typography";
import {kProjectsCollectionName} from "../../utils/CollectionConstants";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";


const titleAndDateAreaStyle = {
    display: "flex",
    gap: "12px",
    flexDirection: "column",
    marginTop: "20px",
}

const dateTextStyle = {
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.14px",
    color: "#808080",
}

const NewEditableTitle = ({projectId, isEditing, setIsEditingTitle}) => {
    const theme = useTheme();
    const [title, setTitle] = useState('');
    const [updateTime, setUpdateTime] = useState('');

    const [isHovered, setIsHovered] = useState(false);

    const hoverStyles = isHovered ? {border: `0.5px solid ${theme.palette.primary.main}`, borderRadius: '5px'} : {};

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const fetchTitle = async () => {
            const entityRef = doc(db, kProjectsCollectionName, projectId);
            try {
                const docSnap = await getDoc(entityRef);
                if (docSnap.exists()) {
                    setTitle(docSnap.data().name);
                    setUpdateTime(formatUnixTime(docSnap.data().updateTime));
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching title: ', error);
            }
        };

        fetchTitle();
    }, [projectId]);

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const updateTitle = async () => {
        setIsEditingTitle(false);

        // Update Firestore
        const entityRef = doc(db, kProjectsCollectionName, projectId);
        try {
            await updateDoc(entityRef, {name: title});
            console.log('Title updated successfully');
        } catch (error) {
            console.error('Error updating title: ', error);
        }
    };
    const handleKeyDown = async (e) => {
        if (e.key === 'Enter') {
            updateTitle();
        }
    };

    const handleClickAway = () => {
        if (isEditing) {
            updateTitle();
        }
    };

    const commonStyles = {
        fontSize: '42px',
        fontWeight: 500,
        padding: '0px', // Reduced padding to compensate for border
        margin: '0',
        height: '52px', // Match line-height
        lineHeight: '52px',
        backgroundColor: 'transparent',
        width: '100%',
        maxWidth: '100%',
        border: '1px solid transparent', // Always have a border, but transparent when not editing
        boxSizing: 'border-box', // Include border in element's dimensions
        fontFamily: "Inter",
        fontStyle: "normal",
        transition: 'border-color 0.3s', // Smooth transition for border color
    };

    const editingStyles = {
        ...commonStyles,
        borderColor: theme.palette.primary.main,
        outline: 'none', // Remove default focus outline
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div style={titleAndDateAreaStyle}
            >
                {isEditing ? (
                    <input
                        type="text"
                        value={title}
                        onChange={handleTitleChange}
                        onKeyDown={handleKeyDown}
                        autoFocus
                        maxLength={50}
                        style={{...editingStyles}}
                    />
                ) : (
                    <Box style={{...commonStyles, cursor: 'pointer'}}>
                        {isMobile ? formatToReadableTitle(title) : formatToReadableLargeTitle(title)}
                    </Box>
                )}
                <Box style={dateTextStyle}>{updateTime}</Box>
            </div>
        </ClickAwayListener>
    );
};

export const ReadOnlyTitle = ({ title, date }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const titleStyle = {
        fontSize: '42px',
        fontWeight: 500,
        padding: '0px',
        margin: '0',
        height: '52px',
        lineHeight: '52px',
        backgroundColor: 'transparent',
        width: '100%',
        maxWidth: '100%',
        boxSizing: 'border-box',
        fontFamily: "Inter",
        fontStyle: "normal",
    };

    return (
        <div style={titleAndDateAreaStyle}>
            <Box style={titleStyle}>
                {isMobile ? formatToReadableTitle(title) : formatToReadableLargeTitle(title)}
            </Box>
            <Box style={dateTextStyle}>{formatUnixTime(date)}</Box>
        </div>
    );
};

export default NewEditableTitle;
