import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import React from "react";
import {Card, CardContent, ClickAwayListener} from "@mui/material";


const EditorLayoutCard = ({videoPlayerVisible, textEditorVisible, setVideoPlayerVisible, setTextEditorVisible, onClose}) => {

    const featureDescStyle = {
        alignSelf: "stretch",
        color: "#1a1a1a",
        fontFamily: "Inter",
        fontSize: "16px",
        padding: "8px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "16px",
        letterSpacing: "0.48px",
    };

    const HideVideoCanvasBox = () => {
        return (
            <Box onClick={() => {
                if (!videoPlayerVisible) {
                    setVideoPlayerVisible(true);
                    return;
                }
                if (videoPlayerVisible) {
                    if (!textEditorVisible) {
                        setTextEditorVisible(true);
                    }
                    setVideoPlayerVisible(false);
                }
            }} sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingX: 1,
                marginBottom: 2,
                cursor: 'pointer',
                transition: 'background-color 0.3s, border 0.3s',
                border: '1px solid transparent',
                borderRadius: '8px',
                '&:hover': {
                    border: '1px solid',
                    borderColor: '#2B6BFD',
                    borderRadius: '8px',
                },
            }}>
                <Box flexDirection="row" display="flex" justify gap={1}>
                    <Box flexDirection="column">
                        <Box sx={featureDescStyle}>Hide video canvas</Box>
                    </Box>
                </Box>
                {!videoPlayerVisible && <CheckIcon sx={{fontSize: '1.5rem', color: 'green'}}/>}
            </Box>
        )
    }

    const HideTranscriptEditorBox = () => {
        return (
            <Box onClick={() => {
                if (!textEditorVisible) {
                    setTextEditorVisible(true);
                    return;
                }
                if (textEditorVisible) {
                    if (!videoPlayerVisible) {
                        setVideoPlayerVisible(true);
                    }
                    setTextEditorVisible(false);
                }
            }} sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingX: 1,
                marginBottom: 2,
                cursor: 'pointer',
                transition: 'background-color 0.3s, border 0.3s',
                border: '1px solid transparent',
                borderRadius: '8px',
                '&:hover': {
                    border: '1px solid',
                    borderColor: '#2B6BFD',
                    borderRadius: '8px',
                },
            }}>
                <Box flexDirection="row" display="flex" justify gap={1}>
                    <Box flexDirection="column">
                        <Box sx={featureDescStyle}>Hide transcript editor</Box>
                    </Box>
                </Box>
                {!textEditorVisible && <CheckIcon sx={{fontSize: '1.5rem', color: 'green'}}/>}
            </Box>
        )
    }

    return (
        <ClickAwayListener onClickAway={onClose}>
            <Card
                elevation={5}
                style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: "70px",
                    right: "150px",
                    width: 270,
                    backgroundColor: 'rgba(255, 255, 255, 0.95)',
                    borderRadius: '16px',
                    boxShadow: '0px 30px 80px 0px rgba(0, 0, 0, 0.15)',
                    overflow: 'visible',
                    height: 120,
                    zIndex: 9999
                }}
            >
                <CardContent>
                    <HideVideoCanvasBox/>
                    <HideTranscriptEditorBox/>
                </CardContent>
            </Card>
        </ClickAwayListener>
    )
}

export default EditorLayoutCard;