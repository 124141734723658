import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {StudioBackIconButton} from "./SettingsIcon";
import {useState} from "react";
import {CustomPrejoin} from "./CustomPrejoin";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import TextField from "@mui/material/TextField";
import {Button, DialogContent} from "@mui/material";
import {LibrettoStudioTheme} from "../../utils/utils";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";


const CheckedCheckBox = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18" fill="none">
            <circle cx="9" cy="9" r="9" fill="#2B6BFD"/>
            <path d="M13.1667 6.48254L7.43754 12.2117L4.83337 9.60754" stroke="white" strokeWidth="2"
                  strokeLinecap="square" strokeLinejoin="round"/>
        </svg>
    )
}

const CheckBox = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <rect x="0.75" y="0.75" width="18.5" height="18.5" rx="9.25" stroke="#BFBFBF" strokeWidth="1.5"/>
        </svg>
    )
}

const ErrorMessageSpace = ({ errorMessage, isHost, style }) => {
    const twolineTextStyle = {
        fontFamily: "Inter",
        fontStyle: "normal",
        lineHeight: "20px",
        fontWeight: "500",
        alignSelf: "stretch",
        letterSpacing: "0.14px",
        fontSize: "14px",
        color: "#999",
        marginTop: "8px",
        maxWidth: "410px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        overflow: "hidden",
        ...style, // Allow for additional style overrides
    };

    const placeholderStyle = {
        ...twolineTextStyle,
        height: '40px', // Adjust this value based on your typical error message height
        visibility: 'hidden',
    };

    return (
        <Box style={errorMessage && !isHost ? twolineTextStyle : placeholderStyle}>
            {errorMessage && !isHost ? errorMessage : ""}
        </Box>
    );
};

const NewLobby = ({userFriendlyStudioName, onSubmit, isHost, errorMessage, isSmallScreen}) => {

    const [usingHeadphones, setUsingHeadphones] = useState(false);
    const [recordingTitle, setRecordingTitle] = useState("My podcast episode");

    const [currentUsername, setCurrentUsername] = React.useState('');
    const [audioEnabled, setAudioEnabled] = React.useState(null);
    const [videoEnabled, setVideoEnabled] = React.useState(null);

    const handleUsernameChange = (username) => {
        setCurrentUsername(username);
    };

    const handleAudioEnabledChange = (enabled) => {
        setAudioEnabled(enabled);
    }

    const handleVideoEnabledChange = (enabled) => {
        setVideoEnabled(enabled);
    }

    const enterStudioButtonDisabled = recordingTitle === "" || currentUsername === "";

    const navigate = useNavigate();

    const pageStyle = {
        height: "100vh",
        width: "100vw",
        backgroundColor: "#f3f4f5",
    }

    const headerTextStyle = {
        fontFamily: "Inter",
        fontSize: "42px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "52px",
    }

    const subHeaderTextStyle = {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px",
        alignSelf: "stretch",
        color: "#808080",
        marginTop: "8px",
    }

    const twolineTextStyle = {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px",
        color: "#808080",
        marginTop: "8px",
        maxWidth: "410px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        overflow: "hidden",
    };

    const titleTextStyle = {
        ...subHeaderTextStyle,
        color: "#1A1A1A",
        fontWeight: "500",
        marginTop: "40px",
    }

    const audioTitleStyle = {
        ...subHeaderTextStyle,
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "0.16px",
        marginTop: "40px",
        color: "#1A1A1A",
    }

    const headphoneTextStyle = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "20px",
        alignSelf: "stretch",
        color: "#1a1a1a",
        letterSpacing: "0.14px",
        fontSize: "14px",
    }

    const buttonTextStyle = {
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        fontHeight: "20px",
        letterSpacing: "0.14px",
        color: "white",
    }

    const descStyle = {
        ...headphoneTextStyle,
        marginTop: "16px",
        color: "#999",
    }

    const buttonStyle = {
        display: "flex",
        height: "52px",
        padding: "10px 60px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "8px",
        background: "#2B6BFD",
        marginTop: "40px",
        width: "210px",
        cursor: "pointer",
        // Hover style
        "&:hover": {
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #2B6BFD",
        },
        // Disabled style
        "&:disabled": {
            background: "#2B6BFD",
            cursor: "not-allowed",
            opacity: "0.3",
        },
    }

    const navigateToDashboard = () => {
        navigate("/dashboard")
    }

    const spanStyle = {
        // display: "inline-block",
        maxWidth: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontWeight: "600",
    };

    const handleEnterStudio = () => {
        onSubmit({
            title: recordingTitle,
            username: currentUsername,
            audioEnabled,
            videoEnabled,
            usingHeadphones,
        });
    }

    const participantStatus = isHost ? "host" : "guest";

    const alignLobbyItems = isHost ? "flex-start" : "center";

    return (
        <Grid container justifyContent="center" alignItems="center" style={pageStyle}>
            {isHost && <Box style={{top: "20px", left: "32px", position: "absolute"}}>
                <StudioBackIconButton handleClick={navigateToDashboard} theme={LibrettoStudioTheme.Light}/>
            </Box>}
            <Dialog
                open={isSmallScreen}
                onClose={() => {}}
                aria-labelledby="form-dialog-title"
            >
                <DialogContent sx={{borderRadius: '30px',
                    display: 'flex',        // Enable flexbox
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center',     // Center vertically
                    minWidth: { xs: '300px', md: '400px' },
                }}>
                    <Typography><b>Please switch to a larger screen.</b> For now, you need a larger screen to enter a studio.</Typography>
                </DialogContent>
            </Dialog>
            <Box style={{display: "flex", alignItems: alignLobbyItems, gap: "100px", flexDirection: "row"}}>
                <Box style={{display: "flex", flexDirection: "column"}}>
                    <Box style={headerTextStyle}>
                        New Recording
                    </Box>
                    <Box style={twolineTextStyle}>
                        <div>
                            You are about to enter{" "}
                            <span style={spanStyle}>{userFriendlyStudioName}</span>{" "}
                            as a <span>{participantStatus}</span>.
                        </div>
                    </Box>
                    {isHost &&
                        <>
                            <Box style={titleTextStyle}>
                                Title
                            </Box>
                            <TextField
                                variant="outlined"
                                label="Recording Title"
                                value={recordingTitle}
                                onChange={(e) => setRecordingTitle(e.target.value)}
                                InputProps={{
                                    style: {
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        lineHeight: "20px",
                                        letterSpacing: "0.14px",
                                        color: "#000000",
                                        height: "64px",
                                        backgroundColor: "white",
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#9E9E9E',
                                        fontFamily: 'Inter',
                                        fontSize: '12px',
                                        fontWeight: 400,
                                    },
                                    shrink: true,
                                }}
                                sx={{
                                    width: '100%',
                                    height: '64px',
                                    marginTop: "10px",
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: "1px solid #e8e8e8",
                                        },
                                        '&:hover fieldset': {
                                            border: "1px solid #e8e8e8",
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: "1px solid #e8e8e8",
                                        },
                                    },
                                    '& .MuiInputBase-root': {
                                        backgroundColor: 'white',
                                    },
                                }}
                            />
                        </>}
                    <Box style={audioTitleStyle}>
                        Audio
                    </Box>
                    <Box style={{marginTop: "16px", flexDirection: "row", display: "flex", gap: "12px"}}>
                        <Box onClick={() => setUsingHeadphones(true)} style={{cursor: "pointer"}}>
                            {usingHeadphones ? <CheckedCheckBox/> : <CheckBox/>}
                        </Box>
                        <Box style={headphoneTextStyle}>
                            I'm using headphones
                        </Box>
                    </Box>
                    <Box style={{marginTop: "10px", flexDirection: "row", display: "flex", gap: "12px"}}>
                        <Box onClick={() => setUsingHeadphones(false)} style={{cursor: "pointer"}}>
                            {usingHeadphones ? <CheckBox/> : <CheckedCheckBox/>}
                        </Box>
                        <Box style={headphoneTextStyle}>
                            I'm not using headphones
                        </Box>
                    </Box>
                    <Box style={descStyle}>
                        Knowing this will allow Libretto to optimize audio recording.
                    </Box>
                    {/*<Box style={descStyle}>*/}
                    {/*    Tip: Inside the studio, Press Ctrl + F enable full screen mode for the recording area, and press ESC to exit full screen mode.*/}
                    {/*</Box>*/}
                    <Button sx={buttonStyle} disabled={enterStudioButtonDisabled} onClick={handleEnterStudio}>
                        <div style={buttonTextStyle}>
                            Enter Studio
                        </div>
                    </Button>
                    <ErrorMessageSpace errorMessage={errorMessage} isHost={isHost}/>
                </Box>
                {!isSmallScreen && <CustomPrejoin onUsernameChange={handleUsernameChange} onAudioEnabledChange={handleAudioEnabledChange}
                               onVideoEnabledChange={handleVideoEnabledChange}/>}
            </Box>
        </Grid>
    )
}

export default NewLobby;
