import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { reaction } from 'mobx';
import clsx from 'clsx';
import { TransitionClipIcon } from "../../../../assets/icons/TransitionClipIcon";
import { TimelineStore } from "../../../../store/TimelineStore";
import { RendleyStore } from "../../../../store/RendleyStore";
import { convertTimeToUnits } from "../../../../utils/dom/convertTimeToUnits";
import { useCreateTransitionOnDrop } from "../../hooks/transitions/useCreateTransitionOnDrop";
import { ApplicationStore } from "../../../../store/ApplicationStore";
import './Transition.styles.scss';

interface TransitionProps {
    type: "permanent" | "temporary" | null;
    permanentTransition?: { transitionId: string };
    temporaryTransition?: { startTime: number; layerId: string; clipId: string };
}

export const Transition: React.FC<TransitionProps> = observer(({ type, permanentTransition, temporaryTransition }) => {
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [x, setX] = useState(0);
    const [isSelected, setIsSelected] = useState(false);

    const handleCreateTransitionOnDrop = useCreateTransitionOnDrop();

    const isPermanent = type === "permanent";
    const isTemporary = type === "temporary";

    useEffect(() => {
        if (isPermanent) {
            const disposer = reaction(
                () => ({
                    timelineWidth: TimelineStore.width,
                    transition: permanentTransition?.transitionId && RendleyStore.transitions[permanentTransition.transitionId],
                    selectedTransitionId: ApplicationStore.selectedTransitionId,
                }),
                ({ timelineWidth, transition, selectedTransitionId }) => {
                    const clip = transition ? RendleyStore.clips[transition.endClipId] : null;

                    setIsSelected(selectedTransitionId === permanentTransition?.transitionId);

                    if (timelineWidth && clip?.startTime != null) {
                        setX(convertTimeToUnits(clip.startTime + clip.leftTrim));
                    }

                    updateVisibility();
                },
                { fireImmediately: true }
            );

            return () => disposer();
        }

        if (isTemporary) {
            handleCalculatePosition();
        }
    }, [isPermanent, isTemporary, permanentTransition, temporaryTransition]);

    const handleCalculatePosition = () => {
        if (isPermanent) return;

        const timelineWidth = TimelineStore.width;
        const startTime = temporaryTransition?.startTime;

        if (timelineWidth && startTime) {
            setX(convertTimeToUnits(startTime));
        }
    };

    const updateVisibility = () => {
        if (isTemporary) return;

        const transition = permanentTransition?.transitionId && RendleyStore.transitions[permanentTransition.transitionId];

        if (!transition) {
            setIsVisible(false);
            return;
        }

        const isClipBeingDragged = !!TimelineStore.draggedClips[transition.endClipId];
        setIsVisible(!isClipBeingDragged);
    };

    const handleDragOver = (event: React.DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDraggingOver(true);
    };

    const handleDragLeave = (event: React.DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDraggingOver(false);
    };

    const handleDrop = async (event: React.DragEvent) => {
        handleDragLeave(event);

        try {
            const transitionId = Object.keys(TimelineStore.draggedTransitions)[0];

            if (!transitionId) return null;

            let clipId = temporaryTransition?.clipId;
            let layerId = temporaryTransition?.layerId;

            if (isPermanent) {
                const transition = permanentTransition?.transitionId == null
                    ? null
                    : RendleyStore.transitions[permanentTransition.transitionId];

                const clip = transition != null ? RendleyStore.clips[transition.endClipId] : null;

                clipId = clip?.id;

                if (clipId == null) return;

                layerId = Object.values(RendleyStore.layers).find((layer) => layer.clipsIds.includes(clipId!))?.id;
            }

            if (clipId == null || layerId == null) return;

            await handleCreateTransitionOnDrop({
                transitionId,
                clipId,
                layerId,
            });
        } catch {
            return null;
        }
    };

    const handleClick = () => {
        if (!isPermanent) return;

        const transition = permanentTransition?.transitionId == null
            ? null
            : RendleyStore.transitions[permanentTransition.transitionId];

        if (transition == null) return;

        ApplicationStore.setSelectedTransitionId(transition.id);
    };

    return (
        <div
            className={clsx("transition", {
                transition__visible: isVisible,
            })}
            style={{ left: `${x}px` }}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleClick}
        >
            <div
                className={clsx("transition__content", {
                    "transition__dragging-over": isDraggingOver,
                    transition__permanent: isPermanent,
                    transition__temporary: isTemporary,
                    transition__selected: isSelected,
                })}
            >
                <TransitionClipIcon />
            </div>
        </div>
    );
});

export default Transition;