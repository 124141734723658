import Box from "@mui/material/Box";
import React, {useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import {UploadIcon} from "../NewHome/HomeButtons";
import {DialogContent} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import UploadContentCard from "../NewHome/UploadContentCard";
import {Upload} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";


const initialCardStyle = {
    position: 'fixed',
    top: 'calc(50% - 200px)',
    left: 'calc(50% - 240px)',
    display: 'flex',
    width: '390px',
    padding: '24px 24px 24px 24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '14px',
    borderRadius: '12px',
    zIndex: 1000,
    background: "#FFFFFF",
}

const buttonAreaStyle = {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
}

const subtextStyle = {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.14px",
}

const headerTextStyle = {
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 600,
    color: "#2b6bfd",
    lineHeight: "30px",
    letterSpacing: "0.24px",
}

const addButtonStyle = {
    alignSelf: 'stretch',
    padding: "10px 20px",
    height: "43px",
    width: "100px",
    background: '#2B6BFD',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
    // Set hovered state
    "&:hover": {
        background: "linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #2B6BFD",
    },
    // Set focused state
    "&:focus": {
        background: "linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #2B6BFD"
    },
}

const uploadButtonTextStyle = {
    color: 'white',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.14px',
}

const uploadButtonStyle = {
    display: "flex",
    height: "43px",
    padding: "10px 20px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "6px",
    background: "#E3E5E8",
    cursor: "pointer",
    "&:hover": {
        background: "linear-gradient(0deg, rgba(0, 0, 0, 0.10)0%, rgba(0, 0, 0, 0.10)100%), #E3E5E8",
    },
    // Disabled state
    "&:disabled": {
        background: "linear-gradient(0deg, rgba(255, 255, 255, 0.10)0%, rgba(255, 255, 255, 0.10)100%), #E3E5E8",
        cursor: "not-allowed",
    },
    // Focus state
    "&:focus": {
        background: "linear-gradient(0deg, rgba(255, 255, 255, 0.10)0%, rgba(255, 255, 255, 0.10)100%), #E3E5E8",
    }
}

const CreateSomethingCard = ({projectId, editId}) => {

    const navigate = useNavigate();

    const [showUploadDialog, setShowUploadDialog] = useState(false);
    const [showAddDialog, setShowAddDialog] = useState(false);

    const handleUploadClick = () => {
        setShowUploadDialog(true);
    }

    const handleChooseClick = () => {
        navigate("/dashboard")
    }

    return (
        <>
            <Dialog
                open={showUploadDialog}
                onClose={() => {
                }}
                aria-labelledby="form-dialog-title"
            >
                <DialogContent sx={{
                    borderRadius: '30px',
                    display: 'flex',        // Enable flexbox
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center',     // Center vertically
                    padding: "0px 0px 0px 0px",
                }}>
                    <UploadContentCard allowImageUpload={false} onClose={() => setShowUploadDialog(false)}
                                       currentProjectId={projectId} editId={editId}/>
                </DialogContent>
            </Dialog>
            <Box sx={initialCardStyle}>
                <Box sx={headerTextStyle}>Create something you will be proud
                    of</Box>
                <Box style={subtextStyle}>Add an asset to the timeline to start editing.</Box>
                <Box sx={buttonAreaStyle}>
                    <Box sx={addButtonStyle} onClick={handleUploadClick}>
                        <Upload sx={{color: "white"}}/>
                        <Box sx={uploadButtonTextStyle}>Upload</Box>

                    </Box>
                    <Tooltip title={"Select an asset and click edit"}>
                        <Box sx={uploadButtonStyle} onClick={handleChooseClick}>
                            <AddIcon/>
                            <Box>Choose Existing</Box>
                        </Box>
                    </Tooltip>
                </Box>
            </Box>
        </>
    )
}

export default CreateSomethingCard;