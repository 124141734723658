import React from 'react';
import clsx from 'clsx';
import {Label} from "../Label/Label";
import './Textarea.styles.scss';

const Textarea = ({
                      placeholder,
                      label,
                      error,
                      name,
                      value,
                      defaultValue,
                      onChangeText
                  }) => {
    const handleChangeText = (event: { target: { value: any; }; }) => {
        onChangeText?.(event.target.value);
    };

    const hasError = error != null;

    return (
        <div className="textarea">
            {label != null && <Label error={hasError}>{label}</Label>}

            <textarea
                className={clsx("textarea__input", {
                    "textarea__input-error": hasError,
                })}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={handleChangeText}
                defaultValue={defaultValue}
            />
            {hasError && <p className="textarea__error-label">{error}</p>}
        </div>
    );
};

export default Textarea;