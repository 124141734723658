import './Label.styles.scss';

export const Label = ({error, children}) => {

    const labelStyle = `label ${error ? 'label-error' : ''}`;

    return (
        <label
            className={labelStyle}
        >
            {children}
        </label>
    );
}