import Moveable from "moveable";

export enum ResizeDirectionEnum {
    LEFT = -1,
    RIGHT = 1,
}

interface InteractiveClipOptions {
    onDragStart?: (clientX: number, clientY: number) => void;
    onResizeStart?: (clientX: number) => void;
    onDrag?: (clientX: number, clientY: number) => void;
    onResize?: (clientX: number, direction: ResizeDirectionEnum) => void;
    onResizeEnd?: (direction: ResizeDirectionEnum, distance: number) => void;
    onDragEnd?: (clientX: number, clientY: number) => void;
    onClick?: () => void;
}

interface InteractiveClipMountOptions {
    elementGuidelines?: string[];
}

class InteractiveClip {
    moveable: Moveable | null;

    constructor(
        private element: HTMLElement,
        private containerElement: HTMLElement,
        private options: InteractiveClipOptions,
    ) {
        this.moveable = null;
    }

    mount(event?: MouseEvent, options?: InteractiveClipMountOptions) {
        if (this.moveable != null) {
            this.destroy();
        }

        this.moveable = new Moveable(this.containerElement, {
            target: this.element,
            className: "clip__moveable",
            draggable: true,
            resizable: true,
            snappable: true,
            snapDirections: { top: true, left: true, bottom: true, right: true },
            elementGuidelines: options?.elementGuidelines,
            isDisplaySnapDigit: false,
            throttleResize: 0,
            throttleDrag: 0,
            origin: false,
            renderDirections: ["e", "w"],
        });

        this.moveable.on("click", () => {
            this.options.onClick?.();
        });

        this.moveable.on("resizeStart", (event) => {
            this.options.onResizeStart?.(event.clientX);
        });

        this.moveable.on("resize", (event) => {
            const directionX = event.direction?.[0];
            const direction = directionX === -1 ? ResizeDirectionEnum.LEFT : ResizeDirectionEnum.RIGHT;

            this.options.onResize?.(event.clientX, direction);
        });

        this.moveable.on("resizeEnd", (payload) => {
            const lastEvent = payload.lastEvent;

            if (lastEvent == null) {
                return;
            }

            const directionX = lastEvent.direction?.[0];

            if (directionX == null) {
                return;
            }

            const direction = directionX === -1 ? ResizeDirectionEnum.LEFT : ResizeDirectionEnum.RIGHT;
            const distance = lastEvent.dist?.[0] ?? 0;

            this.options.onResizeEnd?.(direction, distance);
        });

        let isDragging = false;

        this.moveable.on("dragEnd", (event) => {
            if (event.lastEvent?.translate == null) {
                return;
            }

            this.options.onDragEnd?.(event.clientX, event.clientY);
            isDragging = false;
        });

        this.moveable.on("drag", (event) => {
            // dragStart triggers before onClick
            // and this workaround makes sure the event has actually been dragged and not clicked
            if (!isDragging) {
                this.options.onDragStart?.(event.clientX, event.clientY);
                isDragging = true;
            }

            this.options.onDrag?.(event.clientX, event.clientY);
        });

        if (event != null) {
            this.moveable.dragStart(event, event.target);
        }
    }

    destroy() {
        this.moveable?.destroy();
        this.moveable = null;
    }
}

export { InteractiveClip };
