import React, {useContext, useEffect, useState} from "react";
import {LibrettoLiveblocksContext} from "../LibrettoLiveblocksContext";
import {createRemoveTrack} from "../Liveblocks";
import Grid from "@mui/material/Grid";
import {MiniTrashButton, PlayPauseButton} from "../../../components/CoolButtons";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {formatDurationForEditor} from "../../../utils/utils";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

const TimelineControls = ({duration, currentTime, isPlaying, setIsPlaying, selectedTrackIndex, setSelectedTrackIndex, orderOfTracks, waveformRefs, handleVideoPlayerPlayToggle, videoPlayerRef}) => {

    const liveblocksContext = useContext(LibrettoLiveblocksContext);

    const [zoomLevel, setZoomLevel] = useState(0);

    useEffect(() => {
        setZoomLevel(0);
    }, [selectedTrackIndex]);

    const deleteTrackFromTrackOrder = createRemoveTrack(liveblocksContext);

    const timelineInnerStyle = {
        marginTop: 0,
        paddingTop: 0,
    }

    const [playPauseInProgress, setPlayPauseInProgress] = useState(false);

    const handleTrashButtonClick = () => {
        deleteTrackFromTrackOrder(selectedTrackIndex);
        if (orderOfTracks.length > 1) {
            setSelectedTrackIndex(0);
        }
    }

    const handlePlayPauseToggle = async () => {
        const currentWaveform = waveformRefs[selectedTrackIndex].current;
        if (currentWaveform === null) {
            return;
        }

        setPlayPauseInProgress(true);
        await Promise.all([handleVideoPlayerPlayToggle(currentTime, !isPlaying)])
        setIsPlaying(!isPlaying);
        setPlayPauseInProgress(false);
    }

    const deleteButtonDisabled = orderOfTracks.length === 0;

    const playPauseButtonDisabled = orderOfTracks.length === 0 || playPauseInProgress;

    return (
        <Grid container justifyContent="space-between" alignItems="center" style={{ width: '100%', paddingLeft: '20px', paddingRight: '20px'}}>
            <Grid item>
                <MiniTrashButton handleClick={handleTrashButtonClick} disabled={deleteButtonDisabled}/>
            </Grid>
            <Grid item>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={timelineInnerStyle}
                >
                    <Box>
                        <PlayPauseButton isPlaying={isPlaying} handleClick={handlePlayPauseToggle} disabled={playPauseButtonDisabled} />
                    </Box>
                    <Box>
                        <Typography style={{ display: 'inline', fontWeight: 500 }}>
                            <Typography style={{ display: 'inline', fontWeight: 600 }}>
                                {formatDurationForEditor(currentTime)}
                            </Typography>{' '}
                            / {formatDurationForEditor(duration)}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={timelineInnerStyle}
                >
                    <Box>
                        <ZoomInIcon/>
                    </Box>
                    <Box>
                        <input type="range" min="0" max="100" defaultValue={1.0} value={zoomLevel}
                               onChange={(event) => setZoomLevel(event.target.valueAsNumber)}/>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default TimelineControls;