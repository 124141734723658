import React from 'react';
import {Box} from '@mui/material'; // Assuming you're using Material-UI, but you can adapt as needed
import './ModernOneToolsSection.css';
import CardMedia from "@mui/material/CardMedia";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {VideoPlayer} from "../../../components/VideoPlayer"; // External CSS file

const ModernOneToolSection = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const direction = isSmallScreen ? "column" : "row";
    const width = isSmallScreen ? "100%" : "30%";
    const height = isSmallScreen ? "250px" : isSmallerScreen ? "350px" : "430px";

    return (
        <Box display="flex" justifyContent="space-around" width="100%" flexDirection={direction} gap={"30px"}>
            <Box className="box-item" width={width} display="flex" flexDirection="column" padding="35px">
                <Box
                    component={CardMedia}
                    height={height}  // Set a fixed height for the image
                    image={"https://image.mux.com/00o8NrdvemNbYfHddK02hr3MTkM01AW002MqYKSFKHSNowY/thumbnail.png?width=450&height=450&time=2"}
                />
                {/* Image goes here */}
                <Box flexDirection="column" alignItems="flex-start" marginTop="20px" display="flex">
                    <Box sx={{fontWeight: "600", fontSize: "24px"}}>
                        Video Content
                    </Box>
                    <Box sx={{fontWeight: "500", fontSize: "20px", color: "text.secondary", marginTop: "18px"}}>
                        For making product demos, screen recordings, tutorials, webinars. For your website or social
                        media.
                    </Box>
                </Box>
            </Box>

            <Box className="box-item" width={width} display="flex" flexDirection="column" padding="35px">
                <Box
                    component={CardMedia}
                    height={height} // Set a fixed height for the image
                    image={"https://image.mux.com/VbaPNbrlslYJc82thIFDZagQjV00HeZB01kDplNUOmTMQ/thumbnail.png?width=450&height=450&time=2"}
                />
                {/* Image goes here */}
                <Box flexDirection="column" alignItems="flex-start" marginTop="20px" display="flex">
                    <Box sx={{fontWeight: "600", fontSize: "24px"}}>
                        Podcasts
                    </Box>
                    <Box sx={{fontWeight: "500", fontSize: "20px", color: "text.secondary", marginTop: "18px"}}>
                        Everything you need to make a podcast: record, edit and share. Click, invite and record with
                        guests. No download required.
                    </Box>
                </Box>
            </Box>

            <Box className="box-item" width={width} display="flex" flexDirection="column" padding="35px">
                <Box
                    component={CardMedia}
                    height={height} // Set a fixed height for the image
                    image={"https://storage.googleapis.com/libretto-public-assets/images/Voiceover.jpeg"}
                />
                {/* Image goes here */}
                <Box flexDirection="column" alignItems="flex-start" marginTop="20px" display="flex">
                    <Box sx={{fontWeight: "600", fontSize: "24px"}}>
                        Voiceovers
                    </Box>
                    <Box sx={{fontWeight: "500", fontSize: "20px", color: "text.secondary", marginTop: "18px"}}>
                        Record voiceovers, and easily add sound effects and music from the built-in media board.
                        Multitrack recording gives you max flexibility.
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export const ModernEffortlessEditingSection = () => {

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('lg'));

    // Adjust the box properties based on screen size
    const width = isSmallScreen ? "100%" : "30%";

    const height = isSmallScreen ? "250px" : isSmallerScreen ? "350px" : "430px";
    const direction = isSmallScreen ? "column" : "row";

    return (
        <Box display="flex" justifyContent="space-around" width="100%" flexDirection={direction} gap={"30px"}>
            <Box className="box-item" width={width} display="flex" flexDirection="column" padding="35px">
                <Box
                    component={CardMedia}
                    height={height}  // Set a fixed height for the image
                    image={"https://image.mux.com/01sZnj005QdYpBC9HU201FX1sNjGOofTqMl00iIcGPu1wP00/thumbnail.png?width=450&height=450&time=2"}
                />
                {/* Image goes here */}
                <Box flexDirection="column" alignItems="flex-start" marginTop="20px" display="flex">
                    <Box sx={{fontWeight: "600", fontSize: "24px"}}>
                        Split, trim, crop
                    </Box>
                    <Box sx={{fontWeight: "500", fontSize: "20px", color: "text.secondary", marginTop: "18px"}}>
                        Split scenes, trim down clips, add music and repurpose for social media with essential editing
                        tools.
                    </Box>
                </Box>
            </Box>

            <Box className="box-item" width={width} display="flex" flexDirection="column" padding="35px">
                <Box
                    component={CardMedia}
                    height={height} // Set a fixed height for the image
                >
                    <VideoPlayer
                        src={"https://stream.mux.com/ysf02H00YYG7NshadK8vpZuRWJkL5Bbyxh17cGqMjl1xQ.m3u8"}
                        poster={"https://image.mux.com/ysf02H00YYG7NshadK8vpZuRWJkL5Bbyxh17cGqMjl1xQ/thumbnail.png?width=214&height=121&time=3"}
                        width={"auto"} height={height} autoplay={true} controls={false} loop={true} muted={true} cover={true}/>
                </Box>
                {/* Image goes here */}
                <Box flexDirection="column" alignItems="flex-start" marginTop="20px" display="flex">
                    <Box sx={{fontWeight: "600", fontSize: "24px"}}>
                        Studio sound
                    </Box>
                    <Box sx={{fontWeight: "500", fontSize: "20px", color: "text.secondary", marginTop: "18px"}}>
                        No soundproofing required. Remove noise, enhance speech and correct loudness at the click of a button.
                    </Box>
                </Box>
            </Box>

            <Box className="box-item" width={width} display="flex" flexDirection="column" padding="35px">
                <Box
                    component={CardMedia}
                    height={height} // Set a fixed height for the image
                    image={"https://storage.googleapis.com/libretto-public-assets/images/Fillerwords.png"}
                />
                {/* Image goes here */}
                <Box flexDirection="column" alignItems="flex-start" marginTop="20px" display="flex">
                    <Box sx={{fontWeight: "600", fontSize: "24px"}}>
                        Remove filler words
                    </Box>
                    <Box sx={{fontWeight: "500", fontSize: "20px", color: "text.secondary", marginTop: "18px"}}>
                        Instantly cut out filler words like "um" and "uh" from clips. Save time and make your content
                        more engaging.
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ModernOneToolSection;
