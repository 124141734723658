import {SearchIcon} from "../../assets/icons/SearchIcon";
import './Searchbar.styles.scss';

const Searchbar = ({onChangeText}) => {
    return (
        <div className="searchbar">
            <input
                className="searchbar__input"
                type="text"
                placeholder="Search"
                onInput={onChangeText}
            />
            <SearchIcon/>
        </div>
    );
}

export default Searchbar;