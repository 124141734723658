import React, {useContext, useState} from "react";
import {CLIENT_URL, FetchContext} from "../../context/FetchContext";
import {AuthContext} from "../../context/AuthContext";
import {Button, Card, CardActions, CardContent, ClickAwayListener, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";


const CollaborateCard = ({ onClose, editId, assetId }) => {

    const fetchContext = useContext(FetchContext);
    const authContext = useContext(AuthContext);

    const [inviteeName, setInviteeName] = useState('');
    const [inviteLinkCreated, setInviteLinkCreated] = useState(false);
    const [shareLink, setShareLink] = useState('');
    const [linkCopied, setLinkCopied] = useState(false);

    const projectShareUrl = ({token, assetId, editId}) => {
        return CLIENT_URL + "/editor/" + assetId + "/" + editId + "/" + token;
    }

    const createGuestEditorToken = async () => {
        try {
            const response = await fetchContext.authAxios.post(`/share/editor/${editId}`, {
                name: inviteeName,
                assetId: assetId,
                editId: editId,
            }, {
                headers: {
                    Authorization: `Bearer ${authContext.getToken()}`,
                },
            });
            setShareLink(projectShareUrl({token: response.data.token, editId: editId, assetId: assetId}));
            setInviteLinkCreated(true);
        } catch (error) {
            console.error("Error fetching token:", error);
        }
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(shareLink);
        setLinkCopied(true);
    };

    const InviteButtonDisabled = () => {
        return inviteeName.length === 0;
    }

    const handleInviteNameKeyPress = async (e) => {
        if (e.key === 'Enter') {
            await createGuestEditorToken();
        }
    }

    const handleCopyKeyPress = async (e) => {
        if (e.key === 'Enter') {
            copyToClipboard();
        }
    }

    const copyButtonText = linkCopied ? 'Copied!' : 'Copy';

    return (
        <ClickAwayListener onClickAway={onClose}>
            <Card
                elevation={5}
                style={{ cursor: 'pointer', position: 'absolute',
                    top: 70,
                    right: 110,
                    maxWidth: 480,
                    backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark background
                    borderRadius: '16px', // Rounded corners
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
                    overflow: 'visible',
                    height: 170,
                    zIndex: 1000,
                }}
            >
                <CardContent>
                    <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }} variant="contained">
                        <CloseIcon style={{fontSize: "1.0rem", color: "white"}}/>
                    </IconButton>
                    <Typography variant="h6" marginBottom={0} color="white" fontWeight={700}>
                        Invite collaborators
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary" fontSize="1.0rem">
                        Enter name and create a shareable link. Anyone with the link will be able to edit and have access to this project's files.
                    </Typography>
                </CardContent>
                <CardActions style={{ marginTop: '-10px', paddingLeft: '12px', justifyContent: 'space-between'}}>
                    {inviteLinkCreated ?
                        <>
                            <TextField
                                variant="outlined"
                                size="small"
                                value={shareLink}
                                onKeyPress={handleCopyKeyPress}
                                InputProps={{
                                    readOnly: true,
                                    style: { backgroundColor: 'rgba(0, 0, 0, 0.8)', color: 'white', width: '370px' }, // Adjust text color if needed
                                }}
                                style={{ borderRadius: '7px' }}
                            />
                            <Button
                                variant="contained"
                                onClick={copyToClipboard}
                                style={{ height: '40px', width: '80px', borderRadius: '10px' }} // Adjust button colors as needed
                            >
                                <Typography fontWeight={600}>
                                    {copyButtonText}
                                </Typography>
                            </Button>
                        </> :
                        <>
                        <TextField
                            variant="outlined"
                            size="small"
                            label="Name"
                            value={inviteeName}
                            onKeyPress={handleInviteNameKeyPress}
                            onChange={(e) => setInviteeName(e.target.value)}
                            InputProps={{
                                style: { backgroundColor: 'rgba(0, 0, 0, 0.8)', color: 'white', width: '370px' }, // Adjust text color if needed
                            }}
                            style={{ borderRadius: '7px' }}
                        />
                        <Button
                            variant="contained"
                            onClick={() => createGuestEditorToken()}
                            disabled={InviteButtonDisabled()}
                            style={{ height: '40px', width: '80px', borderRadius: '10px' }} // Adjust button colors as needed
                        >
                            <Typography fontWeight={600}>
                                Invite
                            </Typography>
                        </Button>
                        </>}
                </CardActions>
            </Card>
        </ClickAwayListener>
    );
};

export default CollaborateCard;
