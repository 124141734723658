import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {debounce} from "lodash-es";
import {MergeTrims} from "../../../utils/utils";
import Box from "@mui/material/Box";
import TimelineControls from "./TimelineControls";
import AllTimelineWaveforms from "./AllTimelineWaveforms";

const TimelineEditor = forwardRef(function TimelineEditorJs({tracks, videoPlayerRef, editorRef, currentTime, setCurrentTime, duration, setDuration, enhancedWavedataForTracks, waveformRefs, trims, fillerWordRemovalTrims, orderOfTracks, waveDataForTracks, selectedTrackIndex, setSelectedTrackIndex, assetId, handleVideoPlayerPlayToggle, setIsPlaying, isPlaying, loadTracks}, ref) {

    const [prefixSumDurations, setPrefixSumDurations] = useState([]);

    const handleUpdateTextEditorTime = debounce(function (time, orderedTrackIndex) {
        if (editorRef.current) {
            editorRef.current.updateCurrentTime(time, orderedTrackIndex);
        }
    }, 100);

    const handleTimeUpdate = (time, timeToSubtract, orderedTrackIndex) => {
        setCurrentTime(time + prefixSumDurations[orderedTrackIndex]);
        handleUpdateTextEditorTime(time, orderedTrackIndex);
    }

    const handleTrackFinishedPlaying = (orderedTrackIndex) => {
        if (orderedTrackIndex < orderOfTracks.length - 1) {
            setSelectedTrackIndex(orderedTrackIndex + 1);
        }
    }

    useEffect(() => {
        let totalDuration = 0;
        let newPrefixSumDuration = new Array(orderOfTracks.length).fill(0.0);
        orderOfTracks.forEach((trackId, index) => {
            const track = tracks.get(trackId);
            if (track) {
                totalDuration += Number(track.duration);
                newPrefixSumDuration[index] = Number(track.duration);
            }
        });
        trims.forEach((trim, strIndex) => {
            const trackTrims = trim.trims;

            const trackFillerWordRemovalTrims = fillerWordRemovalTrims.get(strIndex) ? fillerWordRemovalTrims.get(strIndex).trims : [];

            const mergedTrims = MergeTrims({trims: trackTrims, fillerWordRemovalTrims: trackFillerWordRemovalTrims})

            let trimDuration = 0;
            mergedTrims.forEach((trackTrim) => {
                trimDuration += Number(trackTrim.endTime - trackTrim.startTime);
            });

            const intIndex = parseInt(strIndex);
            newPrefixSumDuration[intIndex] -= trimDuration;
            totalDuration -= trimDuration;
        });
        setDuration(totalDuration);

        for (let i = 1; i < newPrefixSumDuration.length; i++) {
            newPrefixSumDuration[i] += newPrefixSumDuration[i-1];
        }

        newPrefixSumDuration.unshift(0.0)
        setPrefixSumDurations(newPrefixSumDuration);
    }, [tracks, trims, fillerWordRemovalTrims, orderOfTracks]);

    const TimelineStyle = {
        borderTop: `1px solid #8B8C90`,
        marginTop: 0,
        paddingTop: 0,
        marginBottom: 0,
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        height: '100%',
        boxSizing: 'border-box',
    }

    useImperativeHandle(ref, () => ({
        updateCurrentTime: (time, timeToSubtract, orderedTrackIndex) => {
            console.log("update current time timeline editor called");
            setSelectedTrackIndex(orderedTrackIndex);
            const currentWaveformRef = waveformRefs[orderedTrackIndex].current;
            if (currentWaveformRef) {
                currentWaveformRef.setTime(time);
            }
            setCurrentTime(time - timeToSubtract + prefixSumDurations[orderedTrackIndex]);
        },
    }));

    return (
        <Box style={TimelineStyle}>
            <Box>
                <TimelineControls videoPlayerRef={videoPlayerRef} isPlaying={isPlaying} setIsPlaying={setIsPlaying} setSelectedTrackIndex={setSelectedTrackIndex} waveformRefs={waveformRefs} handleVideoPlayerPlayToggle={handleVideoPlayerPlayToggle} duration={duration} currentTime={currentTime} selectedTrackIndex={selectedTrackIndex} tracks={tracks} orderOfTracks={orderOfTracks}/>
            </Box>
            <Box style={{overflowX: 'auto', overflowY: 'hidden', whiteSpace: 'nowrap', padding: 0, margin: 0}}>
                <AllTimelineWaveforms videoPlayerRef={videoPlayerRef} editorRef={editorRef} isPlaying={isPlaying} handleTrackFinishedPlaying={handleTrackFinishedPlaying} waveformRefs={waveformRefs} handleTimeUpdate={handleTimeUpdate} tracks={tracks} trims={trims} fillerWordRemovalTrims={fillerWordRemovalTrims} orderOfTracks={orderOfTracks} waveDataForTracks={waveDataForTracks} enhancedWavedataForTracks={enhancedWavedataForTracks} selectedTrackIndex={selectedTrackIndex} setSelectedTrackIndex={setSelectedTrackIndex} assetId={assetId} loadTracks={loadTracks}/>
            </Box>
        </Box>
    )
});

export default TimelineEditor;