import React, { useRef, useCallback } from 'react';
import './Draggable.styles.scss';

interface DraggableProps {
    isDraggable?: boolean;
    payload?: object;
    onDragStart?: (event: React.DragEvent<HTMLDivElement>) => void;
    onDragEnd?: (event: React.DragEvent<HTMLDivElement>) => void;
    children: React.ReactNode;
}

const Draggable: React.FC<DraggableProps> = ({
                                                 isDraggable = true,
                                                 onDragStart,
                                                 onDragEnd,
                                                 children
                                             }) => {
    const ref = useRef<HTMLDivElement | null>(null);

    const handleDragStart = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        if (ref.current == null) {
            return;
        }

        ref.current.style.opacity = "0.4";
        const thumbnail = ref.current.querySelector(".thumbnail") as HTMLElement | null;

        if (thumbnail) {
            thumbnail.style.pointerEvents = "none";
        }

        onDragStart?.(event);
    }, [onDragStart]);

    const handleDragEnd = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        if (ref.current == null) {
            return;
        }

        ref.current.style.opacity = "1";

        const thumbnail = ref.current.querySelector(".thumbnail") as HTMLElement | null;

        if (thumbnail) {
            thumbnail.style.pointerEvents = "";
        }

        onDragEnd?.(event);
    }, [onDragEnd]);

    if (!isDraggable) {
        return <>{children}</>;
    }

    return (
        <div
            className="draggable"
            ref={ref}
            draggable
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
        >
            {children}
        </div>
    );
};

export default Draggable;