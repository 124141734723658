import {LoadingIcon} from "../../assets/icons/LoadingIcon";
import './CardLoadingOverlay.styles.scss';

export const CardLoadingOverlay = () => {
        return (
            <div className="card-loading-overlay">

                <div className="card-loading-overlay__icon" title="Loading">
                    <LoadingIcon />
                </div>
            </div>
        )
}
