
export const ZoomOutIcon = () => {
    return (
        <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m14 14-2.9-2.9M5.333 7.333h4m3.334 0A5.333 5.333 0 1 1 2 7.333a5.333 5.333 0 0 1 10.667 0Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
