import {Button, Card, ClickAwayListener, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import ContentCutIcon from '@mui/icons-material/ContentCut';
import RestoreIcon from '@mui/icons-material/Restore';


const TimelineContextMenu = ({ closeCard, top, left, restoreEnabled, handleCut, handleCrop, handleRestore}) => {

    const clickAwayHandler = () => {
        closeCard();
    }

    return (
        <ClickAwayListener onClickAway={clickAwayHandler}>
            <Card
                elevation={5}
                style={{ cursor: 'pointer', position: 'absolute',
                    top: 10,
                    left: left,
                    width: 200,
                    height: 70,
                    padding: 10,
                    gap: 10,
                    backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark background
                    borderRadius: '16px', // Rounded corners
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
                    overflow: 'visible',
                    zIndex: 1000,
                }}
            >
                <Grid container direction="column">
                    <Grid item>
                        <Button
                            variant="text"
                            style={{ height: '25px', borderRadius: '5px', justifyContent: 'flex-start' }} // Adjust button colors as needed
                            fullWidth
                            startIcon={<ContentCutIcon style={{color: restoreEnabled ? "text.secondary" : "white"}}/>}
                            onClick={handleCut}
                            disabled={restoreEnabled}
                        >
                            <Typography fontWeight={600} color={restoreEnabled ? "text.secondary" : "white"}>
                                Cut
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="text"
                            style={{ height: '25px', borderRadius: '5px', justifyContent: 'flex-start' }} // Adjust button colors as needed
                            fullWidth
                            startIcon={<RestoreIcon style={{color: restoreEnabled ? "text.secondary" : "white"}}/>}
                            onClick={handleRestore}
                            disabled={!restoreEnabled}
                        >
                            <Typography fontWeight={600} color={restoreEnabled ? "white" : "text.secondary"}>
                                Restore
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </ClickAwayListener>
    );
}

export default TimelineContextMenu;
