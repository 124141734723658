import { ClipTypeEnum, Engine, EngineOptions, LottieClip, Subtitles, SubtitlesClip, TextBlock } from "@rendley/sdk";
import { ApplicationStore } from "../store/ApplicationStore";
import { RendleyStore } from "../store/RendleyStore";

export class RendleyService {
    private static get engine() {
        return Engine.getInstance();
    }

    static init(options: EngineOptions) {
        return this.engine.init(options);
    }

    static getEngine() {
        return this.engine;
    }

    static getCurrentTime() {
        return RendleyService.engine.getTimeline().currentTime;
    }

    static getFitDuration() {
        return RendleyService.engine.getTimeline().getFitDuration();
    }

    static getFps() {
        return RendleyService.engine.getTimeline().fps;
    }

    static play() {
        return RendleyService.engine.play();
    }

    static pause() {
        return RendleyService.engine.pause();
    }

    static seek(time: number) {
        return RendleyService.engine.seek(time);
    }

    static setBackgroundColor(color: string) {
        return RendleyService.engine.getDisplay().setBackgroundColor(color);
    }

    static setResolution(width: number, height: number) {
        return RendleyService.engine.getDisplay().setResolution(width, height);
    }

    static getFonts() {
        return RendleyService.engine.getFontRegistry().fonts.map((font) => font.font);
    }

    static getMediaById(mediaId: string) {
        return RendleyService.engine.getLibrary().getMediaById(mediaId);
    }

    static addMediaToGallery(file: File | string, mimeType?: string) {
        return RendleyService.engine.getLibrary().addMedia(file, mimeType);
    }

    static getClipById(clipId: string) {
        return RendleyService.engine.getClipById(clipId);
    }

    static getLayerById(layerId: string) {
        return RendleyService.engine.getTimeline().getLayerById(layerId);
    }

    static createLayer(index?: number) {
        return RendleyService.engine.getTimeline().createLayer({ index });
    }

    static deleteLayer(layerId: string) {
        return RendleyService.engine.getTimeline().removeLayer(layerId);
    }

    static async moveClipToLayer(clipId: string, layerId: string) {
        return RendleyService.engine.getTimeline().moveClipToLayer(clipId, layerId);
    }

    static async addMediaToLayer(layerId: string, mediaId: string, dropTime = RendleyService.getCurrentTime()) {
        const layer = RendleyService.getLayerById(layerId);

        if (layer == null) {
            return null;
        }

        return layer.addClip({
            mediaDataId: mediaId,
            startTime: dropTime,
        });
    }

    static addTextClipToLayer(layerId: string, text: string = "Hello World") {
        const layer = RendleyService.getLayerById(layerId);
        const currentTime = RendleyService.getCurrentTime();

        if (layer == null) {
            return null;
        }

        return layer.addClip({
            text,
            startTime: currentTime,
            duration: 1,
            style: {
                color: "#FFFFFF",
            },
        });
    }

    static deleteMediaById(mediaId: string) {
        return RendleyService.engine.getLibrary().deleteMedia(mediaId);
    }

    static deleteClipById(clipId: string) {
        return RendleyService.engine.getTimeline().removeClip(clipId);
    }

    static deleteTransitionById(transitionId: string, layerId: string) {
        const layer = RendleyService.getLayerById(layerId);
        return layer?.removeTransition(transitionId);
    }

    static deleteAtCurrentTime() {
        const selectedClipId = ApplicationStore.selectedClipId;

        if (selectedClipId != null) {
            return RendleyService.deleteClipById(selectedClipId);
        }

        const selectedTransitionId = ApplicationStore.selectedTransitionId;

        if (selectedTransitionId != null) {
            const layerId = RendleyStore.getLayerFromTransitionId(selectedTransitionId)?.id;

            if (layerId != null) {
                return RendleyService.deleteTransitionById(selectedTransitionId, layerId);
            }
        }
    }

    static splitAtCurrentTime = async () => {
        const selectedClipId = ApplicationStore.selectedClipId;

        console.log("The current selected clip is: ", selectedClipId)

        if (selectedClipId == null) {
            return;
        }

        const currentTime = RendleyStore.currentTime;
        const clip = RendleyStore.clips[selectedClipId];

        if (clip == null) {
            return;
        }

        if (clip?.type === ClipTypeEnum.SUBTITLES) {
            return;
        }

        const layerId = Object.values(RendleyStore.layers).find((layer) => layer.clipsIds.includes(selectedClipId))?.id;

        if (layerId == null) {
            return;
        }

        const layer = this.engine.getTimeline().getLayerById(layerId);

        if (layer == null) {
            return;
        }

        await layer.splitClip(clip.id, currentTime);
    };

    static moveClipLeft = () => {
        const selectedClipId = ApplicationStore.selectedClipId;
        if (selectedClipId == null) {
            return;
        }

        const layerId = Object.values(RendleyStore.layers).find((layer) => layer.clipsIds.includes(selectedClipId))?.id;

        if (layerId == null) {
            return;
        }

        const layer = this.engine.getTimeline().getLayerById(layerId);

        if (layer == null) {
            return;
        }

        for (let i = 0; i < layer.clipsIds.length; i++) {
            if (layer.clipsIds[i] === selectedClipId) {
                if (i > 0) {
                    layer.clips[selectedClipId].setStartTime(
                        layer.clips[layer.clipsIds[i - 1]].getRightBound() - layer.clips[selectedClipId].getLeftTrim(),
                    );
                    RendleyStore.clips[selectedClipId].startTime = layer.clips[selectedClipId].getStartTime();
                } else {
                    RendleyStore.clips[selectedClipId].startTime = -layer.clips[selectedClipId].getLeftTrim();
                    layer.clips[selectedClipId].setStartTime(RendleyStore.clips[selectedClipId].startTime);
                }
                break;
            }
        }
    };

    static export() {
        const exportOptions = {
            type: "video",
        }
        // @ts-ignore
        return RendleyService.engine.export(exportOptions);
    }

    static async importSubtitlesSrt(srtContent: string) {
        const subtitles = Engine.getInstance().getSubtitlesManager().convertSRTToSubtitles(srtContent);

        return await RendleyService.addSubtitles(subtitles);
    }

    static async addManualSubtitles(textBlocks?: TextBlock[]) {
        const subtitles = new Subtitles({
            textBlocks: textBlocks ?? [
                {
                    text: "Sample",
                    time: 0,
                    duration: 1,
                },
            ],
        });

        return await RendleyService.addSubtitles(subtitles);
    }

    static async addSubtitles(subtitles: Subtitles) {
        const subtitlesId = Engine.getInstance().getLibrary().addSubtitles(subtitles);

        const layer = RendleyService.createLayer();

        const subtitlesClip = new SubtitlesClip({
            subtitlesId: subtitlesId,
        });

        await layer.addClip(subtitlesClip);

        return subtitlesClip.id;
    }

    static async createLottieClip(options: {
        dataUrl: string;
        propertiesUrl: string;
        startTime: number;
        layerId?: string;
    }) {
        const layer = options.layerId ? RendleyService.getLayerById(options.layerId) : RendleyService.createLayer();

        if (layer == null) {
            return null;
        }

        const lottieClip = new LottieClip({
            dataUrl: options.dataUrl,
            propertiesUrl: options.propertiesUrl,
            startTime: options.startTime,
        });

        await layer.addClip(lottieClip);

        // scale to fit
        lottieClip.style.setScale(0.3, 0.3);

        return lottieClip;
    }

    static async createTextClip(options: { text: string; startTime: number; layerId?: string }) {
        const layer = options.layerId ? RendleyService.getLayerById(options.layerId) : RendleyService.createLayer();

        if (layer == null) {
            console.log("cannot create layer");
            return null;
        }

        const clip = await layer.addClip({
            type: "text",
            text: options.text,
            startTime: options.startTime,
        });

        return clip;
    }

    static async replaceMedia(mediaDataId: string, file: File | Uint8Array) {
        return RendleyService.getEngine().getLibrary().replaceMedia(mediaDataId, file);
    }
}
