import React, {forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef} from "react";
import Hls from "hls.js";
import {GetWebVTTUrl} from "../pages/Editor/Transcript";
import {LibrettoLiveblocksContext} from "../pages/Editor/LibrettoLiveblocksContext";


const mimeTypeMap = {
    mp4: 'video/mp4',
    webm: 'video/webm',
    ogv: 'video/ogg',
    wav: 'audio/wav',
    mkv: 'video/x-matroska', // Note: Browser support for MKV is limited
    m3u8: 'application/vnd.apple.mpegurl', // HLS streams
    mov: 'video/quicktime', // Note: Browser support may vary
    avi: 'video/x-msvideo', // Note: Browser support is very limited
};

export const VideoPlayer = forwardRef(function VideoPlayer(
    { src, poster, height, width, autoplay, controls, loop, muted, border, showCaptions, captionsSrc, cover },
    ref,
) {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            const fileExtension = src.split('?')[0].split('.').pop(); // Split by '?' to ignore URL parameters, then get the extension
            const mimeType = mimeTypeMap[fileExtension];
            if (fileExtension && fileExtension !== 'm3u8') {
                // Directly set src for MP4 files
                videoRef.current.src = src;
            } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
                // Native playback for browsers like Safari
                videoRef.current.src = src;
            } else if (Hls.isSupported()) {
                // HLS.js for browsers that don't support HLS natively
                const hls = new Hls();
                hls.loadSource(src);
                hls.attachMedia(videoRef.current);
            }
        }
    }, [src]);

    useImperativeHandle(ref, () => ({
        togglePlayPause: (time, play) => {
            if (videoRef.current) {
                videoRef.current.currentTime = time;
                play ? videoRef.current.play() : videoRef.current.pause();
            }
        },
        seekTo: (time) => {
            if (videoRef.current) {
                console.log('Seeking video player to: ', time);
                videoRef.current.currentTime = time;
            }
        },
    }));

    return (
        <video
            ref={videoRef}
            poster={poster}
            autoPlay={autoplay}
            loop={loop}
            controls={controls}
            muted={muted}
            style={{
                width: width,
                maxWidth: '100%',
                height: height, // Maintain aspect ratio
                borderRadius: '20px',
                border: border ? '1px solid #e0e0e0' : 'none',
                objectFit: cover ? 'cover' : 'contain',
            }}
        >
            {showCaptions && <track kind="captions" src={captionsSrc} default/>}
        </video>
    );
});


export const VideoPlayerForEditor = forwardRef(function VideoPlayer(
    { src, poster, height, width, autoplay, controls, loop, border, showCaptions, mediaType, transcriptJson },
    ref,
) {
    const videoRef = useRef(null);

    const liveblocksContext = useContext(LibrettoLiveblocksContext)

    const trims = liveblocksContext.useStorage((root) => root.trims);
    const corrections = liveblocksContext.useStorage((root) => root.corrections);

    useEffect(() => {
        if (videoRef.current) {
            const fileExtension = src.split('?')[0].split('.').pop(); // Split by '?' to ignore URL parameters, then get the extension
            const mimeType = mimeTypeMap[fileExtension];
            if (mimeType && videoRef.current.canPlayType(mimeType)) {
                // Directly set src for MP4 files
                videoRef.current.src = src;
            } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
                // Native playback for browsers like Safari
                videoRef.current.src = src;
            } else if (Hls.isSupported()) {
                // HLS.js for browsers that don't support HLS natively
                const hls = new Hls();
                hls.loadSource(src);
                hls.attachMedia(videoRef.current);
            }
        }

    }, [src]);


    const onTimeUpdate = useCallback(() => {
        const timeInSeconds = videoRef.current.currentTime;
        if (trims) {
            for (let trim of trims) {
                if (timeInSeconds > trim.endTime) {
                    continue;
                }
                if (timeInSeconds >= trim.startTime && timeInSeconds < trim.endTime) {
                    videoRef.current.currentTime = trim.endTime;
                    return;
                }
            }
        }
    }, [trims]);

    useEffect(() => {
        if (!videoRef.current) {
            return;
        }

        videoRef.current.addEventListener('timeupdate', onTimeUpdate);

        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('timeupdate', onTimeUpdate);
            }
        }

    }, [trims, videoRef.current]);

    useImperativeHandle(ref, () => ({
        togglePlayPause: (time, play) => {
            if (videoRef.current) {
                videoRef.current.currentTime = time;
                play ? videoRef.current.play() : videoRef.current.pause();
            }
        },
        seekTo: (time) => {
            if (videoRef.current) {
                videoRef.current.currentTime = time;
            }
        },
    }));

    return (
        <video
            ref={videoRef}
            type={mediaType}
            muted={true}
            style={{
                width: width,
                height: height, // Maintain aspect ratio
                borderRadius: '20px',
                border: border ? '1px solid #e0e0e0' : 'none',
            }}
        >
            {showCaptions && transcriptJson && <track kind="captions" src={GetWebVTTUrl(transcriptJson, corrections)} default/>}
        </video>
    );
});
