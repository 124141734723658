import Moveable from "moveable";

interface InteractivePlayheadListeners {
    onDrag?: (x: number) => void;
    onDragEnd?: () => void;
}

class InteractivePlayhead {
    private moveable?: Moveable;

    constructor(
        private element: HTMLElement,
        private containerElement: HTMLElement,
        private listeners: InteractivePlayheadListeners,
    ) {}

    mount() {
        this.moveable = new Moveable(this.containerElement, {
            target: this.element,
            className: "playhead__moveable",
            draggable: true,
            resizable: false,
            throttleResize: 0,
            throttleDrag: 0,
            origin: false,
            renderDirections: ["e", "w"],
        });

        this.moveable.on("drag", (event) => {
            const [deltaX] = event.delta;
            this.listeners.onDrag?.(deltaX);
        });

        this.moveable.on("dragEnd", () => {
            this.listeners.onDragEnd?.();
        });
    }

    dragStart(event: MouseEvent) {
        this.moveable?.dragStart(event, event.target);
    }
}

export { InteractivePlayhead };
