import { TextNode } from 'lexical'
import {IsFillerWordInTranscript} from "../Editor/Transcript";

function isPlayingStyle(isPlaying) {
    return isPlaying ? '"GRAD" 150, "YOPQ" 100' : ''
}

function updateStyle(config, element, loc, isPlaying, isCorrected) {
    element.style.fontVariationSettings = isPlayingStyle(isPlaying)
    element.style.textDecoration = isPlaying ? 'underline' : ''
    element.style.backgroundColor = 'transparent';  // Highlight background when playing
    if (isCorrected) {
        element.style.color = '#2196f3';  // Highlight background when corrected
    }
}

// SoundNode properties
// originalText
// text
// isSilence
// isSpeakerNode
// start
// end
// clipId

export class ModernSoundNode extends TextNode {
    constructor(text, loc, key) {
        super(text, key)
        this.__soundLoc = { ...loc, key: this.getKey() }
        this.__isPlaying = false
    }

    static getType() {
        return 'sound'
    }

    static clone(node) {
        return new ModernSoundNode(node.__text, node.__soundLoc, node.__key)
    }

    createDOM(config) {
        const element = super.createDOM(config)
        updateStyle(config, element, this.__soundLoc, this.__isPlaying,this.__soundLoc.originalText !== undefined && this.__soundLoc.originalText !== this.__text)
        return element
    }

    updateDOM(prevNode, dom, config) {
        if (super.updateDOM(prevNode, dom, config)) {
            return true
        }
        if (
            prevNode.__isPlaying !== this.__isPlaying ||
            prevNode.__soundLoc !== this.__soundLoc
        ) {
            updateStyle(config, dom, this.__soundLoc, this.__isPlaying, this.__soundLoc.originalText !== undefined && this.__soundLoc.originalText !== this.__text)
        }
        return false
    }

    getSoundLocation() {
        const self = this.getLatest()
        return self.__soundLoc
    }

    setTextContent(text) {
        const self = this.getWritable()
        self.__text = text
        return self
    }

    getTextContent() {
        return this.__text
    }

    getLayerId() {
        return this.__soundLoc.layerId
    }

    getClipId() {
        return this.__soundLoc.clipId
    }

    getWidthInPixels() {
        return this.__text.length * 10
    }

    setIsPlaying(isPlaying) {
        const self = this.getWritable()
        self.__isPlaying = isPlaying
        return self
    }

    isFillerWord() {
        if (!this.__soundLoc.rawWord) return false
        return IsFillerWordInTranscript(this.__soundLoc.rawWord)
    }

    exportJSON() {
        return {
            ...super.exportJSON(),
            soundLoc: this.__soundLoc,
            type: 'sound',
        }
    }

    isTrueSoundNode() {
        return this.__soundLoc.isSilence === false && this.__soundLoc.isSpeakerNode === false
    }

    isSilenceNode() {
        if (this.__soundLoc.isSilence === null) {
            return false
        }
        return this.__soundLoc.isSilence
    }

    isSpeakerNode() {
        if (this.__soundLoc.isSpeakerNode === null) {
            return false
        }
        return this.__soundLoc.isSpeakerNode
    }

    getDuration() {
        return this.__soundLoc.end - this.__soundLoc.start
    }

    getStart() {
        return this.__soundLoc.start
    }

    getEnd() {
        return this.__soundLoc.end
    }

    getEntityId() {
        return this.__soundLoc.entityId;
    }

    getIndexForCorrection() {
        return this.__soundLoc.indexForCorrection
    }

    getClipId() {
        return this.__soundLoc.clipId
    }

    static importJSON(serializedNode) {
        const node = $createModernSoundNode(serializedNode.text, serializedNode.soundLoc)
        return node
    }
}

export function $createModernSoundNode(text, soundLoc) {
    return new ModernSoundNode(text, soundLoc)
}

export function $isModernSoundNode(node) {
    return node instanceof ModernSoundNode
}