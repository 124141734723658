import * as React from 'react';
import { Track } from 'livekit-client';
import {isTrackReference, isTrackReferencePinned, setupParticipantName} from '@livekit/components-core';
import {
    ConnectionQualityIndicator,
    TrackMutedIndicator,
    ParticipantContext, useEnsureParticipant,
} from '@livekit/components-react';
import {
    TrackRefContext,
    useEnsureTrackRef,
    useFeatureContext,
    useMaybeLayoutContext,
    useMaybeParticipantContext,
    useMaybeTrackRefContext,
    FocusToggle,
    ParticipantPlaceholder,
    LockLockedIcon,
    ScreenShareIcon,
    AudioTrack,
    useParticipantTile,
    useIsEncrypted,
} from '@livekit/components-react';
import {CustomParticipantName} from "./CustomParticipantName";
import './customParticipantTile.css';
import {useCustomObservableState} from "./useCustomObservableState";
import {CustomVideoTrack} from "./CustomVideoTrack";

export function ParticipantContextIfNeeded(props) {
    const hasContext = !!useMaybeParticipantContext();
    return props.participant && !hasContext ? (
        <ParticipantContext.Provider value={props.participant}>
            {props.children}
        </ParticipantContext.Provider>
    ) : (
        <>{props.children}</>
    );
}

/**
 * Only create a `TrackRefContext` if there is no `TrackRefContext` already.
 */
function TrackRefContextIfNeeded(props) {
    const hasContext = !!useMaybeTrackRefContext();
    return props.trackRef && !hasContext ? (
        <TrackRefContext.Provider value={props.trackRef}>{props.children}</TrackRefContext.Provider>
    ) : (
        <>{props.children}</>
    );
}

/**
 * The `ParticipantTile` component is the base utility wrapper for displaying a visual representation of a participant.
 * This component can be used as a child of the `TrackLoop` component or by passing a track reference as property.
 *
 * @example Using the `ParticipantTile` component with a track reference:
 * ```tsx
 * <ParticipantTile trackRef={trackRef} />
 * ```
 * @example Using the `ParticipantTile` component as a child of the `TrackLoop` component:
 * ```tsx
 * <TrackLoop>
 *  <ParticipantTile />
 * </TrackLoop>
 * ```
 * @public
 */
export const CustomParticipantTile = /* @__PURE__ */ React.forwardRef(function CustomParticipantTile(
    {
        trackRef,
        children,
        nameStyle,
        videoMirrored,
        hostIdentity,
        brandLogoUrl,
        onParticipantClick,
        disableSpeakingIndicator,
        ...htmlProps
    },
    ref,
) {
    const trackReference = useEnsureTrackRef(trackRef);

    const { elementProps } = useParticipantTile({
        htmlProps,
        disableSpeakingIndicator,
        onParticipantClick,
        trackRef: trackReference,
    });
    const isEncrypted = useIsEncrypted(trackReference.participant);
    const layoutContext = useMaybeLayoutContext();

    const autoManageSubscription = useFeatureContext()?.autoSubscription;

    const handleSubscribe = React.useCallback(
        (subscribed) => {
            if (
                trackReference.source &&
                !subscribed &&
                layoutContext &&
                layoutContext.pin.dispatch &&
                isTrackReferencePinned(trackReference, layoutContext.pin.state)
            ) {
                layoutContext.pin.dispatch({ msg: 'clear_pin' });
            }
        },
        [trackReference, layoutContext],
    );

    const p = useEnsureParticipant(trackReference.participant);

    const { className, infoObserver } = React.useMemo(() => {
        return setupParticipantName(p);
    }, [p]);

    const { identity, name } = useCustomObservableState(infoObserver, {
        name: p.name,
        identity: p.identity,
        metadata: p.metadata,
    });

    const isHost = identity === hostIdentity;

    const applyMirrorTransform = videoMirrored && trackReference.source === Track.Source.Camera;

    return (
        <div ref={ref} style={{ position: 'relative' }} {...elementProps}>
            <TrackRefContextIfNeeded trackRef={trackReference}>
                <ParticipantContextIfNeeded participant={trackReference.participant}>
                    {children ?? (
                        <>
                            {isTrackReference(trackReference) &&
                            (trackReference.publication?.kind === 'video' ||
                                trackReference.source === Track.Source.Camera ||
                                trackReference.source === Track.Source.ScreenShare) ? (
                                <CustomVideoTrack
                                    trackRef={trackReference}
                                    onSubscriptionStatusChanged={handleSubscribe}
                                    manageSubscription={autoManageSubscription}
                                    applyMirrorTransform={applyMirrorTransform}
                                />
                            ) : (
                                isTrackReference(trackReference) && (
                                    <AudioTrack
                                        trackRef={trackReference}
                                        onSubscriptionStatusChanged={handleSubscribe}
                                    />
                                )
                            )}
                            <div className="lk-participant-placeholder">
                                <ParticipantPlaceholder />
                            </div>
                            {isHost && brandLogoUrl && <div className="libretto-participant-top-item">
                                <img src={brandLogoUrl} style={{ height: '100%', width: '100%', borderRadius: "6px", objectFit: 'cover', background: "transparent" }} />
                            </div>}
                            <div className="lk-participant-metadata">
                                <div className="libretto-participant-metadata-item">
                                    {trackReference.source === Track.Source.Camera ? (
                                        <>
                                            {isEncrypted && <LockLockedIcon style={{ marginRight: '0.25rem' }} />}
                                            <TrackMutedIndicator
                                                trackRef={{
                                                    participant: trackReference.participant,
                                                    source: Track.Source.Microphone,
                                                }}
                                                show={'muted'}
                                            ></TrackMutedIndicator>
                                            <CustomParticipantName nameStyle={nameStyle} />
                                        </>
                                    ) : (
                                        <>
                                            <ScreenShareIcon style={{ marginRight: '0.25rem' }} />
                                            <CustomParticipantName nameStyle={nameStyle}>&apos;s screen</CustomParticipantName>
                                        </>
                                    )}
                                </div>
                                <ConnectionQualityIndicator className="lk-participant-metadata-item" />
                            </div>
                        </>
                    )}
                    <FocusToggle trackRef={trackReference} />
                </ParticipantContextIfNeeded>
            </TrackRefContextIfNeeded>
        </div>
    );
});
