import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import {initializedApp} from "./Firebase";
import { getAnalytics } from "firebase/analytics";
import * as Sentry from "@sentry/react";
import App from "./App";

import mixpanel from "mixpanel-browser";


Sentry.init({
    dsn: "https://6cfe550be8ee5be2a1fdfd7759c7f94b@o4507749912477696.ingest.us.sentry.io/4507776899743744",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", "libretto.fm", "api.neuvideo.io", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Near entry of your product, init Mixpanel
mixpanel.init("0cd8b639c500d2f90b33084bdf33efd9", {
    debug: false,
    track_pageview: true,
    persistence: "cookie",
    ignore_dnt: true,
});

getAnalytics(initializedApp);


const root = createRoot(document.getElementById('root'));
root.render(
    <StrictMode>
        <App />
    </StrictMode>
);
