import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {ClipSize16And9RatioId, ClipSize1And1RatioId, ClipSize9And16RatioId, MergeTrims} from "../../utils/utils";
import {useTheme} from "@mui/material/styles";
import './modernVideoPlayer.css';
import {GetUtteranceBasedWebVTTUrl} from "../../pages/Editor/Transcript";


const ModernVideoPlayer = forwardRef(function VideoJP({clipSize, captionsEnabled, transcripts, corrections, tracks, trackSettings, orderOfTracks, trims, fillerWordRemovalTrims, isPlaying}, ref) {
    const videoRef = useRef(null);

    const getTrackTrims = (index) => {
        const strIndex = String(index);
        return trims && trims.get(strIndex) ? trims.get(strIndex).trims : [];
    }

    const getTrackFillerWordRemovalTrims = (index) => {
        const strIndex = String(index);
        return fillerWordRemovalTrims && fillerWordRemovalTrims.get(strIndex) ? fillerWordRemovalTrims.get(strIndex).trims : [];
    }

    const getTrackSource = (index) => {
        if (!tracks || !orderOfTracks || !tracks.get(orderOfTracks[index])) {
            return null;
        }

        if (trackSettings && trackSettings.get(String(index)) && trackSettings.get(String(index)).enhanceAudio) {
            if (tracks.get(orderOfTracks[index]).enhancedAudioUrl) {
                return tracks.get(orderOfTracks[index]).enhancedAudioUrl;
            }
            return tracks.get(orderOfTracks[index]).objectUrl;
        }
        return tracks.get(orderOfTracks[index]).renditionName && tracks.get(orderOfTracks[index]).renditionUrl ? tracks.get(orderOfTracks[index]).renditionUrl : tracks.get(orderOfTracks[currentVideoIndex]).objectUrl;
    }

    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [currentVideoTranscript, setCurrentVideoTranscript] = useState(null);
    const [currentWebVttUrl, setCurrentWebVttUrl] = useState(null);
    const [currentVideoTrims, setCurrentVideoTrims] = useState(MergeTrims({trims: getTrackTrims(currentVideoIndex), fillerWordRemovalTrims: getTrackFillerWordRemovalTrims(currentVideoIndex)}));
    const [desiredTime, setDesiredTime] = useState(null);

    useEffect(() => {
        if (videoRef.current && desiredTime !== null) {
            videoRef.current.currentTime = desiredTime;
            setDesiredTime(null); // Reset the desired time
        }
    }, [currentVideoIndex, desiredTime]);

    let aspectClass;
    if (clipSize === ClipSize16And9RatioId) {
        aspectClass = "aspect-16-9";
    }
    if (clipSize === ClipSize9And16RatioId) {
        aspectClass = "aspect-9-16";
    }
    if (clipSize === ClipSize1And1RatioId) {
        aspectClass = "aspect-1-1";
    }

    let playerPaddingTheme;
    const theme = useTheme();
    if (theme.palette.mode === 'dark') {
        playerPaddingTheme = "dark-theme"
    } else {
        playerPaddingTheme = "light-theme"
    }

    const onTimeUpdate = useCallback(() => {
        const timeInSeconds = videoRef.current.currentTime;
        if (currentVideoTrims) {
            for (let trim of currentVideoTrims) {
                if (timeInSeconds > trim.endTime) {
                    continue;
                }
                if (timeInSeconds >= trim.startTime && timeInSeconds < trim.endTime) {
                    videoRef.current.currentTime = trim.endTime;
                    return;
                }
            }
        }
    }, [currentVideoTrims, videoRef.current]);


    const onEnded = useCallback(() => {
        setCurrentVideoIndex(prevIndex => {
            if (prevIndex < orderOfTracks.length - 1) {
                return prevIndex + 1;
            } else {
                return prevIndex; // or handle end of playlist
            }
        });
    }, [orderOfTracks]);


    useEffect(() => {
        if (videoRef.current) {
            const currentVideo = videoRef.current;

            currentVideo.addEventListener('timeupdate', onTimeUpdate);
            currentVideo.addEventListener('ended', onEnded);

            return () => {
                currentVideo.removeEventListener('timeupdate', onTimeUpdate);
                currentVideo.removeEventListener('ended', onEnded);
            };
        }
    }, [onTimeUpdate, onEnded, currentVideoIndex]);

    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.src = getTrackSource(currentVideoIndex);
            isPlaying ? videoElement.play() : videoElement.pause();
        }
    }, [currentVideoIndex, trackSettings, tracks]);

    useEffect(() => {
        setCurrentVideoTrims(MergeTrims({trims: getTrackTrims(currentVideoIndex), fillerWordRemovalTrims: getTrackFillerWordRemovalTrims(currentVideoIndex)}));
    }, [currentVideoIndex, trims, fillerWordRemovalTrims]);

    useEffect(() => {
        setCurrentVideoTranscript(transcripts.get(orderOfTracks[currentVideoIndex]));
    }, [currentVideoIndex, captionsEnabled]);

    useEffect(() => {
        if (captionsEnabled && currentVideoTranscript) {
            setCurrentWebVttUrl(GetUtteranceBasedWebVTTUrl(currentVideoTranscript, corrections.has(String(currentVideoIndex)) && corrections.get(String(currentVideoIndex)) ? corrections.get(String(currentVideoIndex)).corrections : []));
        }

    }, [currentVideoTranscript, corrections, currentVideoIndex, captionsEnabled]);


    // Preloading. Setting the next video source url to a dummy video element to hint the browser to preload the video
    // into its cache.
    useEffect(() => {
        if (currentVideoIndex < orderOfTracks.length - 1) {
            const nextVideoUrl = getTrackSource(currentVideoIndex + 1);
            const nextVideo = document.createElement('video');
            nextVideo.src = nextVideoUrl;
            nextVideo.preload = 'auto'; // Hint the browser to preload this video

            return () => {
                nextVideo.src = ''; // Free up memory
            };
        }
    }, [currentVideoIndex, orderOfTracks, trackSettings, tracks]);

    useImperativeHandle(ref, () => ({
        togglePlayPause: (time, play) => {
            if (videoRef.current) {
                if (play) {
                    return videoRef.current.play();
                }
                return videoRef.current.pause();
            }
        },
        seekTo: (time, videoIndex) => {
            setCurrentVideoIndex(videoIndex)
            setDesiredTime(time);
        },
        triggerCurrentTranscriptDownload: () => {
            if (currentWebVttUrl) {
                const url = currentWebVttUrl;
                const a = document.createElement('a');
                a.href = url;
                a.download = `transcript-${currentVideoIndex}.vtt`;
                a.click();
                URL.revokeObjectURL(url);
            }
        }
    }));

    return (
        <div className={`video-container ${aspectClass} ${playerPaddingTheme}`}>
            <video
                ref={videoRef}
                id={'editor-video-player'}
                style={{
                width: "854px",
                height: "auto", // Maintain aspect ratio
                borderRadius: '20px',
                // border: '1px solid #e0e0e0',
                    background: 'black',
            }}>
                {captionsEnabled && currentVideoTranscript && <track key={currentVideoIndex} kind="captions" src={currentWebVttUrl} default/>}
            </video>
        </div>
    );
});

export default ModernVideoPlayer;
