import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import AppBar from "@mui/material/AppBar";
import Container from "../../components/Container";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import {AuthContext} from "../../context/AuthContext";
import EditorTopbar from "./EditorTopbar";
import {useParams} from "react-router-dom";
import {collection, onSnapshot, query, where} from "firebase/firestore";
import {db} from "../../Firebase";
import {kTracksCollectionName, TrackDeleted, TrackId, TrackUserId} from "../../utils/CollectionConstants";
import Typography from "@mui/material/Typography";
import {FetchContext} from "../../context/FetchContext";
import {ClientSideSuspense} from "@liveblocks/react";
import {LibrettoLiveblocksContext} from "./LibrettoLiveblocksContext";
import EditorInner from "./EditorInner";
import {ClipSize16And9RatioId, loadTranscriptForTrack, RefreshTokenAndRetry} from "../../utils/utils";
import {createSaveFillerWordRemovalTrims, createUndoAllFillerWordRemovalTrims} from "./Liveblocks";


const EditorWrapper = () => {

    const {assetId, editId} = useParams();

    const liveblocksContext = useContext(LibrettoLiveblocksContext);

    const RoomProvider = liveblocksContext.RoomProvider;

    return (
        <Box>
            {editId === null ? <Typography>Loading...</Typography> :
                <RoomProvider id={editId} initialPresence={{cursor: null}} autoConnect={true}>
                    <ClientSideSuspense fallback="Loading…">
                        {() =>
                            <>
                                <Editor assetId={assetId} editId={editId}/>
                            </>}
                    </ClientSideSuspense>
                </RoomProvider>}
        </Box>
    )
}

const Editor = ({assetId, editId}) => {
    const theme = useTheme();
    const authContext = useContext(AuthContext);
    const fetchContext = useContext(FetchContext);
    const userId = authContext.getUserId();
    const [tracks, setTracks] = useState(new Map());
    const [tracksLoaded, setTracksLoaded] = useState(false);

    const [selectedTrackIndex, setSelectedTrackIndex] = useState(0);

    const [waveDataForTracks, setWaveDataForTracks] = useState(new Map());
    const [enhancedWavedataForTracks, setEnhancedWavedataForTracks] = useState(new Map());
    const [transcripts, setTranscripts] = useState(new Map());
    const [transcriptsLoaded, setTranscriptsLoaded] = useState(false);
    const [captionsEnabled, setCaptionsEnabled] = useState(false);
    const [videoPlayerVisible, setVideoPlayerVisible] = useState(true);

    const editorRef = useRef(null);
    const videoplayerRef = useRef(null);
    const timelineEditorRef = useRef(null);

    const liveblocksContext = useContext(LibrettoLiveblocksContext);

    const orderOfTracks = liveblocksContext.useStorage((root) => root.order) ?? [];
    const trims = liveblocksContext.useStorage((root) => root.trims) ?? new Map();
    const fillerWordRemovalTrims = liveblocksContext.useStorage((root) => root.fillerWordRemovalTrims) ?? new Map();
    const corrections = liveblocksContext.useStorage((root) => root.corrections) ?? new Map();
    const editSettings = liveblocksContext.useStorage((root) => root.editSettings) ?? new Map();

    const [trackIdSet, setTrackIdSet] = useState(() => new Set(orderOfTracks));
    const [waveformRefs, setWaveformRefs] = useState(() => orderOfTracks.map(() => React.createRef()));

    const [clipSize, setClipSize] = useState(() => {
        const initialSettings = editSettings.get("editSettings");
        return initialSettings && initialSettings.videoAspectRatio ? initialSettings.videoAspectRatio : ClipSize16And9RatioId;
    });

    useEffect(() => {
        if (editSettings && editSettings.get("editSettings")) {
            setClipSize(editSettings.get("editSettings").videoAspectRatio);
        }
    }, [editSettings]);

    useEffect(() => {
        setTrackIdSet(new Set(orderOfTracks));
        setWaveformRefs(orderOfTracks.map(() => React.createRef()));
    }, [orderOfTracks]);

    const handleVideoPlayerPlayToggle = (time, play) => {
        if (videoplayerRef.current) {
            videoplayerRef.current.togglePlayPause(time, play);
        }
    }

    const getTrackForSelectedIndex = () => {
        return tracks.get(orderOfTracks[selectedTrackIndex]);
    }

    // Load the tracks.
    useEffect(() => {
        if (!userId) {
            return;
        }

        if (orderOfTracks === undefined || orderOfTracks === null) {
            return;
        }
        if (orderOfTracks.length === 0) {
            setTracksLoaded(true);
            return;
        }

        const filterList = orderOfTracks && orderOfTracks.length > 0 ? orderOfTracks : [];

        const q = query(collection(db, kTracksCollectionName),
            where(TrackId, "in", filterList),
            where(TrackDeleted, "==", false),
            where(TrackUserId, "==", userId));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let newTracks = [];
            querySnapshot.forEach((doc) => {
                newTracks.push(doc.data());
            });
            // Set the tracks to the tracks state map keyed by trackId
            setTracks(new Map(newTracks.map(track => [track.trackId, track])));
            setTracksLoaded(true);
        });

        return () => unsubscribe();
    }, [orderOfTracks, userId]);


    useEffect(() => {
        const loadAllTranscripts = async () => {
            const trackIdsArray = Array.from(trackIdSet);
            const transcriptPromises = trackIdsArray.map(trackId => loadTranscriptForTrack({ trackId, authContext, fetchContext }));

            try {
                const transcriptsData = await Promise.all(transcriptPromises);

                const newTranscripts = new Map();
                trackIdsArray.forEach((trackId, index) => {
                    newTranscripts.set(trackId, transcriptsData[index]);
                });

                setTranscripts(newTranscripts);
            } catch (error) {
                console.error("Error loading transcripts:", error);
            }
        };

        if (trackIdSet.size > 0) {
            loadAllTranscripts().then(() => {
                setTranscriptsLoaded(true);
            });
        }
        setTranscriptsLoaded(true);
    }, [trackIdSet]);

    const LoadWaveformData = async ({wavedataUrl}) => {
        try {
            const response = await fetch(wavedataUrl);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return await response.arrayBuffer();
        } catch (error) {
            console.error("Error getting track urls:", error);
        }
    }

    useEffect(() => {
        const loadAllWaveformData = async () => {
            const waveformDataPromises = [];
            const waveformDataTrackIds = [];
            tracks.forEach((track, trackId) => {
                waveformDataTrackIds.push(trackId);
                if (track.hasAudioStream) {
                    waveformDataPromises.push(LoadWaveformData({wavedataUrl: track.wavedataUrl}));
                } else {
                    waveformDataPromises.push(Promise.resolve(new ArrayBuffer(40 * Number(track.duration))));
                }
            });
            try {
                const waveformData = await Promise.all(waveformDataPromises);
                const waveformDataMap = new Map();
                waveformData.forEach((arrayBuffer, index) => {
                    const int16Array = new Int16Array(arrayBuffer);
                    // Normalize the Int16Array to a Float32Array (range from -1 to 1)
                    const float32Array = new Float32Array(int16Array.length);
                    for (let i = 0; i < int16Array.length; i++) {
                        float32Array[i] = int16Array[i] / 32768;
                    }
                    waveformDataMap.set(waveformDataTrackIds[index], float32Array);
                });
                setWaveDataForTracks(waveformDataMap);
            } catch (error) {
                console.error("Error loading waveform:", error);
            }
        };

        if (tracksLoaded) {
            loadAllWaveformData().then(() => {
            })
        }

    }, [tracks, tracksLoaded]);

    // Load enhanced audio wavedata
    useEffect(() => {
        const loadAllWaveformData = async () => {
            const waveformDataPromises = [];
            const waveformDataTrackIds = [];
            tracks.forEach((track, trackId) => {
                waveformDataTrackIds.push(trackId);
                if (track.hasAudioStream && track.hasEnhancedAudio) {
                    waveformDataPromises.push(LoadWaveformData({wavedataUrl: track.enhancedAudioWavedataUrl}));
                } else {
                    waveformDataPromises.push(Promise.resolve(new ArrayBuffer(40 * Number(track.duration))));
                }
            });
            try {
                const waveformData = await Promise.all(waveformDataPromises);
                const waveformDataMap = new Map();
                waveformData.forEach((arrayBuffer, index) => {
                    const int16Array = new Int16Array(arrayBuffer);
                    // Normalize the Int16Array to a Float32Array (range from -1 to 1)
                    const float32Array = new Float32Array(int16Array.length);
                    for (let i = 0; i < int16Array.length; i++) {
                        float32Array[i] = int16Array[i] / 32768;
                    }
                    waveformDataMap.set(waveformDataTrackIds[index], float32Array);
                });
                setEnhancedWavedataForTracks(waveformDataMap);
            } catch (error) {
                console.error("Error loading waveform:", error);
            }
        };

        if (tracksLoaded) {
            loadAllWaveformData().then(() => {
            })
        }

    }, [tracks, tracksLoaded]);

    const loadProjectTracks = async ({assetId}) => {
        try {
            const {data} = await fetchContext.authAxios.get('/project/tracks/' + assetId, {
                headers: {
                    Authorization: `Bearer ${authContext.getToken()}`,
                },
            });
            return data.tracks;
        } catch (error) {
            if (error.response.status === 401) {
                await RefreshTokenAndRetry(error, authContext, fetchContext);
            }
            console.error("Error loading project tracks:", error);
            return [];
        }
    }

    const saveAllFillerWordTrims = createSaveFillerWordRemovalTrims(liveblocksContext);

    const undoAllFillerWordTrims = createUndoAllFillerWordRemovalTrims(liveblocksContext);


    const handleRemoveFillerWordsRequest = async (removeFillerWords) => {
        if (removeFillerWords) {
            if (editorRef.current) {
                const fillerWordTrims = editorRef.current.getFillerWordTrims();
                saveAllFillerWordTrims(fillerWordTrims);
            }
        } else {
             undoAllFillerWordTrims(orderOfTracks.length);
        }
    }

    const noAudioStream = (tracks) => {
        for (let track of tracks.values()) {
            if (track.hasAudioStream) {
                return false;
            }
        }
        return true;
    }

    return (
        <Box>
            <AppBar
                position={'fixed'}
                sx={{
                    backgroundColor: theme.palette.background.paper,
                }}
                elevation={0}
            >
                <Container maxWidth={1} paddingY={{xs: 1, sm: 1.5}}>
                    <EditorTopbar assetId={assetId} editId={editId}
                                  selectedTrackIndex={selectedTrackIndex}
                                  videoPlayerVisible={videoPlayerVisible}
                                  setVideoPlayerVisible={setVideoPlayerVisible}
                                  captionsEnabled={captionsEnabled}
                                  noAudioStream={noAudioStream(tracks)}
                                  handleRemoveFillerWordsRequest={handleRemoveFillerWordsRequest}
                                  clipSize={clipSize} setClipSize={setClipSize}
                                  videoPlayerRef={videoplayerRef}
                                  setCaptionsEnabled={() => setCaptionsEnabled(!captionsEnabled)} track={getTrackForSelectedIndex()}/>
                </Container>
            </AppBar>
            <EditorInner tracks={tracks} transcriptsLoaded={transcriptsLoaded}
                         tracksLoaded={tracksLoaded} transcripts={transcripts}
                         waveDataForTracks={waveDataForTracks}
                         enhancedWavedataForTracks={enhancedWavedataForTracks}
                         trims={trims}
                         fillerWordRemovalTrims={fillerWordRemovalTrims}
                         corrections={corrections}
                         handleSelect={() => console.log("Selected")} editorRef={editorRef}
                         timelineEditorRef={timelineEditorRef}
                         captionsEnabled={captionsEnabled} videoplayerRef={videoplayerRef}
                         videoPlayerVisible={videoPlayerVisible}
                         clipSize={clipSize}
                         waveformRefs={waveformRefs}
                         selectedTrackIndex={selectedTrackIndex}
                         setSelectedTrackIndex={setSelectedTrackIndex}
                         orderOfTracks={orderOfTracks}
                         handleVideoPlayerPlayToggle={handleVideoPlayerPlayToggle} assetId={assetId} loadTracks={loadProjectTracks}/>
        </Box>
    )
}

export default EditorWrapper;

