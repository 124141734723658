import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import {HourglassBottom} from "@mui/icons-material";

const textStyle = {
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.14px",
}

const smallTextStyle = {
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "0.48px",
}

export const CameraIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
                d="M2.625 2.625C1.6585 2.625 0.875 3.4085 0.875 4.375V9.625C0.875 10.5915 1.6585 11.375 2.625 11.375H7.4375C8.404 11.375 9.1875 10.5915 9.1875 9.625V4.375C9.1875 3.4085 8.404 2.625 7.4375 2.625H2.625Z"
                fill="white"/>
            <path
                d="M11.6313 10.9375L10.0625 9.3687V4.63126L11.6313 3.06248C12.1825 2.51126 13.125 2.90166 13.125 3.6812V10.3188C13.125 11.0983 12.1825 11.4887 11.6313 10.9375Z"
                fill="white"/>
        </svg>
    )
}

export const UploadIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
                d="M1.75 11.0837H12.25V12.2503H1.75V11.0837ZM7.58333 3.39999V9.91699H6.41667V3.39999L2.87525 6.94199L2.05042 6.11716L7 1.16699L11.9496 6.11658L11.1247 6.94141L7.58333 3.40116V3.39999Z"
                fill="#1A1A1A"/>
        </svg>
    )
}

const ShareIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.93351 4.26667C8.93351 3.01482 9.94833 2 11.2002 2C12.452 2 13.4668 3.01482 13.4668 4.26667C13.4668 5.51851 12.452 6.53333 11.2002 6.53333C10.6077 6.53333 10.0683 6.30602 9.66448 5.93384L7.00784 7.48419C7.04639 7.64989 7.06678 7.82255 7.06678 7.99998C7.06678 8.17729 7.04642 8.34984 7.00792 8.51543L9.66625 10.0645C10.0699 9.69329 10.6086 9.46663 11.2002 9.46663C12.452 9.46663 13.4668 10.4815 13.4668 11.7333C13.4668 12.9851 12.452 14 11.2002 14C9.94833 14 8.93351 12.9851 8.93351 11.7333C8.93351 11.5551 8.95408 11.3817 8.99296 11.2154L6.33593 9.66705C5.93208 10.0393 5.39265 10.2667 4.80011 10.2667C3.54827 10.2667 2.53345 9.25183 2.53345 7.99998C2.53345 6.74814 3.54827 5.73332 4.80011 5.73332C5.39252 5.73332 5.93185 5.96058 6.33567 6.33268L8.99241 4.78227C8.95388 4.61664 8.93351 4.44403 8.93351 4.26667Z"
                  fill="#1A1A1A"/>
        </svg>
    )
}

const ThreeDotsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M4.40001 8.00039C4.40001 8.31865 4.27358 8.62387 4.04853 8.84892C3.82349 9.07396 3.51827 9.20039 3.20001 9.20039C2.88175 9.20039 2.57652 9.07396 2.35148 8.84892C2.12643 8.62387 2.00001 8.31865 2.00001 8.00039C2.00001 7.68213 2.12643 7.37691 2.35148 7.15186C2.57652 6.92682 2.88175 6.80039 3.20001 6.80039C3.51827 6.80039 3.82349 6.92682 4.04853 7.15186C4.27358 7.37691 4.40001 7.68213 4.40001 8.00039ZM9.20001 8.00039C9.20001 8.31865 9.07358 8.62387 8.84853 8.84892C8.62349 9.07396 8.31827 9.20039 8.00001 9.20039C7.68175 9.20039 7.37652 9.07396 7.15148 8.84892C6.92643 8.62387 6.80001 8.31865 6.80001 8.00039C6.80001 7.68213 6.92643 7.37691 7.15148 7.15186C7.37652 6.92682 7.68175 6.80039 8.00001 6.80039C8.31827 6.80039 8.62349 6.92682 8.84853 7.15186C9.07358 7.37691 9.20001 7.68213 9.20001 8.00039ZM13.6485 8.84892C13.4235 9.07396 13.1183 9.20039 12.8 9.20039C12.4817 9.20039 12.1765 9.07396 11.9515 8.84892C11.7264 8.62387 11.6 8.31865 11.6 8.00039C11.6 7.68213 11.7264 7.37691 11.9515 7.15186C12.1765 6.92682 12.4817 6.80039 12.8 6.80039C13.1183 6.80039 13.4235 6.92682 13.6485 7.15186C13.8736 7.37691 14 7.68213 14 8.00039C14 8.31865 13.8736 8.62387 13.6485 8.84892Z"
                fill="#1A1A1A" stroke="#1A1A1A" strokeWidth="0.8"/>
        </svg>
    )
}

const SortIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M5.33331 6.00033L5.33331 12.667" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.6667 9.66667L10.6667 3" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.00002 10.0003L5.33335 12.667L2.66669 10.0003" stroke="#1A1A1A" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M7.99998 5.66667L10.6666 3L13.3333 5.66667" stroke="#1A1A1A" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}

const ListViewIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M12 5H8" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 5H6" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 8H8" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 8H6" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 11H8" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 11H6" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

const GridViewIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <rect x="3" y="3" width="4" height="4" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="9" y="3" width="4" height="4" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="3" y="9" width="4" height="4" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="9" y="9" width="4" height="4" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const ChangeViewButton = ({isGridView, onClick}) => {

    const boxStyle = {
        display: "flex",
        padding: "6px 12px",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",
        borderRadius: "58px",
        background: "#E3E5E8",
        flexDirection: "row",
        height: "28px",
        width: "108px",
        cursor: "pointer",
        "&:hover": {
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.10)0%, rgba(0, 0, 0, 0.10)100%), #E3E5E8",
        },
        "&:disabled": {
            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.10)0%, rgba(255, 255, 255, 0.10)100%), #E3E5E8",
            cursor: "not-allowed",
        },
        // Focus state
        "&:focus": {
            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.10)0%, rgba(255, 255, 255, 0.10)100%), #E3E5E8",
        }
    }

    return (
        <Box sx={boxStyle} onClick={onClick}>
            <Box style={{width: "16px", height: "16px", marginBottom: "3px"}}>
                {isGridView ? <ListViewIcon/> : <GridViewIcon/>}
            </Box>
            <Box style={smallTextStyle}>
                {isGridView ? "List View" : "Grid View"}
            </Box>
        </Box>
    )
}

export const SortButton = ({onClick, sortNewest, iconOnly}) => {

    const boxStyle = {
        display: "flex",
        padding: "6px 12px",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",
        borderRadius: "58px",
        background: "#E3E5E8",
        flexDirection: "row",
        height: "28px",
        cursor: "pointer",
        "&:hover": {
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.10)0%, rgba(0, 0, 0, 0.10)100%), #E3E5E8",
        },
        "&:disabled": {
            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.10)0%, rgba(255, 255, 255, 0.10)100%), #E3E5E8",
            cursor: "not-allowed",
        },
        // Focus state
        "&:focus": {
            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.10)0%, rgba(255, 255, 255, 0.10)100%), #E3E5E8",
        }
    }

    return (
        <Tooltip title={sortNewest ? "Sort by oldest" : "Sort by newest"}>
            <Box sx={boxStyle} onClick={onClick}>

                <Box style={{width: "16px", height: "16px"}}>
                    <SortIcon/>
                </Box>
                {!iconOnly && <Box style={smallTextStyle}>
                    Sort
                </Box>}
            </Box>
        </Tooltip>
    )
}

export const ThreeDotsButton = ({onClick}) => {

    const boxStyle = {
        display: "flex",
        height: "40px",
        width: "40px",
        padding: "10px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        borderRadius: "8px",
        border: "1px solid #D9DBDE",
        cursor: "pointer",
        // Hovered state
        "&:hover": {
            border: "1px solid #BFBFBF",
        },
    }

    return (
        <Box sx={boxStyle} onClick={onClick}>
            <ThreeDotsIcon/>
        </Box>
    )
}

export const ShareButton = ({onClick}) => {

    const boxStyle = {
        display: "flex",
        height: "40px",
        padding: "10px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "6px",
        background: "#E3E5E8",
        cursor: "pointer",
        "&:hover": {
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.10)0%, rgba(0, 0, 0, 0.10)100%), #E3E5E8",
        },
        // Disabled state
        "&:disabled": {
            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.10)0%, rgba(255, 255, 255, 0.10)100%), #E3E5E8",
            cursor: "not-allowed",
        },
        // Focus state
        "&:focus": {
            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.10)0%, rgba(255, 255, 255, 0.10)100%), #E3E5E8",
        }
    }

    const buttonTextStyle = {
        ...textStyle,
        color: "#1A1A1A",
    }

    const iconContainerStyle = {
        display: "flex",
        width: "20px",
        height: "20px",
        padding: "2px",
        justifyContent: "center",
        alignItems: "center",
    }

    return (
        <Box sx={boxStyle} onClick={onClick}>
            <Box style={iconContainerStyle}>
                <ShareIcon/>
            </Box>
            <Box style={buttonTextStyle}>
                Share
            </Box>
        </Box>
    )
}

export const UploadButton = ({onClick}) => {

    const boxStyle = {
        display: "flex",
        height: "40px",
        padding: "10px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "6px",
        background: "#E3E5E8",
        cursor: "pointer",
        // Set hovered state
        "&:hover": {
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.10)0%, rgba(0, 0, 0, 0.10)100%), #E3E5E8",
        },
        "&:disabled": {
            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.10)0%, rgba(255, 255, 255, 0.10)100%), #E3E5E8",
            cursor: "not-allowed",
        },
        // Focus state
        "&:focus": {
            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.10)0%, rgba(255, 255, 255, 0.10)100%), #E3E5E8",
        }

    }

    const buttonTextStyle = {
        ...textStyle,
        color: "#1A1A1A",
    }

    const iconContainerStyle = {
        display: "flex",
        width: "14px",
        height: "14px",
        justifyContent: "center",
        alignItems: "center",
    }

    return (
        <Box sx={boxStyle} onClick={onClick}>
            <Box style={iconContainerStyle}>
                <UploadIcon/>
            </Box>
            <Box style={buttonTextStyle}>
                Upload
            </Box>
        </Box>
    )
}

export const EditorUploadButton = ({onClick}) => {

    const boxStyle = {
        display: "flex",
        height: "35px",
        padding: "10px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "6px",
        background: "#E3E5E8",
        cursor: "pointer",
        // Set hovered state
        "&:hover": {
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.10)0%, rgba(0, 0, 0, 0.10)100%), #E3E5E8",
        },
        "&:disabled": {
            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.10)0%, rgba(255, 255, 255, 0.10)100%), #E3E5E8",
            cursor: "not-allowed",
        },
        // Focus state
        "&:focus": {
            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.10)0%, rgba(255, 255, 255, 0.10)100%), #E3E5E8",
        }

    }

    const buttonTextStyle = {
        ...textStyle,
        color: "#1A1A1A",
    }

    const iconContainerStyle = {
        display: "flex",
        width: "14px",
        height: "14px",
        justifyContent: "center",
        alignItems: "center",
    }

    return (
        <Box sx={boxStyle} onClick={onClick}>
            <Box style={iconContainerStyle}>
                <UploadIcon/>
            </Box>
            <Box style={buttonTextStyle}>
                Upload
            </Box>
        </Box>
    )
}

export const MobileLogoutButton = ({onClick}) => {

    const boxStyle = {
        display: "flex",
        flexDirection: "row",
        height: "40px",
        padding: "10px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "6px",
        backgroundColor: "#2B6BFD",
        cursor: "pointer",
        // Set hovered state
        "&:hover": {
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #2B6BFD",
        },
        // Set focused state
        "&:focus": {
            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #2B6BFD"
        },
        // Set disabled state
        "&:disabled": {
            background: "#2B6BFD",
            opacity: 0.3,
            cursor: "not-allowed",
        }
    }

    const buttonTextStyle = {
        ...textStyle,
        color: "white",
    }

    return (
        <Box sx={boxStyle} onClick={onClick}>
            <Box style={buttonTextStyle}>
                Logout
            </Box>
        </Box>
    )
}

export const RecordingStudioButton = ({onClick}) => {

    const boxStyle = {
        display: "flex",
        flexDirection: "row",
        height: "40px",
        padding: "10px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "6px",
        backgroundColor: "#2B6BFD",
        cursor: "pointer",
        // Set hovered state
        "&:hover": {
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #2B6BFD",
        },
        // Set focused state
        "&:focus": {
            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #2B6BFD"
        },
        // Set disabled state
        "&:disabled": {
            background: "#2B6BFD",
            opacity: 0.3,
            cursor: "not-allowed",
        }

    }

    const buttonTextStyle = {
        ...textStyle,
        color: "white",
    }

    const iconBoxStyle = {
        // width: "14px",
        // height: "14px",
        paddingTop: "6px",
    }

    return (
        <Box sx={boxStyle} onClick={onClick}>
            <Box style={iconBoxStyle}>
                <CameraIcon/>
            </Box>
            <Box style={buttonTextStyle}>
                Record
            </Box>
        </Box>
    )
}

export const EditButton = ({onClick, loading, disabled}) => {

    const handleClick = () => {
        if (disabled) return;
        onClick();
    }

    const boxStyle = (disabled) => ({
        display: "flex",
        flexDirection: "row",
        height: "40px",
        padding: "10px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "6px",
        backgroundColor: disabled ? "#2B6BFD" : "#2B6BFD",
        opacity: disabled ? 0.3 : 1,
        cursor: disabled ? "not-allowed" : "pointer",
        "&:hover": !disabled && {
            background:
                "linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #2B6BFD",
        },
        "&:focus": !disabled && {
            background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #2B6BFD",
        },
    });

    const buttonTextStyle = {
        ...textStyle,
        color: "white",
    }

    const iconBoxStyle = {
        // width: "14px",
        // height: "14px",
        paddingTop: "7px",
    }

    return (
        <Box sx={boxStyle(disabled)} onClick={handleClick}>
            <Box style={iconBoxStyle}>
                {loading ? <HourglassBottom style={{fontSize: "1.2rem", color: "white"}}/> : <ContentCutIcon style={{fontSize: "1.2rem", color: "white"}}/>}
            </Box>
            <Box style={buttonTextStyle}>
                Edit
            </Box>
        </Box>
    )
}
