import * as React from "react";
import {useEnsureParticipant} from "@livekit/components-react";
import {setupParticipantName} from "@livekit/components-core";
import {mergeProps} from "./MergeProps";
import {useCustomObservableState} from "./useCustomObservableState";
import styles from './participantNameStyles.css';

export const CustomParticipantName = /* @__PURE__ */ React.forwardRef(function ParticipantName({ participant, nameStyle = 'bold', ...props }, ref) {
    const p = useEnsureParticipant(participant);

    const { className, infoObserver } = React.useMemo(() => {
        return setupParticipantName(p);
    }, [p]);

    const { identity, name } = useCustomObservableState(infoObserver, {
        name: p.name,
        identity: p.identity,
        metadata: p.metadata,
    });

    const mergedProps = React.useMemo(() => {
        return mergeProps(props, { className, 'data-lk-participant-name': name });
    }, [props, className, name]);

    return (
        <span ref={ref} {...mergedProps} className={nameStyle}>
      {name !== '' ? name : identity}
            {props.children}
    </span>
    );
});
