import React from 'react';
import clsx from 'clsx';
import {Label} from "../Label/Label";
import './Input.styles.scss';

type InputType = "number" | "text";

interface InputProps {
    type?: InputType;
    placeholder?: string;
    label?: string;
    error?: string;
    name?: string;
    value?: string;
    onChangeText?: (value: string) => void;
}

const Input: React.FC<InputProps> = ({
                                         type = "text",
                                         placeholder,
                                         label,
                                         error,
                                         name,
                                         value,
                                         onChangeText
                                     }) => {
    const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeText?.(event.target.value);
    };

    const hasError = error != null;

    return (
        <div className="input">
            {label != null && <Label error={hasError}>{label}</Label>}

            <input
                className={clsx("input__input", {
                    "input__input-error": hasError,
                })}
                type={type}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={handleChangeText}
            />
            {hasError && <p className="input__error-label">{error}</p>}
        </div>
    );
};

export default Input;