import { TextNode } from 'lexical'
import {IsFillerWordInTranscript} from "./Transcript";

function isPlayingStyle(isPlaying) {
    return isPlaying ? '"GRAD" 150, "YOPQ" 100' : ''
}

function updateStyle(config, element, loc, isPlaying, isDeleted, isCorrected) {
    element.style.fontVariationSettings = isPlayingStyle(isPlaying)
    element.style.textDecoration = isPlaying ? 'underline' : ''
    element.style.textDecoration = isDeleted ? 'line-through' : ''
    element.style.backgroundColor = isDeleted ? 'rgba(0, 0, 0, 0.6)' : 'transparent';  // Highlight background when playing
    if (isCorrected) {
        element.style.color = '#2196f3';  // Highlight background when corrected
    }
}

export class SoundNode extends TextNode {
    constructor(text, loc, key) {
        super(text, key)
        this.__soundLoc = { ...loc, key: this.getKey() }
        this.__isPlaying = false
    }

    static getType() {
        return 'sound'
    }

    static clone(node) {
        return new SoundNode(node.__text, node.__soundLoc, node.__key)
    }

    createDOM(config) {
        const element = super.createDOM(config)
        updateStyle(config, element, this.__soundLoc, this.__isPlaying, this.__soundLoc.isDeleted || this.__soundLoc.isRemovedAsFillerWord, this.__soundLoc.originalText !== undefined && this.__soundLoc.originalText !== this.__text)
        return element
    }

    updateDOM(prevNode, dom, config) {
        if (super.updateDOM(prevNode, dom, config)) {
            return true
        }
        if (
            prevNode.__isPlaying !== this.__isPlaying ||
            prevNode.__soundLoc !== this.__soundLoc
        ) {
            updateStyle(config, dom, this.__soundLoc, this.__isPlaying, this.__soundLoc.isDeleted || this.__soundLoc.isRemovedAsFillerWord, this.__soundLoc.originalText !== undefined && this.__soundLoc.originalText !== this.__text)
        }
        return false
    }

    getSoundLocation() {
        const self = this.getLatest()
        return self.__soundLoc
    }

    setSoundLocation(loc) {
        const self = this.getWritable()
        self.__soundLoc = { ...loc, key: this.getKey() }
        return self
    }

    setTextContent(text) {
        const self = this.getWritable()
        self.__text = text
        return self
    }

    getTextContent() {
        return this.__text
    }

    getWidthInPixels() {
        return this.__text.length * 10
    }

    setIsPlaying(isPlaying) {
        const self = this.getWritable()
        self.__isPlaying = isPlaying
        return self
    }

    setRemovedAsFillerWord() {
        const self = this.getWritable()
        self.__soundLoc.isRemovedAsFillerWord = true
        return self
    }

    unsetRemovedAsFillerWord() {
        const self = this.getWritable()
        self.__soundLoc.isRemovedAsFillerWord = false
        return self
    }

    setDeleted() {
        const self = this.getWritable()
        self.__soundLoc.isDeleted = true
        return self
    }

    isDeleted() {
        return this.__soundLoc.isDeleted || this.__soundLoc.isRemovedAsFillerWord
    }

    isFillerWord() {
        return IsFillerWordInTranscript(this.__text)
    }

    exportJSON() {
        return {
            ...super.exportJSON(),
            soundLoc: this.__soundLoc,
            type: 'sound',
        }
    }

    isTrueSoundNode() {
        return this.__soundLoc.isSilence === false && this.__soundLoc.isSpeakerNode === false
    }

    isSilenceNode() {
        if (this.__soundLoc.isSilence === null) {
            return false
        }
        return this.__soundLoc.isSilence
    }

    isSpeakerNode() {
        if (this.__soundLoc.isSpeakerNode === null) {
            return false
        }
        return this.__soundLoc.isSpeakerNode
    }

    getDuration() {
        return this.__soundLoc.end - this.__soundLoc.start
    }

    getStart() {
        return this.__soundLoc.start
    }

    getEnd() {
        return this.__soundLoc.end
    }

    getTranscriptIndex() {
        return this.__soundLoc.transcriptIndex
    }

    getOrderedTrackIndex() {
        return this.__soundLoc.orderedTrackIndex
    }

    static importJSON(serializedNode) {
        const node = $createSoundNode(serializedNode.text, serializedNode.soundLoc)
        return node
    }
}

export function $createSoundNode(text, soundLoc) {
    return new SoundNode(text, soundLoc)
}

export function $isSoundNode(node) {
    return node instanceof SoundNode
}
