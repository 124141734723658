/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {VideoPlayer} from "../../../components/VideoPlayer";
import CardMedia from "@mui/material/CardMedia";

export const NewVideoLayoutFeature = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [viewPortEntered, setViewPortEntered] = useState(false);
    const setViewPortVisibility = (isVisible) => {
        if (viewPortEntered) {
            return;
        }

        setViewPortEntered(isVisible);
    };

    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const videoHeight = isXs ? 210 : (isSm ? 270 : 360);
    const videoWidth = isXs ? 370 : (isSm ? 480 : 640);

    return (
        <Box>
            <Grid container spacing={isMd ? 4 : 0} direction={isMd ? 'row' : 'column'} alignItems="center">
                <Grid item xs={12} md={5}>
                    <Box marginBottom={4}>
                        <Typography sx={{ fontWeight: 700 }} variant={'h3'} gutterBottom color={'#2B6BFD'}>
                            Smart Video Layouts
                        </Typography>
                        <Typography component={'p'} color={'text.secondary'} fontFamily={'Inter'} fontSize="1.2rem" fontWeight={500} marginBottom={3}>
                            Choose from a variety of video layouts. For speaker-oriented layouts,
                            Libretto automatically detects and centers the active speaker.
                        </Typography>
                    </Box>
                </Grid>
                <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    md={7}
                >
                    <Box
                            component={CardMedia}
                            height={videoHeight}
                            width={videoWidth}
                            borderRadius={'20px'}
                            image={"https://storage.googleapis.com/libretto-public-assets/VideoLayout.png"}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export const GuideSectionOnLandingPage = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [viewPortEntered, setViewPortEntered] = useState(false);
    const setViewPortVisibility = (isVisible) => {
        if (viewPortEntered) {
            return;
        }

        setViewPortEntered(isVisible);
    };

    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const videoHeight = isXs ? 210 : (isSm ? 270 : 360);
    const videoWidth = isXs ? 370 : (isSm ? 480 : 640);

    return (
        <Box marginTop={"30px"} display={"flex"} alignItems={"center"} flexDirection={"column"}>
            <Grid container spacing={isMd ? 4 : 4} maxWidth={{ xs: "95%", sm: "95%", md: 1236 }} direction={isMd ? 'row' : 'column'} alignItems="center" justifyContent="flex-start">
                <Grid item container xs={12} md={6} alignSelf={'flex-start'}>
                        <Typography sx={{ fontWeight: 600 }} variant={'h3'} gutterBottom>
                            Libretto makes recording and editing simple.
                        </Typography>
                        <Typography component={'p'} color={'text.secondary'} fontFamily={'Inter'} fontSize="1.2rem" fontWeight={450} marginBottom={3}>
                            Empowering you to focus on your craft and bring your vision to life. From recording to editing and sharing, one tool makes it all easy.
                        </Typography>
                        <Box style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <Box style={{display: "flex", flexDirection: "column", width: "30%"}}>
                                <Typography variant={"h3"} color={"#2b6bfd"} fontWeight={"550"}>
                                    100+
                                </Typography>
                                <Typography variant={"subtitle1"} color={"text.secondary"}>Trusted by hundreds worldwide.</Typography>
                            </Box>
                            <Box style={{display: "flex", flexDirection: "column", width: "30%"}}>
                                <Typography variant={"h3"} color={"#2b6bfd"} fontWeight={"550"}>
                                    50%
                                </Typography>
                                <Typography variant={"subtitle1"} color={"text.secondary"}>Save up to half of editing time.</Typography>
                            </Box>
                            <Box style={{display: "flex", flexDirection: "column", width: "30%"}}>
                                <Typography variant={"h3"} color={"#2b6bfd"} fontWeight={"550"}>
                                    99%
                                </Typography>
                                <Typography variant={"subtitle1"} color={"text.secondary"}>Uptime and reliability you can count on.</Typography>
                            </Box>
                        </Box>
                </Grid>
                <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={11}
                    md={6}
                >
                    <VideoPlayer
                        src={"https://stream.mux.com/w4027e1dwI3LEGN7nulaPfN8GZRt5RukWMdrcu13KRcU.m3u8"}
                        poster={"https://image.mux.com/w4027e1dwI3LEGN7nulaPfN8GZRt5RukWMdrcu13KRcU/thumbnail.png?width=728&height=410&time=3"}
                        width={videoWidth} height={videoHeight} autoplay={false} controls={false} loop={true} muted={true} cover={true}/>
                </Grid>
            </Grid>
        </Box>
    );
};

const VideoLayoutFeature = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [viewPortEntered, setViewPortEntered] = useState(false);
    const setViewPortVisibility = (isVisible) => {
        if (viewPortEntered) {
            return;
        }

        setViewPortEntered(isVisible);
    };

    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const videoHeight = isXs ? 210 : (isSm ? 270 : 360);
    const videoWidth = isXs ? 370 : (isSm ? 480 : 640);

    return (
        <Box>
            <Grid container spacing={isMd ? 4 : 0} direction={isMd ? 'row' : 'column'} alignItems="center">
                <Grid item xs={12} md={5}>
                    <Box marginBottom={4}>
                        <Typography sx={{ fontWeight: 700 }} variant={'h3'} gutterBottom>
                            Smart{' '}
                            <Typography
                                color={'primary'}
                                component={'span'}
                                variant={'inherit'}
                            >
                                Video Layouts
                            </Typography>
                        </Typography>
                        <Typography component={'p'} color={'text.secondary'} fontSize="1.2rem" fontWeight={500} marginBottom={3}>
                            Choose from a variety of video layouts. For
                            {' '}<span style={{fontWeight: 900}}>
                                    speaker-oriented layouts,
                                </span>{' '}
                            Libretto automatically detects and centers the active speaker.
                        </Typography>
                    </Box>
                </Grid>
                <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    md={7}
                >
                    {isSm ?
                        <Box
                            component={Card} boxShadow={4} height={videoHeight} width={videoWidth}
                        >
                            <VideoPlayer src={"https://stream.mux.com/b0181Cc9e7bB02fwuhUw00OX6mP00ojbiUIvWOv6nafoca4.m3u8"} poster={`https://image.mux.com/b0181Cc9e7bB02fwuhUw00OX6mP00ojbiUIvWOv6nafoca4/thumbnail.png?time=0&width=${videoWidth}&height=${videoHeight}`} width={videoWidth} height={videoHeight} autoplay={true} controls={false} muted={true} loop={true}/>
                        </Box>
                        : <Box
                            component={CardMedia}
                            height={videoHeight}
                            width={videoWidth}
                            borderRadius={'20px'}
                            image={`https://image.mux.com/b0181Cc9e7bB02fwuhUw00OX6mP00ojbiUIvWOv6nafoca4/thumbnail.png?time=0&width=${videoWidth}&height=${videoHeight}`}
                        />}
                </Grid>
            </Grid>
        </Box>
    );
};

export default VideoLayoutFeature;
