import React from 'react';
import Clip from '../Clip/Clip';  // Adjust the import path as needed

interface LottieClipProps {
    clipId: string;
    layerId: string;
}

const LottieClip: React.FC<LottieClipProps> = ({ clipId, layerId }) => {
    return (
        <Clip clipId={clipId} layerId={layerId} filename="Lottie" backgroundColor={"218, 160, 109"}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <circle cx="12" cy="12" r="10"></circle>
                <circle cx="12" cy="12" r="3"></circle>
            </svg>
        </Clip>
    );
};

export default LottieClip;