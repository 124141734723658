import { Engine } from "@rendley/sdk";
import { RendleyStore } from "../../../store/RendleyStore";
import { RendleyService } from "../../../services/RendleyService";

const removeTransitionIfClipsAreNotAdjacent = (clipId: string, layerId: string, hasNearby: boolean) => {
    if (!hasNearby) {
        // remove transition if clips are not adjacent
        const transitionId = RendleyStore.layers[layerId].transitionIds.find((id) => {
            const transition = RendleyStore.transitions[id];
            return transition.startClipId === clipId || transition.endClipId === clipId;
        });

        if (transitionId) {
            const layer = Engine.getInstance().getTimeline().getLayerById(layerId);
            layer?.removeTransition(transitionId);
        }
    }
};

function numberToFixed(number: number) {
    return Number(number.toFixed(3));
}

export function updateAdjacency(currentLayerId: string) {
    const clipsIds = RendleyService.getLayerById(currentLayerId)?.clipsIds ?? [];

    for (let i = 0; i < clipsIds.length; i++) {
        const previousClipId = clipsIds[i - 1];
        const currentClipId = clipsIds[i];
        const nextClipId = clipsIds[i + 1];
        const currentClip = RendleyService.getClipById(currentClipId);

        if (currentClip == null) {
            return;
        }

        let hasPredecessor = false;
        let hasSuccessor = false;
        const differenceThreshold = numberToFixed(1 / RendleyService.getFps());

        if (previousClipId != null) {
            const prevClip = RendleyService.getClipById(previousClipId);

            if (prevClip == null) {
                return;
            }

            const difference = numberToFixed(Math.abs(currentClip.getLeftBound() - prevClip.getRightBound()));

            hasPredecessor = difference < differenceThreshold;
        }

        if (nextClipId != null) {
            const nextClip = RendleyService.getClipById(nextClipId);

            if (nextClip == null) {
                return;
            }

            const difference = numberToFixed(Math.abs(nextClip.getLeftBound() - currentClip.getRightBound()));

            hasSuccessor = difference < differenceThreshold;
        }

        removeTransitionIfClipsAreNotAdjacent(currentClipId, currentLayerId, hasPredecessor || hasSuccessor);

        RendleyStore.updateClip(currentClipId, {
            hasPredecessor,
            hasSuccessor,
        });
    }
}
