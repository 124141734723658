import React from 'react';
import { Box, Typography } from '@mui/material';

const RecordingGuidelinesCard = () => {
    const mainStyle = {
        display: "flex",
        width: "520px",
        padding: "16px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "24px",
        borderRadius: "12px",
        background: "#FFF",
        boxShadow: "0px 30px 80px 0px rgba(0, 0, 0, 0.15)",
    };

    const headerStyle = {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '20px',
        letterSpacing: '0.14px',
        color: '#000',
    };

    const textStyle = {
        color: "#333",
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
        letterSpacing: '0.14px',
    };

    const bulletPointStyle = {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '12px',
    };

    const bulletStyle = {
        minWidth: '24px',
        color: '#2B6BFD',
        fontWeight: '700',
    };

    return (
        <Box sx={mainStyle}>
            <Typography sx={headerStyle}>Recording Guidelines</Typography>
            <Box>
                <Box sx={bulletPointStyle}>
                    <Typography sx={{...textStyle, ...bulletStyle}}>•</Typography>
                    <Typography sx={textStyle}>
                        <strong>Use Chrome or Edge browser only.</strong> Libretto currently works best with Chrome and Edge. All participants should use one of these browsers for recording.
                    </Typography>
                </Box>
                <Box sx={bulletPointStyle}>
                    <Typography sx={{...textStyle, ...bulletStyle}}>•</Typography>
                    <Typography sx={textStyle}>
                        <strong>Enable microphone and camera before recording.</strong> All participants should enable their microphone (and camera for video recording) at least once after entering the studio, but before starting a recording. These can be muted or turned off at any time afterwards, even during the recording session.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default RecordingGuidelinesCard;