import {FormControl, Select} from "@mui/material";
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {LibrettoStudioNameStyle, LibrettoStudioTheme} from "../../utils/utils";

const languages = {
    "Auto Detect": "auto",
    "English": "en",
    "Spanish": "es",
    "French": "fr",
    "German": "de",
    "Chinese (Mandarin, Simplified)": "zh",
    "Portuguese": "pt",
    "Italian": "it",
    "Bulgarian": "bg",
    "Catalan": "ca",
    "Czech": "cs",
    "Danish": "da",
    "Dutch": "nl",
    "Estonian": "et",
    "Finnish": "fi",
    "Flemish": "nl-BE",
    "German (Switzerland)": "de-CH",
    "Greek": "el",
    "Hindi": "hi",
    "Hungarian": "hu",
    "Romanian": "ro",
    "Russian": "ru",
    "Slovak": "sk",
    "Swedish": "sv",
    "Thai": "th",
    "Turkish": "tr",
    "Ukrainian": "uk",
    // "Vietnamese": "vi"
    // "Indonesian": "id",
    // "Japanese": "ja",
    // "Korean": "ko",
    // "Latvian": "lv",
    // "Lithuanian": "lt",
    // "Malay": "ms",
    // "Norwegian": "no",
    // "Polish": "pl",
};


const baseModelLanguages = {
    "Auto Detect": "auto",
    "Chinese": "zh",
    "Danish": "da",
    "Dutch": "nl",
    "English": "en",
    "Flemish": "nl",
    "French": "fr",
    "German": "de",
    "Hindi": "hi",
    "Indonesian": "id",
    "Italian": "it",
    "Japanese": "ja",
    "Korean": "ko",
    "Norwegian": "no",
    "Polish": "pl",
    "Portuguese": "pt",
    "Russian": "ru",
    "Spanish": "es",
    "Swedish": "sv",
    "Tamasheq": "taq",
    "Turkish": "tr",
    "Ukrainian": "uk"
};

const LibrettoThemeIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_4525_2211)">
                <path d="M45 -21H-21L45 45V-21Z" fill="#E3E5E8"/>
                <path d="M-21 45L45 45L-21 -21L-21 45Z" fill="#2B6BFD"/>
            </g>
            <defs>
                <clipPath id="clip0_4525_2211">
                    <rect width="24" height="24" rx="12" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

const DarkThemeIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_4525_2211)">
                <path d="M45 -21H-21L45 45V-21Z" fill="#E3E5E8"/>
                <path d="M-21 45L45 45L-21 -21L-21 45Z" fill="#1A1A1A"/>
            </g>
            <defs>
                <clipPath id="clip0_4525_2211">
                    <rect width="24" height="24" rx="12" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

const LightThemeIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_4525_2211)">
                <path d="M45 -21H-21L45 45V-21Z" fill="#FFFFFF"/>
                <path d="M-21 45L45 45L-21 -21L-21 45Z" fill="#FFFFFF"/>
            </g>
            <defs>
                <clipPath id="clip0_4525_2211">
                    <rect width="24" height="24" rx="12" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}


const NewTranscriptionLangDropdown = ({selectedLanguage, onChange, isBaseModel}) => {

    const languageSet = isBaseModel ? baseModelLanguages : languages;

    return (
        <FormControl style={{width: '234px', height: '64px'}}>
            <Select
                value={selectedLanguage}
                onChange={(event) => onChange(event.target.value)}
                sx={{
                    color: '#1a1a1a', // Color of the text in the select box
                    backgroundColor: 'white', // Background color of the select box
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: "#e8e8e8",
                    },
                    '&:hover': {
                        '.MuiOutlinedInput-notchedOutline': {
                            // borderColor: 'white', // Border hover color
                        }
                    },
                    '& .MuiSelect-icon': {
                        color: 'black' // Color of the dropdown icon
                    },
                    width: '234px',
                    height: '64px',
                }}
                MenuProps={{
                    sx: {
                        '& .MuiMenu-paper': {
                            bgcolor: 'white', // Background color of the dropdown
                            color: '#1a1a1a', // Text color for dropdown options
                            borderRadius: '8px', // Rounded corners
                            '& .MuiMenuItem-root': {
                                '&:hover': {
                                    backgroundColor: '#e8e8e8',
                                }
                            }
                        }
                    }
                }}
            >
                {Object.entries(languageSet).map(([language, code]) => (
                    <MenuItem key={code} value={code}>
                        {language}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const ThemeItems = [
    {icon: <LightThemeIcon/>, label: 'Light', code: LibrettoStudioTheme.Light},
    {icon: <LibrettoThemeIcon/>, label: 'Libretto', code: LibrettoStudioTheme.Libretto},
    {icon: <DarkThemeIcon/>, label: 'Dark', code: LibrettoStudioTheme.Dark},
];

const sideBarItemTextStyle = {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: "0.16px",
}

const sideBarIconStyle = {
    display: "flex",
    width: "20px",
    height: "20px",
    justifyContent: "center",
    alignItems: "center",
}

const sideBarItemStyle = {
    display: 'flex',
    flexDirection: 'row',
    padding: "16px 12px",
    alignItems: 'center',
    gap: "12px",
    alignSelf: "stretch",
    borderRadius: "8px",
    cursor: 'pointer',
    backgroundColor: "transparent",
}

export const NameStyleSelect = ({selectedStyle, updateStudioNameStyle}) => {

    const nameBoxStyle = {
        display: "flex",
        padding: "20px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "12px",
        width: "592px",
        alignSelf: "stretch",
        borderRadius: "8px",
        cursor: 'pointer',
        marginTop: "4px",
    }

    const ordinaryBoxStyle = {
        ...nameBoxStyle,
        border: "1px solid #E8E8E8",
    }

    const selectedBoxStyle = {
        ...nameBoxStyle,
        border: "1.5px solid #2B6BFD",
    }


    const cleanTextStyle = {
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "0.14px",
    }

    const boldStyle = {
        backgroundColor: "#2B6BFD",
        borderRadius: "2px",
        // width: "30px",
        // height: "20px",
        display: "flex",
        padding: "0px 4px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
    }

    const boldTextStyle = {
        color: "#FFF",
        fontFamily: "Oswald",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "0.14px",
    }

    const edgyStyle = {
        backgroundColor: "#1a1a1a",
        borderRadius: "2px",
        display: "flex",
        padding: "0px 4px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
    }

    const edgyTextStyle = {
        color: "#FFF",
        fontFamily: "Patrick Hand",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "0.14px",
    }

    const classicStyle = {
        backgroundColor: "#ffffff",
        borderRadius: "2px",
        display: "flex",
        padding: "0px 4px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
    }

    const classicTextStyle = {
        color: "#1a1a1a",
        fontFamily: "Patrick Hand",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "0.14px",
    }

    const funStyle = {
        display: "flex",
        padding: "0px 8px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "100px",
        backgroundColor: "#2B6BFD",
    }

    const funTextStyle = {
        color: "#FFF",
        fontFamily: "Bowlby One",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px",
        letterSpacing: "0.14px",
    }

    const effectiveStyle = (code) => {
        return selectedStyle === code ? selectedBoxStyle : ordinaryBoxStyle;
    }

    const NameStyleBox = ({text, style, textStyle, code, onClick}) => {
        return (
            <Box style={effectiveStyle(code)} onClick={onClick}>
                <Box style={style}>
                    <Box style={textStyle}>
                        {text}
                    </Box>
                </Box>
            </Box>
        )
    }

    const NameStyleItems = [
        {style: cleanTextStyle, textStyle: {}, label: 'Clean', code: LibrettoStudioNameStyle.Clean},
        {style: boldStyle, textStyle: boldTextStyle, label: 'Bold', code: LibrettoStudioNameStyle.Bold},
        {style: edgyStyle, textStyle: edgyTextStyle, label: 'Edgy', code: LibrettoStudioNameStyle.Edgy},
        {style: classicStyle, textStyle: classicTextStyle, label: 'Classic', code: LibrettoStudioNameStyle.Classic},
        {style: funStyle, textStyle: funTextStyle, label: 'Fun', code: LibrettoStudioNameStyle.Fun},
    ];

    const handleClick = (code) => {
        updateStudioNameStyle({studioNameStyle: code});
    }

    return (
        <Grid item>
            {NameStyleItems.map((item, index) => (
                <NameStyleBox text={item.label} style={item.style} textStyle={item.textStyle} key={index} code={item.code} onClick={() => handleClick(item.code)}/>
            ))}
        </Grid>
    )

}

export const StudioThemeSelectDropdown = ({selectedTheme, updateStudioTheme}) => {

    return (
        <FormControl style={{width: '234px', height: '64px'}}>
            <Select
                value={selectedTheme}
                onChange={(event) => updateStudioTheme({studioTheme: event.target.value})}
                sx={{
                    color: '#1a1a1a', // Color of the text in the select box
                    backgroundColor: 'white', // Background color of the select box
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: "#e8e8e8",
                    },
                    '&:hover': {
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#e8e8e8', // Border hover color
                        }
                    },
                    '& .MuiSelect-icon': {
                        color: 'black' // Color of the dropdown icon
                    },
                    width: '234px',
                    height: '64px',
                }}
                MenuProps={{
                    sx: {
                        '& .MuiMenu-paper': {
                            bgcolor: 'white', // Background color of the dropdown
                            color: '#1a1a1a', // Text color for dropdown options
                            borderRadius: '8px', // Rounded corners
                            // COlor of option on hover
                            '& .MuiMenuItem-root': {
                                '&:hover': {
                                    backgroundColor: '#e8e8e8',
                                }
                            }
                        }
                    }
                }}
            >
                {ThemeItems.map((item, index) => (
                    <MenuItem key={index} value={item.code}>
                        <Box key={index} sx={sideBarItemStyle}>
                            <Box sx={sideBarIconStyle}>{item.icon}</Box>
                            <Typography sx={sideBarItemTextStyle}>{item.label}</Typography>
                        </Box>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default NewTranscriptionLangDropdown;

