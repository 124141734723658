import {useContext, useEffect} from "react";
import {
    createAddMediaToLibrary,
    createSetDisplayBackground,
    createSetDisplayResolution, useCreateAddMediaToLibrary, useCreateSetDisplayBackground,
    useCreateSetDisplayResolution
} from "./ModernLiveblocks";
import {useStorage} from "@liveblocks/react";


const RendleyLibrettoBridge = () => {

    const display = useStorage((root) => root.display) || {};

    const library = useStorage((root) => root.library) || [];

    const corrections = useStorage((root) => root.corrections) || {};

    const setDisplayResolution = useCreateSetDisplayResolution();

    const setDisplayBackground = useCreateSetDisplayBackground();

    const addMediaToLibrary = useCreateAddMediaToLibrary();

    const handleDisplayResolutionUpdated = (event) => {
        setDisplayResolution(event.detail.width, event.detail.height);
    }

    const handleDisplayBackgroundUpdated = (event) => {
        setDisplayBackground(event.detail.backgroundColor);
    }

    const handleLibraryAdded = (event) => {
        const mediaData = event.detail;
        addMediaToLibrary(mediaData);
    }

    const handleClipUpdated = (event) => {

    }

    const handleClipAdded = (event) => {

    }

    useEffect(() => {
        window.addEventListener("LIBRETTO_DISPLAY_RESOLUTION_UPDATED", handleDisplayResolutionUpdated);
        window.addEventListener("LIBRETTO_DISPLAY_BACKGROUND_UPDATED", handleDisplayBackgroundUpdated);
        window.addEventListener("LIBRETTO_LIBRARY_ADDED", handleLibraryAdded);
        // window.addEventListener("LIBRETTO_CLIP_ADDED", handleLibraryAdded);

        return () => {
            window.removeEventListener("LIBRETTO_DISPLAY_RESOLUTION_UPDATED", handleDisplayResolutionUpdated);
            window.removeEventListener("LIBRETTO_DISPLAY_BACKGROUND_UPDATED", handleDisplayBackgroundUpdated);
            window.removeEventListener("LIBRETTO_LIBRARY_ADDED", handleLibraryAdded);
        };
    });

    return null;
}

export default RendleyLibrettoBridge;