import React, {useState, useContext, useEffect} from 'react';
import {
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    Switch,
    Slider,
    IconButton,
    Tooltip,
    DialogContent, Select, FormControl
} from '@mui/material';
import {ClickAwayListener, Stack} from '@mui/material';
import {ArrowBackIos, Close, HourglassEmpty, HourglassFull} from '@mui/icons-material';
import {LibrettoLiveblocksContext} from '../Editor/LibrettoLiveblocksContext';
import {createSetEnhancedAudio, createSetRemoveFillerWords} from '../Editor/Liveblocks';
import CloseIcon from "@mui/icons-material/Close";
import {ToggleButton} from "../NewStudio/ToggleButton";
import MenuItem from "@mui/material/MenuItem";
import {Engine} from "@rendley/sdk";

const ModernMagicToolsCard = ({
                                  handleClose,
                                  track,
                                  textEditorRef,
                                  noAudioStream,
                                  selectedTrackindex,
                                  handleEnhanceAudioRequest,
                                  handleRemoveFillerWordsRequest,
                                  topPosition,
                                  leftPosition,
                                  guestEditor,
                              }) => {
    const [showSetPaceCard, setShowSetPaceCard] = useState(false);
    const [paceSetting, setPaceSetting] = useState(0);

    const [showEnhanceAudioCard, setShowEnhanceAudioCard] = useState(false);
    const [fillerWordTrims, setFillerWordTrims] = useState([]);
    const [fillerWordInstancesCount, setFillerWordInstancesCount] = useState(0);
    const [fillerWordsTotalDuration, setFillerWordsTotalDuration] = useState(0);

    const [selectedClipId, setSelectedClipId] = useState(123);

    const [recalculateFillerWords, setRecalculateFillerWords] = useState(false);

    const trackSettings = new Map();

    useEffect(() => {
        if (textEditorRef.current) {
            const currentTrims = textEditorRef.current.getFillerWordCuts();
            currentTrims.sort((a, b) => a.startTime - b.startTime);
            setFillerWordTrims(currentTrims);
            setFillerWordInstancesCount(currentTrims.length);
            let totalDuration = 0;
            currentTrims.forEach(trim => {
                totalDuration += trim.endTime - trim.startTime;
            });
            const readableDuration = totalDuration.toFixed(2);
            setFillerWordsTotalDuration(readableDuration);
        } else {
            console.log("Text editor ref is null");
        }
    }, [textEditorRef.current, recalculateFillerWords]);

    const strIndex = String(selectedTrackindex);
    const [enhancedAudioSelected, setEnhancedAudioSelected] = useState(trackSettings.get(strIndex) ? trackSettings.get(strIndex).enhanceAudio : false);
    const [removeFillerWordsLoading, setRemoveFillerWordsLoading] = useState(false);

    const [enhanceAudioContentType, setEnhanceAudioContentType] = useState("podcast");

    const contentTypeDescriptions = {
        podcast: "Close mic speech with some musical content.",
        conference: "Speech content where microphone is far from the speaker.",
        interview: "Speech content where microphone is close to the speaker.",
        lecture: "Speech content where microphone is far from the speaker in a large room.",
        meeting: "Speech content where microphone is close to the speaker.",
        mobile_phone: "Speech content where microphone location is variable.",
        music: "Musical content rather than speech.",
        studio: "Close mic speech content with limited background noise.",
        voice_over: "Close mic speech content."
    }

    const contentTypeTitles = {
        podcast: "Podcast",
        conference: "Conference",
        interview: "Interview",
        lecture: "Lecture",
        meeting: "Meeting",
        mobile_phone: "Mobile Phone",
        music: "Music",
        studio: "Studio",
        voice_over: "Voice Over"
    }


    const setEnhanceAudio = () => {
    };
    const setRemoveFillerWordsInStorage = () => {
    };

    const handleEnhanceAudioChange = (enhancedAudioSelected) => {
        setEnhanceAudio(selectedTrackindex, enhancedAudioSelected);
        setEnhancedAudioSelected(enhancedAudioSelected);
    }

    const paceSettingTitleMap = {
        0: "Original", 1: "Natural", 2: "Balanced", 3: "Fast", 4: "Very fast"
    }

    const handleRemoveFillerWords = async () => {
        setRemoveFillerWordsLoading(true);
        const timeline = Engine.getInstance().getTimeline();

        const cutsPerClip = new Map();
        for (let trim of fillerWordTrims) {
           const clipId = trim.clipId;
           if (clipId) {
                if (cutsPerClip.has(clipId)) {
                     cutsPerClip.get(clipId).push(trim);
                } else {
                     cutsPerClip.set(clipId, [trim]);
                }
           }
        }

        for (let [clipId, cuts] of cutsPerClip) {
            const currentLayer = timeline.getLayerById(timeline.layersOrder[0]);
            let lastClip = timeline.getClipById(clipId);
            const clipList = [lastClip];
            for (let i = 0; i < cuts.length; i++) {
                const badClip = await currentLayer.splitClip(lastClip.id, cuts[i].startTime);
                lastClip = await currentLayer.splitClip(badClip.id, cuts[i].endTime);
                currentLayer.removeClip(badClip.id);
                clipList.push(lastClip);
            }
            for (let i = 0; i < clipList.length - 1; i++) {
                const currentClip = clipList[i];
                const nextClip = clipList[i + 1];
                nextClip.moveBy(currentClip.getRightBound() - nextClip.getLeftBound());
            }
        }

        setRemoveFillerWordsLoading(false);
        setRecalculateFillerWords(!recalculateFillerWords);
    }

    const paceSettingDescriptionMap = {
        0: "Keep all pauses in original recording.",
        1: "A smooth pace for long form content on YouTube, Spotify etc.",
        2: "An engaging pace for long form content on YouTube, Spotify etc.",
        3: "A face pace for short and long form content on YouTube, Instagram & TikTok.",
        4: "A very fast pace for short form content like YouTube Shorts, Reels and TikToks."
    }

    const paceSettingToTitle = (paceSetting) => paceSettingTitleMap[paceSetting];
    const paceSettingToDescription = (paceSetting) => paceSettingDescriptionMap[paceSetting];

    const EnhanceAudioButton = ({disabled}) => (
        <Box width="85px" justifyContent="center" alignItems="center">
            {track && track.enhancedAudioUrl === "placeholder" ?
                <Box width="85px" justifyContent="center" alignItems="center">
                    <HourglassEmpty sx={{color: "text.primary"}}/>
                </Box> :
                <Button variant="contained"
                        style={{borderRadius: "5px", height: "35px", width: "85px", backgroundColor: "#2B6BFD"}}
                        onClick={() => handleEnhanceAudioRequest(track.trackId)} disabled={disabled}>
                    <Typography color="white">Select</Typography>
                </Button>
            }
        </Box>
    );

    const enhanceAudioDisabled = track ? !track.hasAudioStream : true;
    const removeFillerWordsDisabled = noAudioStream;

    const setPaceIconAreaStyle = {
        display: 'flex',
        padding: '16px',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
    }

    const magicToolsBoxStyle = {
        cursor: 'pointer',
        position: 'absolute',
        zIndex: 9999,
        top: "70px",
        left: leftPosition,
        display: 'flex',
        width: '390px',
        padding: '16px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        borderRadius: '12px',
        background: 'var(--Basic-White, #FFF)',
        boxShadow: '0px 30px 80px 0px rgba(0, 0, 0, 0.15)',
    };

    const enhanceAudioBoxStyle = {
        cursor: 'pointer',
        position: 'absolute',
        zIndex: 9999,
        top: "70px",
        left: leftPosition,
        display: 'flex',
        width: '420px',
        height: "210px",
        padding: '16px',
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'flex-start',
        borderRadius: '12px',
        background: 'var(--Basic-White, #FFF)',
        gap: "16px"
    }

    const setPaceBoxStyle = {
        display: 'flex',
        padding: '16px',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
    };

    const setPaceTitleStyle = {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '8px',
        alignItems: 'center',
    }

    const setPaceTextAreaStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        width: "220px",
    };

    const setPaceDescriptionTextStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        height: '52px',
        overflow: 'hidden'
    }

    const featureNameStyle = {
        color: "#1A1A1A",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.14px",
        alignSelf: "flex-start"
    };

    const featureDescStyle = {
        alignSelf: "stretch",
        color: "#999",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "16px",
        letterSpacing: "0.48px",
    };

    const constrainedFeatureDescStyle = {
        alignSelf: "stretch",
        color: "#999",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "16px",
        letterSpacing: "0.48px",
        maxWidth: "240px",
        height: "40px",
    }

    const smallDescStyle = {
        alignSelf: "stretch",
        color: "#999",
        fontFamily: "Inter",
        fontSize: "9px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "16px",
        letterSpacing: "0.48px",
    };

    const dropdownStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    }

    const BackArrow = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 onClick={() => setShowSetPaceCard(false)}>
                <path d="M15 17L10 12L15 7" stroke="#1A1A1A" strokeWidth="1.5" strokeLinecap="square"
                      strokeLinejoin="round"/>
            </svg>
        );
    };

    const handleSliderChange = (event, newValue) => {
        setPaceSetting(newValue);
    };

    const marks = [
        {value: 0, label: ''},
        {value: 1, label: ''},
        {value: 2, label: ''},
        {value: 3, label: ''},
        {value: 4, label: ''},
    ];

    if (showEnhanceAudioCard) {
        return (
            <ClickAwayListener onClickAway={handleClose}>
                <Box sx={enhanceAudioBoxStyle}>
                    <Box sx={setPaceTitleStyle}>
                        Enhance Audio
                    </Box>
                    <Box sx={featureNameStyle}>
                        Select the category to apply the best audio enhancements to the clip.
                    </Box>
                    <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "30px"}}>
                        <Box sx={{display: "flex", flexDirection: "column", gap: "8px"}}>
                            <Box>
                                <FormControl sx={{width: "200px"}}>
                                    <Select
                                        sx={{width: "200px", height: "40px", zIndex: 999}}
                                        label={contentTypeTitles[enhanceAudioContentType]}
                                        variant="outlined"
                                        value={enhanceAudioContentType}
                                        onChange={(e) => setEnhanceAudioContentType(e.target.value)}
                                    >
                                        {Object.entries(contentTypeTitles).map(([key, title]) => (
                                            <MenuItem key={key} value={key}>
                                                {title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={constrainedFeatureDescStyle}>
                                {contentTypeDescriptions[enhanceAudioContentType]}
                            </Box>
                        </Box>
                        <Box>
                            <Button variant={"contained"} sx={{color: "#2b6bfd", height: "40px"}}>
                                <Typography sx={{color: "white"}}>Apply</Typography>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </ClickAwayListener>
        )
    }

    if (showSetPaceCard) {
        return (
            <ClickAwayListener onClickAway={handleClose}>
                <Box sx={magicToolsBoxStyle}>
                    <Box sx={setPaceTitleStyle}>
                        <BackArrow/>
                        <Box sx={featureNameStyle}>
                            Set Pace
                        </Box>
                    </Box>
                    <Box sx={setPaceBoxStyle}>
                        <Box sx={setPaceDescriptionTextStyle}>
                            <Box sx={featureNameStyle}>
                                {paceSettingToTitle(paceSetting)}
                            </Box>
                            <Box sx={featureDescStyle}>
                                {paceSettingToDescription(paceSetting)}
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{width: '100%', padding: '0 16px'}}>
                        <Slider
                            value={paceSetting}
                            onChange={handleSliderChange}
                            marks={marks}
                            step={1}
                            min={0}
                            max={4}
                            sx={{
                                '& .MuiSlider-mark': {
                                    backgroundColor: '#ffffff',
                                    height: 3,
                                    width: 3,
                                    borderRadius: '50%',
                                },
                                '& .MuiSlider-rail': {color: '#E8E8E8'},
                                '& .MuiSlider-track': {color: '#2B6BFD'},
                                '& .MuiSlider-thumb': {color: '#2B6BFD'},
                            }}
                        />
                    </Box>
                    <Box sx={{...setPaceBoxStyle, justifyContent: 'space-between', width: '100%'}}>
                        <Typography sx={featureDescStyle}>Original</Typography>
                        <Typography sx={featureDescStyle}>Very fast</Typography>
                    </Box>
                    <Box sx={{...setPaceBoxStyle, justifyContent: 'space-between', width: '100%'}}>
                        <Typography sx={featureDescStyle}>Remove pauses.</Typography>
                        <Button
                            variant="contained"
                            style={{
                                borderRadius: "10px",
                                height: "35px",
                                backgroundColor: '#1A1A1A',
                                color: '#FFF',
                                textTransform: 'none',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontWeight: 500,
                            }}
                        >
                            Apply
                        </Button>
                    </Box>
                </Box>
            </ClickAwayListener>
        );
    }

    const ForwardArrow = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10 17L15 12L10 7" stroke="#d7d7d7" stroke-width="1.5" stroke-linecap="square"
                      stroke-linejoin="round"/>
            </svg>
        )
    }

    const handleShowSetPaceCardClick = () => {
        // setShowSetPaceCard(true);
    }

    return (
        <>
            <ClickAwayListener onClickAway={handleClose}>
                <Box sx={magicToolsBoxStyle}>
                    <Box sx={setPaceBoxStyle}>
                        <Box sx={setPaceTextAreaStyle}>
                            <Box sx={featureNameStyle}>
                                Set Pace
                            </Box>
                            <Box sx={featureDescStyle}>
                                Remove pauses and improve speech flow.
                            </Box>
                        </Box>
                        <Box sx={setPaceIconAreaStyle} onClick={handleShowSetPaceCardClick}>
                            <ForwardArrow sx/>
                        </Box>
                    </Box>
                    <Box sx={setPaceBoxStyle}>
                        <Box sx={setPaceTextAreaStyle}>
                            <Box sx={featureNameStyle}>
                                Enhance Audio
                            </Box>
                            <Box sx={featureDescStyle}>
                                Noise reduction and other audio enhancements to a clip.
                            </Box>
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <Button variant="contained"
                                    style={{borderRadius: "5px", height: "35px", width: "85px"}}
                                    onClick={() => setShowEnhanceAudioCard(!showEnhanceAudioCard)}
                                    disabled={true}>
                                <Typography color="white">Select</Typography>
                            </Button>
                            {selectedClipId === null && <Box sx={smallDescStyle}>Select clip to enhance</Box>}
                        </Box>

                        {/*{track && track.hasEnhancedAudio ?*/}
                        {/*    <ToggleButton checked={enhancedAudioSelected}*/}
                        {/*                  onChange={(e) => handleEnhanceAudioChange(!enhancedAudioSelected)}/> :*/}
                        {/*    <EnhanceAudioButton disabled={enhanceAudioDisabled}/>}*/}
                    </Box>
                    <Box sx={setPaceBoxStyle}>
                        <Box sx={setPaceTextAreaStyle}>
                            <Box sx={featureNameStyle}>
                                Smooth Speech
                            </Box>
                            <Box sx={featureDescStyle}>
                                Remove filler words.
                                {fillerWordInstancesCount > 0 ? <Box
                                        sx={featureDescStyle}>{fillerWordInstancesCount} found, {fillerWordsTotalDuration}s total.</Box> :
                                    <Box sx={featureDescStyle}>No filler words found.</Box>}
                            </Box>
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <Button variant="contained"
                                    style={{borderRadius: "5px", height: "35px", width: "85px"}}
                                    onClick={() => handleRemoveFillerWords()}
                                    disabled={!fillerWordInstancesCount}>
                                {removeFillerWordsLoading ? <HourglassEmpty/> :
                                    <Typography color="white">Apply</Typography>}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </ClickAwayListener>
        </>
    )
}

export default ModernMagicToolsCard;