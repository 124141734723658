import {Label} from "../Label/Label";
import Input from "../Input/Input";
import ColorPicker from "../ColorPicker/ColorPicker";
import './ColorInput.styles.scss';

const ColorInput = ({ label, color, onChangeColor }) => {
    const handleChangeColor = (value) => {
        onChangeColor?.(value);
    };

    return (
        <div className="color-input">
            {label != null && <Label error={false}>{label}</Label>}

            <div className="color-input__content">
                <Input
                    value={color}
                    placeholder="Transparent"
                    onChangeText={handleChangeColor}
                />
                <ColorPicker
                    color={color}
                    onChangeColor={handleChangeColor}
                />
            </div>
        </div>
    );
};

export default ColorInput;