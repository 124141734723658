import React, { useState, useRef } from 'react';
import { Engine } from "@rendley/sdk";
import { CirclePlus } from "../../../../assets/icons/CirclePlus";
import { RendleyService } from "../../../../services/RendleyService";
import clsx from "clsx";
import { ApplicationStore } from "../../../../store/ApplicationStore";
import { RendleyStore } from "../../../../store/RendleyStore";
import { convertUnitsToTime } from "../../../../utils/dom/convertUnitsToTime";
import './EmptyTimelineButton.styles.scss';
import {SidebarStore} from "../../../../store/SidebarStore";

interface EmptyTimelineButtonProps {
    onUploadClip?: (event: React.DragEvent<HTMLDivElement>, mediaId: string) => void;
}

const EmptyTimelineButton: React.FC<EmptyTimelineButtonProps> = ({ onUploadClip }) => {

    const handleClickUpload = () => {
        if (SidebarStore.activeItemKey === null) {
            SidebarStore.setActiveItemKey("media");
        } else {
            SidebarStore.setActiveItemKey(null);
        }
    };

    return (
        <div
            className={clsx("empty-timeline-button", {
                "empty-timeline-button__dragging-over": false,
            })}
            onClick={handleClickUpload}
        >
            <CirclePlus />
            <p className="empty-timeline-button__text">Add media content</p>
        </div>
    );
};

export default EmptyTimelineButton;