import * as React from 'react';
import {useMaybeRoomContext} from "@livekit/components-react";
import {RoomEvent} from 'livekit-client';
import {useMediaDeviceSelect} from "@livekit/components-react";
import clsx from "clsx";
import './deviceMenu.css';
import {ClickAwayListener} from "@mui/material";
import {CheckmarkIcon} from "./SettingsIcon";
import {mergeProps} from "./MergeProps";

/** @public */
// export interface MediaDeviceSelectProps
// extends Omit<React.HTMLAttributes<HTMLUListElement>, 'onError'> {
//     kind: MediaDeviceKind;
//     onActiveDeviceChange?: (deviceId: string) => void;
//     onDeviceListChange?: (devices: MediaDeviceInfo[]) => void;
//     onDeviceSelectError?: (e: Error) => void;
//     initialSelection?: string;
//     /** will force the browser to only return the specified device
//      * will call `onDeviceSelectError` with the error in case this fails
//      */
//     exactMatch?: boolean;
//     track?: LocalAudioTrack | LocalVideoTrack;
//     /**
//      * this will call getUserMedia if the permissions are not yet given to enumerate the devices with device labels.
//      * in some browsers multiple calls to getUserMedia result in multiple permission prompts.
//      * It's generally advised only flip this to true, once a (preview) track has been acquired successfully with the
//      * appropriate permissions.
//      *
//      * @see {@link MediaDeviceMenu}
//      * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/enumerateDevices | MDN enumerateDevices}
//      */
//     requestPermissions?: boolean;
//     onError?: (e: Error) => void;
// }

/**
 * The `MediaDeviceSelect` list all media devices of one kind.
 * Clicking on one of the listed devices make it the active media device.
 *
 * @example
 * ```tsx
 * <LiveKitRoom>
 *   <MediaDeviceSelect kind='audioinput' />
 * </LiveKitRoom>
 * ```
 * @public
 */

export const NewMediaDeviceSelect = /* @__PURE__ */ (function NewMediaDeviceSelect(
    {
        kind,
        initialSelection,
        onActiveDeviceChange,
        onDeviceListChange,
        onDeviceSelectError,
        exactMatch,
        track,
        requestPermissions,
        onError,
        left,
        bottom,
        maybeRoom,
        setIsOpen,
        ...props
    },
) {
    const roomFromContext = useMaybeRoomContext();
    const room = maybeRoom ?? roomFromContext;
    const handleError = React.useCallback(
        (e) => {
            if (room) {
                // awkwardly emit the event from outside of the room, as we don't have other means to raise a MediaDeviceError
                room.emit(RoomEvent.MediaDevicesError, e);
            }
            onError?.(e);
        },
        [room, onError],
    );
    const {devices, activeDeviceId, setActiveMediaDevice, className} = useMediaDeviceSelect({
        kind,
        room,
        track,
        requestPermissions,
        onError: handleError,
    });
    React.useEffect(() => {
        if (initialSelection !== undefined) {
            setActiveMediaDevice(initialSelection);
        }
    }, [setActiveMediaDevice]);

    React.useEffect(() => {
        if (typeof onDeviceListChange === 'function') {
            onDeviceListChange(devices);
        }
    }, [onDeviceListChange, devices]);

    React.useEffect(() => {
        if (activeDeviceId && activeDeviceId !== '') {
            onActiveDeviceChange?.(activeDeviceId, false);
        }
    }, [activeDeviceId]);


    const handleActiveDeviceChange = async (deviceId) => {
        try {
            await setActiveMediaDevice(deviceId, {exact: exactMatch});
        } catch (e) {
            if (e instanceof Error) {
                onDeviceSelectError?.(e);
            } else {
                throw e;
            }
        }
    };

    const mergedProps = React.useMemo(
        () => mergeProps(props, {className}, {className: 'lk-list'}),
        [className, props],
    );

    function isActive(deviceId, activeDeviceId, index) {
        return deviceId === activeDeviceId || (index === 0 && activeDeviceId === 'default');
    }

    const listPositionStyle = {
        top: bottom ? bottom : 0,
        left: left,
        zIndex: 100,
        backgroundColor: 'white',
    }

    const listItemStyle = {
        display: 'flex',
        padding: "16px",
        alignItems: 'center',
        alignSelf: 'stretch',
        borderRadius: "8px",
    }

    const selectedListItemStyle = {
        display: 'flex',
        padding: "16px",
        alignItems: 'center',
        alignSelf: 'stretch',
        borderRadius: "8px",
        justifyContent: 'space-between',
        backgroundColor: '#eff4fe',
    }

    return (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <ul {...mergedProps} className="device-list" style={listPositionStyle}>
                {devices.map((device, index) => (
                <li
                key={device.deviceId}
                id={device.deviceId}
                style={isActive(device.deviceId, activeDeviceId, index) ? selectedListItemStyle : listItemStyle}
                role="option"
                >
                <button className="lk-button" onClick={() => handleActiveDeviceChange(device.deviceId)}>
                    {device.label}
                    {isActive(device.deviceId, activeDeviceId, index) && <CheckmarkIcon/>}
                </button>
            </li>
        ))}
        </ul>
     </ClickAwayListener>
);
});
