import { action, makeAutoObservable } from "mobx";
import { EffectDescription, FilterDescription, TitleDescription, TransitionDescription } from "../config/config";
import { MediaData } from "./RendleyStore";

export type ThemeVariants = "dark" | "light" | "system";

type DraggingObjectPayload =
  | {
      type: "media";
      payload: MediaData;
    }
  | {
      type: "text-preset";
      payload: TitleDescription;
    };

class ApplicationStoreInstance {
  isLoading: boolean = true;
  isRendering: boolean = false;
  selectedClipId: string | null = null;
  selectedTransitionId: string | null = null;
  transitions: Record<string, TransitionDescription> = {};
  filters: Record<string, FilterDescription> = {};
  effects: Record<string, EffectDescription> = {};
  draggingObject: DraggingObjectPayload | null = null;
  theme: ThemeVariants = "system";
  filtersPath: string | null = null;
  effectsPath: string | null = null;
  transitionsPath: string | null = null;
  titlesPath: string | null = null;
  subtitleStylesPath: string | null = null;

  constructor() {
    makeAutoObservable(this, {
      setIsLoading: action,
      setIsRendering: action,
      setSelectedClipId: action,
      setSelectedTransitionId: action,
      setDraggingObject: action,
      setTheme: action,
      setFiltersPath: action,
      setTitlesPath: action,
      setEffectsPath: action,
      setTransitionsPath: action,
      setTransitions: action,
      setFilters: action,
      setEffects: action,
    });
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setIsRendering(value: boolean) {
    this.isRendering = value;
  }

  setSelectedClipId(id: string | null) {
    this.selectedClipId = id;
    this.setSelectedTransitionId(null);
  }

  setSelectedTransitionId(id: string | null) {
    this.selectedTransitionId = id;
  }

  setSubtitleStylesPath(path: string | null) {
    this.subtitleStylesPath = path;
  }

  setDraggingObject(draggingObject: DraggingObjectPayload | null) {
    this.draggingObject = draggingObject;
  }

  setTheme(value: ThemeVariants = "dark") {
    if (value === "system") {
      ApplicationStore.theme = "light";
    } else {
      ApplicationStore.theme = "light";
    }
  }

  setFiltersPath(path: string | null) {
    this.filtersPath = path;
  }

  setEffectsPath(path: string | null) {
    this.effectsPath = path;
  }

  setTransitionsPath(path: string | null) {
    this.transitionsPath = path;
  }

  setTitlesPath(path: string | null) {
    this.titlesPath = path;
  }

  setTransitions(transitions: Record<string, TransitionDescription>) {
    this.transitions = transitions;
  }

  setFilters(filters: Record<string, FilterDescription>) {
    this.filters = filters;
  }

  setEffects(effects: Record<string, EffectDescription>) {
    this.effects = effects;
  }
}

const ApplicationStore = new ApplicationStoreInstance();

export { ApplicationStore };
