import React, { useEffect } from 'react';

const PrivacyPolicy = () => {

    useEffect(() => {

        // Function to dynamically load the Termly script
        const loadTermlyScript = () => {
            const scriptId = 'termly-jssdk';
            if (document.getElementById(scriptId)) {
                return; // Script already loaded
            }
            const script = document.createElement('script');
            script.id = scriptId;
            script.src = "https://app.termly.io/embed-policy.min.js";
            document.body.appendChild(script);
        };

        loadTermlyScript();

    }, []);

    return (
        <div>
            <div name="termly-embed" data-id="cf768e6e-bdbf-4e0d-80a9-a681d87d3458" style={{backgroundColor: "white"}}></div>
        </div>
    );
};

export default PrivacyPolicy;