import React, {useState, useEffect} from 'react';
import {Box, Tabs, Tab, Typography, LinearProgress, Button, ButtonGroup} from '@mui/material';
import {VideoPlayer} from "../../../components/VideoPlayer";
import {NavbarContainer} from "../../../components/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";

const tabData = [
    {
        title: 'Remote Recording',
        subtext: 'For podcasts, interviews and meetings',
        video: 'https://stream.mux.com/Ni6Vo3onPWVa6JJsi9RsV402iXN4ga8mPgSQuDwZUEN00.m3u8',
        poster: "https://image.mux.com/Ni6Vo3onPWVa6JJsi9RsV402iXN4ga8mPgSQuDwZUEN00/thumbnail.png?time=0&width=728&height=410"
        // Replace with actual video URL or source
    },
    {
        title: 'Screen Recording',
        subtext: 'With camera video and microphone audio overlay',
        video: 'https://stream.mux.com/fyOijASbltpDsbOyHEB01HJAlEBSCTJt01O9Jwhoaus01A.m3u8',
        poster: "https://image.mux.com/fyOijASbltpDsbOyHEB01HJAlEBSCTJt01O9Jwhoaus01A/thumbnail.png?width=728&height=410&time=7",
    },
    {
        title: 'AI editing tools',
        subtext: 'Powerful AI tools to make editing simple',
        video: "https://stream.mux.com/5LJ0157Y4tTOBCjyO2cZKkPhAf0100Wh9wkAVLdVLmbwLE.m3u8",
        poster: "https://image.mux.com/5LJ0157Y4tTOBCjyO2cZKkPhAf0100Wh9wkAVLdVLmbwLE/thumbnail.png?width=728&height=410&time=5"
    },
    // {
    //     title: 'One-click sharing',
    //     subtext: 'Seamless sharing of content with a single click',
    //     video: 'https://stream.mux.com/01202S3cNid2Jimgi2RY2CpNQvDdep4WxhN9swrfMxC4Y.m3u8',
    //     poster: 'https://image.mux.com/01202S3cNid2Jimgi2RY2CpNQvDdep4WxhN9swrfMxC4Y/thumbnail.png?width=214&height=121&time=1',
    // },
];

const HeroSectionCarousel = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [progress, setProgress] = useState(0);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('smd'));

    const timeout = isSmallScreen ? 3000 : 6000;

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveTab((prev) => (prev + 1) % tabData.length);
        }, timeout);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const progressInterval = setInterval(() => {
            setProgress((prev) => (prev >= 100 ? 0 : prev + 20));
        }, 1000);
        return () => clearInterval(progressInterval);
    }, [activeTab]);

    const handleButtonClick = (index) => {
        setActiveTab(index);
        setProgress(0);
    };

    const flashingDotStyle = {
        animation: 'flashing 1s infinite',
        '@keyframes flashing': {
            '0%, 100%': {opacity: 1},
            '50%': {opacity: 0},
        },
        height: '10px',
        width: '10px',
        backgroundColor: 'red',
        borderRadius: '50%',
        display: 'inline-block',
    };

    const editingDotStyle = {
        animation: 'editingFlashing 1s infinite',
        '@keyframes editingFlashing': {
            '0%, 100%': {opacity: 1},
            '50%': {opacity: 0},
        },
        height: '10px',
        width: '10px',
        backgroundColor: '#2b6bfd', // Blue color for editing
        borderRadius: '50%',
        display: 'inline-block',
    };

    if (isSmallScreen) {
        return (<Box sx={{width: '100%', padding: '5px', display: 'flex', justifyContent: 'center', flexDirection: "column"}}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '12px',
                }}
            >
                {tabData.map((tab, index) => (
                    <Button
                        key={index}
                        onClick={() => handleButtonClick(index)}
                        sx={{
                            minWidth: '150px',
                            borderRadius: '8px',
                            hover: {backgroundColor: 'transparent'},
                            padding: '8px 16px',
                            textTransform: 'none',
                            display: isSmallScreen ? activeTab === index ? 'block' : 'none' : 'block',
                        }}
                        variant={'text'}
                    >
                        <Box>
                            <Typography sx={{
                                fontWeight: activeTab === index ? 'bold' : 'normal',
                                color: "black",
                                fontSize: activeTab === index ? '22px' : '18px'
                            }}>
                                {tab.title}
                            </Typography>
                        </Box>
                    </Button>
                ))}
            </Box>

            {/* Content area for showing videos */}
            <Box sx={{
                mt: 2,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: "column",
                alignItems: "center"
            }}>
                <Box
                    sx={{
                        width: {xs: '95%', sm: '80%', md: '70%', lg: '800px'},
                        height: {xs: '230px', md: '451px'}, // Auto height to keep aspect ratio
                    }}
                >
                    {isSmallScreen ?
                        <img src={tabData[activeTab].poster} width="100%" height="100%" style={{width: '100%', borderRadius: "20px", height: '100%'}} />
                        : <VideoPlayer
                        src={tabData[activeTab].video}
                        poster={tabData[activeTab].poster}
                        width="100%"
                        height="100%"
                        autoplay={true}
                        controls={false}
                        loop={true}
                        muted={true}
                        cover={true}
                        style={{width: '100%', height: '100%'}}
                    />}
                </Box>
                {(activeTab === 0 || activeTab === 1) && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: 'red',
                            fontWeight: 'bold',
                            mt: 1, // Add margin to separate from video
                        }}
                    >
                        <Box sx={flashingDotStyle}></Box>
                        <Typography variant="h6" sx={{marginLeft: '8px'}}>
                            REC
                        </Typography>
                    </Box>
                )}
                {(activeTab === 2) && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: 'red',
                            fontWeight: 'bold',
                            mt: 1, // Add margin to separate from video
                        }}
                    >
                        <Box sx={editingDotStyle}></Box>
                        <Typography variant="h6" sx={{marginLeft: '8px', color: "#2b6bfd"}}>
                            EDIT
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>)
    }

    return (
        <Box sx={{width: '100%', padding: '10px', display: 'flex', justifyContent: 'center'}}>
            <Box
                sx={{
                    background: '#edf1f7',
                    borderRadius: '12px',
                    maxWidth: isSmallScreen ? '100%' : '1200px',
                    width: '100%',
                    padding: '20px',
                    overflow: 'hidden',
                    paddingTop: "40px",
                }}
            >
                {/* Tabs for selecting different sections */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '12px',
                        mb: 3,
                    }}
                >
                    {tabData.map((tab, index) => (
                        <Button
                            key={index}
                            onClick={() => handleButtonClick(index)}
                            sx={{
                                minWidth: '150px',
                                borderRadius: '8px',
                                hover: {backgroundColor: 'transparent'},
                                padding: '8px 16px',
                                textTransform: 'none',
                                display: isSmallScreen ? activeTab === index ? 'block' : 'none' : 'block',
                            }}
                            variant={'text'}
                        >
                            <Box>
                                <Typography sx={{
                                    fontWeight: activeTab === index ? 'bold' : 'normal',
                                    color: "black",
                                    fontSize: activeTab === index ? '22px' : '18px'
                                }}>
                                    {tab.title}
                                </Typography>
                            </Box>
                        </Button>
                    ))}
                </Box>

                {/* Content area for showing videos */}
                <Box sx={{
                    mt: 4,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                    <Box
                        sx={{
                            width: {xs: '90%', sm: '80%', md: '70%', lg: '800px'},
                            height: {xs: '220px', md: '451px'}, // Auto height to keep aspect ratio
                        }}
                    >
                        <VideoPlayer
                            src={tabData[activeTab].video}
                            poster={tabData[activeTab].poster}
                            width="100%"
                            height="100%"
                            autoplay={true}
                            controls={false}
                            loop={true}
                            muted={true}
                            cover={true}
                            style={{width: '100%', height: '100%'}}
                        />
                    </Box>
                    {(activeTab === 0 || activeTab === 1) && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: 'red',
                                fontWeight: 'bold',
                                mt: 1, // Add margin to separate from video
                            }}
                        >
                            <Box sx={flashingDotStyle}></Box>
                            <Typography variant="h6" sx={{marginLeft: '8px'}}>
                                REC
                            </Typography>
                        </Box>
                    )}
                    {(activeTab === 2) && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: 'red',
                                fontWeight: 'bold',
                                mt: 1, // Add margin to separate from video
                            }}
                        >
                            <Box sx={editingDotStyle}></Box>
                            <Typography variant="h6" sx={{marginLeft: '8px', color: "#2b6bfd"}}>
                                EDIT
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default HeroSectionCarousel;
