import React, { useEffect, useState } from 'react';
import { ClipTypeEnum } from "@rendley/sdk";
import { reaction, IReactionDisposer } from "mobx";
import { observer } from "mobx-react-lite";
import { RendleyStore } from "../../../../store/RendleyStore";
import { ApplicationStore } from "../../../../store/ApplicationStore";
import EditTextPanel from "../../../edit-panel/containers/EditTextPanel/EditTextPanel";
import EditLottiePanel from "../EditLottiePanelContainer/EditLottiePanelContainer";

interface EditPanelContainerProps {
    onClose?: () => void;
}

const EditPanelContainer: React.FC<EditPanelContainerProps> = observer(({ onClose }) => {
    const [selectedClip, setSelectedClip] = useState<{ id: string; type: ClipTypeEnum } | null>(null);

    useEffect(() => {
        const updateSelectedClip = (clipId: string | null) => {
            if (!clipId) {
                setSelectedClip(null);
                return;
            }

            const type = RendleyStore.clips[clipId].type;

            setSelectedClip({
                id: clipId,
                type,
            });
        };

        // Initial setup
        updateSelectedClip(ApplicationStore.selectedClipId);

        // Setup reaction
        const disposeReaction: IReactionDisposer = reaction(
            () => ApplicationStore.selectedClipId,
            (clipId) => updateSelectedClip(clipId)
        );

        // Cleanup
        return () => {
            disposeReaction();
        };
    }, []);

    if (!selectedClip) return null;

    switch (selectedClip.type) {
        // case ClipTypeEnum.VIDEO:
        //   return <ve-edit-video-panel />;
        // case ClipTypeEnum.IMAGE:
        //   return <ve-edit-image-panel />;
        case ClipTypeEnum.TEXT:
            return <EditTextPanel clipId={selectedClip.id} />;
        case ClipTypeEnum.LOTTIE:
            return <EditLottiePanel clipId={selectedClip.id} />;
        // case ClipTypeEnum.AUDIO:
        //   return <ve-edit-audio-panel />;
        // case GIF!?
        default:
            return null;
    }
});

export default EditPanelContainer;