import { ApplicationStore } from "../../../../store/ApplicationStore";
import { convertTimeToUnits } from "../../../../utils/dom/convertTimeToUnits";

function useCreateDropClipPlaceholder(placeholder?: () => HTMLElement | null | undefined) {
    const handleDragEnter = (event: DragEvent) => {
        // event;

        console.log("Drag enter")

        const placeholderRef = placeholder?.();

        if (!placeholderRef) {
            return;
        }

        const draggingObject = ApplicationStore.draggingObject;

        if (draggingObject == null) {
            return;
        }

        if (draggingObject.type !== "media" && draggingObject.type !== "text-preset") {
            return;
        }

        const duration = draggingObject?.payload?.duration ?? 1;
        const durationPx = convertTimeToUnits(duration);

        placeholderRef.style.visibility = "visible";
        placeholderRef.style.width = `${durationPx}px`;
    };

    const handleDragOver = (event: DragEvent) => {
        const placeholderRef = placeholder?.();

        if (!placeholderRef) {
            return;
        }

        placeholderRef.style.left = `${event.offsetX}px`;
    };

    const handleDragLeave = (event: DragEvent) => {
        // event;

        const placeholderRef = placeholder?.();

        if (!placeholderRef) {
            return;
        }

        placeholderRef.style.visibility = "hidden";
    };

    return { onDragEnter: handleDragEnter, onDragOver: handleDragOver, onDragLeave: handleDragLeave };
}

export { useCreateDropClipPlaceholder };
