import React, {useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import {Avatar, Button, ButtonBase, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import {RedoButton, ShowHideVideoButton, ToggleCaptionsButton, UndoButton} from "../../components/CoolButtons";
import {Avatars} from "../Editor/Avatars";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import MagicToolsCard from "../Editor/MagicToolsCard";
import {FetchContext} from "../../context/FetchContext";
import {ClipSize16And9RatioId, ClipSize1And1RatioId} from "../../utils/utils";
import {Crop169, CropPortrait, CropSquare} from "@mui/icons-material";
import ClipSizeCard from "../Editor/ClipSizeCard";

const AccountIconButton = ({handleClick}) => {
    return (
        <IconButton onClick={handleClick} sx={{ fontSize: '1.5rem', position: 'relative' }}>
            <Avatar sx={{
                width: '2rem',
                height: '2rem',
                fontSize: '1.2rem',
                backgroundColor: 'primary.main',
                position: 'relative',
                fontWeight: 600,
            }}>
                <AccountCircleIcon sx={{
                    width: '2rem',
                    height: '2rem',
                    fontSize: '1.5rem',
                    position: 'relative',
                    backgroundColor: 'primary.main',
                    fontWeight: 600,
                }}/>
            </Avatar>
        </IconButton>
    );
}

const AvatarMenu = ({open, anchorEl, handleClose, handleClick}) => {

    const navigate = useNavigate();

    const navigateToLanding = () => {
        navigate('/');
    }

    return (
        <>
            <Tooltip title="Menu">
                <AccountIconButton handleClick={handleClick}/>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 3,
                    sx: {
                        overflow: 'visible',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                        borderRadius: '10px',
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <MenuItem>
                    <Link
                        underline="none"
                        onClick={navigateToLanding}
                        color="text.primary"
                    >
                        Create account
                    </Link>
                </MenuItem>
            </Menu>
        </>
    )
}

const GuestEditorTopbar = ({selectedTrackIndex, trackSettings, videoPlayerVisible, setVideoPlayerVisible, handleRemoveFillerWordsRequest, captionsEnabled, setCaptionsEnabled, track, token,  clipSize, setClipSize, loadTrack}) => {

    const theme = useTheme();
    const { mode } = theme.palette;

    const fetchContext = useContext(FetchContext);

    const [showMagicToolsCard, setShowMagicToolsCard] = useState(false);
    const [showClipSizeCard, setShowClipSizeCard] = useState(false);
    const [magicToolsLeftPosition, setMagicToolsLeftPosition] = useState(0);
    const [magicToolsTopPosition, setMagicToolsTopPosition] = useState(0);

    const [clipSizeLeftPosition, setClipSizeLeftPosition] = useState(0);
    const [clipSizeTopPosition, setClipSizeTopPosition] = useState(0);

    const [captionsButtonRightPosition, setCaptionsButtonRightPosition] = useState(0);
    const [captionsButtonTopPosition, setCaptionsButtonTopPosition] = useState(0);
    const [reloadMagicTools, setReloadMagicTools] = useState(false);

    const handleMagicToolsButtonClick = (event) => {
        const buttonRect = event.target.getBoundingClientRect();
        const left = buttonRect.left;
        const top = buttonRect.top;
        setMagicToolsLeftPosition(left);
        setMagicToolsTopPosition(top);
        setShowClipSizeCard(false);
        setShowMagicToolsCard(true);
    }

    const magicToolsButtonDisabled = track === null || track === undefined;

    const handleClipSizeButtonClick = (event) => {
        const buttonRect = event.target.getBoundingClientRect();
        const left = buttonRect.left;
        const top = buttonRect.top;
        setClipSizeTopPosition(top);
        setClipSizeLeftPosition(left);
        setShowMagicToolsCard(false);
        setShowClipSizeCard(true);
    }

    const handleCaptionsButtonClick = (event) => {
        const buttonRect = event.target.getBoundingClientRect();
        const right = buttonRect.right;
        const top = buttonRect.top;
        setCaptionsButtonRightPosition(right);
        setCaptionsButtonTopPosition(top);
        setCaptionsEnabled(!captionsEnabled);
    }

    const handleEnhanceAudioRequest = async (trackId) => {
        const response = await fetchContext.authAxios.post(`/shared/enhance/${trackId}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        setReloadMagicTools(!reloadMagicTools);
        loadTrack({trackId});
    }

    const handleShowHideVideo = () => {
        setVideoPlayerVisible(!videoPlayerVisible);
    }

    const ClipSizeIcon = ({clipSize}) => {
        if (clipSize === ClipSize16And9RatioId) {
            return <Crop169/>
        }
        if (clipSize === ClipSize1And1RatioId) {
            return <CropSquare/>
        }
        return <CropPortrait/>
    }

    const ClipSizeText = ({clipSize}) => {
        if (clipSize === ClipSize16And9RatioId) {
            return "16:9"
        }
        if (clipSize === ClipSize1And1RatioId) {
            return "1:1"
        }
        return "9:16"
    }

    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={1}
            position={'relative'}
        >
            {/* Wrap the first two Boxes in another Box */}
            <Box
                display={'flex'}
                alignItems={'center'}
            >
                <Box
                    display={'flex'}
                    component={'a'}
                    href="/"
                    title="Libretto"
                    width={{ xs: 100, md: 120 }}
                >
                    <Box
                        component={'img'}
                        src={
                            mode === 'light'
                                ? '/logo-dark.svg'
                                : '/logo.svg'
                        }
                        height={1}
                        width={1}
                    />
                </Box>
            </Box>
            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={1}
                flexGrow={1} // Allows it to expand and take available space
            >
                <Button variant="contained" onClick={handleMagicToolsButtonClick} startIcon={<AutoAwesomeIcon/>} disabled={magicToolsButtonDisabled} style={{borderRadius: '5px', height: '35px'}}>
                    Magic Tools
                </Button>
                {showMagicToolsCard && <MagicToolsCard key={reloadMagicTools} handleRemoveFillerWordsRequest={handleRemoveFillerWordsRequest} selectedTrackindex={selectedTrackIndex} handleClose={() => setShowMagicToolsCard(false)} trackSettings={trackSettings} handleEnhanceAudioRequest={handleEnhanceAudioRequest} track={track} leftPosition={magicToolsLeftPosition} topPosition={magicToolsTopPosition} guestEditor={true}/>}
                <Button variant="contained" onClick={handleClipSizeButtonClick} startIcon={<ClipSizeIcon clipSize={clipSize}/>} disabled={true} style={{borderRadius: '5px', height: '35px', width: '100px'}}>
                    {ClipSizeText({clipSize})}
                </Button>
                {showClipSizeCard && <ClipSizeCard guestEditor={false} clipSize={clipSize} setClipSize={setClipSize} topPosition={clipSizeTopPosition} leftPosition={clipSizeLeftPosition} handleClose={() => setShowClipSizeCard(false)}/> }
                {/*<UndoButton disabled={true}/>*/}
                {/*<RedoButton disabled={true}/>*/}
            </Box>
            <Box display={'flex'} alignItems={'center'}>
                <Box marginLeft={2} marginTop={0.5}>
                    <Avatars/>
                </Box>
                <Box marginLeft={1} marginTop={0.5}>
                    {videoPlayerVisible && <ToggleCaptionsButton handleClick={handleCaptionsButtonClick} isCaptionsVisible={captionsEnabled} rightPosition={captionsButtonRightPosition} topPosition={captionsButtonTopPosition}/> }
                </Box>
                <Box marginLeft={1} marginTop={0.5}>
                    <ShowHideVideoButton isVideoVisible={videoPlayerVisible} handleClick={handleShowHideVideo}/>
                </Box>
            </Box>
        </Box>
    );
};

export default GuestEditorTopbar;
