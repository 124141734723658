import Box from '@mui/material/Box';
import {ButtonBase, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import {
    DialogContent,
} from "@mui/material";
import LoginForm from "../pages/Landing/Components/LoginForm";
import RegisterForm from "../pages/Landing/Components/RegisterForm";
import SignUpMessage from "../pages/Landing/Components/SignUpMessage";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {LibrettoPlan} from "../utils/utils";
import {useNavigate} from "react-router-dom";


export const GetStartedTopButton = ({handleSignupFormOpen}) => {

    const boxStyle = {
        display: "flex",
        flexDirection: "row",
        height: "40px",
        padding: "10px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "6px",
        backgroundColor: "#2B6BFD",
        cursor: "pointer",
        // Set hovered state
        '&:hover': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.15)0%, rgba(0, 0, 0, 0.15)100%), #2B6BFD;',
        },
    }

    const textStyle = {
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.14px",
        color: "white",
    }

    return (
        <Box style={boxStyle} onClick={handleSignupFormOpen}>
            <Box style={textStyle}>
                Get Started
            </Box>
        </Box>
    )
}

export const LoginButton = ({handleLoginFormOpen}) => {

    const boxStyle = {
        display: "flex",
        flexDirection: "row",
        height: "40px",
        padding: "10px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "6px",
        backgroundColor: "white",
        border: "1px solid #2B6BFD",
        cursor: "pointer",
        // Set hovered state
        "&:hover": {
            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.10)0%, rgba(255, 255, 255, 0.10)100%), #E3E5E8",
        },
        // Set focused state
        "&:focus": {
            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.10)0%, rgba(255, 255, 255, 0.10)100%), #E3E5E8"
        }

    }

    const textStyle = {
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.14px",
        color: "#2B6BFD",
    }

    return (
        <Box style={boxStyle} onClick={handleLoginFormOpen}>
            <Box style={textStyle}>
                Log in
            </Box>
        </Box>
    )
}

export const NewNavbar = ({selectedPricingOption, signUpStateMessage, signUpStateMessageOpen, handleSignupStateMessageClose, signinWithGoogle, signupWithGoogle, loginFormOpen, handleLoginFormOpen, handleLoginFormClose, signupFormOpen, handleSignupFormOpen, handleSignupFormClose, signUpWithEmailAndPassword, signinWithEmail, hideSignupButton, pageEnum, sendPasswordResetEmailForUser}) => {

    const theme = useTheme();
    const { mode } = theme.palette;

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const closeSignupOpenLogin = () => {
        handleSignupFormClose();
        handleLoginFormOpen();
    }

    const navigate = useNavigate();

    const navigateToHome = () => {
        navigate("/");
    }

    const navigateToAbout = () => {
        navigate("/about");
    }

    const navigateToPricing = () => {
        navigate("/pricing");
    }

    return (
        <>
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                width={1}
                paddingBottom={1}
                paddingTop={2}
            >
                <ButtonBase>
                    <Box
                        display={'flex'}
                        component="a"
                        href="/"
                        title="Libretto"
                        width={{ xs: 100, md: 120 }}
                    >
                        <Box
                            component={'img'}
                            src={'/logo-new.svg'}
                            height={1}
                            width={1}
                        />
                    </Box>
                </ButtonBase>
                <Box display={'flex'} alignItems={'center'}>
                    <Box marginLeft={2}>
                        <LoginButton handleLoginFormOpen={handleLoginFormOpen}/>
                        <Dialog
                            open={loginFormOpen}
                            onClose={handleLoginFormClose}
                            aria-labelledby="form-dialog-title"
                            sx={{
                                '& .MuiDialog-paper': { // Target the Paper component inside the Dialog
                                    borderRadius: '20px', // Apply your desired borderRadius
                                },
                            }}
                        >
                            <DialogContent sx={{borderRadius: '30px',
                                display: 'flex',        // Enable flexbox
                                justifyContent: 'center', // Center horizontally
                                alignItems: 'center',     // Center vertically
                                minWidth: { xs: '300px', md: '400px' },
                            }}>
                                <LoginForm signinWithGoogle={signinWithGoogle} handleLoginFormClose={handleLoginFormClose} signinWithEmail={signinWithEmail} handleSignUpFormOpen={handleSignupFormOpen} sendPasswordResetEmailForUser={sendPasswordResetEmailForUser}/>
                            </DialogContent>
                        </Dialog>
                    </Box>
                    <Box marginLeft={2}>
                        {!hideSignupButton && <GetStartedTopButton handleSignupFormOpen={handleSignupFormOpen}/>}
                        <Dialog
                            open={signupFormOpen}
                            onClose={handleSignupFormClose}
                            aria-labelledby="form-dialog-title"
                            sx={{
                                '& .MuiDialog-paper': { // Target the Paper component inside the Dialog
                                    borderRadius: '20px', // Apply your desired borderRadius
                                },
                            }}
                        >
                            <DialogContent sx={{borderRadius: '30px',
                                display: 'flex',        // Enable flexbox
                                justifyContent: 'center', // Center horizontally
                                alignItems: 'center',     // Center vertically
                                minWidth: { xs: '300px', md: '400px' },
                            }}>
                                <RegisterForm selectedPricingOption={selectedPricingOption} signupWithGoogle={signupWithGoogle} closeSignupOpenLogin={closeSignupOpenLogin} signUpWithEmailAndPassword={signUpWithEmailAndPassword}/>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={signUpStateMessageOpen}
                            onClose={handleSignupStateMessageClose}
                            aria-labelledby="form-dialog-title"
                        >
                            <DialogContent sx={{borderRadius: '30px',
                                display: 'flex',        // Enable flexbox
                                justifyContent: 'center', // Center horizontally
                                alignItems: 'center',     // Center vertically
                                width: { xs: '300px', md: '400px' },
                            }}>
                                <SignUpMessage signUpStateMessage={signUpStateMessage}/>
                            </DialogContent>
                        </Dialog>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

const Navbar = ({selectedPricingOption, signUpStateMessage, signUpStateMessageOpen, handleSignupStateMessageClose, signinWithGoogle, signupWithGoogle, loginFormOpen, handleLoginFormOpen, handleLoginFormClose, signupFormOpen, handleSignupFormOpen, handleSignupFormClose, signUpWithEmailAndPassword, signinWithEmail, hideSignupButton, sendPasswordResetEmailForUser}) => {

    const theme = useTheme();
    const { mode } = theme.palette;

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const closeSignupOpenLogin = () => {
        handleSignupFormClose();
        handleLoginFormOpen();
    }

    const scrollToPricing = () => {
        const section = document.getElementById('pricing-section');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleGetStartedButtonClick = () => {
        handleSignupFormOpen({LibrettoPlan: LibrettoPlan.StarterPaidAnnual, Code: ""})
    }

    return (
        <>
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={1}
            paddingBottom={1}
            paddingTop={2}
        >
            <ButtonBase>
                <Box
                    display={'flex'}
                    component="a"
                    href="/"
                    title="Libretto"
                    width={{ xs: 100, md: 120 }}
                >
                    <Box
                        component={'img'}
                        src={
                            mode === 'light'
                                ? '/logo-dark.svg'
                                : '/logo.svg'
                        }
                        height={1}
                        width={1}
                    />
                </Box>
            </ButtonBase>
            <Box display={'flex'} alignItems={'center'}>
                <Box marginLeft={2}>
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{minWidth: '80px', height: '35px', borderRadius: '20px'}}
                        onClick={handleLoginFormOpen}
                    >
                        <Typography fontSize="0.9rem" fontWeight={500}>Log in</Typography>
                    </Button>
                    <Dialog
                        open={loginFormOpen}
                        onClose={handleLoginFormClose}
                        aria-labelledby="form-dialog-title"
                        sx={{
                            '& .MuiDialog-paper': { // Target the Paper component inside the Dialog
                                borderRadius: '20px', // Apply your desired borderRadius
                            },
                        }}
                    >
                        <DialogContent sx={{borderRadius: '30px',
                            display: 'flex',        // Enable flexbox
                            justifyContent: 'center', // Center horizontally
                            alignItems: 'center',     // Center vertically
                            minWidth: { xs: '300px', md: '400px' },
                        }}>
                            <LoginForm signinWithGoogle={signinWithGoogle} handleLoginFormClose={handleLoginFormClose} signinWithEmail={signinWithEmail} handleSignUpFormOpen={handleSignupFormOpen}/>
                        </DialogContent>
                    </Dialog>
                </Box>
                <Box marginLeft={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{minWidth: '80px', height: '35px', borderRadius: '20px'}}
                        onClick={handleGetStartedButtonClick}
                        disabled={hideSignupButton}
                    >
                        <Typography fontSize="0.9rem" fontWeight={500}>Get Started</Typography>
                    </Button>
                    <Dialog
                        open={signupFormOpen}
                        onClose={handleSignupFormClose}
                        aria-labelledby="form-dialog-title"
                        sx={{
                            '& .MuiDialog-paper': { // Target the Paper component inside the Dialog
                                borderRadius: '20px', // Apply your desired borderRadius
                            },
                        }}
                    >
                        <DialogContent sx={{borderRadius: '30px',
                            display: 'flex',        // Enable flexbox
                            justifyContent: 'center', // Center horizontally
                            alignItems: 'center',     // Center vertically
                            minWidth: { xs: '300px', md: '400px' },
                        }}>
                            <RegisterForm selectedPricingOption={selectedPricingOption} signupWithGoogle={signupWithGoogle} closeSignupOpenLogin={closeSignupOpenLogin} signUpWithEmailAndPassword={signUpWithEmailAndPassword}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={signUpStateMessageOpen}
                        onClose={handleSignupStateMessageClose}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogContent sx={{borderRadius: '30px',
                            display: 'flex',        // Enable flexbox
                            justifyContent: 'center', // Center horizontally
                            alignItems: 'center',     // Center vertically
                            width: { xs: '300px', md: '400px' },
                        }}>
                            <SignUpMessage signUpStateMessage={signUpStateMessage}/>
                        </DialogContent>
                    </Dialog>
                </Box>
            </Box>
        </Box>
        </>
    );
};

export default Navbar;
