import React, {useEffect, useState} from "react";
import {
    Card,
    CardActions,
    CardContent,
    ClickAwayListener, FormControl,
    IconButton, InputLabel, List, Select,
    Stack,
    Switch
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import getTheme from "../../theme";
import {ThemeProvider} from "@mui/material/styles";
import {useDarkMode} from "../../components/Page";

const FourKOptions = [
    {text: '720p', enabled: true, value: '720p'},
    {text: '1080p HD', enabled: true, value: '1080p'},
    {text: '2160p 4K', enabled: true, value: '4K'},
];

const AudioExportOptions = [
    {text: 'WAV HD', enabled: true, value: "WAV"},
    {text: 'MP3', enabled: true, value: "MP3"},
];

const HDOptions = [
    '720p',
    '1080p HD',
];

const SDOptions = [
    '720p',
];

const VideoQualityMenu = ({selectedOptionValue, setSelectedOptionValue, options}) => {

    const negate = (value) => !value;

    return (
        <FormControl sx={{ width: '150px' }} id="videoQualitySelect">
            <Select
                value={selectedOptionValue}
                onChange={(event) => setSelectedOptionValue(event.target.value)}
                sx={{
                    color: 'white', // Color of the text in the select box
                    backgroundColor: 'black', // Background color of the select box
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white', // Border color of the select box
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white', // Border hover color
                    },
                    '& .MuiSelect-icon': {
                        color: 'white' // Color of the dropdown icon
                    },
                    height: '35px', // Set the height of the select box
                    '& .MuiSelect-select': {
                        padding: '8px 14px', // Adjust padding to fit the desired height
                    },
                }}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            bgcolor: 'rgba(0, 0, 0, 0.9)', // Background color of the dropdown
                            color: 'white', // Text color for dropdown options
                            borderRadius: '10px', // Rounded corners
                        }
                    }
                }}
            >
                {options.map((option, index) => (
                    <MenuItem
                        key={index}
                        value={option.value}
                        disabled={negate(option.enabled)}
                        sx={{
                            height: '35px', // Set the height of each dropdown item
                            minHeight: '35px', // Ensure minimum height is 35px
                            padding: '8px 14px', // Adjust padding to fit the desired height
                        }}
                    >
                        {option.text}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}


const SelectExportTypeTabs = React.memo( ({ exportVideo, setExportVideo}) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Button
                variant={exportVideo ? 'contained' : 'outlined'}
                onClick={() => setExportVideo(true)}
                sx={{width: 140, borderRadius: '5px', height: '35px'}}
            >
                <Typography variant="subtitle1">Video</Typography>
            </Button>
            <Button
                variant={exportVideo === false ? 'contained' : 'outlined'}
                onClick={() => setExportVideo(false)}
                sx={{width: 140, borderRadius: '5px', height: '35px'}}
            >
                <Typography variant="subtitle1">Audio</Typography>
            </Button>
        </Box>
    );
});

export const ExportCard = ({ handleExportRequest, handleClose, normalizeAudio, setNormalizeAudio, applyCaptions, setApplyCaptions, exportVideo, setExportVideo, videoQuality, setVideoQuality, audioQuality, setAudioQuality}) => {
    const options = FourKOptions;

    const [themeMode, themeToggler, mountedComponent] = useDarkMode();

    const ClickAwayHandler = (event) => {
        const cardRect = {
            top: 70,
            right: 15,
            width: 350,
            height: 290
        };

        // Calculate the left boundary of the card
        const cardLeft = window.innerWidth - cardRect.right - cardRect.width;
        const cardBottom = cardRect.top + cardRect.height;

        // Get the click coordinates
        const { clientX, clientY } = event;

        // Check if the click is inside the card's bounding box
        if (clientX >= cardLeft && clientX <= (cardLeft + cardRect.width) &&
            clientY >= cardRect.top && clientY <= cardBottom) {
            // If the click is inside the card, do not close it
            return;
        }

        handleClose();
    }

    return (
        <ThemeProvider theme={getTheme('dark', themeToggler)}>
        <ClickAwayListener onClickAway={ClickAwayHandler}>
            <Card
                elevation={5}
                style={{ cursor: 'pointer', position: 'absolute',
                    top: 70,
                    right: 15,
                    width: 350,
                    backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark background
                    borderRadius: '16px', // Rounded corners
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)', overflow: 'visible', height: 310 }}
            >
                <CardContent>
                    <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8 }} variant="contained">
                        <CloseIcon style={{fontSize: "1.0rem", color: "white"}}/>
                    </IconButton>
                    <Typography variant="h6" marginBottom={2} color="white" fontWeight={700}>
                        Export Options
                    </Typography>
                    <SelectExportTypeTabs exportVideo={exportVideo} setExportVideo={setExportVideo}/>
                    <br/>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" marginBottom={1}>
                        <Typography variant="subtitle2" color="text.secondary" fontSize="1.0rem" fontWeight={700}>
                            {exportVideo ? "Video Quality" : "Audio Quality"}
                        </Typography>
                        {exportVideo ? <VideoQualityMenu options={options} selectedOptionValue={videoQuality} setSelectedOptionValue={setVideoQuality}/> : <VideoQualityMenu options={AudioExportOptions} selectedOptionValue={audioQuality} setSelectedOptionValue={setAudioQuality}/>}
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" marginBottom={0}>
                        <Typography variant="subtitle2" color="text.secondary" fontSize="1.0rem" fontWeight={700}>Normalize audio levels</Typography>
                        <Switch
                            checked={normalizeAudio}
                            onChange={() => setNormalizeAudio(!normalizeAudio)}
                            sx={{
                                color: 'white', // color when off
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: 'white', // color of the thumb when on
                                },
                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: 'white', // color of the track when on
                                },
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Stack>
                    <Box height={'30px'}>
                        {exportVideo && <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" marginBottom={0}>
                        <Typography variant="subtitle2" color="text.secondary" fontSize="1.0rem" fontWeight={700}>Apply captions</Typography>
                        <Switch
                            checked={exportVideo ? applyCaptions : false}
                            onChange={() => setApplyCaptions(!applyCaptions)}
                            disabled={!exportVideo}
                            sx={{
                                color: 'white', // color when off
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: 'white', // color of the thumb when on
                                },
                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: 'white', // color of the track when on
                                },
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Stack>}
                    </Box>
                </CardContent>
                <CardActions style={{ marginTop: '-10px', paddingLeft: '12px', justifyContent: 'space-between'}}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => handleExportRequest()}
                        style={{ height: '40px', borderRadius: '10px' }} // Adjust button colors as needed
                    >
                        <Typography fontWeight={600}>
                            {exportVideo ? "Export Video" : "Export Audio"}
                        </Typography>
                    </Button>
                </CardActions>
            </Card>
        </ClickAwayListener>
        </ThemeProvider>
    );
};
