
export const ScissorsIcon = () => {
    return (
        <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM5.413 5.414 8 8M13.333 2.667l-7.92 7.92M4 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM9.867 9.867l3.466 3.466"
                stroke="currentColor"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};