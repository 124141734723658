import {useEffect, useRef, useState} from "react";
import {DropdownArrowIcon} from "../NewStudio/SettingsIcon";
import {CameraDisabledIcon, CameraIcon, MicDisabledIcon} from "@livekit/components-react";
import MicIcon from "@mui/icons-material/Mic";
import Box from "@mui/material/Box";
import * as React from "react";
import {mergeProps} from "../NewStudio/MergeProps";
import {useCustomTrackToggle} from "../NewStudio/useCustomTrackToggle";
import {Track} from "livekit-client";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


const EnhancedCustomSelect = ({ options, value, onChange, enabled, isCamera, label, setEnabled, disabled }) => {
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Button styles with disabled adjustments
    const buttonStyle = {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 18px',
        justifyContent: 'center',
        gap: '10px',
        width: {md: "112px", lg: "179px", xl: "179px"},
        height: {lg: "39px", xl: "45px"},
        backgroundColor: disabled ? '#d3d3d3' : '#f3f4f5',
        cursor: disabled ? 'not-allowed' : 'pointer',
        borderRadius: '5px 0 0 5px',
        border: 'none',
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: disabled ? '#d3d3d3' : '#e5e5e5', // Darker primary color when not disabled
        },
    };

    const dropdownButtonStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 18px',
        height: {lg: "39px", xl: "45px"},
        backgroundColor: disabled ? '#d3d3d3' : '#e8e8e8',
        cursor: disabled ? 'not-allowed' : 'pointer',
        borderRadius: '0 5px 5px 0',
        border: 'none',
    };

    const listStyle = {
        position: 'absolute',
        bottom: '100%',
        zIndex: 100,
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
        width: '300px',
        maxHeight: '300px',
        overflowY: 'auto',
        right: '0',
        marginBottom: '10px',
    };

    const listItemStyle = {
        display: 'flex',
        padding: '16px',
        alignItems: 'center',
        cursor: 'pointer',
    };

    const selectedListItemStyle = {
        ...listItemStyle,
        backgroundColor: '#eff4fe',
    };

    // Prevent any action if disabled is true
    const handleMainButtonClick = () => {
        if (!disabled) {
            setEnabled(!enabled);
        }
    };

    const handleDropdownToggle = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    };

    return (
        <div ref={containerRef} style={{ display: 'flex', position: 'relative' }}>
            <Box onClick={handleMainButtonClick} sx={buttonStyle}>
                {isCamera ?
                    enabled ? <CameraIcon style={{ marginRight: '5px' }} /> :
                        <CameraDisabledIcon style={{ marginRight: '5px' }} /> :
                    enabled ? <MicIcon style={{ marginRight: '5px' }} /> :
                        <MicDisabledIcon style={{ marginRight: '5px' }} />}
                {label}
            </Box>
            <Box onClick={handleDropdownToggle} sx={dropdownButtonStyle}>
                <DropdownArrowIcon />
            </Box>
            {isOpen && !disabled && (
                <div ref={dropdownRef} style={listStyle}>
                    {options.map((option) => (
                        <div
                            key={option.value}
                            style={option.value === value ? selectedListItemStyle : listItemStyle}
                            onClick={() => {
                                onChange(option.value);
                                setIsOpen(false);
                            }}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export const VideoInputSelector = ({ setEnabled, enabled, onDeviceChange, options, disabled }) => {
    const [selectedDevice, setSelectedDevice] = useState('default');

    const handleDeviceChange = (deviceId) => {
        setSelectedDevice(deviceId);
        onDeviceChange(deviceId);
    };

    return (
        <EnhancedCustomSelect
            options={options}
            value={selectedDevice}
            enabled={enabled}
            onChange={handleDeviceChange}
            isCamera={true}
            label="Camera"
            setEnabled={setEnabled}
            disabled={disabled}
        />
    );
};

export const AudioInputSelector = ({ setEnabled, enabled, onDeviceChange, options, disabled }) => {
    const [selectedDevice, setSelectedDevice] = useState('default');

    const handleDeviceChange = (deviceId) => {
        setSelectedDevice(deviceId);
        onDeviceChange(deviceId);
    };

    return (
        <EnhancedCustomSelect
            options={options}
            value={selectedDevice}
            enabled={enabled}
            onChange={handleDeviceChange}
            isCamera={false}
            label="Mic"
            setEnabled={setEnabled}
            disabled={disabled}
        />
    );
};

const ShareScreenIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
            <path d="M6.5 9L6.5 3" stroke="#1A1A1A" strokeWidth="1.28571" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M3.5 6L6.5 3L9.5 6" stroke="#1A1A1A" strokeWidth="1.28571" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>
    );
};

const textStyle = {
    fontFamily: "Inter",
    fontSize: {md: "9px", lg: "11px", xl: "14px"},
    fontStyle: "normal",
    fontWeight: {md: "350", lg: "400", xl: "500"},
    lineHeight: {md: "13px", lg: "15px", xl: "20px"},
    letterSpacing: {md: "0.1px" , lg:"0.1px", xl: "0.14px"},
    color: "#1A1A1A",
    // disable highlight on double click
    WebkitTouchCallout: "none",
    WebkitUserSelect: "none",
    KhtmlUserSelect: "none",
    MozUserSelect: "none",
    msUserSelect: "none",
}

export const ScreenShareButton = ({enabled, handleClick}) => {

    const buttonStyle = {
        display: "flex",
        padding: '10px 18px',
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        cursor: "pointer",
        backgroundColor: "#f3f4f5",
        width: {md: "112px", lg: "179px", xl: "179px"},
        height: {lg: "39px", xl: "45px"},
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#e5e5e5', // Darker primary color
        },
        // 5px border radius on the top left and bottom left corners
        borderRadius: "5px 5px 5px 5px",
    }

    const enabledButtonStyle = {
        ...buttonStyle,
        backgroundColor: "#e5e5e5",
        border: "1.5px solid #2B6BFD",
    }

    const effectiveStyle = enabled ? enabledButtonStyle : buttonStyle;

    const shareScreenBoxStyle = {
        width: "20px",
        height: "15px",
        padding: "1px",
        flexShrink: 0,
        borderRadius: "3px",
        border: "1.5px solid #1A1A1A",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }

    const theme = useTheme();

    const isLg = useMediaQuery(theme.breakpoints.up("lg"));

    const buttonText = (enabled) => {
        if (enabled) {
            return isLg ? <div sx={textStyle}>Stop sharing</div> : <div sx={textStyle}>Stop</div>;
        }
        return isLg ? <div sx={textStyle}>Share screen</div> : <div sx={textStyle}>Screen</div>;
    }

    return (
        <Box flexDirection="row" display="flex">
            <Box onClick={handleClick} sx={effectiveStyle} alignItems="center" justifyContent="center" flexDirection="row">
                <div style={shareScreenBoxStyle}>
                    {<ShareScreenIcon/>}
                </div>
                {buttonText(enabled)}
            </Box>
        </Box>
    )
}