import {useMaybeRoomContext} from "@livekit/components-react";
import {setupMediaToggle} from "@livekit/components-core";
import {setupManualToggle} from "@livekit/components-core";
import {useCustomObservableState} from "./useCustomObservableState";
import {log} from "@livekit/components-core";
import {mergeProps} from "./MergeProps";
import {useCallback, useEffect, useMemo, useRef} from "react";

export function useCustomTrackToggle({
    source,
    onChange,
    initialState,
    captureOptions,
    publishOptions,
    onDeviceError,
    maybeRoom,
    ...rest
}) {
    const roomFromContext = useMaybeRoomContext();
    const room = maybeRoom ?? roomFromContext;
    const track = room?.localParticipant?.getTrackPublication(source);
    /** `true` if a user interaction such as a click on the TrackToggle button has occurred. */
    const userInteractionRef = useRef(false);

    const { toggle, className, pendingObserver, enabledObserver } = useMemo(
        () =>
            room
                ? setupMediaToggle(source, room, captureOptions, publishOptions, onDeviceError)
                : setupManualToggle(),
        [room, source, JSON.stringify(captureOptions), publishOptions],
    );

    const pending = useCustomObservableState(pendingObserver, false);
    const enabled = useCustomObservableState(enabledObserver, initialState ?? !!track?.isEnabled);

    useEffect(() => {
        onChange?.(enabled, userInteractionRef.current);
        userInteractionRef.current = false;
    }, [enabled, onChange]);

    useEffect(() => {
        if (initialState !== undefined) {
            log.debug('forcing initial toggle state', source, initialState);
            toggle(initialState);
        }
        // only execute once at the beginning
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const newProps = useMemo(() => mergeProps(rest, { className }), [rest, className]);

    const clickHandler = useCallback(
        (evt) => {
            userInteractionRef.current = true;
            toggle().finally(() => (userInteractionRef.current = false));
            rest.onClick?.(evt);
        },
        [rest, toggle],
    );

    return {
        toggle,
        enabled,
        pending,
        track,
        buttonProps: {
            ...newProps,
            'aria-pressed': enabled,
            'data-lk-source': source,
            'data-lk-enabled': enabled,
            disabled: pending,
            onClick: clickHandler,
        },
    };
}
