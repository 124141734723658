import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from "wavesurfer.js/plugins/regions";
import '../../main.css';
import {useTheme} from "@mui/material/styles";
import {LibrettoLiveblocksContext} from "./LibrettoLiveblocksContext";
import {createSaveTrim, createUndoFillerWordRemovalTrim, createUndoTrim} from "./Liveblocks";
import TimelineContextMenu from "./EditorComponents/TimelineContextMenu";

const MarkRegionsForEdits = ({trims, waveSurferRegionsRef}) => {
    ClearEditedRegions(waveSurferRegionsRef);

    if (!trims || trims.length === 0) {
        return 0;
    }

    let removedRegionsDuration = 0;
    for (let trim of trims) {
        removedRegionsDuration += trim.endTime - trim.startTime;
        waveSurferRegionsRef.current.addRegion({
            start: trim.startTime,
            end: trim.endTime,
            color: 'rgba(0, 0, 0, 0.7)',
            drag: false,
            resize: false,
            id: "edit"
        });
    }
    return removedRegionsDuration;
}

const ClearEditedRegions = (waveSurferRegionsRef) => {
    const regionsPlugin = waveSurferRegionsRef.current;
    if (regionsPlugin === null || regionsPlugin === undefined) {
        return;
    }
    for (let region of regionsPlugin.getRegions()) {
        if (region.id === "edit") {
            region.remove();
        }
    }
}

const ClearDraggableRegion = (waveSurferRegionsRef) => {
    const regionsPlugin = waveSurferRegionsRef.current;
    for (let region of regionsPlugin.getRegions()) {
        if (region.id === "draggable") {
            region.remove();
        }
    }
}

const TimelineWaveform = forwardRef(({track, orderedTrackIndex, enhancedAudioSelected, predecodedBuffer, enhancedPredecodedBuffer, handleTimeUpdate, isPlaying, width, selected, setSelectedTrackIndex, handleClick, handleTrackFinishedPlaying, trims, videoPlayerRef, editorRef}, ref) => {
    const containerRef = useRef();
    const waveSurferRef = useRef();
    const waveSurferRegionsRef = useRef();
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [showRestore, setShowRestore] = useState(false);
    const [trimToRestore, setTrimToRestore] = useState(null);

    const liveblocksContext = useContext(LibrettoLiveblocksContext);

    const saveTrim = createSaveTrim(liveblocksContext);

    const undoTrim = createUndoTrim(liveblocksContext);
    const undoFillerRemovalTrim = createUndoFillerWordRemovalTrim(liveblocksContext);

    const theme = useTheme();
    const { mode } = theme.palette;

    if (waveSurferRegionsRef.current) {
        MarkRegionsForEdits({trims, waveSurferRegionsRef});
    }

    const determinePeaksForWavesurfer = ({track, predecodedBuffer, enhancedPredecodedBuffer, enhancedAudioSelected}) => {
        if (track.hasAudioStream === null || track.hasAudioStream === undefined || !track.hasAudioStream) {
            // Create a buffer of zeros based on the track duration
            const length = Math.ceil(Number(track.duration) * 40);
            return new Float32Array(length);
        }

        if (enhancedAudioSelected && enhancedPredecodedBuffer) {
            return enhancedPredecodedBuffer;
        }

        return predecodedBuffer;
    }

    const handleMainViewClick = (event) => {
        event.preventDefault();

        const bbox = containerRef.current.getBoundingClientRect();
        const x = event.clientX - bbox.left;

        let showCut = false;
        for (let region of waveSurferRegionsRef.current.getRegions()) {
            if (region.id === "draggable") {
                showCut = true;
            }
        }

        if (!showRestore && !showCut) {
            setMenuVisible(false);
            return;
        }

        setMenuVisible(!menuVisible);
        setMenuPosition({ x: x, y: event.clientY });
    }

    useEffect(() => {

        const waveSurfer = WaveSurfer.create({
            container: containerRef.current,
            scrollParent: false,
            autoScroll: true,
            hideScrollbar: selected ? false : true,
            partialRender: true,
            fillParent: true,
            pixelRatio: 1,
            normalize: false,
            progressColor: mode === 'dark' ? '#fff' : '#0d47a1',
            waveColor: mode === 'dark' ? '#fff' : '#0d47a1',
            autoCenter: true,
            height: 90,
            barHeight: 0.8,
            cursorWidth: selected ? 3 : 0,
            cursorColor: 'rgba(255, 0, 0, 0.7)',
            media: selected ? document.getElementById('editor-video-player') : null,
            width: width ? width : '33vw',
            duration: Number(track.duration),
            peaks: determinePeaksForWavesurfer({track, predecodedBuffer, enhancedPredecodedBuffer, enhancedAudioSelected}),
        });

        waveSurfer.on('decode', () => {
            if (selected) {
                const slider = document.querySelector('input[type="range"]')

                slider.addEventListener('input', (e) => {
                    const minPxPerSec = e.target.valueAsNumber
                    waveSurfer.zoom(minPxPerSec)
                })
            }
        })

        waveSurfer.on('init', () => {
            waveSurferRef.current = waveSurfer;
            waveSurferRegionsRef.current = waveSurfer.registerPlugin(RegionsPlugin.create());

            MarkRegionsForEdits({trims, waveSurferRegionsRef});

            if (selected) {
                waveSurferRegionsRef.current.enableDragSelection({
                    color: 'rgba(255, 0, 0, 0.1)',
                    id: "draggable",
                });
            }
        });

        waveSurfer.on('ready', () => {
            MarkRegionsForEdits({trims, waveSurferRegionsRef});
            if (selected && isPlaying) {
                waveSurfer.play();
            }
        });

        const onAudioProcess = (time) => {
            let timeToSubtract = 0;
            if (trims) {
                for (let trim of trims) {
                    if (time > trim.endTime) {
                        timeToSubtract += trim.endTime - trim.startTime;
                        continue
                    }
                    if (time > trim.startTime && time < trim.endTime) {
                        timeToSubtract += time - trim.startTime;
                        waveSurferRef.current.setTime(trim.endTime);
                        if (selected) {
                            handleTimeUpdate(trim.endTime, timeToSubtract, orderedTrackIndex);
                        }
                        return;
                    }
                }
            }
            if (selected) {
                handleTimeUpdate(time, timeToSubtract, orderedTrackIndex);
            }
        }

        waveSurfer.on('audioprocess', onAudioProcess);

        return () => {
            waveSurfer.un('audioprocess', onAudioProcess);
            waveSurfer.destroy();
        };
    }, [track, predecodedBuffer, selected, enhancedAudioSelected, enhancedPredecodedBuffer]);

    const UpdateVideoPlayerTime = (time) => {
        if (videoPlayerRef.current) {
            videoPlayerRef.current.seekTo(time, orderedTrackIndex);
        }
    }

    const UpdateTextEditorTime = (time) => {
        if (editorRef.current) {
            editorRef.current.seekTo(time, orderedTrackIndex);
        }
    }

    useEffect(() => {

        if (!waveSurferRef.current) {
            return;
        }

        MarkRegionsForEdits({trims, waveSurferRegionsRef});

        const onClick = (x, y) => {
            setMenuVisible(false)
            ClearDraggableRegion(waveSurferRegionsRef);
            const duration = waveSurferRef.current.getDuration() * x;
            let timeToSubtract = 0;

            if (trims) {
                for (let trim of trims) {
                    if (duration >= trim.startTime && duration <= trim.endTime) {
                        timeToSubtract += trim.endTime - trim.startTime;
                        const midPoint = (trim.startTime + trim.endTime) / 2;
                        if (duration < midPoint) {
                            const widthParentComponent = containerRef.current.getBoundingClientRect().width;
                            setShowRestore(true);
                            setTrimToRestore(trim);
                            setMenuVisible(true);
                            setMenuPosition({ x: x * widthParentComponent, y: y });
                            waveSurferRef.current.seekTo(trim.startTime / waveSurferRef.current.getDuration());
                            timeToSubtract -= trim.endTime - trim.startTime;
                            handleTimeUpdate(trim.startTime, timeToSubtract, orderedTrackIndex);
                            UpdateVideoPlayerTime(trim.startTime);
                            // UpdateTextEditorTime(trim.startTime);
                            return;
                        } else {
                            const widthParentComponent = containerRef.current.getBoundingClientRect().width;
                            setShowRestore(true);
                            setTrimToRestore(trim);
                            setMenuVisible(true);
                            setMenuPosition({ x: x * widthParentComponent, y: y });
                            waveSurferRef.current.seekTo(trim.endTime / waveSurferRef.current.getDuration());
                            handleTimeUpdate(trim.endTime, timeToSubtract, orderedTrackIndex);
                            UpdateVideoPlayerTime(trim.endTime);
                            // UpdateTextEditorTime(trim.endTime);
                            return;
                        }
                    } else if (duration > trim.endTime) {
                        timeToSubtract += trim.endTime - trim.startTime;
                        continue
                    }
                }
            }
            handleTimeUpdate((waveSurferRef.current.getDuration() * x), timeToSubtract, orderedTrackIndex);
            UpdateVideoPlayerTime(waveSurferRef.current.getDuration() * x);
            // UpdateTextEditorTime(waveSurferRef.current.getDuration() * x);
        }

        waveSurferRef.current.on('click', onClick);

        const onAudioProcess = (time) => {
            let timeToSubtract = 0;
            if (trims) {
                for (let trim of trims) {
                    if (time > trim.endTime) {
                        timeToSubtract += trim.endTime - trim.startTime;
                        continue
                    }
                    if (time > trim.startTime && time < trim.endTime) {
                        timeToSubtract += time - trim.startTime;
                        waveSurferRef.current.setTime(trim.endTime);
                        handleTimeUpdate(trim.endTime, timeToSubtract, orderedTrackIndex);
                        return;
                    }
                }
            }
            handleTimeUpdate(time, timeToSubtract, orderedTrackIndex);
        }

        waveSurferRef.current.un('audioprocess', onAudioProcess);
        waveSurferRef.current.on('audioprocess', onAudioProcess);
        waveSurferRef.current.on('finish', () => {
            waveSurferRef.current.setTime(0);
            handleTrackFinishedPlaying(orderedTrackIndex);
        });

        return () => {
            if(waveSurferRef.current) {
                waveSurferRef.current.un('click', onClick);
                waveSurferRef.current.un('audioprocess', onAudioProcess);
                waveSurferRef.current.unAll();
            }
        };
    }, [waveSurferRef.current, waveSurferRegionsRef.current, trims, selected]);

    useImperativeHandle(ref, () => ({
        setTime: (time) => {
            if (waveSurferRef.current) {
                waveSurferRef.current.setTime(time);
            }
        },
        togglePlayPause: () => {
            if (waveSurferRef.current) {
                return waveSurferRef.current.playPause();
            }
        },
        getDuration: () => {
            if (!waveSurferRef.current) {
                return 0;
            }
            const removedDuration = MarkRegionsForEdits({trims, waveSurferRegionsRef});
            return Number(track.duration) - removedDuration;
        },
    }));

    const handleCutAction = () => {
        setMenuVisible(false);
        if (waveSurferRegionsRef.current === null || waveSurferRef.current === null) {
            return;
        }
        const regionsPlugin = waveSurferRegionsRef.current;
        for (let region of regionsPlugin.getRegions()) {
            if (region.id === "draggable") {
                saveTrim({startTime: region.start, endTime: region.end}, orderedTrackIndex);
                region.remove();
            }
        }
    }

    const handleRestoreAction = () => {
        setMenuVisible(false);
        setShowRestore(false);
        if (trimToRestore === null) {
            return;
        }
        undoTrim({startTime: trimToRestore.startTime, endTime: trimToRestore.endTime}, orderedTrackIndex);
        undoFillerRemovalTrim({startTime: trimToRestore.startTime, endTime: trimToRestore.endTime}, orderedTrackIndex);
    }

    return (
                <div ref={containerRef} onContextMenu={handleMainViewClick} style={{width: '100%', height: '100%'}}>
                    {selected && menuVisible && <TimelineContextMenu restoreEnabled={showRestore} top={100} left={menuPosition.x} closeCard={() => setMenuVisible(false)} handleCut={handleCutAction} handleRestore={handleRestoreAction}/>}
                </div>
    );
});

export default TimelineWaveform;
