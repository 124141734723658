/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Link, useNavigate} from 'react-router-dom';
import MyGoogleIcon from "../../../assets/MyGoogleIcon";
import {ButtonBase} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import {useFormik} from "formik";
import * as yup from "yup";


const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    email: yup
        .string()
        .trim()
        .email('Please enter a valid email address')
        .required('Email is required.'),
    password: yup
        .string()
        .required('Please specify your password')
        .min(8, 'The password should have at minimum length of 8'),
});

const RegisterForm = ({selectedPricingOption, signupWithGoogle, closeSignupOpenLogin, signUpWithEmailAndPassword}) => {
    const theme = useTheme();
    const { mode } = theme.palette;

    const initialValues = {
        name: '',
        email: '',
        password: '',
    };

    const registerWithGoogle = () => {
        signupWithGoogle({pricingOption: selectedPricingOption});
    }


    const onSubmit = (values) => {
        signUpWithEmailAndPassword({displayName: values.name, email: values.email, password: values.password, pricingOption: selectedPricingOption})
    };

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit,
    });

    return (
        <Box sx={{ width: '90%' }}>
            {/* Logo Section */}
            <Box sx={{ marginBottom: 5 }}>
                <ButtonBase>
                    <Box
                        display={'flex'}
                        component="a"
                        href="/"
                        title="Libretto"
                        width={{ xs: 150, md: 180 }} // Increased size values
                        height={{ xs: 'auto', md: 'auto' }} // Set height to auto for maintaining aspect ratio
                        alignItems="center" // To center the image vertically if needed
                        justifyContent="center" // To center the image horizontally if needed
                    >
                        <Box
                            component={'img'}
                            src={'/logo-new.svg'}
                            height={1}
                            width={1}
                        />
                    </Box>
                </ButtonBase>
            </Box>

            {/* Text Section */}
            <Box sx={{marginBottom: theme.spacing(3)}}>
                <Typography variant="h4" sx={{fontWeight: 800, marginBottom: theme.spacing(1)}}>
                    Create account
                </Typography>
                <Typography variant="subtitle2">
                    Already have one?{' '}
                    <ButtonBase onClick={closeSignupOpenLogin} style={{textDecoration: 'none'}} onMouseEnter={(e) => e.target.style.textDecoration = 'underline'} onMouseLeave={(e) => e.target.style.textDecoration = 'none'}>
                        <Typography variant="subtitle2" component="span"
                                    style={{color: "#1a1a1a", marginTop:"1px", fontWeight: 'bold'}}>
                            Log in
                        </Typography>
                    </ButtonBase>
                </Typography>

                {/* Google Sign-In Button */}
                <Button
                    onClick={registerWithGoogle}
                    startIcon={<MyGoogleIcon/>}
                    variant="contained" // Changed to 'contained' for more emphasis
                    color="primary" // Use the theme's primary color
                    fullWidth
                    sx={{
                        height: 40,
                        backgroundColor: "#2B6BFD",
                        marginY: theme.spacing(2), // Use theme spacing
                        boxShadow: mode === 'light' ? theme.shadows[1] : 'none', // Conditional shadow
                    }}
                >
                    Continue with Google
                </Button>
                <form onSubmit={formik.handleSubmit}>
                    <Box display="flex" alignItems="center" marginY={theme.spacing(2)}>
                        <Box flex="1" height="1px" bgcolor="divider" />
                        <Typography
                            sx={{
                                marginX: theme.spacing(2),
                                fontWeight: 600,
                            }}
                        >
                            Or
                        </Typography>
                        <Box flex="1" height="1px" bgcolor="divider" />
                    </Box>

                    <Typography variant={'subtitle2'} fontWeight={600}>
                        Name
                    </Typography>
                    <TextField
                        variant="outlined"
                        name={'name'}
                        fullWidth
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        style={{height: '40px', marginBottom: '20px'}}
                        InputProps={{style: {height: '40px'}}}
                    />
                    <Typography variant={'subtitle2'} fontWeight={600}>
                        Email
                    </Typography>
                    <TextField
                        variant="outlined"
                        name={'email'}
                        fullWidth
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        style={{height: '40px', marginBottom: '20px'}}
                        InputProps={{style: {height: '40px'}}}
                    />
                    <Typography variant={'subtitle2'} fontWeight={600}>
                        Password
                    </Typography>
                    <TextField
                        variant="outlined"
                        name={'password'}
                        type={'password'}
                        fullWidth
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        style={{height: '40px', marginBottom: '15px'}}
                        InputProps={{style: {height: '40px'}}}
                    />
                    <Button variant={'contained'} type={'submit'} color="primary" fullWidth sx={{
                        height: '40px',
                        marginBottom: '10px',
                        backgroundColor: "#2B6BFD",
                        marginTop: '15px',
                        boxShadow: mode === 'light' ? theme.shadows[1] : 'none'
                    }}
                            InputProps={{style: {height: '40px'}}} onClick={onSubmit}>
                        <Typography>Create</Typography>
                    </Button>
                </form>
                    {/* Optional: Add more login options here */}
            </Box>

            {/* Additional Info or Links */}
            <Box>
                {/* You can add terms and conditions or other relevant links here */}
            </Box>
        </Box>
    );
};

export default RegisterForm;
