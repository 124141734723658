import Moveable from "moveable";

import { COMPOSITION_CONTAINER_ID } from "../../../config/config";

interface InteractiveElementListeners {
    onRotate?: (rotation: number) => void;
    onDrag?: (x: number, y: number) => void;
    onScale?: (deltaWidth: number, deltaHeight: number) => void;
    onResize?: (deltaWidth: number, deltaHeight: number) => void;
    dragEnd?: () => void;
    rotateEnd?: () => void;
    resizeEnd?: () => void;
    scaleEnd?: () => void;
    onUpdate?: () => void;
}

export class InteractiveElement {
    private moveable: Moveable | null = null;
    private element: HTMLElement;
    private listeners?: InteractiveElementListeners;

    constructor(element: HTMLElement, listeners?: InteractiveElementListeners) {
        this.element = element;
        this.listeners = listeners;

        this.init();
    }

    init() {
        const compositionContainer = document.getElementById(COMPOSITION_CONTAINER_ID) as HTMLElement | null;

        if (!compositionContainer) {
            return;
        }

        this.moveable = new Moveable(compositionContainer, {
            target: this.element,
            className: "visibility-hidden-important",
            draggable: true,
            resizable: true,
            rotatable: true,
            keepRatio: true,
            scalable: true,
            throttleResize: 0,
            throttleDrag: 0,
            throttleRotate: 0,
            throttleScale: 0,
            origin: false,
        });

        this.listeners?.onUpdate?.();

        this.moveable.on("drag", (event) => {
            const {
                translate: [x, y],
            } = event;

            this.listeners?.onDrag?.(x, y);
            this.listeners?.onUpdate?.();
        });

        this.moveable.on("resize", (event) => {
            const [deltaWidth, deltaHeight] = event.delta;
            const [directionX, directionY] = event.direction;
            // directionX;

            if (directionY != 0 && this.listeners?.onScale != null) {
                this.listeners?.onScale?.(deltaWidth, deltaHeight);
            } else {
                this.listeners?.onResize?.(deltaWidth, deltaHeight);
            }

            this.listeners?.onUpdate?.();
        });

        this.moveable.on("rotate", (event) => {
            const rotation = event.rotation * (Math.PI / 180);

            this.listeners?.onRotate?.(rotation);
            this.listeners?.onUpdate?.();
        });

        this.moveable.on("dragEnd", () => this.listeners?.dragEnd);
        this.moveable.on("scaleEnd", () => this.listeners?.scaleEnd);
        this.moveable.on("rotateEnd", () => this.listeners?.rotateEnd);
        this.moveable.on("resizeEnd", () => this.listeners?.resizeEnd);
    }

    showHandlers() {
        if (this.moveable == null) {
            return;
        }

        this.moveable.className = "visibility-visible-important";
        this.element.style.display = "block";
    }

    hideHandlers() {
        if (this.moveable == null) {
            return;
        }

        this.moveable.className = "visibility-hidden-important";
        this.element.style.display = "none";
    }

    destroy() {
        if (this.moveable != null) {
            this.moveable.destroy();
        }
    }

    updateStyle(style: Partial<CSSStyleDeclaration>) {
        Object.assign(this.element.style, style);
        this.moveable?.updateRect();
    }
}
