import Box from "@mui/material/Box";
import {ClickAwayListener, LinearProgress} from "@mui/material";

const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
    flexDirection: "row",
    gap: "8px",
}

const textStyle = {
    color: "#1A1A1A",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: "0.14px",
}

const iconStyle = {
    width: "24px", height: "24px", marginLeft: "auto", cursor: "pointer",
}

const InfoIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                d="M10.0003 18.3333C5.39783 18.3333 1.66699 14.6025 1.66699 9.99996C1.66699 5.39746 5.39783 1.66663 10.0003 1.66663C14.6028 1.66663 18.3337 5.39746 18.3337 9.99996C18.3337 14.6025 14.6028 18.3333 10.0003 18.3333ZM10.0003 16.6666C11.7684 16.6666 13.4641 15.9642 14.7144 14.714C15.9646 13.4638 16.667 11.7681 16.667 9.99996C16.667 8.23185 15.9646 6.53616 14.7144 5.28591C13.4641 4.03567 11.7684 3.33329 10.0003 3.33329C8.23222 3.33329 6.53652 4.03567 5.28628 5.28591C4.03604 6.53616 3.33366 8.23185 3.33366 9.99996C3.33366 11.7681 4.03604 13.4638 5.28628 14.714C6.53652 15.9642 8.23222 16.6666 10.0003 16.6666V16.6666ZM9.16699 5.83329H10.8337V7.49996H9.16699V5.83329ZM9.16699 9.16663H10.8337V14.1666H9.16699V9.16663Z"
                fill="#2B6BFD"/>
        </svg>
    )
}

const CloseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M17 7L7 17" stroke="#1A1A1A" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7 7L17 17" stroke="#1A1A1A" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

const CheckIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M15 6.97913L8.125 13.8541L5 10.7291" stroke="#2B6BFD" strokeWidth="1.5" stroke-linecap="square"
                  stroke-linejoin="round"/>
        </svg>
    )
}

export const UploadSuccessfulCard = ({onClose, onNavigate, isHost}) => {

    const boxStyle = {
        display: "flex",
        width: "410px",
        padding: "24px",
        position: "absolute",
        bottom: 25,
        left: 25,
        zIndex: 100,
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "24px",
        borderRadius: "12px",
        background: "#FFF",
        boxShadow: "0px 30px 80px 0px rgba(0, 0, 0, 0.15)",
    }

    const buttonStyle = {
        display: "flex",
        padding: "16px 24px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "8px",
        cursor: "pointer",
        background: "rgba(43, 107, 253, 0.10)",
        '&:hover': {
            background: "rgba(43, 107, 253, 0.20)",
        }
    }

    const navigateToTracks = () => {
        onNavigate();
    }

    return (
        <ClickAwayListener onClickAway={onClose}>
            <Box style={boxStyle}>
                <Box style={headerStyle}>
                    <Box style={{width: "20px", height: "20px", flexshrink: 0}}>
                        <CheckIcon/>
                    </Box>
                    <Box style={textStyle}>
                        Upload Successful
                    </Box>
                    <Box sx={iconStyle} onClick={onClose}>
                        <CloseIcon/>
                    </Box>
                </Box>
                {isHost ?
                    <>
                        <Box style={textStyle}>
                            Local recording for all participants has finished uploading and is being processed.
                        </Box>
                        <Box style={buttonStyle} onClick={navigateToTracks}>
                            <Box style={textStyle}>
                                View tracks
                            </Box>
                        </Box>
                    </> : <>
                        <Box style={textStyle}>
                            The recording has finished uploading. You can safely navigate away from this page.
                        </Box>
                    </>
                }
            </Box>
        </ClickAwayListener>
    )
}

export const RecordingEndedCard = ({onClose, uploadProgress, ownTracksUploaded}) => {
    const boxStyle = {
        position: "absolute",
        bottom: 25,
        left: 25,
        zIndex: 100,
        display: "flex",
        width: "410px",
        padding: "24px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "12px",
        borderRadius: "12px",
        background: "#FFF",
        boxShadow: "0px 30px 80px 0px rgba(0, 0, 0, 0.15)",
    };

    const headerStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
    };

    const textStyle = {
        fontSize: "14px",
        color: "#000",
    };

    const iconStyle = {
        cursor: "pointer",
    };

    const roundToTwoDecimals = (num) => {
        return Math.round((num + Number.EPSILON) * 100) / 100
    }

    return (
        <ClickAwayListener onClickAway={onClose}>
            <Box style={boxStyle}>
                <Box style={headerStyle}>
                    <Box style={{display: "flex", flexDirection: "row", gap: "4px"}}>
                        <Box style={{width: "20px", height: "20px", flexShrink: 0}}>
                            <InfoIcon/>
                        </Box>
                        <Box style={textStyle}>
                            Recording Ended
                        </Box>
                    </Box>
                    <Box sx={iconStyle} onClick={onClose}>
                        <CloseIcon/>
                    </Box>
                </Box>
                <Box style={textStyle}>
                    Please wait for uploading to finish before navigating away from this page.
                </Box>

                {/* Progress Bar */}
                {ownTracksUploaded ?
                    <Box>
                        <Box sx={textStyle}>
                            Your tracks have finished uploading. Waiting for all participant tracks to enter a
                            processing state.
                        </Box>
                    </Box> :
                    <Box style={{width: '100%'}}>
                        <LinearProgress variant="determinate" value={uploadProgress}/>
                        <Box style={{textAlign: 'right', marginTop: '8px', fontSize: '12px', color: '#555'}}>
                            {roundToTwoDecimals(uploadProgress)}% Complete
                        </Box>
                    </Box>}
            </Box>
        </ClickAwayListener>
    );
};
