import Container from '../../components/Container'
import {HEADING} from '@lexical/markdown'
import {
    LexicalComposer,
} from '@lexical/react/LexicalComposer'
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {ContentEditable} from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin'
import {MarkdownShortcutPlugin} from '@lexical/react/LexicalMarkdownShortcutPlugin'
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin'
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin'
import {HeadingNode} from '@lexical/rich-text'
import {
    $getNodeByKey, $getRoot,
    $getSelection, $nodesOfType, $setSelection,
} from 'lexical'
import {debounce} from 'lodash-es'
import {forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react'
import {
    processLocations,
} from './AudioEngine'
import {$isSoundNode, SoundNode} from './SoundNode'
import {SilenceInfoCard, EditSelectedCard, CorrectTextBox} from "./EditSelectedCard";
import {NodeEventPlugin} from "@lexical/react/LexicalNodeEventPlugin";
import {AppendWordsToRoot, CreateEditorState, OptimizeFillerWordRemovalTrim, ParseTranscriptWords} from "./Transcript";
import {LibrettoLiveblocksContext} from "./LibrettoLiveblocksContext";
import {createSaveCorrection, createSaveTrim, createUndoFillerWordRemovalTrim, createUndoTrim} from "./Liveblocks";
import {Typography} from "@mui/material";

function RefPlugin({editorRef}) {
    const [editor] = useLexicalComposerContext()
    editorRef.current = editor
    return null
}

const CollaborativeTextEditor = ({
                                     editorRef,
                                     onSelect,
                                     orderOfTracks,
                                     trims,
                                     fillerWordRemovalTrims,
                                     corrections,
                                     transcripts,
                                     transcriptsLoaded,
                                     setSelectedTrackIndex,
                                     currentTrackIndex,
                                     isPlaying,
                                     videoPlayerRef,
                                     timelineEditorRef
                                 }) => {

    const liveblocksContext = useContext(LibrettoLiveblocksContext);
    const editorState = CreateEditorState(corrections, trims, transcripts, orderOfTracks, fillerWordRemovalTrims);

    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.recreateEditorState(orderOfTracks, corrections, trims, transcripts, fillerWordRemovalTrims);
        }
    }, [orderOfTracks, transcripts, editorRef, corrections, trims, fillerWordRemovalTrims]);

    if (transcriptsLoaded) {
        return (
            <TextEditor ref={editorRef} timelineEditorRef={timelineEditorRef} videoPlayerRef={videoPlayerRef}
                        onSelect={onSelect} editorState={editorState} liveblocksContext={liveblocksContext}
                        setSelectedTrackIndex={setSelectedTrackIndex} currentTrackIndex={currentTrackIndex}
                        isPlaying={isPlaying} fillerWordRemovalTrims={fillerWordRemovalTrims} trims={trims}/>
        )
    }

    return (
        <Typography variant="h6">Loading text editor</Typography>
    )
}

const TextEditor = forwardRef(function Editor(
    {
        onSelect,
        editorState,
        liveblocksContext,
        setSelectedTrackIndex,
        currentTrackIndex,
        isPlaying,
        trims,
        fillerWordRemovalTrims,
        videoPlayerRef,
        timelineEditorRef
    },
    ref,
) {
    const editorRef = useRef(null)
    const [showEditCard, setShowEditCard] = useState(false);
    const [editCardPosition, setEditCardPosition] = useState({x: null, y: null});
    const [isEditSpeakerNode, setIsEditSpeakerNode] = useState(false);
    const [showUndeleteOption, setShowUndeleteOption] = useState(false);
    const [showCorrectOption, setShowCorrectOption] = useState(false);
    const [showSilenceInfoCard, setShowSilenceInfoCard] = useState(false);
    const [silenceInfoCardPosition, setSilenceInfoCardPosition] = useState({x: null, y: null});
    const [silenceInfoDuration, setSilenceInfoDuration] = useState(0);
    const [showCorrectTextBox, setShowCorrectTextBox] = useState(false);
    const [textBoxWidth, setTextBoxWidth] = useState(0);
    const [correctTextBoxPosition, setCorrectTextBoxPosition] = useState({x: null, y: null});
    const [correctTextNodeKey, setCorrectTextNodeKey] = useState(null);
    const [correctedText, setCorrectedText] = useState("");
    const [selectedNodes, setSelectedNodes] = useState([]);

    const [currentNodeElement, setCurrentNodeElement] = useState(null);
    const previousNodeElementRef = useRef(null);

    useEffect(() => {

        if (currentNodeElement) {
            if (currentNodeElement.style.textDecoration !== 'line-through') {
                currentNodeElement.style.backgroundColor = 'rgba(255, 0, 0, 0.5)';
            }
        }

        if (previousNodeElementRef.current && previousNodeElementRef.current.style.textDecoration !== 'line-through') {
            previousNodeElementRef.current.style.backgroundColor = 'transparent';
        }
        previousNodeElementRef.current = currentNodeElement;

    }, [currentNodeElement]);

    const saveCorrection = createSaveCorrection(liveblocksContext);
    const saveTrim = createSaveTrim(liveblocksContext);
    const undoTrim = createUndoTrim(liveblocksContext);
    const undoFillerWordRemovalTrim = createUndoFillerWordRemovalTrim(liveblocksContext);

    const initialConfig = {
        namespace: 'Libretto',
        theme: {
            ltr: 'ltr',
            rtl: 'rtl',
            placeholder: 'editor-placeholder',
            paragraph: 'editor-paragraph',
        },
        editable: false,
        onError: (err) => {
            console.error('Error:', err)
        },
        nodes: [SoundNode, HeadingNode],
        editorState: editorState,
    };

    function getAllSoundNodes() {
        return (
            editorRef.current?.getEditorState().read(() => {
                return $nodesOfType(SoundNode)
            }) ?? []
        )
    }

    function getFillerWordTrims() {
        return (
            editorRef.current?.getEditorState().read(() => {
                const allNodes = getAllSoundNodes();
                const fillerWordNodes = allNodes.filter((n) => n.isFillerWord());
                const fillerWordTrims = new Map();

                // Helper function to get the next non-filler node
                function getNextNonFillerNode(currentIndex) {
                    for (let i = currentIndex + 1; i < allNodes.length; i++) {
                        if (!allNodes[i].isFillerWord()) {
                            return allNodes[i];
                        }
                    }
                    return null;
                }

                // Helper function to get the previous non-filler node
                function getPreviousNonFillerNode(currentIndex) {
                    for (let i = currentIndex - 1; i >= 0; i--) {
                        if (!allNodes[i].isFillerWord()) {
                            return allNodes[i];
                        }
                    }
                    return null;
                }

                for (let i = 0; i < fillerWordNodes.length; i++) {
                    const fillerWordNode = fillerWordNodes[i];
                    const orderedIndex = String(fillerWordNode.getOrderedTrackIndex());
                    const nextNonFillerNode = getNextNonFillerNode(allNodes.indexOf(fillerWordNode));
                    const prevNonFillerNode = getPreviousNonFillerNode(allNodes.indexOf(fillerWordNode));

                    if (nextNonFillerNode && prevNonFillerNode &&
                        nextNonFillerNode.getOrderedTrackIndex() === fillerWordNode.getOrderedTrackIndex() &&
                        prevNonFillerNode.getOrderedTrackIndex() === fillerWordNode.getOrderedTrackIndex()) {

                        let prevEndTime = prevNonFillerNode.isSilenceNode() ? prevNonFillerNode.getEnd() - prevNonFillerNode.getDuration()/2 : prevNonFillerNode.getEnd();
                        let nextStartTime = nextNonFillerNode.isSilenceNode() ? nextNonFillerNode.getStart() + nextNonFillerNode.getDuration()/2 : nextNonFillerNode.getStart();

                        const newTrim = {
                            startTime: Math.min(prevEndTime, fillerWordNode.getStart()),
                            endTime: Math.max(nextStartTime, fillerWordNode.getEnd())
                        };

                        const improvedTrim = OptimizeFillerWordRemovalTrim(newTrim);

                        if (fillerWordTrims.has(orderedIndex)) {
                            const trims = fillerWordTrims.get(orderedIndex);
                            trims.push(improvedTrim);
                            fillerWordTrims.set(orderedIndex, trims);
                        } else {
                            const trims = [improvedTrim];
                            fillerWordTrims.set(orderedIndex, trims);
                        }

                    } else {
                        const newTrim = {
                            startTime: fillerWordNode.getStart(),
                            endTime: fillerWordNode.getEnd()
                        };

                        const improvedTrim = OptimizeFillerWordRemovalTrim(newTrim);

                        if (fillerWordTrims.has(orderedIndex)) {
                            const trims = fillerWordTrims.get(orderedIndex);
                            trims.push(improvedTrim);
                            fillerWordTrims.set(orderedIndex, trims);
                        } else {
                            const trims = [improvedTrim];
                            fillerWordTrims.set(orderedIndex, trims);
                        }
                    }
                }

                return fillerWordTrims;
            }) ?? new Map()
        )
    }

    useImperativeHandle(
        ref,
        () =>
            ({
                getEditor() {
                    return editorRef.current
                },
                processTrimEdit(key) {
                    editorRef.current?.update(() => {
                        const element = editorRef.current.getElementByKey(key);
                        if (element) {
                            element.style.textDecoration = 'line-through';
                            element.style.backgroundColor = 'rgba(0, 0, 0, 0.3)';
                        }
                    });
                },
                recreateEditorState(orderOfTracks, corrections, trims, transcripts, fillerWordRemovalTrims) {
                    editorRef.current?.update(() => {
                        const root = $getRoot();
                        root.clear();
                        const arrayOfAllWords = []
                        const arrayOfCorrections = []
                        const arrayOfTrims = []
                        const arrayOfFillerWordRemovalTrims = []
                        let index = 0;
                        for (const trackId of orderOfTracks) {
                            const transcriptJson = transcripts.get(trackId);
                            const allWords = transcriptJson === undefined || transcriptJson === null ? [] : ParseTranscriptWords(transcriptJson);
                            if (allWords.length === 0) {
                                continue;
                            }
                            allWords.sort((a, b) => a.start - b.start);
                            arrayOfAllWords.push(allWords);
                            const strIndex = String(index);

                            const correctionsForThisTrack = corrections.has(strIndex) && corrections.get(strIndex) ? corrections.get(strIndex).corrections : [];
                            arrayOfCorrections.push(correctionsForThisTrack);

                            const trimsForThisTrack = trims.get(strIndex) ? trims.get(strIndex).trims : [];
                            arrayOfTrims.push(trimsForThisTrack);

                            const fillerWordRemovalTrimsForThisTrack = fillerWordRemovalTrims.get(strIndex) ? fillerWordRemovalTrims.get(strIndex).trims : [];
                            arrayOfFillerWordRemovalTrims.push(fillerWordRemovalTrimsForThisTrack);
                            index++;
                        }

                        let processingIndex = 0;
                        for (const allWords of arrayOfAllWords) {
                            AppendWordsToRoot(allWords, arrayOfCorrections[processingIndex], arrayOfTrims[processingIndex], root, processingIndex, arrayOfFillerWordRemovalTrims[processingIndex]);
                            processingIndex++;
                        }
                    });
                },
                processCorrectionEdit(key, correctedText) {
                    editorRef.current?.update(() => {
                        const node = $getNodeByKey(key);
                        if (node && $isSoundNode(node)) {
                            node.setTextContent(correctedText);
                        }
                    });
                },
                processUndeletionEdit(key) {
                    editorRef.current?.update(() => {
                        const element = editorRef.current.getElementByKey(key);
                        if (element && element.style.textDecoration === 'line-through') {
                            element.style.textDecoration = '';
                            element.style.backgroundColor = 'transparent';
                        }
                    });
                },
                updateSoundNode(key, locUpdate) {
                    editorRef.current?.update(() => {
                        const node = $getNodeByKey(key)
                        if (!node || !$isSoundNode(node)) {
                            console.warn('Unexpected node', node)
                            return
                        }
                        const newLoc = {...node.getSoundLocation(), ...locUpdate}
                        node.setSoundLocation(newLoc)
                        console.log('updated', newLoc)
                    })
                },
                setSoundNodePlaying(key, isPlaying) {
                    editorRef.current?.update(() => {
                        const node = $getNodeByKey(key)
                        if (!node) {
                            console.warn('node not found', key)
                            return
                        }
                        node.setIsPlaying(isPlaying)
                    })
                },
                scrollToKey(key) {
                    const el = editorRef.current?.getElementByKey(key)
                    if (!el) {
                        console.warn('unable to locate element for', key)
                        return
                    }
                    el.scrollIntoView({behavior: 'smooth', block: 'center'})
                },
                updateCurrentTime(time, orderedIndex) {
                    editorRef.current?.update(() => {
                        const allNodes = getAllSoundNodes();
                        const node = allNodes.find((n) => n.getOrderedTrackIndex() === orderedIndex && n.getStart() <= time && n.getEnd() >= time);
                        if (node) {
                            const nodeKey = node.getKey();
                            if (!nodeKey) {
                                return;
                            }
                            const element = editorRef.current.getElementByKey(nodeKey);
                            if (element === null) {
                                return;
                            }
                            setCurrentNodeElement(element);
                            element.scrollIntoView({behavior: 'smooth', block: 'center'});
                        } else {
                           // console.log("Node not found for time: ", time, " and ordered index: ", orderedIndex);
                        }
                    });
                },
                getFillerWordTrims,
                getAllSoundNodes,
            }),
        [],
    )

    const handleSelectionChange = debounce(function handleSelectionChange(
            editorState, editor
        ) {
            editorState.read(() => {
                const selection = $getSelection()

                if (!selection) {
                    onSelect?.([], [])
                    handleNodesSelected([], editor)
                    return
                }

                //console.log("Selection is: ", selection)

                const selectedNodes = selection.getNodes()
                const soundNodes = selectedNodes.filter($isSoundNode)

                const rawLocs = soundNodes.map((l) => l.getSoundLocation())
                const locs = processLocations(rawLocs)


                handleNodesSelected(rawLocs, editor)
                onSelect?.(
                    locs,
                    soundNodes.map((n) => ({...n.exportJSON(), key: n.getKey()})),
                )
            })
        },
        150)

    const editorInputStyle = {
        textAlign: 'justify',
        overflowY: 'auto', // Ensures content is scrollable only vertically
        height: 'calc(100vh - 200px)', // Optional for setting a fixed height
        maxWidth: '820px',
        padding: '50px', // Optional for some internal spacing
        scrollbarWidth: 'none',
        outline: 'none',
    };

    const handleSoundNodeMouseOver = (event, editor, nodeKey) => {
        const node = $getNodeByKey(nodeKey);
        // Show silence info card if the node is a silence node.
        if (node && $isSoundNode(node) && node.isSilenceNode()) {
            setSilenceInfoDuration(node.getDuration());
            setSilenceInfoCardPosition({x: event.clientX, y: event.clientY});
            setShowSilenceInfoCard(true);
        }

        const element = editor.getElementByKey(nodeKey);
        // Highlight the element
        if (element.style.textDecoration !== 'line-through') {
            element.style.backgroundColor = 'rgba(255, 0, 0, 0.1)';
        }
    }

    const handleSoundNodeMouseOut = (event, editor, nodeKey) => {
        setShowSilenceInfoCard(false);
        const element = editor.getElementByKey(nodeKey);
        // Remove the highlight
        if (element.style.textDecoration !== 'line-through') {
            element.style.backgroundColor = 'transparent';
        }
    }

    // Returns whether the node is deleted or not.
    const underlineNode = (nodeKey, editor) => {
        const element = editor.getElementByKey(nodeKey);
        if (element === null) {
            return;
        }
        if (element.style.textDecoration === '') {
            element.style.textDecoration = 'underline';
            return false;
        }
        return element.style.textDecoration === 'line-through';
    }

    const removeUnderlineForNode = (nodeKey, editor) => {
        const element = editor.getElementByKey(nodeKey);
        if (element === null) {
            return;
        }
        if (element.style.textDecoration === 'underline') {
            element.style.textDecoration = '';
        }
    }

    const handleNodesSelected = (nodes, editor) => {
        // Unselect previously selected nodes
        for (let node of selectedNodes) {
            if (node.key) {
                removeUnderlineForNode(node.key, editor);
            }
        }
        setSelectedNodes(nodes);
        if (!nodes || nodes.length === 0) {
            setShowEditCard(false);
            return;
        }

        // Determine the trim of the selected nodes.
        let firstNodeStartTime = nodes[0].start;
        let lastNodeEndTime = nodes[nodes.length - 1].end;

       // console.log("Selected trim with start: ", firstNodeStartTime, " and end: ", lastNodeEndTime)

        // Underline the selected nodes.
        let allDeleted = true;
        for (let node of nodes) {
            if (node.key) {
                const deleted = underlineNode(node.key, editor);
                if (!deleted) {
                    allDeleted = false;
                }
                const fetchedNode = $getNodeByKey(node.key);
                // If the selected node is a speaker node and there are more than one node selected, don't show the edit card.
                if (fetchedNode && fetchedNode.isSpeakerNode() && nodes.length > 1) {
                    return;
                }
            }
        }

        // Use the location of the first node to position the edit card.
        const firstNodeKey = nodes[0].key;
        const element = editor.getElementByKey(firstNodeKey);
        if (element === null) {
            return;
        }
        const rect = element.getBoundingClientRect();
        const xCoordinate = rect.left + window.scrollX; // X coordinate relative to the document
        const yCoordinate = rect.top + window.scrollY;  // Y coordinate relative to the document

        const firstNode = $getNodeByKey(firstNodeKey);
        const shouldShowCorrectOption = firstNode ? nodes.length === 1 && firstNode != null && !firstNode.isSilenceNode() : false;
        const isSpeakerNode = nodes.length === 1 && firstNode != null && firstNode.isSpeakerNode();
        setShowUndeleteOption(allDeleted && nodes.length > 0);
        setEditCardPosition({x: xCoordinate, y: yCoordinate});
        setIsEditSpeakerNode(isSpeakerNode);
        setShowCorrectOption(shouldShowCorrectOption);
        setShowEditCard(true);
    }

    const handleCorrectSelected = () => {
        if (!selectedNodes || selectedNodes.length !== 1) {
            return;
        }

        const nodeKey = selectedNodes[0].key;
        const element = editorRef.current.getElementByKey(nodeKey);
        if (element === null) {
            return;
        }
        const rect = element.getBoundingClientRect();
        const xCoordinate = rect.left + window.scrollX; // X coordinate relative to the document
        const yCoordinate = rect.bottom + window.scrollY;
        setCorrectTextBoxPosition({x: xCoordinate, y: yCoordinate});
        let selectedNode = null;
        editorRef.current.update(() => {
            selectedNode = $getNodeByKey(nodeKey);
        });
        setCorrectedText(selectedNode.getTextContent());
        setCorrectTextNodeKey(nodeKey);
        setShowEditCard(false);
        // Get width of html element
        const elementWidth = element.offsetWidth;
        setTextBoxWidth(elementWidth + 23);
        setShowCorrectTextBox(true);
    }

    const handleCorrectBoxClickAway = () => {
        if (correctedText === "") {
            return;
        }

        editorRef.current.update(() => {
            const selectedNode = $getNodeByKey(correctTextNodeKey);
            if (selectedNode) {
                saveCorrection(selectedNode.getOrderedTrackIndex(), selectedNode.getTranscriptIndex(), correctedText);
            }
        });
        setShowCorrectTextBox(false);
    }

    const handleDeleteSelected = (shouldUndelete) => {
        if (!selectedNodes || selectedNodes.length === 0) {
            return;
        }

        editorRef.current.update(() => {
            let nodeKeys = [];
            for (let node of selectedNodes) {
                if (node.key) {
                    nodeKeys.push(node.key);
                }
                if (node.children) {
                    for (let childNode of node.children) {
                        if (childNode.key) {
                            nodeKeys.push(childNode.key);
                        }
                    }
                }
            }

            const trimsWithOrderedIndices = new Map();
            for (let nodeKey of nodeKeys) {
                const node = $getNodeByKey(nodeKey);
                const orderedIndex = node.getOrderedTrackIndex();
                if (trimsWithOrderedIndices.has(orderedIndex)) {
                    const trim = trimsWithOrderedIndices.get(orderedIndex);
                    const newTrim = {
                        startTime: Math.min(trim.startTime, node.getSoundLocation().start),
                        endTime: Math.max(trim.endTime, node.getSoundLocation().end)
                    };
                    trimsWithOrderedIndices.set(orderedIndex, newTrim);
                } else {
                    trimsWithOrderedIndices.set(orderedIndex, {
                        startTime: node.getSoundLocation().start,
                        endTime: node.getSoundLocation().end
                    });
                }
            }

            trimsWithOrderedIndices.forEach((trim, orderedIndex) => {
                if (shouldUndelete) {
                    undoTrim(trim, orderedIndex);
                    undoFillerWordRemovalTrim(trim, orderedIndex);
                } else {
                    saveTrim(trim, orderedIndex);
                }
            });


            setSelectedNodes([])
            $setSelection(null);
        });

        setShowEditCard(false);
    }

    const UpdateVideoPlayerTime = (time, orderedTrackIndex) => {
        if (videoPlayerRef.current) {
            videoPlayerRef.current.seekTo(time, orderedTrackIndex);
        }
    }

    const UpdateTimelineEditorTime = (time, timeToSubtrack, orderedTrackIndex) => {
        if (timelineEditorRef.current) {
            timelineEditorRef.current.updateCurrentTime(time, timeToSubtrack, orderedTrackIndex);
        }
    }

    const trimForTrackIndex = (index) => {
        const strIndex = String(index);
        return trims.get(strIndex) ? trims.get(strIndex).trims : [];
    }

    const handleSoundNodeClick = (event, editor, nodeKey) => {
        const node = $getNodeByKey(nodeKey);

        if (!node || node.isDeleted()) {
            return;
        }

        const orderedIndex = node.getOrderedTrackIndex();

        const element = editor.getElementByKey(nodeKey);
        setCurrentNodeElement(element);

        const soundNodeTime = node.getStart();
        let timeToSubtract = 0;
        const trimsForTrack = trimForTrackIndex(orderedIndex);

        if (trimsForTrack) {
            for (let trim of trimsForTrack) {
                if (soundNodeTime > trim.endTime) {
                    timeToSubtract += trim.endTime - trim.startTime;
                    continue
                }
            }
        }
        // UpdateVideoPlayerTime(soundNodeTime, orderedIndex);
        UpdateTimelineEditorTime(soundNodeTime, timeToSubtract, orderedIndex);
    }

    return (
        <LexicalComposer initialConfig={initialConfig}>
            {/*<Avatars/>*/}
            <Container
                bg="white"
                maxW="container.md"
                // my="1"
                py="0"
                px="8"
                fontSize="17px"
                fontWeight="normal"
                style={{overflowY: 'auto', margin: 0, padding: 0, display: 'flex', justifyContent: 'center'}}
            >
                <RichTextPlugin
                    contentEditable={
                        <ContentEditable style={editorInputStyle} spellCheck={false}/>
                    }
                    ErrorBoundary={LexicalErrorBoundary}
                />
                <OnChangePlugin onChange={handleSelectionChange}/>
                <NodeEventPlugin nodeType={SoundNode} eventType={'mouseover'}
                                 eventListener={handleSoundNodeMouseOver}/>
                <NodeEventPlugin nodeType={SoundNode} eventType={'mouseout'}
                                 eventListener={handleSoundNodeMouseOut}/>
                <NodeEventPlugin nodeType={SoundNode} eventType={'click'} eventListener={handleSoundNodeClick}/>
                <HistoryPlugin/>
                <RefPlugin editorRef={editorRef}/>
                <MarkdownShortcutPlugin transformers={[HEADING]}/>
                {showCorrectTextBox &&
                    <CorrectTextBox xPosition={correctTextBoxPosition.x} yPosition={correctTextBoxPosition.y}
                                    correctedText={correctedText} setCorrectedText={setCorrectedText}
                                    width={textBoxWidth} handleClickaway={handleCorrectBoxClickAway}/>}
                {showSilenceInfoCard &&
                    <SilenceInfoCard xPosition={silenceInfoCardPosition.x} yPosition={silenceInfoCardPosition.y}
                                     durationSecs={silenceInfoDuration}/>}
                {showEditCard && <EditSelectedCard
                    isSpeakerNode={isEditSpeakerNode}
                    selectedNodeX={editCardPosition.x}
                    selectedNodeY={editCardPosition.y}
                    showUndeleteOption={showUndeleteOption}
                    handleDelete={handleDeleteSelected}
                    showCorrectOption={showCorrectOption}
                    handleCorrect={handleCorrectSelected}/>}
            </Container>
        </LexicalComposer>
    )
})


export default CollaborativeTextEditor;
