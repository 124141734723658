import React, { useState, useCallback } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from '@mui/material';
import Box from "@mui/material/Box";

const ConfirmationDialog = ({
                                title,
                                message,
                                confirmText,
                                cancelText,
                                onConfirm,
                                isOpen,
                                onClose
                            }) => {

    const baseTextStyle = {
        fontFamily: 'Inter',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0.14px',
    }

    const cancelButtonStyle = {
        display: 'flex',
        width: 165,
        height: 52,
        padding: '10px 60px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        borderRadius: "6px",
        background: "#F3F4F5",
        // On Hover effect
        '&:hover': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10)0%, rgba(0, 0, 0, 0.10)100%), #E3E5E8',
        },
    }

    const confirmButtonTextStyle = {
        ...baseTextStyle,
        color: '#ffffff'
    }

    const cancelButtonTextStyle = {
        ...baseTextStyle,
        color: '#1a1a1a'
    }

    const confirmButtonStyle = {
        display: 'flex',
        width: 165,
        height: 52,
        padding: '10px 60px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        borderRadius: "6px",
        background: "#ED0C32",
        // On Hover effect
        '&:hover': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10)0%, rgba(0, 0, 0, 0.10)100%), #D60C2B',
        },
    }

    const topStyle = {
        fontFamily: 'Inter',
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '0.18px',
        color: '#1a1a1a',
    }

    const messageStyle = {
        fontFamily: 'Inter',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0.14px',
        color: '#999',
    }

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            PaperProps={{
                style: {
                    display: 'flex',
                    width: '486px',
                    padding: '24px 24px 40px 24px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '32px',
                    borderRadius: '12px',
                }
            }}
        >
            <Box sx={{ width: '100%', textAlign: 'flex-start' }}>
                <DialogTitle sx={{ padding: 0, marginBottom: '8px' }}>
                    <div style={topStyle}>
                        {title}
                    </div>
                </DialogTitle>
                <DialogContent sx={{ padding: 0 }}>
                    <div style={messageStyle}>
                        {message}
                    </div>
                </DialogContent>
            </Box>
            <DialogActions sx={{ width: '100%', justifyContent: 'center', gap: '16px', padding: 0 }}>
                <Button
                    onClick={onClose}
                    sx={cancelButtonStyle}
                >
                    <div style={cancelButtonTextStyle}>{cancelText}</div>
                </Button>
                <Button
                    onClick={() => {
                        onConfirm();
                        onClose();
                    }}
                    sx={confirmButtonStyle}
                >
                    <div style={confirmButtonTextStyle}>{confirmText}</div>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const useConfirmDialog = ({confirmText, cancelText}) => {
    const [dialogConfig, setDialogConfig] = useState({
        isOpen: false,
        title: '',
        message: '',
        confirmText: confirmText,
        cancelText:  cancelText,
        onConfirm: () => {},
    });

    const openDialog = useCallback((config) => {
        setDialogConfig({ ...config, isOpen: true });
    }, []);

    const closeDialog = useCallback(() => {
        setDialogConfig(prev => ({ ...prev, isOpen: false }));
    }, []);

    const ConfirmDialogComponent = useCallback(() => (
        <ConfirmationDialog
            {...dialogConfig}
            confirmText={confirmText}
            cancelText={cancelText}
            onClose={closeDialog}
        />
    ), [dialogConfig, closeDialog]);

    return { openDialog, ConfirmDialogComponent };
};
