import {useParams} from "react-router-dom";
import {LibrettoLiveblocksContext} from "../Editor/LibrettoLiveblocksContext";
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {ClientSideSuspense} from "@liveblocks/react";
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Container from "../../components/Container";
import GuestEditorTopbar from "./GuestEditorTopbar";
import {useTheme} from "@mui/material/styles";
import {FetchContext} from "../../context/FetchContext";
import {ClipSize16And9RatioId} from "../../utils/utils";
import EditorInner from "../Editor/EditorInner";
import {createSaveFillerWordRemovalTrims, createUndoAllFillerWordRemovalTrims} from "../Editor/Liveblocks";

const kLibrettoLiveblocksToken = 'librettoLiveblocksToken';

const GuestEditorWrapper = () => {

    const {assetId, editId, token} = useParams();
    sessionStorage.setItem(kLibrettoLiveblocksToken, token);

    const liveblocksContext = useContext(LibrettoLiveblocksContext);

    const RoomProvider = liveblocksContext.RoomProvider;

    return (
        <Box>
            {editId === null ? <Typography>Loading...</Typography> :
                <RoomProvider id={editId} initialPresence={{cursor: null}} autoConnect={true}>
                    <ClientSideSuspense fallback="Loading…">
                        {() =>
                            <>
                                <GuestEditor assetId={assetId} token={token}/>
                            </>}
                    </ClientSideSuspense>
                </RoomProvider>}
        </Box>
    )
}


const GuestEditor = ({assetId, token}) => {

    const [tracks, setTracks] = useState(new Map());
    const [tracksLoaded, setTracksLoaded] = useState(false);

    const [selectedTrackIndex, setSelectedTrackIndex] = useState(0);

    const [waveDataForTracks, setWaveDataForTracks] = useState(new Map());
    const [enhancedWavedataForTracks, setEnhancedWavedataForTracks] = useState(new Map());
    const [transcripts, setTranscripts] = useState(new Map());
    const [transcriptsLoaded, setTranscriptsLoaded] = useState(false);
    const [captionsEnabled, setCaptionsEnabled] = useState(false);
    const [videoPlayerVisible, setVideoPlayerVisible] = useState(true);

    const editorRef = useRef(null);
    const videoplayerRef = useRef(null);

    const liveblocksContext = useContext(LibrettoLiveblocksContext);

    const orderOfTracks = liveblocksContext.useStorage((root) => root.order) ?? [];
    const trims = liveblocksContext.useStorage((root) => root.trims) ?? new Map();
    const fillerWordRemovalTrims = liveblocksContext.useStorage((root) => root.fillerWordRemovalTrims) ?? new Map();
    const corrections = liveblocksContext.useStorage((root) => root.corrections) ?? new Map();
    const editSettings = liveblocksContext.useStorage((root) => root.editSettings) ?? new Map();

    const [trackIdSet, setTrackIdSet] = useState(() => new Set(orderOfTracks));
    const [waveformRefs, setWaveformRefs] = useState(() => orderOfTracks.map(() => React.createRef()));

    const [clipSize, setClipSize] = useState(() => {
        const initialSettings = editSettings.get("editSettings");
        return initialSettings && initialSettings.videoAspectRatio ? initialSettings.videoAspectRatio : ClipSize16And9RatioId;
    });

    useEffect(() => {
        if (editSettings && editSettings.get("editSettings")) {
            setClipSize(editSettings.get("editSettings").videoAspectRatio);
        }
    }, [editSettings]);

    useEffect(() => {
        setTrackIdSet(new Set(orderOfTracks));
        setWaveformRefs(orderOfTracks.map(() => React.createRef()));
    }, [orderOfTracks]);

    const theme = useTheme();

    const fetchContext = useContext(FetchContext);

    useEffect(() => {
        setTrackIdSet(new Set(orderOfTracks));
        let newWaveformRefs = [];
        orderOfTracks.forEach(() => {
            newWaveformRefs.push(React.createRef());
        });
        setWaveformRefs(newWaveformRefs);
    }, [orderOfTracks]);

    const handlePlayToggle = (time, play) => {
        if (videoplayerRef.current) {
            videoplayerRef.current.togglePlayPause(time, play);
        }
    }

    const getTrackForSelectedIndex = () => {
        return tracks.get(orderOfTracks[selectedTrackIndex]);
    }

    // Load tracks.
    const loadTrack = async ({trackId}) => {
        try {
            const {data} = await fetchContext.authAxios.get('/shared/track/' + trackId, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return data;
        } catch (error) {
            console.error("Error fetching transcript:", error);
        }
    }

    useEffect(() => {
        if (trackIdSet === undefined || orderOfTracks === null) {
            return;
        }
        if (trackIdSet.size === 0) {
            setTracks(new Map());
            setTracksLoaded(true);
            return;
        }

        const loadAllTracks = async () => {
            const trackIdsArray = Array.from(trackIdSet);
            const trackPromises = trackIdsArray.map(trackId => loadTrack({trackId}));

            try {
                const trackData = await Promise.all(trackPromises);
                setTracks(new Map(trackData.map(track => [track.trackId, track])));
            } catch (error) {
                console.error("Error loading transcripts:", error);
            }
        };

        if (trackIdSet.size > 0) {
            loadAllTracks().then(() => {
                setTracksLoaded(true);
            });
        }

    }, [trackIdSet]);


    // Load transcripts.
    const loadTranscriptForTrack = async ({trackId}) => {
        try {
            const {data} = await fetchContext.authAxios.get('/shared/transcript/' + trackId, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return data;
        } catch (error) {
            console.error("Error fetching transcript:", error);
        }
    }

    useEffect(() => {
        const loadAllTranscripts = async () => {
            const trackIdsArray = Array.from(trackIdSet);
            const transcriptPromises = trackIdsArray.map(trackId => loadTranscriptForTrack({trackId}));

            try {
                const transcriptsData = await Promise.all(transcriptPromises);

                const newTranscripts = new Map();
                trackIdsArray.forEach((trackId, index) => {
                    newTranscripts.set(trackId, transcriptsData[index]);
                });

                setTranscripts(newTranscripts);
            } catch (error) {
                console.error("Error loading transcripts:", error);
            }
        };

        if (trackIdSet.size > 0) {
            loadAllTranscripts().then(() => {
                console.log("Loaded all transcripts")
                setTranscriptsLoaded(true);
            });
        }
        setTranscriptsLoaded(true);
    }, [trackIdSet]);


    useEffect(() => {
        const loadAllTranscripts = async () => {
            const trackIdsArray = Array.from(trackIdSet);
            const transcriptPromises = trackIdsArray.map(trackId => loadTranscriptForTrack({trackId}));

            try {
                const transcriptsData = await Promise.all(transcriptPromises);

                const newTranscripts = new Map();
                trackIdsArray.forEach((trackId, index) => {
                    newTranscripts.set(trackId, transcriptsData[index]);
                });

                setTranscripts(newTranscripts);
            } catch (error) {
                console.error("Error loading transcripts:", error);
            }
        };

        if (trackIdSet.size > 0) {
            loadAllTranscripts().then(() => {
                console.log("Loaded all transcripts")
                setTranscriptsLoaded(true);
            });
        }
        setTranscriptsLoaded(true);
    }, [trackIdSet]);

    const LoadWaveformData = async ({wavedataUrl}) => {
        try {
            const response = await fetch(wavedataUrl);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return await response.arrayBuffer();
        } catch (error) {
            console.error("Error getting track urls:", error);
        }
    }

    // Load audio wavedata
    useEffect(() => {

        const loadAllWaveformData = async () => {
            const waveformDataPromises = [];
            const waveformDataTrackIds = [];
            tracks.forEach((track, trackId) => {
                waveformDataTrackIds.push(trackId);
                if (track.hasAudioStream) {
                    waveformDataPromises.push(LoadWaveformData({wavedataUrl: track.wavedataUrl}));
                } else {
                    waveformDataPromises.push(Promise.resolve(new ArrayBuffer(40 * Number(track.duration))));
                }
            });
            try {
                const waveformData = await Promise.all(waveformDataPromises);
                const waveformDataMap = new Map();
                waveformData.forEach((arrayBuffer, index) => {
                    const int16Array = new Int16Array(arrayBuffer);
                    // Normalize the Int16Array to a Float32Array (range from -1 to 1)
                    const float32Array = new Float32Array(int16Array.length);
                    for (let i = 0; i < int16Array.length; i++) {
                        float32Array[i] = int16Array[i] / 32768;
                    }
                    waveformDataMap.set(waveformDataTrackIds[index], float32Array);
                });
                setWaveDataForTracks(waveformDataMap);
            } catch (error) {
                console.error("Error loading waveform:", error);
            }
        };

        if (tracksLoaded) {
            loadAllWaveformData().then(() => {
            })
        }

    }, [tracks, tracksLoaded]);

    // Load enhanced audio wavedata
    useEffect(() => {
        const loadAllWaveformData = async () => {
            const waveformDataPromises = [];
            const waveformDataTrackIds = [];
            tracks.forEach((track, trackId) => {
                waveformDataTrackIds.push(trackId);
                if (track.hasAudioStream && track.hasEnhancedAudio) {
                    waveformDataPromises.push(LoadWaveformData({wavedataUrl: track.enhancedAudioWavedataUrl}));
                } else {
                    waveformDataPromises.push(Promise.resolve(new ArrayBuffer(40 * Number(track.duration))));
                }
            });
            try {
                const waveformData = await Promise.all(waveformDataPromises);
                const waveformDataMap = new Map();
                waveformData.forEach((arrayBuffer, index) => {
                    const int16Array = new Int16Array(arrayBuffer);
                    // Normalize the Int16Array to a Float32Array (range from -1 to 1)
                    const float32Array = new Float32Array(int16Array.length);
                    for (let i = 0; i < int16Array.length; i++) {
                        float32Array[i] = int16Array[i] / 32768;
                    }
                    waveformDataMap.set(waveformDataTrackIds[index], float32Array);
                });
                setEnhancedWavedataForTracks(waveformDataMap);
            } catch (error) {
                console.error("Error loading waveform:", error);
            }
        };

        if (tracksLoaded) {
            loadAllWaveformData().then(() => {
            })
        }

    }, [tracks, tracksLoaded]);

    const loadProjectTracks = async ({assetId}) => {
        try {
            const {data} = await fetchContext.authAxios.get(`/shared/project/tracks/${assetId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return data.tracks;
        } catch (error) {
            if (error.response.status === 401) {
                return [];
            }
            return [];
        }
    }

    const saveAllFillerWordTrims = createSaveFillerWordRemovalTrims(liveblocksContext);

    const undoAllFillerWordTrims = createUndoAllFillerWordRemovalTrims(liveblocksContext);


    const handleRemoveFillerWordsRequest = async (removeFillerWords) => {
        if (removeFillerWords) {
            if (editorRef.current) {
                const fillerWordTrims = editorRef.current.getFillerWordTrims();
                saveAllFillerWordTrims(fillerWordTrims);
            }
        } else {
            undoAllFillerWordTrims(orderOfTracks.length);
        }
    }

    return (
        <Box>
            <AppBar
                position={'fixed'}
                sx={{
                    backgroundColor: theme.palette.background.paper,
                }}
                elevation={0}
            >
                <Container maxWidth={1} paddingY={{xs: 1, sm: 1.5}}>
                    <GuestEditorTopbar videoPlayerVisible={videoPlayerVisible}
                                       setVideoPlayerVisible={setVideoPlayerVisible}
                                       selectedTrackIndex={selectedTrackIndex}
                                       captionsEnabled={captionsEnabled}
                                       handleRemoveFillerWordsRequest={handleRemoveFillerWordsRequest}
                                       setCaptionsEnabled={() => setCaptionsEnabled(!captionsEnabled)}
                                       track={getTrackForSelectedIndex()}
                                       clipSize={clipSize} setClipSize={setClipSize}
                                       token={token} loadTrack={loadTrack}/>
                </Container>
            </AppBar>
            <EditorInner tracks={tracks} transcriptsLoaded={transcriptsLoaded}
                         tracksLoaded={tracksLoaded} transcripts={transcripts}
                         waveDataForTracks={waveDataForTracks}
                         enhancedWavedataForTracks={enhancedWavedataForTracks}
                         trims={trims} corrections={corrections}
                         handleSelect={() => console.log("Selected")} editorRef={editorRef}
                         captionsEnabled={captionsEnabled} videoplayerRef={videoplayerRef}
                         videoPlayerVisible={videoPlayerVisible}
                         clipSize={clipSize}
                         fillerWordRemovalTrims={fillerWordRemovalTrims}
                         waveformRefs={waveformRefs}
                         selectedTrackIndex={selectedTrackIndex}
                         setSelectedTrackIndex={setSelectedTrackIndex}
                         orderOfTracks={orderOfTracks}
                         handlePlayToggle={handlePlayToggle} assetId={assetId} loadTracks={loadProjectTracks}/>
    </Box>
    )
}

export default GuestEditorWrapper;
