import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { PlusIcon } from '../../../../assets/icons/PlusIcon';
import { TimelineEventsEnum } from '../../types/timeline.types';
import { ApplicationStore } from '../../../../store/ApplicationStore';
import './TrackDivider.styles.scss';

// @ts-ignore
const TrackDivider = ({ index = 0, onDropMediaClip, onDropTextClip }) => {
    const [isDraggingOver, setIsDraggingOver] = useState(false);

    useEffect(() => {
        // @ts-ignore
        const handleDragClipOver = (event) => {
            setIsDraggingOver(event.detail.index === index);
        };

        //const shadowRoot = getShadowRoot();
        document.addEventListener(TimelineEventsEnum.CLIP_DRAG_OVER_DIVIDER, handleDragClipOver);

        return () => {
            document.removeEventListener(TimelineEventsEnum.CLIP_DRAG_OVER_DIVIDER, handleDragClipOver);
        };
    }, [index]);

    // @ts-ignore
    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!isDraggingOver) {
            setIsDraggingOver(true);
        }
    };

    // @ts-ignore
    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDraggingOver(false);
    };

    // @ts-ignore
    const handleDrop = async (event) => {
        handleDragLeave(event);

        try {
            const draggingObject = ApplicationStore.draggingObject;

            if (!draggingObject) {
                return null;
            }

            if (draggingObject.type === "media") {
                onDropMediaClip?.(event, draggingObject.payload.id);
            }

            if (draggingObject.type === "text-preset") {
                onDropTextClip?.(event, draggingObject.payload);
            }
        } catch {
            return null;
        }
    };

    return (
        <div
            className="track-divider"
            data-index={index}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <div
                className={clsx("track-divider__content", {
                    "track-divider__visible": isDraggingOver,
                })}
            >
                <div className="track-divider__icon">
                    <PlusIcon />
                </div>
            </div>
        </div>
    );
};

export default TrackDivider;