import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import CompositionArea from "./CompositionArea";
import Split from "react-split";
import Timeline from "../../editor/modules/timeline/Timeline";
import Sidebar from "./Sidebar";
import ModernTextEditor from "./ModernTextEditor";
import Typography from "@mui/material/Typography";

const ModernEditorInner = ({projectId, compositionContainerRef, canvasRef, editorRef, videoPlayerVisible, textEditorVisible, corrections, currentUser}) => {

    const [videoPlayerWidth, setVideoPlayerWidth] = useState(6);
    const [textEditorWidth, setTextEditorWidth] = useState(5);

    useEffect(() => {
        if (videoPlayerVisible && textEditorVisible) {
            setVideoPlayerWidth(6);
            setTextEditorWidth(5);
        } else if (videoPlayerVisible && !textEditorVisible) {
            setVideoPlayerWidth(11);
            setTextEditorWidth(0);
        } else if (!videoPlayerVisible && textEditorVisible) {
            setVideoPlayerWidth(0);
            setTextEditorWidth(11);
        }
    }, [videoPlayerVisible, textEditorVisible]);

    const [sizes, setSizes] = useState([80, 20]); // Initial split sizes: 80% top, 20% bottom

    const handleDrag = (newSizes) => {
        setSizes(newSizes);
    };

    const [padding, setPadding] = useState(0);

    useEffect(() => {
        const container = document.getElementById("main_container"); // Assume container has this id
        if (container) {
            const containerWidth = container.offsetWidth;
            const gridUnitWidth = containerWidth / 12; // Calculate how much 1 unit in the grid is
            setPadding(gridUnitWidth); // Set this value as padding
        }
    }, []);

    return (
        <Box>
            <Split
                sizes={sizes}
                minSize={150}
                expandToMin={false}
                gutterSize={3}
                gutterAlign="center"
                // snapOffset={30}
                dragInterval={1}
                direction="vertical"
                onDrag={handleDrag}
                gutter={(index, direction) => {
                    const gutter = document.createElement('div');
                    gutter.className = `gutter gutter-${direction}`;
                    gutter.style.backgroundColor = '#888';
                    gutter.style.backgroundRepeat = 'no-repeat';
                    gutter.style.backgroundPosition = '50%';
                    gutter.style.backgroundImage = 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==")';
                    gutter.style.transition = 'background-color 0.3s';
                    gutter.style.cursor = 'row-resize';
                    gutter.addEventListener('mouseover', () => gutter.style.backgroundColor = '#aaa');
                    gutter.addEventListener('mouseout', () => gutter.style.backgroundColor = '#888');
                    return gutter;
                }}
                style={{display: 'flex', flexDirection: 'column', height: 'calc(100vh - 88px)'}}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    height: '100%',
                    flexGrow: 1,
                    minHeight: 0
                }}>
                    <Grid container alignItems="center" justifyContent="space-between"
                          style={{padding: 0, height: '100%', flexGrow: 1, minHeight: 0}} id="main_container">
                        <Grid item xs={textEditorWidth} style={{
                            display: textEditorVisible ? 'flex' : 'none',
                            flexDirection: 'column',
                            paddingLeft: videoPlayerVisible ? 0 : padding/2,
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%'
                        }}>
                            {currentUser ? <ModernTextEditor
                                editorRef={editorRef}
                                corrections={corrections}
                            /> : <Typography>Loading..</Typography>}
                        </Grid>
                        <Grid item xs={videoPlayerWidth} style={{
                            display: videoPlayerVisible ? 'flex' : 'none',
                            flexDirection: 'column',
                            paddingLeft: textEditorVisible ? 0 : padding/2,
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            minHeight: 0,
                            minWidth: 0,
                            flexGrow: 1,
                        }}>
                            <CompositionArea containerRef={compositionContainerRef} canvasRef={canvasRef}/>
                        </Grid>
                        <Grid item style={{alignSelf: "flex-start", height: "100%"}}>
                            <Sidebar projectId={projectId}/>
                        </Grid>
                    </Grid>
                </div>
                <div style={{overflow: 'hidden', paddingTop: "20px"}}>
                    <Timeline/>
                </div>
            </Split>
        </Box>
    )
}

export default ModernEditorInner;
