import {Label} from "../Label/Label";
import './ButtonGroup.styles.scss';

const ButtonGroup = ({label, children}) => {
    return (
        <div className="btn-group">
            {label != null && <Label error={false}>{label}</Label>}

            <div className="btn-group__content">
                {children}
            </div>
        </div>
    );
}

export default ButtonGroup;