import React, { useRef, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { InteractivePlayhead } from '../../utils/InteractivePlayhead';
import { RendleyStore } from '../../../../store/RendleyStore';
import { convertTimeToUnits } from '../../../../utils/dom/convertTimeToUnits';
import { convertUnitsToTime } from '../../../../utils/dom/convertUnitsToTime';
import { RendleyService } from '../../../../services/RendleyService';
import { TimelineStore } from '../../../../store/TimelineStore';
import './Playhead.styles.scss';

// @ts-ignore
const Playhead  = observer(({ containerElement }) => {
    const elementRef = useRef(null);
    const interactionRef = useRef(null);
    const scrollPageRef = useRef(0);

    const x = convertTimeToUnits(RendleyStore.currentTime);

    // @ts-ignore
    const handleDrag = useCallback((deltaX) => {
        const newX = x + deltaX;
        const time = convertUnitsToTime(newX);
        if (time >= 0) {
            RendleyService.seek(time);
        }
    }, [x]);

    const handleDragEnd = useCallback(() => {
        const time = convertUnitsToTime(x);
        if (time < 0) {
            RendleyService.seek(0);
        } else {
            RendleyService.seek(time);
        }
    }, [x]);

    useEffect(() => {
        if (!elementRef.current || !containerElement.current) return;

        // @ts-ignore
        interactionRef.current = new InteractivePlayhead(elementRef.current, containerElement, {
            onDrag: handleDrag,
            onDragEnd: handleDragEnd,
        });

        // @ts-ignore
        interactionRef.current.mount();

        return () => {
            // @ts-ignore
            interactionRef.current?.unmount();
        };
    }, [containerElement, handleDrag, handleDragEnd]);

    useEffect(() => {
        // @ts-ignore
        const handleSeekingTimeRulerStart = (event) => {
            const mouseEvent = event.detail;
            RendleyService.pause();
            RendleyService.seek(convertUnitsToTime(mouseEvent.x));
            // @ts-ignore
            interactionRef.current?.dragStart(mouseEvent.event);
        };

        window.addEventListener('seekingTimeRulerStart', handleSeekingTimeRulerStart);

        return () => {
            window.removeEventListener('seekingTimeRulerStart', handleSeekingTimeRulerStart);
        };
    }, []);

    useEffect(() => {
        const page = Math.floor(x / TimelineStore.tracksContainerWidth);

        if (page !== scrollPageRef.current) {
            scrollPageRef.current = page;
            const event = new CustomEvent('scrollToPage', { detail: scrollPageRef.current });
            window.dispatchEvent(event);
        }
    }, [x]);

    return (
        <div className="playhead" ref={elementRef} style={{ transform: `translateX(${x}px)` }}>
            <div className="playhead__container">
                <div className="playhead__marker">
                    <svg width="13" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2 .5h9A1.5 1.5 0 0 1 12.5 2v3.736a1.5 1.5 0 0 1-.55 1.161l-4.5 3.68a1.5 1.5 0 0 1-1.9 0l-4.5-3.68a1.5 1.5 0 0 1-.55-1.16V2A1.5 1.5 0 0 1 2 .5Z"
                            fill="#fff"
                            stroke="#757575"
                        />
                    </svg>
                </div>
                <div className="playhead__handle"></div>
            </div>
        </div>
    );
});

export default Playhead;