import { IReactionDisposer, autorun, reaction } from "mobx";
import { ApplicationStore } from "../../../../store/ApplicationStore";
import { RendleyStore } from "../../../../store/RendleyStore";
import { TimelineStore } from "../../../../store/TimelineStore";
import { createHighPPICanvas } from "../../../../utils/canvas";
import { drawCanvasTimelineRuler } from "./utils/canvasRuler";
import {useEffect, useRef, useState} from "react";
import './CanvasTimeRuler.styles.scss';

const CanvasTimeRuler = () => {
    const [duration, setDuration] = useState(RendleyStore.duration);
    const [timelineZoom, setTimelineZoom] = useState(TimelineStore.zoom);
    const [scrollLeft, setScrollLeft] = useState(0);

    const canvasContainerRef = useRef(null);
    const canvasRef = useRef(null);
    const canvasContextRef = useRef(null);
    const canvasFillColorRef = useRef(null);

    useEffect(() => {
        const disposeAutorun = autorun(() => {
            setDuration(RendleyStore.duration);
            setTimelineZoom(TimelineStore.zoom);
        });

        const disposeReaction = reaction(
            () => ApplicationStore.theme,
            updateTheme
        );

        window.addEventListener('resize', resizeCanvas);

        return () => {
            disposeAutorun();
            disposeReaction();
            window.removeEventListener('resize', resizeCanvas);
        };
    }, []);

    useEffect(() => {
        if (canvasRef.current) {
            // @ts-ignore
            canvasContextRef.current = canvasRef.current.getContext('2d');
            // @ts-ignore
            canvasFillColorRef.current = "#707070";
            resizeCanvas();
        }
    }, []);

    useEffect(() => {
        // @ts-ignore
        const handleTracksScrollLeft = (event) => {
            setScrollLeft(event.detail);
        };

        window.addEventListener('tracksScrollLeft', handleTracksScrollLeft);

        return () => {
            window.removeEventListener('tracksScrollLeft', handleTracksScrollLeft);
        };
    }, []);

    useEffect(() => {
        redrawCanvas();
    }, [duration, scrollLeft, timelineZoom]);

    // @ts-ignore
    const handleMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!canvasContainerRef.current) {
            return;
        }

        // @ts-ignore
        const { left: leftOffset } = canvasContainerRef.current.getBoundingClientRect();
        const x = event.clientX - leftOffset + scrollLeft;

        // Emit a custom event instead of using @Event decorator
        const customEvent = new CustomEvent('seekingTimeRulerStart', {
            bubbles: true,
            detail: { event, x },
        });
        // @ts-ignore
        canvasContainerRef.current.dispatchEvent(customEvent);
    };

    const updateTheme = () => {
        // @ts-ignore
        canvasFillColorRef.current = "#707070";
        redrawCanvas();
    };

    const resizeCanvas = () => {
        if (!canvasContainerRef.current || !canvasContextRef.current) {
            return;
        }

        // @ts-ignore
        const height = canvasContainerRef.current.clientHeight;
        // @ts-ignore
        const width = canvasContainerRef.current.clientWidth;

        createHighPPICanvas(canvasContextRef.current, width, height);
        redrawCanvas();
    };

    const redrawCanvas = () => {
        if (canvasContextRef.current) {
            drawCanvasTimelineRuler(canvasContextRef.current, duration, {
                scrollOffset: scrollLeft,
                fillColor: canvasFillColorRef.current ?? '#000',
                zoom: timelineZoom,
            });
        }
    };

    return (
        <div
            className="canvas-time-ruler"
            onMouseDown={handleMouseDown}
            ref={canvasContainerRef}
        >
            <canvas ref={canvasRef}></canvas>
        </div>
    );
};

export default CanvasTimeRuler;
