import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDRfyE4EsR2dnfAJrQ5XNTmROr1zdmSbpE",
    authDomain: "libretto.fm",
    projectId: "neuvideo-web",
    storageBucket: "neuvideo-web.appspot.com",
    messagingSenderId: "279035355113",
    appId: "1:279035355113:web:84873e0cd2a9c7ade03578",
    measurementId: "G-X9ZJG1R7JS"
};

// Initialize Firebase
const initializedApp = initializeApp(firebaseConfig);

const db = getFirestore(initializedApp, "libretto-nam");

export { db, initializedApp };