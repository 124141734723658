import { makeAutoObservable } from "mobx";

class SidebarStoreInstance {
  activeItemKey: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setActiveItemKey(id: string | null) {
    this.activeItemKey = id;
  }
}

const SidebarStore = new SidebarStoreInstance();

export { SidebarStore };
