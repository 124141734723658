import React, {useContext, useEffect, useState} from "react";
import {LibrettoLiveblocksContext} from "../LibrettoLiveblocksContext";
import {createAddTrack} from "../Liveblocks";
import Grid from "@mui/material/Grid";
import {Button, Card, CardContent} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import AddTrackCard from "./AddTrackCard";
import Box from "@mui/material/Box";
import TimelineWaveform from "../TimelineWaveform";
import {MergeTrims} from "../../../utils/utils";

const AllTimelineWaveforms = ({
                               tracks,
                               trims,
                               fillerWordRemovalTrims,
                               orderOfTracks,
                               waveformRefs, waveDataForTracks, enhancedWavedataForTracks, handleTrackFinishedPlaying, isPlaying, selectedTrackIndex, handleClick, handleTimeUpdate, setSelectedTrackIndex, assetId, videoPlayerRef, editorRef, loadTracks}) => {

    const [showAddTrackCard, setShowAddTrackCard] = useState(false);
    const [addTrackCardTopPosition, setAddTrackCardTopPosition] = useState(0);
    const [addTrackCardLeftPosition, setAddTrackCardLeftPosition] = useState(0);
    const [addTrackIndex, setAddTrackIndex] = useState(0);
    const [timelineTracks, setTimelineTracks] = useState([]);
    const [waveformWidth, setWaveformWidth] = useState('80vw');

    useEffect(() => {
        const newTimelineTracks= [];
        orderOfTracks.forEach((trackId) => {
            const track = tracks.get(trackId);
            if (track) {
                newTimelineTracks.push(track);
            }
        });
        setTimelineTracks(newTimelineTracks);
    }, [tracks, orderOfTracks]);

    useEffect(() => {
        if (orderOfTracks.length === 1) {
            setWaveformWidth('80vw');
            return;
        }
        if (orderOfTracks.length === 2) {
            setWaveformWidth('40vw');
            return;
        }
        setWaveformWidth('25vw');
    }, [orderOfTracks]);

    const waveformsContainerStyle = {
        paddingLeft: '10px',
        paddingRight: '10px',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        overflowY: 'hidden',
    }

    const waveDataForTrackId = (trackId) => {
        return waveDataForTracks.get(trackId);
    }

    const enhancedWavedataForTrackId = (trackId) => {
        return enhancedWavedataForTracks.get(trackId);
    }

    const waveformRefForIndex = (index) => {
        return waveformRefs[index];
    }

    const trimForTrackIndex = (index) => {
        const strIndex = String(index);
        return trims.get(strIndex) ? trims.get(strIndex).trims : [];
    }

    const fillerWordRemovalTrimsForTrackIndex = (index) => {
        const strIndex = String(index);
        return fillerWordRemovalTrims.get(strIndex) ? fillerWordRemovalTrims.get(strIndex).trims : [];
    }

    const handleAddTrackButtonClick = (event, index) => {
        const buttonRect = event.target.getBoundingClientRect();
        const left = buttonRect.left;
        const top = buttonRect.top;
        setAddTrackCardLeftPosition(left);
        setAddTrackCardTopPosition(top);
        setAddTrackIndex(index);
        setShowAddTrackCard(true);
    }

    const liveblocksContext = useContext(LibrettoLiveblocksContext);
    const trackSettings = liveblocksContext.useStorage((root) => root.trackSettings) ?? new Map();

    const addTrackToTrackOrder = createAddTrack(liveblocksContext);

    return (
        <Grid container spacing={1} direction="row" wrap="nowrap" style={waveformsContainerStyle}>
            {timelineTracks && timelineTracks.length > 0 &&
                <Grid item>
                    <Card variant="outlined" style={{border: '1px dashed', height: 90}}>
                        <CardContent style={{textAlign: 'center'}}>
                            <Tooltip title="Add Track">
                                <Button onClick={(event) => handleAddTrackButtonClick(event, 0)}>
                                    <AddIcon fontSize="large"/>
                                </Button>
                            </Tooltip>
                        </CardContent>
                    </Card>
                </Grid>}
            {showAddTrackCard && <AddTrackCard left={addTrackCardLeftPosition} top={addTrackCardTopPosition} closeCard={() => setShowAddTrackCard(false)} tracks={tracks} assetId={assetId} addTrack={addTrackToTrackOrder} addTrackIndex={addTrackIndex} loadTracks={loadTracks}/>}
            {videoPlayerRef.current && timelineTracks && timelineTracks.length > 0 && timelineTracks.map((track, index) => (
                <Grid item key={index}>
                    <Box
                        onClick={(event) => setSelectedTrackIndex(index)}
                        elevation={5}
                        style={{
                            cursor: 'pointer',
                            position: 'relative',
                            border: selectedTrackIndex === index ? `2px solid #2196f3` : '2px solid #aaa',
                            borderRadius: '10px',
                            overflow: 'visible',
                            height: 90,
                            padding: 0,
                        }}
                    >
                        <TimelineWaveform
                            track={track}
                            isPlaying={isPlaying}
                            orderedTrackIndex={index}
                            enhancedAudioSelected={trackSettings.get(String(index)) ? trackSettings.get(String(index)).enhanceAudio : false}
                            handleTrackFinishedPlaying={handleTrackFinishedPlaying}
                            ref={waveformRefForIndex(index)}
                            selected={selectedTrackIndex === index}
                            width={waveformWidth}
                            setSelectedTrackIndex={setSelectedTrackIndex}
                            predecodedBuffer={waveDataForTrackId(track.trackId)}
                            enhancedPredecodedBuffer={enhancedWavedataForTrackId(track.trackId)}
                            trims={MergeTrims({trims: trimForTrackIndex(index), fillerWordRemovalTrims: fillerWordRemovalTrimsForTrackIndex(index)})}
                            handleClick={() => {}}
                            handleTimeUpdate={handleTimeUpdate}
                            videoPlayerRef={videoPlayerRef}
                            editorRef={editorRef}
                        />
                    </Box>
                </Grid>
            ))}
            <Grid item hidden={false} style={{marginBottom: '10px'}}>
                <Card variant="outlined" style={{border: '1px dashed', height: 90}}>
                    <CardContent style={{textAlign: 'center'}}>
                        <Tooltip title="Add Track">
                            <Button onClick={(event) => handleAddTrackButtonClick(event, orderOfTracks.length)}>
                                <AddIcon fontSize="large"/>
                            </Button>
                        </Tooltip>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default AllTimelineWaveforms;