import SquareIcon from "@mui/icons-material/Square";
import React, {useEffect, useRef, useState} from "react";
import {HourglassEmpty} from "@mui/icons-material";
import Box from "@mui/material/Box";

const ButtonCheckIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path
                d="M12.5 22C6.977 22 2.5 17.523 2.5 12C2.5 6.477 6.977 2 12.5 2C18.023 2 22.5 6.477 22.5 12C22.5 17.523 18.023 22 12.5 22ZM12.5 20C14.6217 20 16.6566 19.1571 18.1569 17.6569C19.6571 16.1566 20.5 14.1217 20.5 12C20.5 9.87827 19.6571 7.84344 18.1569 6.34315C16.6566 4.84285 14.6217 4 12.5 4C10.3783 4 8.34344 4.84285 6.84315 6.34315C5.34285 7.84344 4.5 9.87827 4.5 12C4.5 14.1217 5.34285 16.1566 6.84315 17.6569C8.34344 19.1571 10.3783 20 12.5 20ZM12.5 15C11.7044 15 10.9413 14.6839 10.3787 14.1213C9.81607 13.5587 9.5 12.7956 9.5 12C9.5 11.2044 9.81607 10.4413 10.3787 9.87868C10.9413 9.31607 11.7044 9 12.5 9C13.2956 9 14.0587 9.31607 14.6213 9.87868C15.1839 10.4413 15.5 11.2044 15.5 12C15.5 12.7956 15.1839 13.5587 14.6213 14.1213C14.0587 14.6839 13.2956 15 12.5 15Z"
                fill="white"/>
        </svg>
    )
}

export const RecordingStateDisplay = React.memo(({
                                                     recordingInProgress,
                                                     localRecordingInProgress,
                                                 }) => {
    const [recordTime, setRecordTime] = useState(0);
    const timerRef = useRef(null);

    useEffect(() => {
        if (recordingInProgress) {
            timerRef.current = setInterval(() => {
                setRecordTime(prevTime => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(timerRef.current);
            setRecordTime(0);
        }

        return () => clearInterval(timerRef.current);
    }, [recordingInProgress]);

    const buttonStyle = {
        background: recordingInProgress || localRecordingInProgress ? "#000" : "#E3E5E8",
        color: "white",
        width: "179px",
        height: "52px",
        borderRadius: "8px",
        display: 'flex', // Ensure flexbox is used
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        gap: "10px",
        cursor: "pointer",
    }

    const ButtonIcon = () => {
        const iconStyle = recordingInProgress || localRecordingInProgress
            ? {
                color: '#F23000',
                animation: 'flashing 1s infinite'
            }
            : {
                color: '#F23000'
            };


        if (recordingInProgress) {
            return (
                <SquareIcon style={iconStyle}/>
            );
        }

        if (localRecordingInProgress) {
            return (
                <HourglassEmpty/>
            );
        }

        return (
            <ButtonCheckIcon/>
        );
    }

    const formatTime = ({recordTime}) => {
        const hours = Math.floor(recordTime / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((recordTime % 3600) / 60).toString().padStart(2, '0');
        const seconds = (recordTime % 60).toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };

    const ButtonText = () => {
        if (recordingInProgress) {
            return (
                <Box style={textStyle}>
                    {formatTime({recordTime})}
                </Box>
            );
        }

        if (localRecordingInProgress) {
            return (
                <Box style={textStyle}>
                    Starting
                </Box>
            );
        }

        return (
            <Box style={textStyle}>
                {formatTime({recordTime})}
            </Box>
        );
    }

    const textStyle = {
        fontFamily: "Inter",
        fontSize: {md: "5px", lg: "5px", xl: "14px"},
        fontStyle: "normal",
        fontWeight: {md: "350", lg: "400", xl: "500"},
        lineHeight: {md: "13px", lg: "15px", xl: "20px"},
        letterSpacing: {md: "0.1px", lg: "0.1px", xl: "0.14px"},
        color: recordingInProgress || localRecordingInProgress ? "white" : "#1a1a1a",
        // disable highlight on double click
        WebkitTouchCallout: "none",
        WebkitUserSelect: "none",
        KhtmlUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none",
    }

    const textContainerStyle = {
        width: "63px",
        cursor: "pointer",
    }

    return (
        <>
            <style>
                {`
                    @keyframes flashing {
                        0% { opacity: 1; }
                        50% { opacity: 0; }
                        100% { opacity: 1; }
                    }
                `}
            </style>
            {<Box sx={buttonStyle}>
                {<ButtonIcon/>}
                <Box style={textContainerStyle}>{ButtonText()}</Box>
            </Box>}
        </>);
});

const NewRecordButton = React.memo(({
                                        recordingInProgress,
                                        localRecordingInProgress,
                                        startRecording,
                                        stopRecording,
                                        recordingPerformed
                                    }) => {
    const [recordTime, setRecordTime] = useState(0);
    const timerRef = useRef(null);

    useEffect(() => {
        if (recordingInProgress) {
            timerRef.current = setInterval(() => {
                setRecordTime(prevTime => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(timerRef.current);
            setRecordTime(0);
        }

        return () => clearInterval(timerRef.current);
    }, [recordingInProgress]);

    const buttonStyle = (recordingPerformed) => {
        return ({
            backgroundColor: recordingInProgress || localRecordingInProgress ? "#000" : recordingPerformed ? "#E3E5E8" : "#F23000",
            color: recordingPerformed ? "#1a1a1a" : "white",
            width: "179px",
            height: "52px",
            borderRadius: "8px",
            // Padding 24Px on left and right, and 14px on top and bottom
            // padding: "24px 14px",
            display: 'flex', // Ensure flexbox is used
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            gap: "10px",
            cursor: "pointer",
        })
    }

    const ButtonIcon = () => {
        const iconStyle = recordingInProgress || localRecordingInProgress
            ? {
                color: '#F23000',
                animation: 'flashing 1s infinite'
            }
            : {
                color: '#F23000'
            };


        if (recordingInProgress) {
            return (
                <SquareIcon style={iconStyle}/>
            );
        }

        if (localRecordingInProgress) {
            return (
                <HourglassEmpty/>
            );
        }

        return (
            <ButtonCheckIcon/>
        );
    }

    const handleButtonClick = async () => {
        if (localRecordingInProgress && !recordingInProgress) return; // Prevents button action if disabled

        if (recordingPerformed) {
            // Refresh the page
            window.location.reload();
            return;
        }

        if (recordingInProgress) {
            await stopRecording();
        } else {
            await startRecording();
        }
    }

    const formatTime = ({recordTime}) => {
        const hours = Math.floor(recordTime / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((recordTime % 3600) / 60).toString().padStart(2, '0');
        const seconds = (recordTime % 60).toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };

    const ButtonText = () => {
        if (recordingInProgress) {
            return (
                <Box style={textStyle}>
                    {formatTime({recordTime})}
                </Box>
            );
        }

        if (localRecordingInProgress) {
            return (
                <Box style={textStyle}>
                    Starting
                </Box>
            );
        }

        if (recordingPerformed) {
            return (
                <Box style={textStyle}>
                    New Recording
                </Box>
            );
        }

        return (
            <Box style={textStyle}>
                Record
            </Box>
        );
    }

    const textStyle = {
        fontFamily: "Inter",
        fontSize: {md: "5px", lg: "5px", xl: "14px"},
        fontStyle: "normal",
        fontWeight: {md: "350", lg: "400", xl: "500"},
        lineHeight: {md: "13px", lg: "15px", xl: "20px"},
        letterSpacing: {md: "0.1px", lg: "0.1px", xl: "0.14px"},
        color: recordingPerformed ? "#1a1a1a" : "white",
        // disable highlight on double click
        WebkitTouchCallout: "none",
        WebkitUserSelect: "none",
        KhtmlUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none",
    }

    const textContainerStyle = {
        cursor: "pointer",
    }

    return (
        <>
            <style>
                {`
                    @keyframes flashing {
                        0% { opacity: 1; }
                        50% { opacity: 0; }
                        100% { opacity: 1; }
                    }
                `}
            </style>
            {<Box onClick={handleButtonClick} sx={buttonStyle(recordingPerformed)}>
                {!recordingPerformed && <ButtonIcon/>}
                <Box style={textContainerStyle}>{ButtonText()}</Box>
            </Box>}
        </>);
});

export default NewRecordButton;
