import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import MyGoogleIcon from "../../../assets/MyGoogleIcon";
import {ButtonBase, DialogContent} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {useFormik} from "formik";
import Dialog from "@mui/material/Dialog";
import {LibrettoPlan} from "../../../utils/utils";


const validationSchema = yup.object({
    email: yup
        .string()
        .trim()
        .email('Please enter a valid email address')
        .required('Email is required.'),
    password: yup
        .string()
        .required('Please specify your password')
        .min(8, 'The password should have at minimum length of 8'),
});

const resetEmailValidationSchema = yup.object({
    email: yup
        .string()
        .trim()
        .email('Please enter a valid email address')
        .required('Email is required.'),
});

const LoginForm = ({
                       signinWithGoogle,
                       handleLoginFormClose,
                       signinWithEmail,
                       handleSignUpFormOpen,
                       sendPasswordResetEmailForUser
                   }) => {
    const theme = useTheme();
    const {mode} = theme.palette;


    const [forgotPasswordMessageOpen, setForgotPasswordMessageOpen] = React.useState(false);
    const [resetEmailSent, setResetEmailSent] = React.useState(false);
    const [resetEmailError, setResetEmailError] = React.useState(false);

    const initialValues = {
        email: '',
        password: '',
    };

    const onSubmit = (values) => {
        signinWithEmail({email: values.email, password: values.password})
    };

    const onResetEmailSubmit = (values) => {
        sendPasswordResetEmailForUser({email: values.email, setResetEmailSent, setResetEmailError});
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit,
    });

    const resetEmailFormik = useFormik({
        initialValues: {email: ''},
        validationSchema: resetEmailValidationSchema,
        onSubmit: onResetEmailSubmit,
    });

    const handleStartForFreeClick = () => {
        handleLoginFormClose();
        handleSignUpFormOpen({LibrettoPlan: LibrettoPlan.StarterPaidAnnual, code: ""});
    }

    const handleForgotPasswordMessageClose = () => {
        handleLoginFormClose();
        setForgotPasswordMessageOpen(false);
        setResetEmailSent(false);
        setResetEmailError(false);
    }

    const handleForgotPasswordClick = () => {
        setForgotPasswordMessageOpen(true);
    }

    return (
        <Box sx={{width: '90%',}}>
            {/* Logo Section */}
            <Box sx={{marginBottom: 5}}>
                <ButtonBase>
                    <Box
                        display={'flex'}
                        component="a"
                        href="/"
                        title="Libretto"
                        width={{xs: 150, md: 180}} // Increased size values
                        height={{xs: 'auto', md: 'auto'}} // Set height to auto for maintaining aspect ratio
                        alignItems="center" // To center the image vertically if needed
                        justifyContent="center" // To center the image horizontally if needed
                    >
                        <Box
                            component={'img'}
                            src={'/logo-new.svg'}
                            height={1}
                            width={1}
                        />
                    </Box>
                </ButtonBase>
            </Box>

            {/* Text Section */}
            <Box sx={{marginBottom: theme.spacing(3)}}>
                <Typography variant="h4" sx={{fontWeight: 800, marginBottom: theme.spacing(1)}}>
                    Sign in to Libretto
                </Typography>
                <Typography variant="subtitle2">
                    Don't have an account?{' '}
                    <ButtonBase onClick={handleStartForFreeClick} style={{textDecoration: 'none'}}
                                onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
                                onMouseLeave={(e) => e.target.style.textDecoration = 'none'}>
                        <Typography variant="subtitle2" component="span"
                                    style={{color: "#1a1a1a", fontWeight: 'bold'}}>
                            Start for free
                        </Typography>
                    </ButtonBase>
                </Typography>

                {/* Google Sign-In Button */}
                <Button
                    onClick={signinWithGoogle}
                    startIcon={<MyGoogleIcon/>}
                    variant="contained" // Changed to 'contained' for more emphasis
                    fullWidth
                    sx={{
                        backgroundColor: "#2B6BFD",
                        height: 40,
                        marginY: theme.spacing(2), // Use theme spacing
                        boxShadow: mode === 'light' ? theme.shadows[1] : 'none', // Conditional shadow
                    }}
                >
                    Continue with Google
                </Button>
                <form onSubmit={formik.handleSubmit}>
                    <Box display="flex" alignItems="center" marginY={theme.spacing(2)}>
                        <Box flex="1" height="1px" bgcolor="divider"/>
                        <Typography
                            sx={{
                                marginX: theme.spacing(2),
                                fontWeight: 600,
                            }}
                        >
                            Or
                        </Typography>
                        <Box flex="1" height="1px" bgcolor="divider"/>
                    </Box>
                    <Typography variant={'subtitle2'} fontWeight={600}>
                        Email
                    </Typography>
                    <TextField
                        variant="outlined"
                        name={'email'}
                        fullWidth
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        style={{height: '40px', marginBottom: '15px'}}
                        InputProps={{style: {height: '40px'}}}
                    />
                    <Grid container item justifyContent="space-between">
                        <Grid item>
                            <Typography variant={'subtitle2'} fontWeight={600}>
                                Password
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ButtonBase onClick={handleForgotPasswordClick} style={{textDecoration: 'none'}}
                                        onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
                                        onMouseLeave={(e) => e.target.style.textDecoration = 'none'}>
                                <Typography variant={'subtitle2'} fontWeight={500} style={{marginLeft: 'auto'}}>
                                    Forgot password?
                                </Typography>
                            </ButtonBase>
                        </Grid>
                    </Grid>
                    <TextField
                        variant="outlined"
                        name={'password'}
                        type={'password'}
                        fullWidth
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        style={{height: '40px', marginBottom: '15px'}}
                        InputProps={{style: {height: '40px'}}}
                    />
                    <Button variant={'contained'} type={'submit'} color="primary" fullWidth sx={{
                        backgroundColor: "#2B6BFD",
                        height: '40px',
                        marginBottom: '10px',
                        marginTop: '15px',
                        boxShadow: mode === 'light' ? theme.shadows[1] : 'none'
                    }}
                            InputProps={{style: {height: '40px'}}}>
                        <Typography>Sign in</Typography>
                    </Button>
                </form>
                <Dialog
                    open={forgotPasswordMessageOpen}
                    onClose={handleForgotPasswordMessageClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogContent sx={{
                        borderRadius: '30px',
                        display: 'flex',        // Enable flexbox
                        justifyContent: 'center', // Center horizontally
                        alignItems: 'center',     // Center vertically
                        width: {xs: '300px', md: '400px'},
                    }}>
                        <form onSubmit={resetEmailFormik.handleSubmit}>
                            <Typography variant="h6"
                                        sx={{fontWeight: 400, alignSelf: "center", marginBottom: theme.spacing(1)}}>
                                Reset your password
                            </Typography>
                            <TextField
                                variant="outlined"
                                name={'email'}
                                type={'email'}
                                fullWidth
                                value={resetEmailFormik.values.email}
                                onChange={resetEmailFormik.handleChange}
                                error={resetEmailFormik.touched.email && Boolean(resetEmailFormik.errors.email)}
                                helperText={resetEmailFormik.touched.email && resetEmailFormik.errors.email}
                                style={{height: '40px', marginBottom: '15px'}}
                                InputProps={{style: {height: '40px'}}}
                            />
                            <Button variant={'contained'} type={'submit'} color="primary" fullWidth disabled={resetEmailSent || resetEmailError} sx={{
                                    backgroundColor: "#2B6BFD",
                                    height: '40px',
                                    marginBottom: '10px',
                                    marginTop: '15px',
                                    boxShadow: mode === 'light' ? theme.shadows[1] : 'none'
                                }}
                                        InputProps={{style: {height: '40px'}}}>
                                    <Typography>Send reset link</Typography>
                            </Button>
                            {(resetEmailSent || resetEmailError) && <Typography variant={'subtitle2'} fontWeight={600} style={{color: 'green', marginBottom: '15px'}}>
                                If the email is registered, you will receive a password reset link.
                            </Typography>}
                        </form>
                    </DialogContent>
                </Dialog>
                {/* Optional: Add more login options here */}
            </Box>

            {/* Additional Info or Links */}
            <Box>
                {/* You can add terms and conditions or other relevant links here */}
            </Box>
        </Box>
    );
};

export default LoginForm;
