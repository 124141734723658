import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RendleyStore } from "../../../../store/RendleyStore";
import { reaction } from "mobx";
import { Clip } from '../Clip/Clip';
import './TextClip.styles.scss';

interface TextClipProps {
    clipId: string;
    layerId: string;
}

export const TextClip: React.FC<TextClipProps> = observer(({ clipId, layerId }) => {
    const [text, setText] = useState<string | undefined>(RendleyStore.getTextByClipId(clipId));

    useEffect(() => {
        const disposer = reaction(
            () => RendleyStore.getTextByClipId(clipId),
            (newText) => {
                setText(newText);
            },
            { fireImmediately: true }
        );

        return () => {
            disposer();
        };
    }, [clipId]);

    return (
        <Clip clipId={clipId} layerId={layerId} filename={text} backgroundColor={"118, 100, 233"}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2 3.5V2H10V3.5"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.5 10H7.5"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6 2V10"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Clip>
    );
});

export default TextClip;