import React, { useRef } from 'react';
import './ColorPicker.styles.scss';

interface ColorPickerProps {
    color?: string;
    onChangeColor?: (color: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ color, onChangeColor }) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleOpen = () => {
        inputRef.current?.click();
    };

    const handleChangeColor = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeColor?.(event.target.value);
    };

    return (
        <div
            className="color-picker"
            style={{ background: color }}
            onPointerDown={handleOpen}
        >
            <input
                ref={inputRef}
                type="color"
                className="color-picker__input"
                onChange={handleChangeColor}
            />
        </div>
    );
};

export default ColorPicker;