import * as React from "react";

const CustomParticipantPlaceHolder = () => (
    <svg width="552" height="505" viewBox="0 0 552 505" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M276 282.5C352.5 282.5 414.545 226.682 414.545 150C414.545 73.3182 352.5 17.5 276 17.5C199.5 17.5 137.455 73.3182 137.455 150C137.455 226.682 199.5 282.5 276 282.5Z"
            fill="black"
            fill-opacity="0.25"
        />
        <path
            d="M168.236 305.872C178.797 301.83 190.839 303.137 201.389 307.508C224.449 318.703 250.182 325 276 325C301.818 325 327.551 318.703 350.611 307.508C361.161 303.137 373.203 301.83 383.764 305.872C453.08 337.373 503.353 398.65 514.769 471.97C517.502 489.059 501.742 505 483.5 505H69.5C51.2581 505 35.4975 489.059 38.2313 471.97C49.6473 398.65 99.9201 337.373 168.236 305.872Z"
            fill="black"
            fill-opacity="0.25"
        />
    </svg>
);
export default CustomParticipantPlaceHolder;