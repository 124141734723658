import {useState} from "react";
import {RefreshTokenAndRetry} from "../../utils/utils";
import {doc, updateDoc} from "firebase/firestore";
import {db} from "../../Firebase";
import {kAssetsCollectionName} from "../../utils/CollectionConstants";
import {Box} from "@mui/material";
import {CloseIcon} from "../NewHome/HomeIcons";
import TextField from "@mui/material/TextField";
import * as React from "react";
import {useNavigate} from "react-router-dom";


export const RenameEntityCard = ({currentEntityTitle, onClose, isAsset, entityId, fetchContext, authContext}) => {

    const [entityTitle, setEntityTitle] = useState(currentEntityTitle);

    const entityIdForRequest = isAsset ? entityId[0] : entityId;

    const updateTrackTitle = async () => {
        fetchContext.authAxios.post(`/tracks/update`, {
            trackIds: entityIdForRequest,
            title: entityTitle,
        }, {
            headers: {
                Authorization: `Bearer ${authContext.getToken()}`,
            }
        }).then(() => {
            onClose();
        }).catch(async (err) => {
            if (err.response.status === 401) {
                await RefreshTokenAndRetry(err, authContext, fetchContext);
            }
        })
    };

    const updateAssetTitle = async () => {

        // Update Firestore
        const entityRef = doc(db, kAssetsCollectionName, entityIdForRequest);
        try {
            await updateDoc(entityRef, { title: entityTitle });
        } catch (error) {
            console.error('Error updating title: ', error);
        }
    };

    const onKeyDown = async (event) => {
        if (event.key === 'Enter') {
            await handleConfirmClick();
        }
    }

    const handleConfirmClick = async () => {
        if (isAsset) {
            await updateAssetTitle();
        } else {
            await updateTrackTitle();
        }
        onClose();
    }

    const boxStyle = {
        display: "flex",
        flexDirection: "column",
        width: "486px",
        padding: "24px 24px 40px 24px",
        alignItems: "center",
        gap: "32px",
        borderRadius: "12px",
    }

    const headerStyle = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        alignSelf: "stretch",
    }

    const headerTextStyle = {
        fontFamily: "Inter",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing: "0.18px",
    }

    const textBoxStyle = {
        display: "flex",
        width: "100%",
    }

    const confirmButtonStyle = {
        display: "flex",
        height: "52px",
        padding: "10px 60px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        background: "#2B6BFD",
        borderRadius: "6px",
        cursor: "pointer",
        // Hover effect
        '&:hover': {
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.10)0%, rgba(0, 0, 0, 0.10)100%), #2B6BFD",
        },
    }

    const buttonTextStyle = {
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.14px",
        color: "#FFFFFF",
    }

    return (
        <Box style={boxStyle}>
            <Box style={headerStyle}>
                <Box style={headerTextStyle}>
                    {isAsset ? "Rename recording" : "Rename track"}
                </Box>
                <Box style={{display: "flex", marginLeft: "auto", cursor: "pointer"}} onClick={onClose}>
                    <CloseIcon/>
                </Box>
            </Box>
            <Box style={textBoxStyle}>
                <TextField
                    variant="outlined"
                    name="title"
                    fullWidth
                    onKeyDown={onKeyDown}
                    value={entityTitle}
                    onChange={(e) => setEntityTitle(e.target.value)}
                    margin="normal"
                    InputProps={{
                        style: {
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "20px",
                            letterSpacing: "0.14px",
                            color: "#000000",
                            height: "64px",
                            backgroundColor: "white",
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#9E9E9E',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 400,
                        },
                        shrink: true,
                    }}
                    sx={{
                        width: '100%',
                        height: '64px',
                        marginTop: "10px",
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: "1px solid #e8e8e8",
                            },
                            '&:hover fieldset': {
                                border: "1px solid #e8e8e8",
                            },
                            '&.Mui-focused fieldset': {
                                border: "1px solid #e8e8e8",
                            },
                        },
                        '& .MuiInputBase-root': {
                            backgroundColor: 'white',
                        },
                    }}
                />
            </Box>
            <Box sx={confirmButtonStyle} onClick={handleConfirmClick}>
                <Box style={buttonTextStyle}>Confirm</Box>
            </Box>
        </Box>
    )
}
