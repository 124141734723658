import React from 'react';
import Clip from '../Clip/Clip';  // Adjust the import path as needed

interface SubtitlesClipProps {
    clipId: string;
    layerId: string;
}

const SubtitlesClip: React.FC<SubtitlesClipProps> = ({ clipId, layerId }) => {
    return (
        <Clip clipId={clipId} layerId={layerId} filename="Subtitles" backgroundColor={"0, 142, 255"}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-file-text"
            >
                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                <polyline points="14 2 14 8 20 8"></polyline>
                <line x1="16" y1="13" x2="8" y2="13"></line>
                <line x1="16" y1="17" x2="8" y2="17"></line>
                <polyline points="10 9 9 9 8 9"></polyline>
            </svg>
        </Clip>
    );
};

export default SubtitlesClip;