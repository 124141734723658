import React, {useCallback, useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {autorun, IReactionDisposer, reaction} from "mobx";
import {CANVAS_ID, COMPOSITION_CONTAINER_ID} from "../../editor/config/config";
import {ApplicationStore} from "../../editor/store/ApplicationStore";
import {RendleyStore} from "../../editor/store/RendleyStore";
import {WindowStore} from "../../editor/store/WindowStore";
import {SelectionWatcher} from "../../editor/modules/composition/utils/CompositionSelectionWatcher";
import {calculateAspectRatioFit} from "../../editor/utils/math/calculateAspectRatioFit";
import './Composition.styles.scss';
import DragResizeRotateContainer
    from "../../editor/modules/composition/containers/DragResizeRotateContainer/DragResizeRotateContainer";
import {throttle} from "lodash-es";
import ColorInput from "../../editor/components/ColorInput/ColorInput";
import {Engine} from "@rendley/sdk";


const CompositionArea = observer(({containerRef, canvasRef}) => {

    const [canvasBackgroundColor, setCanvasBackgroundColor] = useState(Engine.getInstance().getDisplay().getBackgroundColor());

    const handleBackgroundColorChange = (color) => {
        Engine.getInstance().getDisplay().setBackgroundColor(color);
        setCanvasBackgroundColor(color);
    }

    // Autorun for observing changes in MobX stores
    useEffect(() => {
        const disposer = reaction(
            () => ({
                backgroundColor: RendleyStore.display.backgroundColor,
                canvasResolution: WindowStore.canvasResolution,
                videoResolution: RendleyStore.display,
                windowResolution: WindowStore.resolution,
                style: ApplicationStore.selectedClipId != null ? RendleyStore.styles?.[ApplicationStore.selectedClipId] : null,
                text: ApplicationStore.selectedClipId != null ? RendleyStore.clips[ApplicationStore.selectedClipId]?.text : null,
            }),
            () => {
                setCanvasBackgroundColor(RendleyStore.display.backgroundColor);
                // This will run whenever RendleyStore.display or RendleyStore.updateTimestamp changes
                updateCanvasSize();
            });

        return () => {
            disposer(); // Dispose of the autorun when the component unmounts
        };
    }, []);

    // Handle ResizeObserver and MutationObserver
    useEffect(() => {
        if (containerRef.current) {
            // ResizeObserver for container size changes
            const resizeObserver = new ResizeObserver((entries) => {
                try {
                    for (const entry of entries) {
                        if (entry.target === containerRef.current) {
                            const {width, height} = entry.contentRect;
                            updateCanvasSize(); // Update canvas size based on container resize
                            WindowStore.setCanvasResolution([width, height]); // Sync with WindowStore
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            });
            resizeObserver.observe(containerRef.current);

            // MutationObserver for child DOM changes
            const mutationObserver = new MutationObserver(() => {
                initializeCanvas();
            });
            mutationObserver.observe(containerRef.current, {childList: true, subtree: true});

            // Initial canvas setup
            initializeCanvas();

            // Cleanup observers on component unmount
            return () => {
                resizeObserver.disconnect();
                mutationObserver.disconnect();
            };
        }
    }, [containerRef]);

    // Calculate the size for the canvas based on container dimensions
    const calculateWidthForComposition = () => {
        if (!containerRef.current || !canvasRef.current) return {width: 0, height: 0};

        const {clientWidth: containerWidth, clientHeight: containerHeight} = containerRef.current;
        const {width: canvasWidth, height: canvasHeight} = canvasRef.current;

        return calculateAspectRatioFit(canvasWidth, canvasHeight, containerWidth, containerHeight);
    };

    // Update the canvas size based on the container's dimensions
    const updateCanvasSize = useCallback(throttle(() => {
        const {newWidth, newHeight} = calculateWidthForComposition();

        if (canvasRef.current) {
            canvasRef.current.style.width = `${newWidth}px`;
            canvasRef.current.style.height = `${newHeight}px`;
        }
    }, 100), []);

    // Initialize the canvas when the component mounts
    const initializeCanvas = () => {
        if (canvasRef.current) {
            const selectionWatcher = SelectionWatcher.getInstance();
            selectionWatcher.init(canvasRef.current);
            updateCanvasSize(); // Ensure the canvas size is updated after initialization
        }
    };

    // Handle mouse down event (deselect clip)
    const handleMouseDown = () => {
        ApplicationStore.setSelectedClipId(null);
    };

    const compositionStyles = {
        veComposition: {
            flexGrow: 1,      // Allow it to grow and take available space
            flexShrink: 1,    // Allow shrinking if necessary
            minWidth: 0,
            height: '100%',   // Ensure it takes the full height of its container
            width: "100%",
            position: 'relative',
            // zIndex: 1,
            // maxWidth: '40vw',
            minHeight: 0,
        },
        composition: {
            alignItems: 'center',
            // boxShadow: '0px 8px 32px 32px rgba(0, 0, 0, 0.04)',
            boxSizing: 'border-box',
            display: 'flex',
            flexGrow: 1,      // Allow it to grow and take available space in the flexbox
            height: '100%',
            justifyContent: 'center',
            padding: '16px 16px calc(16px + 14px)',
            position: 'relative',
            width: '100%',
            maxWidth: '100%',
            minHeight: 0,
        },
        compositionContainer: {
            position: 'relative',
            display: 'grid',
            height: '100%',
            placeContent: 'center',
            width: '100%',
            maxWidth: '100%',
            minHeight: 0,
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "center",
            // border: "1px solid black"
        },
        canvas: {
            width: '100%',
            height: 'auto',
        },
        relative: {
            position: 'relative',
        },
    };

    return (
        <>
            <div style={compositionStyles.veComposition}>
                <div style={compositionStyles.composition} id={COMPOSITION_CONTAINER_ID} onMouseDown={handleMouseDown}>
                    <div style={compositionStyles.compositionContainer} ref={containerRef}>
                        <div
                            style={compositionStyles.relative}
                            onMouseDown={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <canvas id={CANVAS_ID} ref={canvasRef} style={compositionStyles.canvas}></canvas>
                            <DragResizeRotateContainer/>
                        </div>
                        <div style={{justifyContent: "center", display: "flex"}}>
                            <ColorInput
                                color={canvasBackgroundColor}
                                onChangeColor={handleBackgroundColorChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default CompositionArea;