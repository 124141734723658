export const LogoIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="112" height="28" viewBox="0 0 112 28" fill="none">
            <path
                d="M0 10.2218C0 5.31265 3.73096 1.33301 8.33333 1.33301H20V18.755C20 23.6641 16.269 27.9994 11.6667 27.9994H0V10.2218Z"
                fill="#2B6BFD"/>
            <path d="M5.74207 22.0002V6.30566H9.0603V19.2644H15.7887V22.0002H5.74207Z" fill="white"/>
            <path
                d="M104.598 22.2344C103.426 22.2344 102.41 21.9766 101.551 21.4609C100.691 20.9453 100.025 20.224 99.5508 19.2969C99.082 18.3698 98.8477 17.2865 98.8477 16.0469C98.8477 14.8073 99.082 13.7214 99.5508 12.7891C100.025 11.8568 100.691 11.1328 101.551 10.6172C102.41 10.1016 103.426 9.84375 104.598 9.84375C105.77 9.84375 106.785 10.1016 107.645 10.6172C108.504 11.1328 109.168 11.8568 109.637 12.7891C110.111 13.7214 110.348 14.8073 110.348 16.0469C110.348 17.2865 110.111 18.3698 109.637 19.2969C109.168 20.224 108.504 20.9453 107.645 21.4609C106.785 21.9766 105.77 22.2344 104.598 22.2344ZM104.613 19.9688C105.249 19.9688 105.78 19.7943 106.207 19.4453C106.634 19.0911 106.952 18.6172 107.16 18.0234C107.374 17.4297 107.48 16.7682 107.48 16.0391C107.48 15.3047 107.374 14.6406 107.16 14.0469C106.952 13.4479 106.634 12.9714 106.207 12.6172C105.78 12.263 105.249 12.0859 104.613 12.0859C103.962 12.0859 103.421 12.263 102.988 12.6172C102.561 12.9714 102.241 13.4479 102.027 14.0469C101.819 14.6406 101.715 15.3047 101.715 16.0391C101.715 16.7682 101.819 17.4297 102.027 18.0234C102.241 18.6172 102.561 19.0911 102.988 19.4453C103.421 19.7943 103.962 19.9688 104.613 19.9688Z"
                fill="#2B6BFD"/>
            <path
                d="M97.127 10V12.1875H90.2285V10H97.127ZM91.9316 7.125H94.7598V18.3906C94.7598 18.7708 94.8171 19.0625 94.9316 19.2656C95.0514 19.4635 95.2077 19.599 95.4004 19.6719C95.5931 19.7448 95.8066 19.7812 96.041 19.7812C96.2181 19.7812 96.3796 19.7682 96.5254 19.7422C96.6764 19.7161 96.791 19.6927 96.8691 19.6719L97.3457 21.8828C97.1947 21.9349 96.9785 21.9922 96.6973 22.0547C96.4212 22.1172 96.0827 22.1536 95.6816 22.1641C94.9733 22.1849 94.3353 22.0781 93.7676 21.8438C93.1999 21.6042 92.7493 21.2344 92.416 20.7344C92.0879 20.2344 91.9264 19.6094 91.9316 18.8594V7.125Z"
                fill="#2B6BFD"/>
            <path
                d="M88.7695 10V12.1875H81.8711V10H88.7695ZM83.5742 7.125H86.4023V18.3906C86.4023 18.7708 86.4596 19.0625 86.5742 19.2656C86.694 19.4635 86.8503 19.599 87.043 19.6719C87.2357 19.7448 87.4492 19.7812 87.6836 19.7812C87.8607 19.7812 88.0221 19.7682 88.168 19.7422C88.319 19.7161 88.4336 19.6927 88.5117 19.6719L88.9883 21.8828C88.8372 21.9349 88.6211 21.9922 88.3398 22.0547C88.0638 22.1172 87.7253 22.1536 87.3242 22.1641C86.6159 22.1849 85.9779 22.0781 85.4102 21.8438C84.8424 21.6042 84.3919 21.2344 84.0586 20.7344C83.7305 20.2344 83.569 19.6094 83.5742 18.8594V7.125Z"
                fill="#2B6BFD"/>
            <path
                d="M75.0488 22.2344C73.8457 22.2344 72.8066 21.9844 71.9316 21.4844C71.0618 20.9792 70.3926 20.2656 69.9238 19.3438C69.4551 18.4167 69.2207 17.3255 69.2207 16.0703C69.2207 14.8359 69.4551 13.7526 69.9238 12.8203C70.3978 11.8828 71.0592 11.1536 71.9082 10.6328C72.7572 10.1068 73.7546 9.84375 74.9004 9.84375C75.64 9.84375 76.3379 9.96354 76.9941 10.2031C77.6556 10.4375 78.2389 10.8021 78.7441 11.2969C79.2546 11.7917 79.6556 12.4219 79.9473 13.1875C80.2389 13.9479 80.3848 14.8542 80.3848 15.9062V16.7734H70.5488V14.8672H77.6738C77.6686 14.3255 77.5514 13.8437 77.3223 13.4219C77.0931 12.9948 76.7728 12.6589 76.3613 12.4141C75.9551 12.1693 75.4811 12.0469 74.9395 12.0469C74.3613 12.0469 73.8535 12.1875 73.416 12.4688C72.9785 12.7448 72.6374 13.1094 72.3926 13.5625C72.153 14.0104 72.0306 14.5026 72.0254 15.0391V16.7031C72.0254 17.401 72.153 18 72.4082 18.5C72.6634 18.9948 73.0202 19.375 73.4785 19.6406C73.9368 19.901 74.4733 20.0312 75.0879 20.0312C75.4993 20.0312 75.8717 19.974 76.2051 19.8594C76.5384 19.7396 76.8275 19.5651 77.0723 19.3359C77.3171 19.1068 77.502 18.8229 77.627 18.4844L80.2676 18.7812C80.1009 19.4792 79.7832 20.0885 79.3145 20.6094C78.8509 21.125 78.2572 21.526 77.5332 21.8125C76.8092 22.0938 75.9811 22.2344 75.0488 22.2344Z"
                fill="#2B6BFD"/>
            <path
                d="M61.5078 22V10H64.25V12H64.375C64.5938 11.3073 64.9688 10.7734 65.5 10.3984C66.0365 10.0182 66.6484 9.82812 67.3359 9.82812C67.4922 9.82812 67.6667 9.83594 67.8594 9.85156C68.0573 9.86198 68.2214 9.88021 68.3516 9.90625V12.5078C68.2318 12.4661 68.0417 12.4297 67.7812 12.3984C67.526 12.362 67.2786 12.3438 67.0391 12.3438C66.5234 12.3438 66.0599 12.4557 65.6484 12.6797C65.2422 12.8984 64.9219 13.2031 64.6875 13.5938C64.4531 13.9844 64.3359 14.4349 64.3359 14.9453V22H61.5078Z"
                fill="#2B6BFD"/>
            <path
                d="M47.7754 22V6H50.6035V11.9844H50.7207C50.8665 11.6927 51.0723 11.3828 51.3379 11.0547C51.6035 10.7214 51.9629 10.4375 52.416 10.2031C52.8691 9.96354 53.4473 9.84375 54.1504 9.84375C55.0775 9.84375 55.9134 10.0807 56.6582 10.5547C57.4082 11.0234 58.002 11.7188 58.4395 12.6406C58.8822 13.5573 59.1035 14.6823 59.1035 16.0156C59.1035 17.3333 58.8874 18.4531 58.4551 19.375C58.0228 20.2969 57.4342 21 56.6895 21.4844C55.9447 21.9688 55.1009 22.2109 54.1582 22.2109C53.4707 22.2109 52.9004 22.0964 52.4473 21.8672C51.9941 21.638 51.6296 21.362 51.3535 21.0391C51.0827 20.7109 50.8717 20.401 50.7207 20.1094H50.5566V22H47.7754ZM50.5488 16C50.5488 16.776 50.6582 17.4557 50.877 18.0391C51.1009 18.6224 51.4212 19.0781 51.8379 19.4062C52.2598 19.7292 52.7702 19.8906 53.3691 19.8906C53.9941 19.8906 54.5176 19.724 54.9395 19.3906C55.3613 19.0521 55.679 18.5911 55.8926 18.0078C56.1113 17.4193 56.2207 16.75 56.2207 16C56.2207 15.2552 56.1139 14.5938 55.9004 14.0156C55.6868 13.4375 55.3691 12.9844 54.9473 12.6562C54.5254 12.3281 53.9993 12.1641 53.3691 12.1641C52.765 12.1641 52.252 12.3229 51.8301 12.6406C51.4082 12.9583 51.0879 13.4036 50.8691 13.9766C50.6556 14.5495 50.5488 15.224 50.5488 16Z"
                fill="#2B6BFD"/>
            <path
                d="M41.9141 22V10H44.7422V22H41.9141ZM43.3359 8.29688C42.888 8.29688 42.5026 8.14844 42.1797 7.85156C41.8568 7.54948 41.6953 7.1875 41.6953 6.76562C41.6953 6.33854 41.8568 5.97656 42.1797 5.67969C42.5026 5.3776 42.888 5.22656 43.3359 5.22656C43.7891 5.22656 44.1745 5.3776 44.4922 5.67969C44.8151 5.97656 44.9766 6.33854 44.9766 6.76562C44.9766 7.1875 44.8151 7.54948 44.4922 7.85156C44.1745 8.14844 43.7891 8.29688 43.3359 8.29688Z"
                fill="#2B6BFD"/>
            <path d="M29.5781 22V6H32.4766V19.5703H39.5234V22H29.5781Z" fill="#2B6BFD"/>
        </svg>
    )
}

export const NewProjectIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M10 5.83301V14.1663" stroke="#2B6BFD" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M5.83331 10H14.1666" stroke="#2B6BFD" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}

export const CloseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M17 7L7 17" stroke="#1A1A1A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7 7L17 17" stroke="#1A1A1A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const RenameIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                d="M13.761 4.32695C14.0159 4.22135 14.2892 4.16699 14.5651 4.16699C14.8411 4.16699 15.1143 4.22135 15.3693 4.32695C15.6242 4.43255 15.8559 4.58734 16.051 4.78247C16.2462 4.9776 16.4009 5.20925 16.5065 5.46421C16.6122 5.71916 16.6665 5.99241 16.6665 6.26837C16.6665 6.54432 16.6122 6.81758 16.5065 7.07253C16.4009 7.32748 16.2462 7.55913 16.051 7.75426L8.02957 15.7757L4.1665 16.667L5.05778 12.8039L13.0792 4.78247C13.2744 4.58734 13.506 4.43255 13.761 4.32695Z"
                stroke="#808080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.6665 6.66699L14.1665 9.16699" stroke="#808080" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    )
}

export const DeleteIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                d="M4.7778 7.30036L4.58357 7.31891C4.58277 7.28807 4.58832 7.25738 4.59988 7.22868C4.61144 7.19998 4.62878 7.17385 4.65087 7.15185C4.67296 7.12984 4.69934 7.1124 4.72846 7.10058C4.75757 7.08875 4.78882 7.08278 4.82034 7.08301L4.7778 7.30036ZM4.7778 7.30036L4.79701 7.29853L4.80841 7.29744L4.81875 7.29645L4.82034 7.2963L5.33018 7.24761L5.38608 7.83301M4.7778 7.30036L4.81478 7.83301H4.82034H5.38608M5.38608 7.83301H14.6154L13.8185 16.1664L13.8185 16.1664L13.8181 16.171C13.7936 16.445 13.662 16.7031 13.4451 16.8898C13.2278 17.0769 12.9421 17.1771 12.6471 17.1654C12.6372 17.165 12.6273 17.1648 12.6174 17.1648H7.38301C7.37559 17.1648 7.36818 17.1649 7.36076 17.1652C7.0646 17.1739 6.77858 17.072 6.56033 16.8841L6.07962 17.4424L6.56033 16.8841C6.34293 16.6969 6.20947 16.4392 6.18167 16.1646C6.18162 16.1641 6.18157 16.1637 6.18153 16.1632L5.38608 7.83301Z"
                stroke="#ED0C32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.75 10.833V14.1663" stroke="#ED0C32" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M11.25 10.833V14.1663" stroke="#ED0C32" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M3.75 5H16.25" stroke="#ED0C32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M7 3.66699C7 2.97664 7.55964 2.41699 8.25 2.41699H11.75C12.4404 2.41699 13 2.97664 13 3.66699V4.87533H7V3.66699Z"
                stroke="#ED0C32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}