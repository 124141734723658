import {AreEqualTimes} from "../../utils/utils";

export const createUndoTrim = (liveblocksContext) => {

    return liveblocksContext.useMutation(({storage}, trim, index) => {
        // Remove trim from trims list.
        const strIndex = String(index);
        const trackTrims = storage.get("trims").get(strIndex);
        const trimsOfTrack = trackTrims.trims;

        let newTrims = [];

        let i = 0;
        while (i < trimsOfTrack.length) {
            const currentTrim = trimsOfTrack[i];
            if (currentTrim.endTime < trim.startTime) {
                newTrims.push(currentTrim);
                i++;
            } else if (currentTrim.startTime > trim.endTime) {
                newTrims.push(currentTrim);
                i++;
            } else {
                if (AreEqualTimes(currentTrim.startTime, trim.startTime) && AreEqualTimes(currentTrim.endTime, trim.endTime)) {
                    i++;
                    continue;
                }
                if (currentTrim.startTime < trim.startTime) {
                    newTrims.push({startTime: currentTrim.startTime, endTime: trim.startTime});
                }
                if (currentTrim.endTime > trim.endTime) {
                    newTrims.push({startTime: trim.endTime, endTime: currentTrim.endTime});
                }
                i++;
            }
        }
        trackTrims.trims = newTrims;
        storage.get("trims").set(strIndex, trackTrims);
    }, []);
}

export const createUndoFillerWordRemovalTrim = (liveblocksContext) => {

    return liveblocksContext.useMutation(({storage}, trim, index) => {
        // Remove trim from trims list.
        const strIndex = String(index);
        const trackTrims = storage.get("fillerWordRemovalTrims").get(strIndex);
        const trimsOfTrack = trackTrims.trims;

        let newTrims = [];

        let i = 0;
        while (i < trimsOfTrack.length) {
            const currentTrim = trimsOfTrack[i];
            if (currentTrim.endTime < trim.startTime) {
                newTrims.push(currentTrim);
                i++;
            } else if (currentTrim.startTime > trim.endTime) {
                newTrims.push(currentTrim);
                i++;
            } else {
                if (AreEqualTimes(currentTrim.startTime, trim.startTime) && AreEqualTimes(currentTrim.endTime, trim.endTime)) {
                    i++;
                    continue;
                }
                if (currentTrim.startTime < trim.startTime) {
                    newTrims.push({startTime: currentTrim.startTime, endTime: trim.startTime});
                }
                if (currentTrim.endTime > trim.endTime) {
                    newTrims.push({startTime: trim.endTime, endTime: currentTrim.endTime});
                }
                i++;
            }
        }
        trackTrims.trims = newTrims;
        storage.get("fillerWordRemovalTrims").set(strIndex, trackTrims);
    }, []);
}

export const createSaveTrim = (liveblocksContext) => {
    return liveblocksContext.useMutation(({ storage }, trim, index) => {
        // Add trim to trims list and merge overlapping trims.
        const strIndex = String(index);
        const trackTrims = storage.get("trims").get(strIndex);

        const trimsOfTrack = trackTrims.trims;
        let newTrims = [];

        let i = 0;
        while (i < trimsOfTrack.length) {
            const currentTrim = trimsOfTrack[i];
            if (currentTrim.endTime < trim.startTime) {
                newTrims.push(currentTrim);
                i++;
            } else if (currentTrim.startTime > trim.endTime) {
                break;
            } else {
                trim = {startTime: Math.min(currentTrim.startTime, trim.startTime), endTime: Math.max(currentTrim.endTime, trim.endTime)};
                i++;
            }
        }

        newTrims.push(trim);

        while (i < trimsOfTrack.length) {
            newTrims.push(trimsOfTrack[i]);
            i++;
        }
        trackTrims.trims = newTrims;

        storage.get("trims").set(strIndex, trackTrims);
    }, []);
}

export const createSaveFillerWordRemovalTrims = (liveblocksContext) => {
    return liveblocksContext.useMutation(({ storage }, allTrims) => {

        for (let [index, trims] of allTrims) {

            // Add trim to trims list and merge overlapping trims.
            const strIndex = String(index);
            const trackTrims = storage.get("fillerWordRemovalTrims").get(strIndex);

            let trimsOfTrack = trackTrims.trims;
            for (let trim of trims) {

                let newTrims = [];

                let i = 0;
                while (i < trimsOfTrack.length) {
                    const currentTrim = trimsOfTrack[i];
                    if (currentTrim.endTime < trim.startTime) {
                        newTrims.push(currentTrim);
                        i++;
                    } else if (currentTrim.startTime > trim.endTime) {
                        break;
                    } else {
                        trim = {
                            startTime: Math.min(currentTrim.startTime, trim.startTime),
                            endTime: Math.max(currentTrim.endTime, trim.endTime)
                        };
                        i++;
                    }
                }

                newTrims.push(trim);

                while (i < trimsOfTrack.length) {
                    newTrims.push(trimsOfTrack[i]);
                    i++;
                }

                trimsOfTrack = newTrims;
            }

            trackTrims.trims = trimsOfTrack;
            storage.get("fillerWordRemovalTrims").set(strIndex, trackTrims);
        }
    }, []);
}

export const createUndoAllFillerWordRemovalTrims = (liveblocksContext) => {
    return liveblocksContext.useMutation(({ storage }, numTracks) => {
        for (let i = 0; i < numTracks; i++) {
            const trackTrims = storage.get("fillerWordRemovalTrims").get(String(i));
            trackTrims.trims = [];
            storage.get("fillerWordRemovalTrims").set(String(i), trackTrims);
        }
    }, []);
}

export const createAddTrack = (liveblocksContext) => {
    return liveblocksContext.useMutation(({storage}, trackId, index) => {
        // Add track to the ordered list of tracks at the specified index.
        const order = storage.get("order");
        if (index < 0 || index > order.length) {
            index = order.length;
        }

        // Increase numOrderedTracks in editSettings
        const editSettings = storage.get("editSettings").get("editSettings");
        editSettings.numOrderedTracks = order.length + 1;
        storage.get("editSettings").set("editSettings", editSettings);

        if (index === order.length) {
            order.push(trackId);
            const strIndex = String(index);
            storage.get("trackSettings").set(strIndex, {trackId: trackId, enhanceAudio: false});
            storage.get("corrections").set(strIndex, {trackId: trackId, corrections: new Map()});
            storage.get("trims").set(strIndex, {trackId: trackId, trims: []});
            storage.get("fillerWordRemovalTrims").set(strIndex, {trackId: trackId, trims: []});
            return;
        }

        const orderItems = order.toArray();
        order.clear();

        const newOrder = [];
        const newTrackSettings = new Map();
        const newCorrections = new Map();
        const newTrims = new Map();
        const newFillerWordRemovalTrims = new Map();

        orderItems.forEach((item, itemIndex) => {
            if (itemIndex === index) {
                newOrder.push(trackId);
                const strIndex = String(newOrder.length - 1);
                newTrackSettings.set(strIndex, { trackId: trackId, enhanceAudio: false });
                newCorrections.set(strIndex, { trackId: trackId, corrections: new Map() });
                newTrims.set(strIndex, { trackId: trackId, trims: [] });
                newFillerWordRemovalTrims.set(strIndex, { trackId: trackId, trims: [] });
            }

            newOrder.push(item);
            const strIndex = String(newOrder.length - 1);

            newTrackSettings.set(strIndex, storage.get("trackSettings").get(String(itemIndex)));
            newCorrections.set(strIndex, storage.get("corrections").get(String(itemIndex)));
            newTrims.set(strIndex, storage.get("trims").get(String(itemIndex)));
            newFillerWordRemovalTrims.set(strIndex, storage.get("fillerWordRemovalTrims").get(String(itemIndex)));
        });

        // Populate the original maps with new values
        newTrackSettings.forEach((value, key) => storage.get("trackSettings").set(key, value));
        newCorrections.forEach((value, key) => storage.get("corrections").set(key, value));
        newTrims.forEach((value, key) => storage.get("trims").set(key, value));
        newFillerWordRemovalTrims.forEach((value, key) => storage.get("fillerWordRemovalTrims").set(key, value));

        // Set the new order
        newOrder.forEach(item => order.push(item));
    }, []);
}

export const createRemoveTrack = (liveblocksContext) => {
    return liveblocksContext.useMutation(({storage}, index) => {
        const order = storage.get("order");
        if (index < 0 && index > order.length) {
            return;
        }

        // Decrease numOrderedTracks in editSettings
        const editSettings = storage.get("editSettings").get("editSettings");
        editSettings.numOrderedTracks = order.length - 1;
        storage.get("editSettings").set("editSettings", editSettings);

        const newOrder = [];
        const newTrackSettings = new Map();
        const newCorrections = new Map();
        const newTrims = new Map();
        const newFillerWordRemovalTrims = new Map();

        const orderItems = order.toArray();
        order.clear();

        orderItems.forEach((item, itemIndex) => {

            if (itemIndex === index) {
                // Delete the track settings, corrections, and trims for the index being removed.
                storage.get("trackSettings").delete(String(itemIndex));
                storage.get("corrections").delete(String(itemIndex));
                storage.get("trims").delete(String(itemIndex));
                storage.get("fillerWordRemovalTrims").delete(String(itemIndex));
                return;
            }

            newOrder.push(item);
            const strIndex = String(newOrder.length - 1);

            newTrackSettings.set(strIndex, storage.get("trackSettings").get(String(itemIndex)));
            newCorrections.set(strIndex, storage.get("corrections").get(String(itemIndex)));
            newTrims.set(strIndex, storage.get("trims").get(String(itemIndex)));
            newFillerWordRemovalTrims.set(strIndex, storage.get("fillerWordRemovalTrims").get(String(itemIndex)));

            // Remove
            storage.get("trackSettings").delete(String(itemIndex));
            storage.get("corrections").delete(String(itemIndex));
            storage.get("trims").delete(String(itemIndex));
            storage.get("fillerWordRemovalTrims").delete(String(itemIndex));
        });

        // Populate the original maps with new values
        newTrackSettings.forEach((value, key) => storage.get("trackSettings").set(key, value));
        newCorrections.forEach((value, key) => storage.get("corrections").set(key, value));
        newTrims.forEach((value, key) => storage.get("trims").set(key, value));
        newFillerWordRemovalTrims.forEach((value, key) => storage.get("fillerWordRemovalTrims").set(key, value));

        // Set the new order
        newOrder.forEach(item => order.push(item));

    }, []);
}

export const createSetClipSize = (liveblocksContext) => {
    return liveblocksContext.useMutation(({storage}, clipSize) => {
        const editSettings = storage.get("editSettings").get("editSettings");
        editSettings.videoAspectRatio = clipSize;
        storage.get("editSettings").set("editSettings", editSettings);
    }, []);
}

export const createSetRemoveFillerWords = (liveblocksContext) => {
    return liveblocksContext.useMutation(({storage}, removeFillerWords) => {
        const editSettings = storage.get("editSettings").get("editSettings");
        console.log("Edit settings:", editSettings)
        editSettings.removeFillerWords = removeFillerWords;
        storage.get("editSettings").set("editSettings", editSettings);
    }, []);
}

export const createSetEnhancedAudio = (liveblocksContext) => {
    return liveblocksContext.useMutation(({storage}, trackIndex, enhanceAudio) => {
        const trackSettings = storage.get("trackSettings").get(String(trackIndex));
        trackSettings.enhanceAudio = enhanceAudio;
        storage.get("trackSettings").set(String(trackIndex), trackSettings);
    }, []);
}

export const createSaveCorrection = (liveblocksContext) => {
    return liveblocksContext.useMutation(({storage}, orderedTrackIndex, transcriptIndex, correctedText) => {
        const trackCorrections = storage.get("corrections").get(String(orderedTrackIndex))
        const newCorrectionsForTrack = trackCorrections.corrections;
        newCorrectionsForTrack[String(transcriptIndex)] = {correctedText: correctedText, index: transcriptIndex};
        trackCorrections.corrections = newCorrectionsForTrack;
        console.log("Track corrections is: ", trackCorrections);
        storage.get("corrections").set(String(orderedTrackIndex), trackCorrections);
    }, []);
}
