import React from 'react';
import { ClickAwayListener, Card, CardActions, Button, Typography } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import TextField from "@mui/material/TextField";
import RestoreIcon from '@mui/icons-material/Restore';

export const CorrectTextBox = ({xPosition, yPosition, correctedText, setCorrectedText, width, handleClickaway}) => {
    const CARD_WIDTH = width; // Define the card width here, adjust as needed if you include more buttons
    const CARD_HEIGHT = 20; // Define the card height here, adjust as needed if you include padding or more content

    const handleTextBoxChange = (event) => {
        setCorrectedText(event.target.value);
    }

    // Add a handler for the Return key press to submit the corrected text
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleClickaway();
        }
    }

    const cardStyle = {
        cursor: 'pointer',
        position: 'absolute',
        left: xPosition, // Center the card based on the click position
        top: yPosition - CARD_HEIGHT - 5, // Position the card above the click with space for the card's height
        width: CARD_WIDTH,
        backgroundColor: '#222',
        borderRadius: '5px',
        boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.3)',
        // Ensure the card expands to fit the text
        overflow: 'hidden',
        zIndex: 1000,
        display: 'flex', // Use flexbox to layout buttons
        justifyContent: 'space-around', // Distribute buttons evenly
        alignItems: 'center', // Center buttons vertically
        boxSizing: 'border-box', // Ensure padding is included in the width
    };


    return (
        <ClickAwayListener onClickAway={handleClickaway}>
            <TextField variant="outlined" onChange={handleTextBoxChange} value={correctedText} style={cardStyle} InputProps={{style: {height: '30px', color: "white"}}} onKeyDown={handleKeyPress}/>
        </ClickAwayListener>
    )
}


export const SilenceInfoCard = ({xPosition, yPosition, durationSecs}) => {
    const CARD_WIDTH = 95; // Define the card width here, adjust as needed if you include more buttons
    const CARD_HEIGHT = 30; // Define the card height here, adjust as needed if you include padding or more content

    const cardStyle = {
        cursor: 'pointer',
        position: 'absolute',
        left: xPosition - CARD_WIDTH / 2, // Center the card based on the click position
        top: yPosition - CARD_HEIGHT - 20, // Position the card above the click with space for the card's height
        width: CARD_WIDTH,
        backgroundColor: '#222',
        borderRadius: '5px',
        boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.3)',
        overflow: 'hidden', // Ensure that content does not spill out
        zIndex: 1000,
        display: 'flex', // Use flexbox to layout buttons
        justifyContent: 'space-around', // Distribute buttons evenly
        alignItems: 'center', // Center buttons vertically
        padding: '0 5px', // Add some padding on the sides
        boxSizing: 'border-box', // Ensure padding is included in the width
    };

    return (
            <Card elevation={5} style={cardStyle}>
                <CardActions>
                        <Typography color="white" variant="caption">
                            {durationSecs.toFixed(1) + 's'}{' '} pause
                        </Typography>
                </CardActions>
            </Card>
    );
}

export const EditSelectedCard = ({ selectedNodeX, selectedNodeY, handleDelete, showUndeleteOption, showCorrectOption, handleCorrect, isSpeakerNode }) => {

    const CARD_WIDTH = showUndeleteOption || !showCorrectOption ? 130 : 240 // Define the card width here, adjust as needed if you include more buttons
    const CARD_HEIGHT = 35; // Define the card height here, adjust as needed if you include padding or more content

    // Adjust the style to ensure the buttons fit inside the card
    const cardStyle = {
        cursor: 'pointer',
        position: 'absolute',
        left: isSpeakerNode ? selectedNodeX + CARD_WIDTH / 2 : selectedNodeX - CARD_WIDTH / 2, // Center the card based on the click position
        top: isSpeakerNode ? selectedNodeY - 15 : selectedNodeY - CARD_HEIGHT - 15, // Position the card above the click with space for the card's height
        width: CARD_WIDTH,
        backgroundColor: '#222',
        borderRadius: '16px',
        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
        overflow: 'hidden', // Ensure that content does not spill out
        zIndex: 1000,
        display: 'flex', // Use flexbox to layout buttons
        justifyContent: 'space-around', // Distribute buttons evenly
        alignItems: 'center', // Center buttons vertically
        padding: '0 5px', // Add some padding on the sides
        boxSizing: 'border-box', // Ensure padding is included in the width
    };

    return (
            <Card elevation={5} style={cardStyle}>
                <CardActions>
                    {/* Conditionally render buttons based on showUndeleteOption and showCorrectOption */}
                    {showUndeleteOption ? (
                        // Show only the "Undelete" button when showUndeleteOption is true
                        <Button variant="text" startIcon={<RestoreIcon/>} onClick={() => handleDelete(true)} style={{ height: '25px', minWidth: '60px', padding: '0px 8px', fontSize: '0.75rem', margin: '0 5px', color: "white" }}>
                            <Typography color="white" fontWeight={600}>
                                Restore
                            </Typography>
                        </Button>
                    ) : (
                        isSpeakerNode ? (
                            <Button variant="text" startIcon={<ModeEditIcon/>} onClick={handleCorrect} style={{ height: '25px', minWidth: '60px', padding: '0px 8px', fontSize: '0.75rem', margin: '0 5px', color: "white" }}>
                                <Typography color="white" fontWeight={600}>
                                    Correct
                                </Typography>
                            </Button>
                            ) : (
                        // When showUndeleteOption is not true
                        <>
                            {/* Show "Correct" button if showCorrectOption is true */}
                            {showCorrectOption && (
                                <Button variant="text" startIcon={<ModeEditIcon/>} onClick={handleCorrect} style={{ height: '25px', minWidth: '60px', padding: '0px 8px', fontSize: '0.75rem', margin: '0 5px', color: "white" }}>
                                    <Typography color="white" fontWeight={600}>
                                        Correct
                                    </Typography>
                                </Button>
                            )}
                            {/* Always show "Delete" button if showUndeleteOption is not true */}
                            <Button variant="text" startIcon={<DeleteIcon/>} onClick={() => handleDelete(false)} style={{ height: '25px', minWidth: '60px', padding: '0px 8px', fontSize: '0.75rem', margin: '0 5px', color: "white" }}>
                                <Typography color="white" fontWeight={600}>
                                    Delete
                                </Typography>
                            </Button>
                        </>
                    ))}
                </CardActions>
            </Card>
    );
};
