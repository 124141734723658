import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RendleyStore } from "../../../../store/RendleyStore";
import { reaction } from "mobx";
import { Clip } from '../Clip/Clip';
import './ImageClip.styles.scss';

interface ImageClipProps {
    clipId: string;
    layerId: string;
}

export const ImageClip: React.FC<ImageClipProps> = observer(({ clipId, layerId }) => {
    const [filename, setFilename] = useState<string | undefined>(RendleyStore.getFilenameByClipId(clipId));
    const [thumbnail, setThumbnail] = useState<string | undefined>(RendleyStore.getThumbnailByClipId(clipId));

    useEffect(() => {
        const disposer = reaction(
            () => ({
                filename: RendleyStore.getFilenameByClipId(clipId),
                thumbnail: RendleyStore.getThumbnailByClipId(clipId)
            }),
            ({ filename, thumbnail }) => {
                setFilename(filename);
                setThumbnail(thumbnail);
            },
            { fireImmediately: true }
        );

        return () => {
            disposer();
        };
    }, [clipId]);

    return (
        <Clip clipId={clipId} layerId={layerId} filename={filename} thumbnail={thumbnail} backgroundColor={"0, 228, 132"}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.5 1.5H2.5C1.94772 1.5 1.5 1.94772 1.5 2.5V9.5C1.5 10.0523 1.94772 10.5 2.5 10.5H9.5C10.0523 10.5 10.5 10.0523 10.5 9.5V2.5C10.5 1.94772 10.0523 1.5 9.5 1.5Z"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.25 5C4.66421 5 5 4.66421 5 4.25C5 3.83579 4.66421 3.5 4.25 3.5C3.83579 3.5 3.5 3.83579 3.5 4.25C3.5 4.66421 3.83579 5 4.25 5Z"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.5 7.5L8 5L2.5 10.5"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Clip>
    );
});

export default ImageClip;