import { Engine } from "@rendley/sdk";
import { ApplicationStore } from "../../../store/ApplicationStore";

export class SelectionWatcher {
    private static instance: SelectionWatcher | null = null;
    private element: HTMLCanvasElement | null = null;
    private isDragging = false;

    public static getInstance(): SelectionWatcher {
        if (!SelectionWatcher.instance) {
            SelectionWatcher.instance = new SelectionWatcher();
        }
        return SelectionWatcher.instance;
    }

    public init(element: HTMLCanvasElement) {
        if (this.element) {
            this.element.removeEventListener("mousedown", this.onMouseDown);
            this.element.removeEventListener("mouseup", this.onMouseUp);
        }

        this.element = element;

        if (this.element) {
            this.element.addEventListener("mousedown", this.onMouseDown);
            this.element.addEventListener("mouseup", this.onMouseUp);
        }
    }

    public destroy() {
        if (this.element) {
            this.element.removeEventListener("mousedown", this.onMouseDown);
            this.element.removeEventListener("mouseup", this.onMouseUp);
        }
        SelectionWatcher.instance = null; // Allow garbage collection
    }

    private getPositionFromEvent(event: MouseEvent): [number, number] {
        if (!this.element) return [0, 0];

        const { offsetX, offsetY } = event;
        const ratio = Math.min(
            this.element.clientWidth / this.element.width,
            this.element.clientHeight / this.element.height,
        );
        return [offsetX / ratio, offsetY / ratio];
    }

    private onMouseDown = () => {
        this.isDragging = true;
    };

    private onMouseUp = (event: MouseEvent) => {
        if (this.isDragging && this.element) {
            const [cursorX, cursorY] = this.getPositionFromEvent(event);
            const selectedClipId = Engine.getInstance().getDisplay().getClipIdByCursorPosition(cursorX, cursorY);

            ApplicationStore.setSelectedClipId(selectedClipId);

            this.isDragging = false;
        }
    };
}
