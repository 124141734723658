import React, {createContext, useContext} from "react";
import {AuthContext} from "../../context/AuthContext";
import {FetchContext} from "../../context/FetchContext";
import {createClient} from "@liveblocks/client";
import {createRoomContext} from "@liveblocks/react";

const LibrettoLiveblocksContext = createContext();
const { Provider } = LibrettoLiveblocksContext;

const LibrettoLiveblocksProvider = ({children}) => {
    const auth = useContext(AuthContext);
    const fetchContext = useContext(FetchContext);

    const client = createClient({
        authEndpoint: async (room) => {

            const urlEndpoint = auth.isUserAuthenticated() ? "/editor/join" : "/editor/guest";
            const authToken = auth.isUserAuthenticated() ? auth.getToken() : auth.getLibrettoLiveblocksToken();

            let response = null;
            try {
                response = await fetchContext.authAxios.post(urlEndpoint, {}, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    }
                })
                return response.data;
            } catch (error) {
                if (error.response.status === 401) {
                    auth.logout();
                }
            }

            if (response === null) {
                return null;
            }
            return response.data;
        },
        // throttle: 100,
        async resolveUsers({ userIds }) {
            // Used only for Comments and Notifications. Return a list of user information
            // retrieved from `userIds`. This info is used in comments, mentions etc.

            // const usersData = await __fetchUsersFromDB__(userIds);
            //
            // return usersData.map((userData) => ({
            //   name: userData.name,
            //   avatar: userData.avatar.src,
            // }));

            return [];
        },
        async resolveMentionSuggestions({ text }) {
            // Used only for Comments. Return a list of userIds that match `text`.
            // These userIds are used to create a mention list when typing in the
            // composer.
            //
            // For example when you type "@jo", `text` will be `"jo"`, and
            // you should to return an array with John and Joanna's userIds:
            // ["john@example.com", "joanna@example.com"]

            // const users = await getUsers({ search: text });
            // return users.map((user) => user.id);

            return [];
        },
        async resolveRoomsInfo({ roomIds }) {
            // Used only for Comments and Notifications. Return a list of room information
            // retrieved from `roomIds`.

            // const roomsData = await __fetchRoomsFromDB__(roomIds);
            //
            // return roomsData.map((roomData) => ({
            //   name: roomData.name,
            //   url: roomData.url,
            // }));

            return [];
        },
    });

    const { RoomProvider, useSelf, useOthers, useStorage, useMutation, useMyPresence } = createRoomContext(client);

    return (
        <Provider
            value={{
                RoomProvider,
                useSelf,
                useOthers,
                useStorage,
                useMutation,
                useMyPresence,
            }}
        >
            {children}
        </Provider>
    );
}

export { LibrettoLiveblocksContext, LibrettoLiveblocksProvider}
