import {useTheme} from "@mui/material/styles";
import React, {useContext, useState} from "react";
import {AuthContext} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import {Button, Stack, Switch} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import {RedoButton, ShowHideVideoButton, ToggleCaptionsButton, UndoButton} from "../../components/CoolButtons";
import Typography from "@mui/material/Typography";
import {ExportCard} from "./ExportCard";
import GroupIcon from '@mui/icons-material/Group';
import CollaborateCard from "./CollaborateCard";
import {Avatars} from "./Avatars";
import MagicToolsCard from "./MagicToolsCard";
import {FetchContext} from "../../context/FetchContext";
import {ClipSize16And9RatioId, ClipSize1And1RatioId} from "../../utils/utils";
import ClipSizeCard from "./ClipSizeCard";
import {Crop169, CropPortrait, CropSquare} from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";

const EditorTopbar = ({assetId, editId, noAudioStream, videoPlayerRef, selectedTrackIndex, videoPlayerVisible, setVideoPlayerVisible, clipSize, setClipSize, handleRemoveFillerWordsRequest, captionsEnabled, setCaptionsEnabled, track}) => {

    const theme = useTheme();

    const authContext = useContext(AuthContext);
    const fetchContext = useContext(FetchContext);

    const navigate = useNavigate();

    const [showExportCard, setShowExportCard] = useState(false);
    const [showCollaborateCard, setShowCollaborateCard] = useState(false);
    const [showMagicToolsCard, setShowMagicToolsCard] = useState(false);
    const [showClipSizeCard, setShowClipSizeCard] = useState(false);
    const [magicToolsLeftPosition, setMagicToolsLeftPosition] = useState(0);
    const [magicToolsTopPosition, setMagicToolsTopPosition] = useState(0);

    const [clipSizeLeftPosition, setClipSizeLeftPosition] = useState(0);
    const [clipSizeTopPosition, setClipSizeTopPosition] = useState(0);

    const [captionsButtonRightPosition, setCaptionsButtonRightPosition] = useState(0);
    const [captionsButtonTopPosition, setCaptionsButtonTopPosition] = useState(0);

    const [normalizeAudio, setNormalizeAudio] = useState(false);
    const [applyCaptions, setApplyCaptions] = useState(false);
    const [videoQuality, setVideoQuality] = useState('720p');
    const [audioQuality, setAudioQuality] = useState('WAV');
    const [exportVideo, setExportVideo] = useState(true);

    const handleMagicToolsButtonClick = (event) => {
        const buttonRect = event.target.getBoundingClientRect();
        const left = buttonRect.left;
        const top = buttonRect.top;
        setMagicToolsLeftPosition(left);
        setMagicToolsTopPosition(top);
        setShowClipSizeCard(false);
        setShowMagicToolsCard(true);
    }

    const magicToolsButtonDisabled = track === null || track === undefined;

    const handleClipSizeButtonClick = (event) => {
        const buttonRect = event.target.getBoundingClientRect();
        const left = buttonRect.left;
        const top = buttonRect.top;
        setClipSizeTopPosition(top);
        setClipSizeLeftPosition(left);
        setShowMagicToolsCard(false);
        setShowClipSizeCard(true);
    }

    const handleCaptionsButtonClick = (event) => {
        const buttonRect = event.target.getBoundingClientRect();
        const right = buttonRect.right;
        const top = buttonRect.top;
        setCaptionsButtonRightPosition(right);
        setCaptionsButtonTopPosition(top);
        setCaptionsEnabled(!captionsEnabled);
    }

    const navigateToAsset = () => {
        navigate("/assets/" + assetId);
    }

    const handleShowHideVideo = () => {
        setVideoPlayerVisible(!videoPlayerVisible);
    }

    const handleExportRequest = async () => {
        try {
            const response = await fetchContext.authAxios.post(`export/${editId}`, {
                exportVideo: exportVideo,
                assetId: assetId,
                applyCaptions: applyCaptions !== undefined ? applyCaptions : false,
                videoQuality: videoQuality !== undefined ? videoQuality : null,
                audioQuality: audioQuality !== undefined ? audioQuality : null,
            }, {
                headers: {
                    Authorization: `Bearer ${authContext.getToken()}`,
                }
            });
            navigateToAsset();
        } catch (error) {
            if (error.response.status === 401) {
                authContext.logout();
            }
        }
    }

    const handleEnhanceAudioRequest = async (trackId) => {
        try {
            const response = await fetchContext.authAxios.post(`enhance/${trackId}`, {}, {
                headers: {
                    Authorization: `Bearer ${authContext.getToken()}`,
                }
            });
        } catch (error) {
            if (error.response.status === 401) {
                authContext.logout();
            }
        }
    }

    const handleDownloadTranscriptClick = async () => {
        if (videoPlayerRef.current) {
            videoPlayerRef.current.triggerCurrentTranscriptDownload();
        }
    }

    const ClipSizeIcon = ({clipSize}) => {
        if (clipSize === ClipSize16And9RatioId) {
            return <Crop169/>
        }
        if (clipSize === ClipSize1And1RatioId) {
            return <CropSquare/>
        }
        return <CropPortrait/>
    }

    const ClipSizeText = ({clipSize}) => {
        if (clipSize === ClipSize16And9RatioId) {
            return "16:9"
        }
        if (clipSize === ClipSize1And1RatioId) {
            return "1:1"
        }
        return "9:16"
    }

    const announcementBarStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '18px',
        backgroundColor: '#2B6BFD', // You can change this to match your theme
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999, // Ensure it's above other elements
        overflow: 'hidden',
        transition: 'height 0.3s ease',
        '&:hover': {
            height: '20px', // Expands on hover for better readability
        }
    };

    const announcementTextStyle = {
        color: 'white',
        fontSize: '13px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };

    return (
        <>
        <Box sx={announcementBarStyle}>
            <Typography sx={announcementTextStyle}>
                This is a legacy version of the editor. A new version with much more functionality is coming soon!
            </Typography>
        </Box>
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={1}
        >
            <Box
                display={'flex'}
                alignItems="center" // This ensures that the items are vertically centered
            >
                <IconButton onClick={navigateToAsset} sx={{
                    color: theme.palette.primary.main, // Set color from theme
                    fontSize: '2rem', // Increase the font size
                    // You can also adjust the size of the icon itself
                    '& .MuiSvgIcon-root': { fontSize: '2rem' }
                }}>
                    <ArrowBackIcon/>
                </IconButton>
            </Box>
            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={1}
                flexGrow={1} // Allows it to expand and take available space
            >
                <Button variant="contained" onClick={handleMagicToolsButtonClick} startIcon={<AutoAwesomeIcon/>} disabled={magicToolsButtonDisabled} style={{borderRadius: '5px', height: '35px'}}>
                    Magic Tools
                </Button>
                {showMagicToolsCard && <MagicToolsCard track={track} noAudioStream={noAudioStream} handleRemoveFillerWordsRequest={handleRemoveFillerWordsRequest} selectedTrackindex={selectedTrackIndex} handleClose={() => setShowMagicToolsCard(false)} handleEnhanceAudioRequest={handleEnhanceAudioRequest} leftPosition={magicToolsLeftPosition} topPosition={magicToolsTopPosition} guestEditor={false}/>}
                <Button variant="contained" onClick={handleClipSizeButtonClick} startIcon={<ClipSizeIcon clipSize={clipSize}/>} disabled={true} style={{borderRadius: '5px', height: '35px', width: '100px'}}>
                    {ClipSizeText({clipSize})}
                </Button>
                {showClipSizeCard && <ClipSizeCard guestEditor={false} handleClose={() => setShowMagicToolsCard(false)} clipSize={clipSize} setClipSize={setClipSize} topPosition={clipSizeTopPosition} leftPosition={clipSizeLeftPosition}/> }
                <Button variant="contained" disabled={!captionsEnabled} onClick={handleDownloadTranscriptClick} startIcon={<DownloadIcon/>} style={{borderRadius: '5px', height: '35px'}}>
                     Transcript
                </Button>
                {/*<UndoButton disabled={true}/>*/}
                {/*<RedoButton disabled={true}/>*/}
            </Box>
            <Box display={'flex'} alignItems={'center'}>
                <Box marginLeft={2} marginTop={0.5}>
                    <Avatars/>
                </Box>
                <Box marginLeft={1} marginTop={0.5}>
                    {videoPlayerVisible && <ToggleCaptionsButton handleClick={handleCaptionsButtonClick} isCaptionsVisible={captionsEnabled} rightPosition={captionsButtonRightPosition} topPosition={captionsButtonTopPosition}/> }
                </Box>
                <Box marginLeft={1} marginTop={0.5}>
                    <ShowHideVideoButton isVideoVisible={videoPlayerVisible} handleClick={handleShowHideVideo}/>
                </Box>
                <Box marginLeft={2} marginTop={0.5}>
                    <Button variant="contained" startIcon={<GroupIcon/>} style={{borderRadius: "20px", height: "35px"}} onClick={() => setShowCollaborateCard(true)}>
                        <Typography fontWeight={500}>Collaborate</Typography>
                    </Button>
                    {showCollaborateCard && <CollaborateCard editId={editId} assetId={assetId} onClose={() => setShowCollaborateCard(false)}/>}
                </Box>
                <Box marginLeft={2} marginTop={0.5}>
                    <Button variant="contained" style={{borderRadius: "20px", height: "35px"}} disabled={magicToolsButtonDisabled} onClick={() => setShowExportCard(true)}>
                        <Typography fontWeight={500}>Export</Typography>
                    </Button>
                    {showExportCard && <ExportCard handleExportRequest={handleExportRequest} exportVideo={exportVideo} setExportVideo={setExportVideo} handleClose={() => setShowExportCard(false)} normalizeAudio={normalizeAudio} setNormalizeAudio={setNormalizeAudio} applyCaptions={applyCaptions} setApplyCaptions={setApplyCaptions} videoQuality={videoQuality} setVideoQuality={setVideoQuality} audioQuality={audioQuality} setAudioQuality={setAudioQuality}/>}
                </Box>
                {/*<Box marginLeft={0.5}>*/}
                {/*    <AvatarMenu handleClick={handleClick} handleLogout={handleLogout} anchorEl={anchorEl} open={Boolean(anchorEl)} handleClose={handleClose} photoUrl={photoUrl} email={email} navigateToSettings={navigateToSettings}/>*/}
                {/*</Box>*/}
            </Box>
        </Box>
        </>
    );
};

export default EditorTopbar;
