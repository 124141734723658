import {Box, Typography} from "@mui/material";
import React from "react";

const PaymentErrorCard = ({errorCode}) => {
    const mainStyle = {
        display: "flex",
        width: "520px",
        padding: "16px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "24px",
        borderRadius: "12px",
        background: "#FFF",
        boxShadow: "0px 30px 80px 0px rgba(0, 0, 0, 0.15)",
    };

    const headerStyle = {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '20px',
        letterSpacing: '0.14px',
        color: '#000',
    };

    const textStyle = {
        color: "#333",
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
        letterSpacing: '0.14px',
    };

    const bulletPointStyle = {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '12px',
    };

    const bulletStyle = {
        minWidth: '24px',
        color: '#2B6BFD',
        fontWeight: '700',
    };

    return (
        <Box sx={mainStyle}>
            <Typography sx={headerStyle}>Error changing plan</Typography>
            <Box>
                <Box sx={bulletPointStyle}>
                    <Typography sx={{...textStyle, ...bulletStyle}}>•</Typography>
                    <Typography sx={textStyle}>
                        {errorCode === 402 ? 'You need a payment method to change to this plan. Add a payment method or update your existing one.' : 'An error occurred while changing your plan. Please try again later. Error code: ' + errorCode}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default PaymentErrorCard;