import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { autorun } from "mobx";
import { Engine, TextClip } from "@rendley/sdk";
import { BoldIcon } from "../../../../assets/icons/BoldIcon";
import { ItalicIcon } from "../../../../assets/icons/ItalicIcon";
import { AlignLeftIcon } from "../../../../assets/icons/AlignLeftIcon";
import { AlignCenterIcon } from "../../../../assets/icons/AlignCenterIcon";
import { AlignRightIcon } from "../../../../assets/icons/AlignRightIcon";
import { RendleyStore } from "../../../../store/RendleyStore";
import { RendleyService } from "../../../../services/RendleyService";
import { getBrowserFonts } from "../../../timeline/utils/getBrowserFonts";
import ConfigurationPanel from '../../components/ConfigurationPanel/ConfigurationPanel';
import Textarea from '../../../../components/Textarea/Textarea';
import Select from '../../../../components/Select/Select';
import Input from '../../../../components/Input/Input';
import ColorInput from '../../../../components/ColorInput/ColorInput';
import ButtonGroup from '../../../../components/ButtonGroup/ButtonGroup';
import Button from '../../../../components/Button/Button';
import './EditTextPanel.styles.scss';

interface EditTextPanelProps {
    clipId: string;
}

const EditTextPanel: React.FC<EditTextPanelProps> = observer(({ clipId }) => {
    const [style, setStyle] = useState<Record<string, any>>({});
    const [text, setText] = useState("");
    const [fonts, setFonts] = useState<string[]>([]);

    console.log("Rendering EditTextPanel");

    useEffect(() => {
        const disposer = autorun(() => {
            setText((RendleyStore.clips[clipId] as unknown as TextClip)?.text ?? "");
            setStyle(RendleyStore.styles[clipId]);
            handleSetFonts();
        });

        return () => disposer();
    }, [clipId]);

    const handleSetFonts = () => {
        const installedFonts = RendleyService.getFonts();
        const browserFonts = getBrowserFonts();
        const allFonts = new Set([...installedFonts.map((font) => font.family), ...browserFonts]);
        setFonts([...allFonts]);
    };

    const handleChangeText = (value: string) => {
        const clip = Engine.getInstance().getClipById(clipId) as TextClip;
        clip.setText(value);
    };

    const handleChangeFontSize = (value: string) => {
        const clip = Engine.getInstance().getClipById(clipId) as TextClip;
        clip.style.setFontSize(parseInt(value, 10));
    };

    const handleChangeColor = (color: string) => {
        const clip = Engine.getInstance().getClipById(clipId) as TextClip;
        clip.style.setColor(color);
    };

    const handleChangeBackgroundColor = (color: string) => {
        const clip = Engine.getInstance().getClipById(clipId) as TextClip;
        clip.style.setBackgroundColor(color.length > 0 ? color : null);
    };

    const handleToggleBold = () => {
        const clip = Engine.getInstance().getClipById(clipId) as TextClip;
        clip.style.setFontWeight(clip.style.getFontWeight() === "bold" ? "normal" : "bold");
    };

    const handleToggleItalic = () => {
        const clip = Engine.getInstance().getClipById(clipId) as TextClip;
        clip.style.setFontStyle(clip.style.getFontStyle() === "italic" ? "normal" : "italic");
    };

    const handleToggleAlignment = (position: "left" | "center" | "right") => {
        const clip = Engine.getInstance().getClipById(clipId) as TextClip;
        clip.style.setTextAlign(clip.style.getTextAlign() === position ? "justify" : position);
    };

    const handleChangeFontFamily = ({label, value}) => {
        const clip = Engine.getInstance().getClipById(clipId) as TextClip;
        clip.style.setFontFamily(value);
    };

    const isBold = style.fontWeight === "bold";
    const isItalic = style.fontStyle === "italic";
    const isAlignLeft = style.textAlign === "left";
    const isAlignCenter = style.textAlign === "center";
    const isAlignRight = style.textAlign === "right";

    return (
        <div style={{width: "100%"}}>
        <ConfigurationPanel>
            <div className="col-span-12">
                {/* @ts-ignore */}
                <Textarea
                    label="Text"
                    placeholder="Text"
                    value={text}
                    onChangeText={handleChangeText}
                />
            </div>

            <div className="col-span-12">
                <Select
                    value={style.fontFamily}
                    label="Font Family"
                    variant="border"
                    onOptionSelected={handleChangeFontFamily}
                    selectStyle={{ fontFamily: style.fontFamily }}
                >
                    {fonts.map((fontName) => (
                        <option key={fontName} value={fontName} style={{ fontFamily: fontName }}>
                            {fontName}
                        </option>
                    ))}
                </Select>
            </div>

            <div className="col-span-12">
                <Input
                    type="number"
                    label="Size"
                    value={style.fontSize}
                    placeholder="18"
                    onChangeText={handleChangeFontSize}
                />
            </div>

            <div className="col-span-12">
                <ColorInput
                    label="Color"
                    color={style.color}
                    onChangeColor={handleChangeColor}
                />
            </div>

            <div className="col-span-12">
                <ColorInput
                    label="Background Color"
                    color={style.backgroundColor}
                    onChangeColor={handleChangeBackgroundColor}
                />
            </div>

            <div className="col-span-12">
                {/* @ts-ignore */}
                <ButtonGroup label="Style">
                    <Button variant={isBold ? "primary" : "default"} onClick={handleToggleBold} icon={<BoldIcon/>}/>
                    <Button
                        variant={isItalic ? "primary" : "default"}
                        className={isItalic ? "edit-text__btn-selected" : ""}
                        onClick={handleToggleItalic}
                        icon={<ItalicIcon/>}
                    >
                    </Button>
                </ButtonGroup>
            </div>

            <div className="col-span-12">
                {/* @ts-ignore */}
                <ButtonGroup label="Alignment">
                    <Button variant={isAlignLeft ? "primary" : "default"} onClick={() => handleToggleAlignment("left")}>
                        <AlignLeftIcon />
                    </Button>
                    <Button variant={isAlignCenter ? "primary" : "default"} onClick={() => handleToggleAlignment("center")}>
                        <AlignCenterIcon />
                    </Button>
                    <Button variant={isAlignRight ? "primary" : "default"} onClick={() => handleToggleAlignment("right")}>
                        <AlignRightIcon />
                    </Button>
                </ButtonGroup>
            </div>
        </ConfigurationPanel>
        </div>
    );
});

export default EditTextPanel;