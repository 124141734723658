import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {useTheme} from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import HighQualityIcon from '@mui/icons-material/HighQuality';
import HdrEnhancedSelectIcon from '@mui/icons-material/HdrEnhancedSelect';
import { LuArrowDownUp } from "react-icons/lu";
import { RiArrowUpDownLine } from "react-icons/ri";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import {ClosedCaption, ClosedCaptionDisabled, PauseCircle, PlayCircle} from "@mui/icons-material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SettingsIcon from "@mui/icons-material/Settings";
import GridViewIcon from "@mui/icons-material/GridView";
import ListIcon from '@mui/icons-material/List';

const TrashButton = ({handleClick}) => {
    const theme = useTheme();

    return (
        <Tooltip title="Delete">
            <IconButton onClick={handleClick} sx={{
            color: theme.palette.primary.main, // Set color from theme
            fontSize: '2rem', // Increase the font size
            // You can also adjust the size of the icon itself
            '& .MuiSvgIcon-root': { fontSize: '2rem' }
        }}>
            <DeleteIcon/>
            </IconButton>
        </Tooltip>
    );
};

const UndoButton = ({handleClick, disabled}) => {
    const theme = useTheme();

    return (
        <Tooltip title="Undo">
            <IconButton onClick={handleClick} disabled={disabled} sx={{
            color: theme.palette.primary.main, // Set color from theme
            fontSize: '2rem', // Increase the font size
            // You can also adjust the size of the icon itself
            '& .MuiSvgIcon-root': { fontSize: '2rem' }
        }}>
            <UndoIcon/>
            </IconButton>
        </Tooltip>
    );
}

const ShowHideVideoButton = ({handleClick, isVideoVisible, disabled}) => {

    const iconColor = disabled ? "disabled" : "primary";

    return (
        <Tooltip title={isVideoVisible ? "Hide canvas" : "Show canvas"}>
            <IconButton onClick={handleClick} disabled={disabled} sx={{
                // The fontSize property here will not apply to IconButton directly. Removed it.
                '& .MuiSvgIcon-root': { fontSize: '2rem' } // Adjust the size of the icon itself
            }}>
                {isVideoVisible ? <VisibilityIcon color={iconColor} /> : <VisibilityOffIcon color={iconColor} />}
            </IconButton>
        </Tooltip>
    );
}

const RedoButton = ({handleClick, disabled}) => {
    const theme = useTheme();

    return (
        <Tooltip title="Redo">
            <IconButton onClick={handleClick} disabled={disabled} sx={{
            color: theme.palette.primary.main, // Set color from theme
            fontSize: '2rem', // Increase the font size
            // You can also adjust the size of the icon itself
            '& .MuiSvgIcon-root': { fontSize: '2rem' }
        }}>
            <RedoIcon/>
            </IconButton>
        </Tooltip>
    );
}

const PlayPauseButton = ({handleClick, isPlaying, disabled}) => {
    return (
        <Tooltip title={isPlaying ? "Pause" : "Play"}>
            <IconButton onClick={handleClick} disabled={disabled} sx={{
                // The fontSize property here will not apply to IconButton directly. Removed it.
                '& .MuiSvgIcon-root': { fontSize: '2rem' } // Adjust the size of the icon itself
            }}>
                {isPlaying ? <PauseCircle/> : <PlayCircle/>}
            </IconButton>
        </Tooltip>
    );
}

const ToggleCaptionsButton = ({handleClick, isCaptionsVisible}) => {
    return (
        <Tooltip title={isCaptionsVisible ? "Remove captions" : "Add captions"}>
            <IconButton onClick={handleClick} sx={{
                // The fontSize property here will not apply to IconButton directly. Removed it.
                '& .MuiSvgIcon-root': { fontSize: '2rem' } // Adjust the size of the icon itself
            }}>
                {isCaptionsVisible ? <ClosedCaption color="primary"/> : <ClosedCaptionDisabled color="primary"/>}
            </IconButton>
        </Tooltip>
    );
}


const SortButton = ({ handleClick, isSortNewest }) => {
    return (
        isSortNewest ? (
            <Tooltip title="Newest">
                <IconButton onClick={handleClick} sx={{
                    // The fontSize property here will not apply to IconButton directly. Removed it.
                    '& .MuiSvgIcon-root': { fontSize: '1.5rem', fontWeight: 600 } // Adjust the size of the icon itself
                }}>
                    <LuArrowDownUp />
                </IconButton>
            </Tooltip>
        ) : (
            <Tooltip title="Oldest">
                <IconButton onClick={handleClick} sx={{
                    // The fontSize property here will not apply to IconButton directly. Removed it.
                    '& .MuiSvgIcon-root': { fontSize: '1.5rem' } // Adjust the size of the icon itself
                }}>
                    <RiArrowUpDownLine />
                </IconButton>
            </Tooltip>
        )
    );
};

const GridViewButton = ({ handleClick, isGridView }) => {
    return (
        isGridView ? (
            <Tooltip title="List View">
                <IconButton onClick={handleClick} sx={{
                    // The fontSize property here will not apply to IconButton directly. Removed it.
                    '& .MuiSvgIcon-root': { fontSize: '1.5rem', fontWeight: 600 } // Adjust the size of the icon itself
                }}>
                    <ListIcon />
                </IconButton>
            </Tooltip>
        ) : (
            <Tooltip title="Grid View">
                <IconButton onClick={handleClick} sx={{
                    // The fontSize property here will not apply to IconButton directly. Removed it.
                    '& .MuiSvgIcon-root': { fontSize: '1.5rem' } // Adjust the size of the icon itself
                }}>
                    <GridViewIcon />
                </IconButton>
            </Tooltip>
        )
    );
}

const ThreeDotsButton = ({handleClick}) => {
    return (
        <Tooltip>
            <IconButton onClick={handleClick} sx={{
                // The fontSize property here will not apply to IconButton directly. Removed it.
                '& .MuiSvgIcon-root': { fontSize: '1.5rem' } // Adjust the size of the icon itself
            }}>
                <MoreHorizIcon />
            </IconButton>
        </Tooltip>
    );
}

const MiniTrashButton = ({handleClick, disabled}) => {
    const theme = useTheme();

    return (
        <Tooltip title="Delete">
            <IconButton onClick={handleClick} sx={{
                color: theme.palette.primary.main, // Set color from theme
                fontSize: '2rem', // Increase the font size
                // You can also adjust the size of the icon itself
                '& .MuiSvgIcon-root': { fontSize: '1.5rem' }
            }} disabled={disabled}>
                <DeleteIcon/>
            </IconButton>
        </Tooltip>
    );
};

const HighQualityAudioIcon = ({disabled}) => {
    const theme = useTheme();

    return (
        <Tooltip title="Auto-enhanced audio">
            <IconButton sx={{
                color: theme.palette.primary.main, // Set color from theme
                fontSize: '2rem', // Increase the font size
                // You can also adjust the size of the icon itself
                '& .MuiSvgIcon-root': { fontSize: '1.5rem' }
            }} disabled={disabled}>
                <HighQualityIcon/>
            </IconButton>
        </Tooltip>
    );
};

const EnhancedMediaIcon = ({disabled}) => {
    const theme = useTheme();

    return (
        <Tooltip title="Audio enhanced">
            <IconButton sx={{
                color: theme.palette.primary.main, // Set color from theme
                fontSize: '2rem', // Increase the font size
                // You can also adjust the size of the icon itself
                '& .MuiSvgIcon-root': { fontSize: '1.5rem' }
            }} disabled={disabled}>
                <HdrEnhancedSelectIcon/>
            </IconButton>
        </Tooltip>
    );
};


const AddButton = ({handleClick, disabled}) => {
    const theme = useTheme();

    return (
        <Tooltip title="Create new project">
            <IconButton onClick={handleClick} disabled={disabled} sx={{
                color: theme.palette.primary.main, // Set color from theme
                fontSize: '1.3rem', // Increase the font size
                // You can also adjust the size of the icon itself
                '& .MuiSvgIcon-root': { fontSize: '1.3rem' }
            }}>
                <AddIcon/>
            </IconButton>
        </Tooltip>
    );
};

const RecordingSettingsButton = ({handleClick, disabled}) => {
    const theme = useTheme();

    return (
        <Tooltip title="Recording settings">
            <IconButton onClick={handleClick} disabled={disabled} sx={{
                color: theme.palette.primary.main,
                // The fontSize property here will not apply to IconButton directly. Removed it.
                '& .MuiSvgIcon-root': { fontSize: '1.6rem' } // Adjust the size of the icon itself
            }}>
                <SettingsIcon/>
            </IconButton>
        </Tooltip>
    );
}

export {TrashButton, GridViewButton, RecordingSettingsButton, ToggleCaptionsButton, UndoButton, RedoButton, ShowHideVideoButton, PlayPauseButton, AddButton, MiniTrashButton, HighQualityAudioIcon, EnhancedMediaIcon, SortButton, ThreeDotsButton};
