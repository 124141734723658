import React from 'react';

const StatusChip = ({ status, progress }) => {

    if (status === "Processing...") {
        status = "Processing";
    }

    if (status === "Uploading...") {
        status = "Uploading";
    }

    const getChipStyle = () => {
        const baseStyle = {
            padding: '4px 8px',
            borderRadius: '5px',
            alignItems: 'flex-start',
            fontSize: '12px',
            fontWeight: '500',
            letterSpacing: '0.14px',
            fontStyle: 'normal',
            fontFamily: 'Inter, sans-serif',
        };

        switch (status) {
            case 'Ready':
                return {
                    ...baseStyle,
                    color: '#13BF38',
                    backgroundColor: '#E5FFEB',
                };
            case 'Uploading':
                return {
                    ...baseStyle,
                    color: '#2B6BFD',
                    backgroundColor: '#E5EDFF',
                };
            case 'Upload starting':
                return {
                    ...baseStyle,
                    color: '#2B6BFD',
                    backgroundColor: '#E5EDFF',
                };
            default:
                return {
                    ...baseStyle,
                    color: '#F2880C',
                    backgroundColor: '#FFF6EB',
                };
        }
    };

    const getContent = () => {
        if (status === 'Uploading' && progress !== undefined) {
            const progressInteger = Math.floor(progress);
            return (
                <>
                    {status} <span style={{ color: '#2B6BFD' }}>{progressInteger}%</span>
                </>
            );
        }
        return status;
    };

    return <span style={getChipStyle()}>{getContent()}</span>;
};

export default StatusChip;
