import {useContext, useEffect, useRef, useState} from "react";
import {LocalAudioTrack, LocalVideoTrack, RoomEvent} from "livekit-client";
import {FetchContext} from "../context/FetchContext";
import {SendEndOfStreamMarker, TimeKeeper, UploadSerializedChunks} from "../utils/utils";
import CustomMediaRecorder from "./MediaRecorder/CustomMediaRecorder";

const DATA_CHANNEL_CAPACITY = 10 * 10 * 1024 * 1024;

const timeKeeper = new TimeKeeper();

const MEDIA_RECORDER_TIME_SLICE_MS = 10000;

const pongs = [];

const SendTimeToServer = (room) => {
    const clientTime = performance.now();
    const buffer = new Float64Array([clientTime]);
    const uint8View = new Uint8Array(buffer.buffer);
    room.localParticipant.publishData(uint8View, {reliable: true, destinationIdentities: ['TimerAgent']});
    EnsureTracksPublishedAtLeastOnce(room);
}

const ParseTimerAgentData = (payload) => {
    const payloadString = new TextDecoder().decode(payload);
    const payloadObject = JSON.parse(payloadString);
    const clientTime = payloadObject.clientTimestamp;
    const serverTime = payloadObject.serverTimestamp;
    return {clientTime, serverTime};
}

let videoTrackPublished = false;
let audioTrackPublished = false;

// Publishing tracks at least once (even if disabled later) makes it possible to record them
// here and in livekit Egress. (cloud recording).
const EnsureTracksPublishedAtLeastOnce = (room) => {
    if (room.localParticipant.videoTrackPublications.size === 0 && !videoTrackPublished) {
        console.log("Resetting camera")
        videoTrackPublished = true;
        room.localParticipant.setCameraEnabled(true).then(() => {
            room.localParticipant.setCameraEnabled(false);
        });
    }

    if (room.localParticipant.audioTrackPublications.size === 0 && !audioTrackPublished) {
        console.log("Resetting microphone")
        audioTrackPublished = true;
        room.localParticipant.setMicrophoneEnabled(true).then(() => {
            room.localParticipant.setMicrophoneEnabled(false);
        });
    }
}

const TimerDataHandler = (payload, participant, kind) => {
    const {clientTime, serverTime} = ParseTimerAgentData(payload);
    const timeReceived = performance.now();
    pongs.push(timeReceived - clientTime);
    while (pongs.length > 5) {
        pongs.shift();
    }
    const latency = pongs.reduce((a, b) => a + b) / pongs.length;
    const remoteTime = serverTime + latency / 2;
    timeKeeper.setStartTime(remoteTime);
}

const RoomMetadataChangedHandler = (metadata, identity, trackIdRef) => {
    const payloadObject = JSON.parse(metadata);

    if (payloadObject.command === "RECORDING_STOPPED") {
        return;
    }

    const trackIds = payloadObject.trackIdsByIdentity;
    const trackId = trackIds[identity];

    console.log("Setting track id");
    trackIdRef.current = trackId;
}

const ConsumeData = async (videoData, consumeAll, fetchContext, livekitToken, trackIdRef, setLocalRecordingDone) => {
    if (trackIdRef.current === null) {
        console.log("Unable to consume data, trackId is null");
        return;
    }

    if (videoData.length > 0) {

        if (consumeAll) {
            console.log("Consuming all data");
        }

        let accumulatedChunks = [];
        let totalSize = 0;

        let sizeLimit = DATA_CHANNEL_CAPACITY;
        if (consumeAll) {
            sizeLimit *= 1000;
        }

        while (videoData.length > 0 && totalSize < sizeLimit) {
            const chunk = videoData[0];
            const chunkSize = chunk.size;

            if (totalSize + chunkSize > DATA_CHANNEL_CAPACITY) {
                break;
            }

            videoData.shift();  // Consider optimizing this operation if audioData is large
            accumulatedChunks.push(chunk);
            totalSize += chunkSize;
        }

        if (accumulatedChunks.length > 0) {
            const blob = new Blob(accumulatedChunks, {type: "video/x-matroska"});
            await UploadSerializedChunks({
                blob,
                fetchContext,
                livekitToken,
                trackId: trackIdRef.current,
                consumeAll,
                setLocalRecordingDone,
                SendEndOfStreamMarker,
                isVideo: true
            });
        }
    } else if (consumeAll) {
        await SendEndOfStreamMarker(fetchContext, trackIdRef.current, livekitToken, setLocalRecordingDone);
    }
};

const RecordVideo = ({room, localRecordingInProgress, livekitToken, setLocalRecordingDone, setLocalRecordingStopped, localRecordingDone, isHDQuality}) => {
    const [recorder, setRecorder] = useState(null);
    const videoDataRef = useRef([]);
    const sendTimeToServerIntervalRef = useRef(null);
    const [audioContext, setAudioContext] = useState(null);
    const [mixedDestination, setMixedDestination] = useState(null);
    const trackIdRef = useRef(null);
    const recordingStopped = useRef(false);
    const fetchContext = useContext(FetchContext);

    useEffect(() => {
        const newAudioContext = new AudioContext({ sampleRate: 48000 });
        const newMixedDestination = newAudioContext.createMediaStreamDestination();
        setAudioContext(newAudioContext);
        setMixedDestination(newMixedDestination);

        return () => {
            if (newAudioContext !== null && newAudioContext.state !== "closed") {
                newAudioContext.close();
            }
        };
    }, []);

    useEffect(() => {
        if (room === null || audioContext === null || mixedDestination === null) {
            return;
        }

        const ConnectTrack = async (trackPublication) => {
            if (trackPublication.track instanceof LocalAudioTrack) {
                const audioTrack = trackPublication.audioTrack;
                const sourceNode = audioContext.createMediaStreamSource(new MediaStream([audioTrack.mediaStreamTrack]));
                sourceNode.connect(mixedDestination);
            }
        };

        if (sendTimeToServerIntervalRef.current) {
            clearInterval(sendTimeToServerIntervalRef.current);
        }
        sendTimeToServerIntervalRef.current = setInterval(() => SendTimeToServer(room), 300);

        room.on(RoomEvent.DataReceived, (payload, participant, kind) => TimerDataHandler(payload, participant, kind));
        room.on(RoomEvent.RoomMetadataChanged, (metadata) => RoomMetadataChangedHandler(metadata, room.localParticipant.identity, trackIdRef));
        room.on(RoomEvent.LocalTrackPublished, ConnectTrack);
        room.on(RoomEvent.LocalTrackUnpublished, (trackPublication) => {
           console.log("Local track unpublished: ", trackPublication.track.kind)
        });

        const startLocalRecording = () => {
            const audioTrack = new LocalAudioTrack(mixedDestination.stream.getAudioTracks()[0]);

            let mediaStream = null;
            if (room.localParticipant.videoTrackPublications.size === 0) {
                mediaStream = new MediaStream([audioTrack.mediaStreamTrack]);
            } else {
                const firstKey = room.localParticipant.videoTrackPublications.keys().next().value;
                const videoTrack = room.localParticipant.videoTrackPublications.get(firstKey).videoTrack;
                mediaStream = new MediaStream([videoTrack.mediaStreamTrack, audioTrack.mediaStreamTrack]);
            }

            const mediaRecorder = new CustomMediaRecorder(mediaStream);

            mediaRecorder.ondataavailable = (event) => {
                videoDataRef.current.push(event.data);
                ConsumeData(videoDataRef.current, recordingStopped.current, fetchContext, livekitToken, trackIdRef, setLocalRecordingDone);
            };

            mediaRecorder.start(MEDIA_RECORDER_TIME_SLICE_MS, isHDQuality).then(() => {
                console.log("Media recorder started");
            });

            if (sendTimeToServerIntervalRef.current) {
                clearInterval(sendTimeToServerIntervalRef.current);
            }
            sendTimeToServerIntervalRef.current = setInterval(() => SendTimeToServer(room), 5000);

            const ReplaceVideoTrack = async (trackPublication) => {
                if (trackPublication.track instanceof LocalVideoTrack) {
                    const videoStreamTrack = trackPublication.videoTrack.mediaStreamTrack;
                    mediaRecorder.replaceVideoTrack(videoStreamTrack);
                }
            }

            room.on(RoomEvent.LocalTrackPublished, ReplaceVideoTrack);
            room.on(RoomEvent.TrackUnmuted, ReplaceVideoTrack);

            setRecorder(mediaRecorder);
        };


        if (localRecordingInProgress) {
            startLocalRecording();
        } else if (recorder) {
            recordingStopped.current = true;
            setLocalRecordingStopped(true);
            setTimeout(() => {
                if (localRecordingDone) {
                    return;
                }
                ConsumeData(videoDataRef.current, true, fetchContext, livekitToken, trackIdRef, setLocalRecordingDone).then(() => {
                });
            }, 5000);
            recorder.stop();
        }

        // Cleanup function when component unmounts or re-renders
        return () => {
            // Cleanup function to stop recording if the component unmounts while recording is in progress
            if (recorder) {
                recorder.stop();
            }
            clearInterval(sendTimeToServerIntervalRef.current);
            room.off(RoomEvent.DataReceived, TimerDataHandler);
            room.off(RoomEvent.LocalTrackPublished, ConnectTrack);
            room.off(RoomEvent.RoomMetadataChanged, RoomMetadataChangedHandler);
        };
    }, [localRecordingInProgress, room, audioContext, mixedDestination]);

    return null;
};

export default RecordVideo;
